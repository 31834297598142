<script>

export default {
  name: "accueilVue",
  data() {
    return {
      moneyFormat: new Intl.NumberFormat("de-DE"),
      spinner:false,
      currentTime:"",
      currentYear: new Date().getFullYear(),
   
    };
  },
  methods:{
     getAlarms(){
      this.$store.dispatch('getAllNew')
      this.$store.dispatch('getAllreservation')
      this.$store.dispatch('getAllVisites')
      this.$store.dispatch('getAllProprieteSold')
      this.$store.dispatch('getAllDemande')
     }
  },
  mounted(){ 
    setInterval(() => {
      this.currentTime = new Date().toLocaleTimeString()
    }, 10)
    this.getAlarms()
  },
};
</script>
<template>
  <div class="col-lg-9 col-md-12 bord">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 text-start p-3">
        <div
        class="form-submit position-relative"
      >
      <h1 class="msg_bienvenue text-center p-0 m-0">Bienvenue <br> chez</h1>
      <marquee-text>
        <h1 class="msg_bienvenue text-center p-0 m-0 w-100">DEXTER.</h1>
      </marquee-text>
      <span class="fw-bold">Bien plus que des logements.....</span>
      <h1 class="fw-bold d-block"> {{ currentTime }}</h1>
       <section class="text-center">
        <button class="btn-lg bg-dark text-light" @click="this.$router.push('/tableau_de_bord')">Mon tableau de bord</button>
       </section>
      </div>
      </div>
      
    </div>
  </div>
</template>
<style scoped>
.fw-bold{
  color:whitesmoke;
  letter-spacing: 0.1em;
}
.btn-lg{
  border-radius: 10px;
  font-weight:bold;
}
.msg_bienvenue{
    font-size:9vw;
    color:white;
    letter-spacing: 0.1em;
}
.texte-defilant {
    width: 100%;
    overflow: hidden;
  }
  
  .texte-defilant p {
    display: inline-block;
    animation: defilement 5s linear infinite;
  }
  
  @keyframes defilement {
    0% { 
        transform: translateX(0);
       
     }
    100% { transform: translateX(-300%);
     }
  }

</style>
