import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.css"
import { VueGoodTable } from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import "bootstrap-icons/font/bootstrap-icons.css";
import MazGallery from 'maz-ui/components/MazGallery'
import naive from 'naive-ui'
import VueNumberFormat from 'vue-number-format'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import VueChartkick from 'vue-chartkick'
import 'chartkick/chart.js'
import UploadMedias from "vue-upload-drop-medias"
import VueApexCharts from "vue3-apexcharts";
import VueSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import VueClickAway from "vue3-click-away";

// import Autocomplete from 'vue3-autocomplete'
// import 'vue3-autocomplete/dist/vue3-autocomplete.css'
import MarqueeText from 'vue-marquee-text-component'
import Vue3Autocounter from 'vue3-autocounter';
import { Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';


const app = createApp(App); 
app.use(VueClickAway)
app.component('VueGoodTable', VueGoodTable);
app.use(VueApexCharts);
app.component('MazGallery', MazGallery);

app.use(Antd)
app.component('vue3-autocounter', Vue3Autocounter)
app.component('VCalendar', Calendar)
app.component('VDatePicker', DatePicker)
app.component('marquee-text', MarqueeText)
app.component('UploadMedias', UploadMedias);
app.use(store);
app.use(naive)
app.use(VueNumberFormat)
app.use(router);
app.use(VueChartkick)
app.component('v-select', VueSelect)
// app.component('AutoComplete', Autocomplete)

app.mount('#app');

