<script>
import LienConnected from './dashboard/class_help_me'
import Swal from "sweetalert2";
export default {
  name: 'HomeView',
  data() {
    return {
        ToggleShowRebootInformationPersonnelle:false,
        email:"",
        password:"",
        emailModifPassword:"",
        dataObject:new LienConnected('password'),
        preloaderSpinner:false,
        hiddenEye:true,
    }
  },
  methods: {
    async reebotMyPassword(){
      try{
        this.preloaderSpinner = true
        let data = {
            email:this.emailModifPassword,
            // address:location.protocol+"//"+location.host+"/"
           }
        const reboot = await this.dataObject.sendMailForRebootPassword(data)
        const response = reboot
        //console.log("RESPONSE FOR PASSWORD",response)
        if(response.data.status === true){
          
          Swal.fire({
         icon: "success",
         title: response.data.message,
         showConfirmButton: true,
        
       });
       this.preloaderSpinner = false
} 
      }catch(error){
        this.preloaderSpinner = false

      }
           
  
    },
    registerUser(){
      let data = {
        email:this.email,
        password:this.password
      }
      this.$store.dispatch('ConnectUser',data)
    },
    passwordRecovery(){
      this.emailModifPassword = ""
    this.ToggleShowRebootInformationPersonnelle = !this.ToggleShowRebootInformationPersonnelle
    },
     togglePassword(idInput) {
   
      let password = document.getElementById(idInput);
      password.type != "text" ? password.setAttribute("type", "text"): password.setAttribute("type", "password");
      this.hiddenEye = !this.hiddenEye
    },
  },
}
</script>

<template>
  <div class="spinner" v-show="this.$store.state.spinner">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
   </div>
   <div class="conteneur_modify" id="modalModify" v-show="ToggleShowRebootInformationPersonnelle">
        
    <div class="content position-relative" >
      
        <div class="ecriteau text-start">
            <h4 class="text-center">Réinitialisation du mot de passe</h4>
           
        </div>
        <span class="close_modal closeWindow" @click.prevent="ToggleShowRebootInformationPersonnelle = !ToggleShowRebootInformationPersonnelle">fermer</span>
        <div class="px-5 mt-2">
            <div class="submit-section">
                <div class="row text-center ">
              <h5>Entrer votre email, et nous vous enverrons <br> un lien de réinitialisation.</h5>
                    <div class="form-group text-start">
            
                      <div class="input-with-icon">
                          <input type="email" autocomplete="yes" class="form-control" placeholder="Email" v-model="emailModifPassword" required>
                          <i class="ti-user"></i>
                      </div>
                  </div>
                </div>
            </div>
            <div class="text-start" v-if="emailModifPassword">
                <button  class="btn bg-dark text-light"
                 @click.prevent="reebotMyPassword">
                  Envoyer                            
                  <span class="spinner-border spinner-border-sm ms-2" v-show="preloaderSpinner"
                    role="status" aria-hidden="true"></span></button>
            </div>
          </div>

    </div>
    </div>
    <div>
      <div class="row acceuil align-items-stretch bg-img-logo">
        <div class="col-md-6 bg-connecter mx-auto col-sm-12 pt-5">
          
          <form @submit.prevent="registerUser">
            <h1 class="modal-header-title text-light">Connexion</h1>
           <div class="login-form">
             <div>
                 <div class="form-group text-start">
         
                     <div class="input-with-icon">
                         <input type="email" class="form-control" placeholder="Email" v-model="email" required>
                         <i class="ti-user"></i>
                     </div>
                 </div>
                 
                 <div class="form-group text-start position-relative">
                  <i class="bi bi-eye-slash position-absolute" @click="togglePassword('password')" v-show="hiddenEye"></i>
                  <i class="bi bi-eye position-absolute" @click="togglePassword('password')" v-show="!hiddenEye"></i>
                     <div class="input-with-icon">
                               
                         <input type="password" class="form-control" id="password" placeholder="Mot de passe" v-model="password" required>
                         <i class="ti-unlock"></i>
                        
                     </div>
                 </div>

                 <div class="form-group">
                     <button  type="submit" class="btn btn-md full-width btn-theme-light-2 rounded w-25">Se connecter</button>
                 </div>
                 <p class="text-warning"><a href="#" class="password_forget" 
                  @click.prevent="passwordRecovery">Mot de passe oublié ?</a></p>
             
               </div>
         </div>
          </form>
        </div>
      

      </div>
    </div>
 
</template>
<style scoped>
form{
  padding:3em;
  border-radius:10px;
}
.form-group{
  margin: 3em 0;
}
.img-logo_sua{
  top:41em;
  left:12em;
  border-radius: 10px;
}
.pt-5{
  padding-top:8em !important;
}

.input-with-icon input{
  width:100%;
}
.bi-eye-slash,.bi-eye{
  top: 25%;
  right: 1em;
  z-index: 12;
  font-size: 1.5em;
  cursor:pointer;
}
.bg-img-logo{
  background-image: linear-gradient(rgba(110, 107, 107, 0), rgba(67, 67, 67, 0)),url('./dashboard/pages/assets/Pinterest.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
 background-color:rgb(26 22 19)
}
.bg-connecter{
  border-radius:3em;
}
.acceuil{
  height:100vh;
}
.btn-theme-light-2,.btn-theme-light-2:hover{
  background-color:black !important;
  width:300px !important;
  color:white  !important;
  font-weight:bold;
  border:3px solid rgb(255, 174, 0);
}
.password_forget{
  color:rgb(255, 174, 0) !important;
  text-decoration: underline;
  font-size: 1.2em;
  font-weight:bold !important;
}
.spinner{
  position:fixed;
  width:100%;
  height:100%;
  top:0;
  left:0;
  display: flex;
  justify-content: center;
  place-items: center;
  background-color:rgba(0, 0, 0, 0.221);
  z-index: 99999;
}
.closeWindow{
  cursor:pointer;
}


.confirmation_modifie{
    position: absolute;
    z-index: 9999;
    background-color:white;
    padding:2em;
    border:3px solid black;
    border-radius: 1em;
  }
  .conteneur_modify{
    position:fixed;
    width:100%;
    height:100%;
    top:0;
    left:0;
    z-index:999;
    background-color:rgba(0, 0, 0, 0.255);
    display:flex;
    justify-content: center;
    place-items: center;
  }
  .conteneur_modify .content {
    width:80%;
    height:auto;
    padding-bottom:1em;
    overflow-y: auto;
    overflow-x: hidden;
    background: white;
    border-radius: 1em;
    box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
  }
  .close_modal{
    position:absolute;
    top:1em;
    right:1em;
  }
  .ecriteau{
    position:sticky;
    top:0;
    left:0;
    width:100%;
    padding:1em;
    background-color:white;
    box-shadow:1px 1px 1px 2px rgba(0, 0, 0, 0.129);
  }
  .submit-pages{
    padding:1em 3em;
  }
  @media screen and (max-width: 680px) {
    .pt-5{
      padding-top:0 !important;
    }
    .img-display{
      display: none;
    }
    .login-form{
      padding:0 1em;
    }
     .btn-theme-light-2{
      width:100% !important;
     }
  }
</style>

