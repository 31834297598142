<script>
import Swal from "sweetalert2";
import LienConnected from '../class_help_me'
export default {
  name: "profilView",
  data() {
    return {
      showConfirmationForModify: false,
      user: this.$store.state.user,
      ToggleShowRebootInformationPersonnelle: false,
      oldpassword: "",
      password: "",
      newPassword: "",
      ConfirmPassword: "",
      last_name: "",
      first_name: "",
    
      email: "",
      phone: "",
      spinner:false,
      CnewPassword:"",
      showPreload:false,
      windowChangePhotoProfil:false,
      proofPieces:"",
      active:false,
      dataObject:new LienConnected('users',this.$store.state.token),
      cniDoc:[],
      lienUrl:new LienConnected().baseUrl,
      otherPicture:[],
    };
  },
  methods: {
    changePhotoProfil(){
this.windowChangePhotoProfil = !this.windowChangePhotoProfil
    },
  async modifyProfil(info=false){
    console.log("modifier")
    this.showPreload = true
    console.log("dataUser",this.user)
    if(info === false){
       try{
 let dataUser = new FormData();
        dataUser.append("lastname", this.user.lastname);
        dataUser.append("firstname", this.user.firstname);
        dataUser.append("email", this.user.email);
        dataUser.append("phone", this.user.phone);
        this.otherPicture.forEach((doc) => {
          dataUser.append("cni_doc[]", doc);
        });

this.$store.dispatch('ModifyUser',dataUser)
this.showPreload = false
 }catch(error){
   //console.log(error)
   this.showPreload = false
 }
     }
     if(info === true){
      try{
        let dataWithPassword ={
        email: this.user.email,
        oldPassword:this.password,
        password:this.newPassword
      }
      const password = new LienConnected('modifyPassword',this.$store.state.token)
     const modifyPassword = await password.sendData(dataWithPassword)
     const response = modifyPassword
     //console.log("RESPONSE FOR MODIFY PASSWORD",response)
     if(response.data.status === true){
               Swal.fire({
              icon: "success",
              title: "Mot de passe modifié",
              showConfirmButton: true,
         
            });
            this.password = ""
            this.newPassword = ""
            
            this.CnewPassword = ""
            this.showPreload = false
     }
      }catch(error){
        //console.log(error)
        this.showPreload = false
      }

     } 
    },
    seePhoto(){
     let photo = this.user.cni_doc ? JSON.parse(this.user.cni_doc) : []
       photo.forEach(el=>{
        this.cniDoc.push(this.lienUrl.replace("/api/", "/")+'public/public/image/'+el)
      })
    },
    handleOtherPicture(e){
    this.otherPicture = e.target.files;
    console.log("this.otherPicture",this.otherPicture)
    }
  },
  mounted(){

 this.seePhoto()
  }
};
</script>
<template>
  <div class="col-lg-9 col-md-12">

    <div class="dashboard-wraper">
   
      <div class="form-submit">
        <div class="d-flex justify-content-start align-items-center">
       
        </div>
        <section class="p-5 mt-3 modify">
          <div class="ecriteau text-start mb-5">
            <h4>Mon profil</h4>
            <span class="text-secondary fw-bold">Gérez les paramètres de votre profil</span>
          </div>
          <form @submit.prevent="modifyProfil(active)">
            <div class="submit-section" v-if="user">
              <div class="row text-start">
                <div class="form-group col-md-6">
                  <label class="text-dark">Nom</label>
                  <input
                    type="text"
                    class="form-control "
                    disabled
                    v-model="user.lastname"
                   
                  />
                </div>
                <div class="form-group col-md-6">
                  <label class="text-dark">Prénoms</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    v-model="user.firstname" 
                  />
                </div>
                

                <div class="form-group col-md-6">
                  <label class="text-dark">Téléphone</label>
                  <input
                    type="text"
                    class="form-control"
                    pattern="[0-9]{10}"
                    v-model="user.phone"
                  />
                  <span v-if="user.phone > 10 && !user.phone.match(/^[0-9]{10}$/)" class="text-danger">Veuillez rentrer 10 chiffres</span>
                </div>
                <div class="form-group col-md-6">
                  <label class="text-dark">Email</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="user.email" 
                  />
                </div>
                <div class="form-group col-md-6">
                  <label class="text-dark">Pièce d'identité</label>
                 <div v-if="cniDoc.length">
                  <MazGallery :images="cniDoc" :height="200" /> 
                 </div>
                 <div v-else>
                  <h5 class="text-dark">Pas de pièce d'identité</h5> 
                 </div>
                 <div>
                  <input type="file" name="" id="" @change="handleOtherPicture">
                 </div>
              
                </div>
                <div class="form-group col-md-6">
                </div>
                <div class="mb-3">
                  <n-space>
                    <n-switch v-model:value="active" @update="active = !active" />
                    <span class="text-dark fw-bold">Modifier mon mot de passe</span>
                  </n-space>
                </div>
               
                <div class="form-group col-md-6" v-if="active">
                  <label class="text-dark">Ancien mot de passe</label>
                  <a-input-password  class="form-control pass" v-model:value="password" placeholder="******" />
                  
                </div>
                <div class="form-group col-md-6">
                </div>
             
                <div class="form-group col-md-6" v-if="active">
                  <label class="text-dark">Nouveau mot de passe</label>
                  <a-input-password  class="form-control pass" v-model:value="newPassword" placeholder="******" />
                </div>
                <div class="form-group col-md-6">
                </div>
                <div class="form-group col-md-6" v-if="active">
                  <label class="text-dark">Confirmation du nouveau mot de passe</label>
                  <a-input-password  class="form-control pass" v-model:value="CnewPassword" placeholder="******" />
                  <span v-if="newPassword != CnewPassword && CnewPassword" class="text-danger">Mot de passe incorrect</span>
                </div>
               
              </div>
            </div>
            <div class="text-start">
              <button type="submit" class="btn bg-dark text-light text-white">
                Modifier
                <span
                class="spinner-border spinner-border-sm ms-2"
                v-show="showPreload" role="status"
                aria-hidden="true"
              ></span>
              </button>
            </div>
          </form>

        </section>
       
      
      </div>
    </div>
  </div>
</template>
<style scoped>
.close{
  top: 0;
  right:.5em;
  cursor:pointer;

}
.bi-x-circle-fill{
  font-size:2em;
}
.confirmation_modifie {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.my_photo_profil{
  left:0;
  right:0;
  top:0;
  width:100%;
  height:100%;
  z-index: 1;
  border-radius: 10%;
}
.confirmation_modifie .card{
  width:500px;
  padding:1em 0;
}
.take-picture{
  width:50px;
  height:50px;
  text-align:center;
  line-height:45px;
  bottom:-5px;
  right:-10px;
  border: 3px solid white;
  background-color:rgb(185, 151, 0);
  border-radius: 100%;
  cursor: pointer;
  z-index: 3;
}
.take-picture i{
  font-size:1.9em;
  font-weight:bold;
}
.modify{
  border-radius:10px;
  background-color:rgb(245, 245, 245);
  box-shadow:1px 1px 6px rgba(0, 0, 0, 0.273) inset;

}
.carte .profil{
  height:150px;
  width:150px;
  border:1px solid rgb(164, 124, 75);
  border-radius:10%;
  margin:1em 0;
  text-align:center;
}
.carte .profil .bi-person-fill{
  font-size:10em;
}
.carte strong{
  margin-left:1em;
}
.carte img{
  position:absolute;
  right: 0;
  top:0;
}
.carte{
  height:auto;
  position:relative;
  width:400px;
  padding:4em 2em;
  border: 5px solid rgb(185, 151, 0);
  background: white;
  border-radius: 10px;
  margin-left:50%;
  transform:translateX(-50%);
  text-align:left;
}



.closeWindow {
  cursor: pointer;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.313);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 80%;
  height: auto;
  padding: 3em;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 0 1px 3px rgba(0, 0, 0, 0.128);
}
.close_modal {
  position: absolute;
  top: 1em;
  right: 1em;
}
.pass{
  border-radius:10px;
}
.text-dark{
  color:black !important;
}
</style>
