<script>
import { NavBar } from "../../components";
export default {
  name: "AccueilView",
  components: {
    NavBar,
  },
  data() {
    return {
      visisble: false,
    };
  },
  methods: {
    openFilterSearch() {
      this.$store.commit("toogleMenu");
      //console.log("toogleMenu",this.$store.state.showMenu)
    },
  },
};
</script>
<template>
  <div class="px-2 row h-100 text-center ">
    <div class="row sub_menu filter">
      <div class="col-lg-12 col-md-12 ">
        <div class="filter_search_opt">
          
          <a href="#" @click.prevent="openFilterSearch" 
           v-if="!this.$store.state.showMenu" >
           <i class="bi bi-list fw-bold h5 ti-menu"></i>
          </a>
        </div>
      </div>
    </div>
    <NavBar></NavBar>
    <router-view />
  </div>
</template>
<style scoped>
.ti-menu{
  margin-right:50%;
  transform: translateX(50%);
}
.sub_menu{
  position:absolute;
  z-index: 30;
}
@media screen and (min-width:990px) {
  .sub_menu{
    display: none;
    }
}
.h-100{
  height:100vh !important;
  align-content: flex-start;
}


</style>
