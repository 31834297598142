<script>
import $ from "jquery";
import Swal from "sweetalert2";
import Vue3BasicAutocomplete from "vue3-basic-autocomplete";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import printJS from "print-js";

import axios from "axios";
const baseUrl = "http://backend-dexter.invest-ci.com/api/";
export default {
  name: "liste-Client",
  components: {
    Vue3BasicAutocomplete,
  },
  data() {
    return {
      user: this.$store.state.user,
      windowRenderInClient: false,
      showWindowDelete: false,
      idclient: "",
      imgProspect: [],
      tab: "Liste des clients",
      nom_client: "",
      prenoms_client: "",
      phoneOneclient: "",
      phoneTwoclient: "",
      emailclient: "",
      dateBirthclient: "",
      profession: "",
      societeclient: "",
      nationaliteclient: "",
      Lieuclient: "",
      adressePostaleclient: "",
      situationMatrimonialeclient: "",
      paysclient: "",
      Banqueclient: "",
      pieceIdentite: "",
      otherPiece: "",
      numeroPiece: "",
      seeFiche: false,
      msgEmailErr: false,
      msgPhoneErr: false,
      moneyFormat: new Intl.NumberFormat("de-DE"),
      showDetailclientForModify: false,
      preload: false,
      preloader: false,
      choiceTypeBienImmobilier: "",
      choiceIlot: "",
      choiceProduit: "",
      choiceLot: "",
      choiceProgramme: "",
      showDetailClient: false,
      confirmapportInitial: "",
      showContrat: false,
      ListesClient:[],
      ListesClientDesiste:[],
      confirmApportInitial:"",
      ApportInitial:"",
      profil: "",
      detailClient:{},
      detailAgentCommercial:{},
      preloadeRejet:false,
      fields: [
        {
          amount: "",
          date: "",
        },
      ],
      listSouscription:[],
      totalModalitePayment:"",
      cni_doc: "",
      name_souscription: "",
      modePaiement_souscription: "",
      produit_souscription: "",
      programme_souscription: "",
      numeroPiece_souscription: "",
      otherPiece_souscription: "",
      pieceIdentite_souscription: "",
      Banque_souscription: "",
      pays_souscription: "",
      situationMatrimoniale_souscription: "",
      adresse_souscription: "",
      Lieu_souscription: "",
      nationalite_souscription: "",
      societe_souscription: "",
      profession_souscription: "",
      birthday_souscription: "",
      email_souscription: "",
      contact2_souscription: "",
      contact1_souscription: "",
      prenoms_souscription: "",
      created_by:"",
      listProduit:[],
      remise_souscription:"",
      dateEcheance:"",
      PrixSouscription:"",
      cashVerse:"",
      preloaderValider:false,
      chooseProduitProject :"", 
          chooseProduitPlace :"",
          chooseProduitIlot :"", 
          chooseProduitType_housing:"",
          codeProspect:"",
          chooseProgramme:"",
          commentRefuseProduit:"",
          showCommentRefuseProduit:false,
    };
  },
  methods: {
    onClickAwayDelete(){
      this.showCommentRefuseProduit = !this.showCommentRefuseProduit
    },
    seeCommentForRefuse(id){
      this.showCommentRefuseProduit = !this.showCommentRefuseProduit
      this.listSouscription.forEach((element)=>{
      if(element.id == id){
        this.commentRefuseProduit = element.pivot.comment;
      }

      })
      //console.log("this.comment",this.comment);
    },
    getlisteClientdesiste(){
      this.preloader = true;
       axios
        .get(
          baseUrl + "clients-revok"
          ,{headers: {
            Authorization: "Bearer " + this.$store.state.token,
          }},
        )
        .then((response) => {
          this.ListesClientDesiste = response.data.data
          let printCounter = 0;
          //console.log("this.ListesClientDesiste", response.data);
          setTimeout(function () {
            $("#MyTableData1").DataTable({
              pagingType: "full_numbers",
              pageLength: 10,
              processing: true,
              dom: "Bfrtip",
              buttons: [
        {
            extend: 'copy',
            text: 'Copier les données',
            messageTop: function () {
                    printCounter++;
 
                    if ( printCounter === 1 ) {
                      Swal.fire({
              icon: "success",
              title: 'Les données ont été copiées dans le presse-papiers.',
              showConfirmButton: false,
              timer: 1500,
            });
                     
                    }
                    else {
                      Swal.fire({
              icon: "info",
              title: 'Les données ont déjà été copiées dans le presse-papiers.',
              showConfirmButton: true,
            });
                    }
                }
        },
        {
          extend: 'csv',
  text: 'Exporter en fichier excel',
  fieldSeparator: ';',
  fieldBoundary: '"',
  bom: true,
  exportOptions: {
    columns: [0,1,2,3,4]
  }
        },
        {
            extend: 'print',
            text: 'Imprimer les données' 
        }
    ],
              order: [],
              language: {
                décimal: "",
                emptyTable: "Aucune donnée disponible dans le tableau",
                infoEmpty: "Showing 0 to 0 of 0 entries",
                info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "Afficher les entrées du _MENU_",
                loadingRecords: "Loading...",
                processing: "Processing...",
                search: "Chercher :",
                stateSave: true,
                zeroRecords: "Aucun enregistrement correspondant trouvé",
                paginate: {
                  first: "Premier",
                  last: "Dernier",
                  next: "Suivant",
                  previous: "Précédent",
                },
                aria: {
                  sortAscending: ": activate to sort column ascending",
                  sortDescending: ": activate to sort column descending",
                },
              },
            });
          }, 10);
          this.preloader = false;
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
              icon: "error",
              title: err.response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
        });
    },
    printDocu() {
      const style = `@media print { 
      *{
        margin:0;
        padding:0;
      }
.section{
  display:flex;
  justify-content: space-between;
  align-items:center;
  padding:1em 0;
}
.section span,span{
font-weight:bold;
}
.section span,
p span {
  font-weight: bold;
  text-decoration:underline;
}

.date{
  text-align:right !important;
}
h4{
  text-align:center;
}
.printDoc{
  width:100%;
  padding:0 1em;
}



.conteneur_modify .content{
  width:100%;
}

.d-flex{
  text-align:center;
  display: flex;
  justify-content:between;
  align-items:center;
}
h5{
  background-color:red;
  color:black;
}

.text-center{
    text-align: center;
}
p{
  width:100%;
  text-align:left;
  padding:.2em 1em;
}
        } `;
      printJS({
        printable: "printjs_doc",
        type: "html",
        style: style,
      });
      this.allocation_number = ""
      this.seeFiche = !this.seeFiche;
    },
    closeFiche() {
      this.seeFiche = !this.seeFiche;
    },
    onClickAwaySeeFiche(event){
      console.log(event)
  this.seeFiche = !this.seeFiche
    },
    selectedProduit(e) {
      this.cashVerse = "";
      //console.log("SELECTED", e);
      this.listProduit.forEach((item) => {
        if (item.label == e.target.value) {
          this.produit_souscription = item.id
          this.cashVerse = item.cost;
          this.PrixSouscription = item.cost;
          this.chooseProduit = item;
          this.chooseProduitProject = item.project;
          this.apportInitialForProduct = item.initial_contribution;
          this.chooseProduitPlace = item.place;
          this.chooseProduitIlot = item.ilot;
          this.chooseProduitType_housing = item.type_housing ? item.type_housing.label : item.type.label
        }
        //console.log("this.PrixSouscription", this.PrixSouscription);
        //console.log("this.chooseProduitType_housing",this.chooseProduitType_housing)
      });
    },
    onClickAway(event) {
      console.log(event.isTrusted)
      this.showDetailclientForModify = !this.showDetailclientForModify;
    },
   
    checkForDuplicates(array) {
  let dates = {};
  for (let i = 0; i < array.length; i++) {
    if (dates[array[i].date]) {
      // Doublon trouvé
      return true;
    }
    dates[array[i].date] = true;
  }
  // Aucun doublon trouvé
  return false;
},
    seeTotalModalitePaymentInTime(e){
      console.log("e",e.target.value)
      this.totalModalitePayment = 
      this.fields.reduce(
      (accumulator, currentValue) => accumulator + currentValue.amount,
       0,
      );
    },
    addField: function () {
      this.fields.push({ amount: "", date: "" });
      //console.log(this.fields);
    },
    deleteField: function (element) {
      const DeleteField = this.fields.findIndex((item) => item === element);
      this.fields.splice(DeleteField, 1);
    },
    listeProduit() {
      axios
        .get(baseUrl + "products", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          //console.log("responseProduit", response.data.data);
          this.listProduit = response.data.data;
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
              icon: "error",
              title: err.response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
        });
    },
    seeProfil() {
      this.user.roles.forEach((item) => {
        this.profil = item.name;
      });
      //console.log("this.profil", this.profil);
    },
    Saveclient() {
      this.$router.push("/client");
    },
  
   

 
   
   ListeClient() {
      this.preloader = true;
       axios
        .get(
          baseUrl + "clients"
          ,{headers: {
            Authorization: "Bearer " + this.$store.state.token,
          }},
        )
        .then((response) => {
          this.ListesClient = response.data.data
          let printCounter = 0;
          //console.log("Liste", response.data);
          setTimeout(function () {
            $("#MyTableData").DataTable({
              pagingType: "full_numbers",
              pageLength: 10,
              processing: true,
              dom: "Bfrtip",
              buttons: [
        {
            extend: 'copy',
            text: 'Copier les données',
            messageTop: function () {
                    printCounter++;
 
                    if ( printCounter === 1 ) {
                      Swal.fire({
              icon: "success",
              title: 'Les données ont été copiées dans le presse-papiers.',
              showConfirmButton: false,
              timer: 1500,
            });
                     
                    }
                    else {
                      Swal.fire({
              icon: "info",
              title: 'Les données ont déjà été copiées dans le presse-papiers.',
              showConfirmButton: true,
            });
                    }
                } 
        },
        {
          extend: 'csv',
  text: 'Exporter en fichier excel',
  fieldSeparator: ';',
  fieldBoundary: '"',
  bom: true,
  exportOptions: {
    columns: [0,1,2,3,4]
  }
        },
        {
            extend: 'print',
            text: 'Imprimer les données' 
        }
    ],
              order: [],
              language: {
                décimal: "",
                emptyTable: "Aucune donnée disponible dans le tableau",
                infoEmpty: "Showing 0 to 0 of 0 entries",
                info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "Afficher les entrées du _MENU_",
                loadingRecords: "Loading...",
                processing: "Processing...",
                search: "Chercher :",
                stateSave: true,
                zeroRecords: "Aucun enregistrement correspondant trouvé",
                paginate: {
                  first: "Premier",
                  last: "Dernier",
                  next: "Suivant",
                  previous: "Précédent",
                },
                aria: {
                  sortAscending: ": activate to sort column ascending",
                  sortDescending: ": activate to sort column descending",
                },
              },
            });
          }, 10);
          this.preloader = false;
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
              icon: "error",
              title: err.response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
        });
    },
 

 

  
    viewDetailsClient(id) {
      let image = "";
      this.imgProspect = [];
        this.ListesClient.forEach((item) => {
          if (item.id === id) {
            this.detailClient = item;
            image = item.cni_doc;
            this.detailAgentCommercial = item.created_by
            this.listSouscription = item.subscriptions
          }
        });
        this.imageTableauAtFiliter = image.length ? image.split(";").forEach((item) => {
        if (item != "") {
          this.imgProspect.push(item);
        }
      }) : null;
      this.showDetailclientForModify = !this.showDetailclientForModify;
    },
    closeWindowDetailConteneurForModify() {
     
      this.showDetailclientForModify = !this.showDetailclientForModify;
    },

    backWindow() {
      this.cashVerse = "";
      this.dateEcheance = "";
      this.bank_card = "";
      this.idUserSouscription = "";
      this.programme_souscription = "";
      this.bank_card = "";
      this.remise_souscription = "";
      this.modePaiement_souscription = "";
      this.produit_souscription = "";
      this.apportInitial="";
      this.windowRenderInClient = !this.windowRenderInClient;
    },
    async newSubscription() {
      let data = {
        type_payment: this.modePaiement_souscription,
        cost: this.cashVerse,
        delay_date: this.dateEcheance,
        versements: this.fields,
        product_id: this.produit_souscription,
        prospect_id: this.idUserSouscription,
      };
      //console.log("DATA", data);
      if(this.totalModalitePayment != this.PrixSouscription){
      Swal.fire({
              icon: "info",
              title: "le total est différent du prix de souscription.",
              showConfirmButton: true,
            
            }); 
      }else{
        //console.log('OK')
        const hasDuplicates = this.checkForDuplicates(this.fields) 
        if(hasDuplicates){
      Swal.fire({
              icon: "info",
              title: "Il y a des dates qui se ressemblent.",
              showConfirmButton: true,
              
            });
        }else{
          this.preloaderValider = true;
        await axios
        .post(baseUrl + "subscriptions", data, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          //console.log("Reponse", response.data);

          if (response.data.status === true) {
            //console.log("Reponse", response.data);
            this.allocation_number = response.data.data.allocation_number
            Swal.fire({
              icon: "success",
              title: "la souscription a été prise en compte.",
              // text: `Numéro de souscription : ${response.data.data.allocation_number}`,
              showConfirmButton: false,
              timer:2000,
            });
           
            this.preloaderValider = false;
            setTimeout(() => {
              this.seeFiche = !this.seeFiche;
              this.windowRenderInClient = !this.windowRenderInClient;
            }, 1500);
          }
          if (response.data.status === false) {
            //console.log("ReponseModify", response.data);
            const monObjet = response.data.message
            let tableau = []
            for (let propriete in monObjet) {
  if (Array.isArray(monObjet[propriete])) {
     tableau = monObjet[propriete];
    //console.log(tableau);
  }else{
    tableau.push(response.data.message)
  }
}
            Swal.fire({
              icon: "error",
              title: `${tableau.toString()}`, 
              showConfirmButton: false,
              timer: 1500,
            });
            this.preloaderValider = false;
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
              icon: "error",
              title: err.response.data.message,
              showConfirmButton: true,
             
            });
          this.preloaderValider = false;
        });
        }
 
 
      }
     
    },
   
    seeWindowForNewSubscription(id) {
      this.ListesClient.forEach((item) => {
        if (item.id == id) {
          //console.log(item);
          this.client = item;
          this.name_souscription = item.last_name;
          this.numeroPiece_souscription = item.cni_number;

          this.pieceIdentite_souscription =
            item.id_card == "CNI" ? "CNI" : item.id_card == "passeport" ? "passeport": item.id_card == "autre" ? "autre":null;
          this.otherPiece_souscription =
            this.pieceIdentite_souscription == "autre" ? item.id_card : null;
          this.Banque_souscription = item.bank;
          this.pays_souscription = item.country;
          this.situationMatrimoniale_souscription = item.marital_status;
          this.adresse_souscription = item.zipcode;
          this.Lieu_souscription = item.address;
          this.nationalite_souscription = item.nationality;
          this.societe_souscription = item.employer;
          this.profession_souscription = item.job;
          this.birthday_souscription = item.birthday;
          this.email_souscription = item.email;
          this.contact2_souscription = item.phone2;
          this.contact1_souscription = item.phone1;
          this.prenoms_souscription = item.first_name;
          this.created_by = item.created_by;
        }
      });
      this.idUserSouscription = id;
      this.windowRenderInClient = !this.windowRenderInClient;
    },
    wantDeleteclient() {
      this.showWindowDelete = !this.showWindowDelete;
    },
    closeWindowDetailclient() {
      this.showDetailClient = !this.showDetailClient;
    },
    windowClientDetail() {
      this.showDetailClient = !this.showDetailClient;
    },
    deleteclient() {
      this.showWindowDelete = !this.showWindowDelete;
    },
    returnDetailClient() {
      this.showDetailClient = !this.showDetailClient;
    },
    closeContrat() {
      this.showContrat = !this.showContrat;
    },
    windowContrat() {
      this.showContrat = !this.showContrat;
    },
  },
  created() {
    this.listeProduit();
     this.ListeClient();
     this.getlisteClientdesiste();
     this.seeProfil();
  },
};
</script>
<template>
  <div id="preloader" v-show="preload">
    <div class="preloader"><span></span><span></span></div>
  </div>
  <div class="confirmation_modifie_comment" v-show="showCommentRefuseProduit" @click="onClickAwayDelete">
    <div class="card position-relative">
      <!-- <i class="bi bi-x-circle-fill cancel_comment"></i> -->
      <h4 class="text-center badge bg-secondary">Commentaire</h4>
      <div>
        <p>{{commentRefuseProduit}}</p>
      </div>
    </div>
  </div>
  <div class="confirmation_modifie" v-show="showWindowDelete">
    <div class="card">
      <h6 class="text-center">Voulez-vous supprimer ce client ?</h6>
      <div class="d-flex justify-content-center align-items-center">
        <div>
          <button
            class="btn bg-dark text-light mx-2 text-white"
            @click.prevent="deleteclient"
          >
            Oui
          </button>
          <button
            class="btn bg-danger mx-2 text-white"
            @click.prevent="wantDeleteclient"
          >
            Retour
          </button>
        </div>
      </div>
    </div>
  </div>


  <div class="conteneur_modify" v-show="windowRenderInClient">
    <div class="content position-relative">
      <div
        class="ecriteau text-start d-flex justify-content-between align-items-center"
      >
        <h4>Souscription</h4>
        <i class="bi bi-x-circle h1" @click.prevent="backWindow"></i>
      </div>
      <div class="col-lg-12 col-md-12">
        <form @submit.prevent="newSubscription">
          <div class="submit-pages text-start">
            <!-- Basic Information -->
            <div class="form-submit">
              <div class="submit-section">
                <div class="row">
                  <div class="form-group col-md-6">
                    <label class="text-dark">Nom</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="name_souscription"
                      required
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="text-dark">Prénoms</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="prenoms_souscription"
                      required
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label class="text-dark">Contact 1</label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="contact1_souscription"
                      required
                      disabled
                    />
                    <p class="text-danger p-0 m-0" v-if="msgPhoneErr">
                      Numéro de téléphone non valide(10 chiffre)
                    </p>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="text-dark">Contact 2</label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="contact2_souscription"
                      required
                      disabled
                    />
                    <p class="text-danger p-0 m-0" v-if="msgPhoneErr2">
                      Numéro de téléphone non valide(10 chiffre)
                    </p>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="text-dark">Email</label>

                    <input
                      type="email"
                      class="form-control"
                      v-model="email_souscription"
                      disabled
                    />
                    <p class="text-danger p-0 m-0" v-if="msgEmailErr">
                      Adresse email non valide
                    </p>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="text-dark">Date de naissance</label>
                    <input
                      type="date"
                      class="form-control"
                      v-model="birthday_souscription"
                      required
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="text-dark">Profession</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="profession_souscription"
                      required
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label class="text-dark"> Société </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="societe_souscription"
                      required
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="text-dark">Nationalité </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="nationalite_souscription"
                      required
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="text-dark">Adresse Géographique</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="Lieu_souscription"
                      required
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="text-dark">Adresse Postale </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="adresse_souscription"
                      required
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="text-dark">Situation matrimoniale</label>
                    <div class="row">
                      <select
                      disabled
                        name=""
                        id=""
                        class="form-control"
                        v-model="situationMatrimoniale_souscription"
                      >
                        <option value="" disabled>
                          situation matrimoniale
                        </option>
                        <option value="marié(e)">marié(e)</option>
                        <option value="celibataire">celibataire</option>
                        <option value="divorcé(e)">divorcé(e)</option>
                        <option value="veuf(ve)">veuf(ve)</option>
                      </select>
                    </div>
                  </div>
                  <!-- <div class="form-group col-md-6">
                    <label class="text-dark">Situation matrimoniale</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="situationMatrimoniale_souscription"
                      required
                      disabled
                    />
                  </div> -->
                  <div class="form-group col-md-6">
                    <label class="text-dark">Pays</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="pays_souscription"
                      required
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="text-dark">Banque de domiciliation</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="Banque_souscription"
                      required
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="text-dark">Pièce d’identité</label>
                    <div class="row container">
                      <section class="col-md-12 circuit">
                        <div
                          class="col-md-12 d-flex align-items-center my-2"
                        >
                          <input
                            type="radio"
                            id="CNI"
                            name="fav_language"
                            value="CNI"
                            required
                            v-model="pieceIdentite_souscription"
                            disabled
                          />
                          <label class="text-dark" for="CNI">CNI</label>
                        </div>

                        <div
                          class="col-md-12 d-flex align-items-center my-2"
                        >
                          <input
                            type="radio"
                            id="passeport"
                            name="fav_language"
                            value="passeport"
                            required
                            v-model="pieceIdentite_souscription"
                            disabled
                          />
                          <label class="text-dark" for="passeport">Passeport</label>
                        </div>

                        <div
                          class="col-md-12 d-flex align-items-center my-2"
                        >
                          <input
                            type="radio"
                            id="autre"
                            name="fav_language"
                            value="autre"
                            required
                            v-model="pieceIdentite_souscription"
                            disabled
                          />
                          <label class="text-dark" for="autre">Autre </label>
                          <input
                            v-if="
                              pieceIdentite_souscription != 'CNI' ||
                              pieceIdentite_souscription != 'passeport'
                            "
                            class="form-control mx-3"
                            type="text"
                            v-model="otherPiece_souscription"
                            disabled
                          />
                        </div>
                        <div class="col-md-12 my-2" v-if="
                        pieceIdentite_souscription == 'CNI' ||
                        pieceIdentite_souscription == 'passeport'
                      ">
                          <label class="text-dark">Numéro de pièce</label>
                          <input
                            class="form-control"
                            type="text"
                            v-model="numeroPiece_souscription"
                            disabled
                          />
                        </div>
                      </section>
                    </div>
                  </div>
                  

                  <!-- <div class="form-group col-md-12">
                    <label class="text-dark">Programme</label>
                    <select
                      v-model="programme_souscription"
                      class="form-control"
                      required
                      @change="slectedProgramme"
                    >
                      <option disabled value="">
                        Sélectionne un programme
                      </option>
                      <option v-for="(programme,index) in listProgramme"
                       :key="index" :value="programme.id">
                      {{programme.label}}
                      </option>
                     
                    </select>
                  </div> -->
                  <div class="form-group col-md-12">
                    <label class="text-dark">Produit</label>
                    <vue3-basic-autocomplete
                    v-model="chooseProgramme"
                    :options="listProduit"
                    trackby="label"
                    none-find=" désole , Pas de resultat"
                    placeholder="Recherche par site"
                    input-class="input-site"
                    @change="selectedProduit"
                  />
                  
                  </div>

                  <div
                  class="form-group col-md-6"
                  v-if="PrixSouscription"
                >
                  <label class="d-block text-dark mb-2">Apport initial (Fcfa)</label>
                  <input
                    type="number"  min="1"
                    v-model="apportInitialForProduct"
                    class="form-control"
                    disabled
                  />
                </div>

                  <div
                    class="form-group col-md-6"
                    v-if="produit_souscription"
                  >
                    <label class="text-dark">Mode de paiement</label>
                    <div class="row container">
                      <section class="col-md-12 circuit">
                        <div
                          class="col-md-12 d-flex align-items-center my-2"
                        >
                          <input
                            type="radio"
                            id="Tempérament"
                            name="language"
                            value="Tempérament"
                            required
                            v-model="modePaiement_souscription"
                          />
                          <label class="text-dark" for="Tempérament">Tempérament</label>
                        </div>

                        <div
                          class="col-md-12 d-flex align-items-center my-2"
                        >
                          <input
                            type="radio"
                            id="CASH"
                            name="language"
                            value="CASH"
                            required
                            v-model="modePaiement_souscription"
                          />
                          <label class="text-dark" for="CASH">CASH</label>
                        </div>

                        <div
                          class="col-md-12 d-flex align-items-center my-2"
                        >
                          <input
                            type="radio"
                            id="credit bancaire"
                            name="language"
                            value="credit bancaire"
                            required
                            v-model="modePaiement_souscription"
                          />
                          <label class="text-dark" for="credit bancaire"
                            >Crédit bancaire</label
                          >
                        </div>
                      </section>
                    </div>
                  </div>
                  <div
                  class="form-group col-md-6"
                  v-if="modePaiement_souscription == 'Tempérament' || modePaiement_souscription == 'credit bancaire'"
                >
                  <label class="d-block text-dark mb-2">Prix (Fcfa)</label>
                  <input
                    type="number"  min="1"
                    v-model="PrixSouscription"
                    class="form-control"
                    required
                    disabled
                  />
                </div>
             
                  <div
                    class="form-group col-md-6"
                    v-if="produit_souscription && modePaiement_souscription"
                  >
                    <label class="d-block text-dark mb-2">Date d'écheance</label>
                    <input
                      type="date"
                      v-model="dateEcheance"
                      class="form-control"
                      :min="new Date().toISOString().slice(0, 10)"
                      required
                      :disabled="
                        this.$store.state.user.roles[0].name != 'Admin'
                          ? false
                          : true
                      "
                    />
                  </div>

                  <div class="form-group col-md-12" v-if="produit_souscription && dateEcheance">
                    <label class="d-block text-light mb-2 w-50 text-center badge bg-dark">Modalité de paiement</label>

                    <div
                      v-for="field in fields"
                      :key="field"
                      class="position-relative row"
                    >
                      <span
                        v-if="field"
                        class="delete text-danger text-end"
                        @click="deleteField(field)"
                        ><i class="bi bi-trash"></i></span
                      >
                      <div class="col-lg-6">
                        <label class="text-dark" :for="field">Date</label>
                        <input
                          :id="field"
                          type="date"
                          class="form-control my-2"
                          :min="new Date().toISOString().slice(0, 10)"
                          :max="dateEcheance"
                          v-model="field.date"
                        
                        />
                      </div>
                      <div class="col-lg-6">
                        <label class="text-dark" :for="field">Montant (Fcfa)</label>
                        <input
                          :id="field"
                          type="number"  min="1"
                          class="form-control my-2"
                          v-model="field.amount"
                          @input="seeTotalModalitePaymentInTime"
                        />
                      </div>
                     
                    </div>
                    <div class="text-end">
                      <h6 class="text-end mt-2">Total :{{totalModalitePayment}}</h6>
                      <button
                        @click.prevent="addField"
                        class="btn bg-primary w-25 my-3 addField text-light"
                      >
                        Ajouter un mode de paiement +
                      </button>
                    </div>
                  </div>
                  <div
                    class="form-group col-md-12"
                    v-if="modePaiement_souscription == 'CASH'"
                  >
                    <label class="text-dark">Montant versé</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="cashVerse"
                      required
                      :disabled="
                        this.$store.state.user.roles[0].name != 'Admin'
                          ? false
                          : true
                      "
                    />
                  </div>

                  

                  <div class="text-start">
                    <button
                      class="btn bg-dark text-light rounded"
                      type="submit"
                    >
                      Valider
                      <span
                        class="spinner-border spinner-border-sm ms-2"
                        v-show="preloaderValider"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="col-lg-9 col-md-12">
    <div class="dashboard-wraper">

      <div class="conteneur_modify" v-if="seeFiche">
        <div class="content position-relative">
          <div
            class="ecriteau text-start d-flex justify-content-between align-items-center"
          >
            <div></div>
            <h4>Fiche de souscription</h4>
            <i class="bi bi-x-circle h1" @click.prevent="closeFiche"></i>
          </div>
          <div class="col-lg-12 col-md-12">
            <div class="submit-pages">
              <div id="printjs_doc" class="printDoc">
                <div class="float-img">
                  <img
                    style="width: 200px"
                    alt="recu"
                    src="../assets/dexter_logo1.jpg"
                    class="img"
                  />
                </div>
                <h4>Fiche de souscription</h4>
               
                <p class="date">
                  <span>Date</span> :{{ new Date().toLocaleDateString("fr") }}
                </p>
                <p class="text-dark"> <span>Numéro de souscription</span> : {{allocation_number}}</p>
                <p class="text-dark">
                  <span>Commerciale</span> : {{ this.created_by.last_name }}
                  {{ this.created_by.first_name }} <br />
                 <span v-if="filiation"> Apporteur d’affaire : {{ this.created_by.last_name }} </span>
                </p>

                <h5 class="text-start bg-danger">COORDONNEES CLIENT</h5>
                <section class="section">
                  <section>
                    <p class="text-dark">
                      <span>Pièce d’identité</span> :
                      {{ this.pieceIdentite_souscription }} <br> <span>N</span> <sup>o</sup> :
                      {{ this.numeroPiece_souscription }} 
                    </p>
                    <p class="text-dark"><span>Nom</span> : {{ this.name_souscription }}</p>
                    <p class="text-dark">
                      <span>Prénoms</span>
                      : {{ this.prenoms_souscription }}
                    </p>
                    <p class="text-dark">
                      <span>Fonction</span>
                      : {{ this.profession_souscription }}
                    </p>
                    <p class="text-dark">
                      <span>Adresse Géographique</span>
                      : {{ this.Lieu_souscription }}
                    </p>
                    <p class="text-dark">
                      <span>Adresse Postale</span>
                      : {{ this.adresse_souscription }}
                    </p>
                  </section>
                  <section>
                    <p class="text-dark">
                      <span>Pays</span>
                      : {{ this.pays_souscription }}
                    </p>
                    <p class="text-dark">
                      <span>Situation matrimoniale</span>
                      : {{ this.situationMatrimoniale_souscription }}
                    </p>
                    <p class="text-dark">
                      <span>Contact 1</span>
                      : {{ this.contact1_souscription }}
                    </p>
                    <p class="text-dark">
                      <span>Contact 2 </span>
                      : {{ this.contact2_souscription }}
                    </p>
                    <p class="text-dark">
                      <span>Email</span>
                      : {{ this.email_souscription }}
                    </p>
                    <p class="text-dark">
                      <span>Banque de domiciliation</span>
                      : {{ this.Banque_souscription }}
                    </p>
                  </section>
                </section>
                <h5 class="text-start bg-danger">CHOIX DU PROJET</h5>
                <p v-if="chooseProduitProject">
                  <input type="checkbox" value="true" />
                  {{ this.chooseProduitProject.label }}
                </p>
                <h5 class="text-start bg-danger">CHOIX DU TYPE DE LOGEMENT</h5>
                <p v-if="this.chooseProduitProject">
                  <input type="checkbox" value="true" />
                  {{ this.chooseProduit.label }} 
                  (
                    {{
                   this.chooseProduitType_housing 
                  }}
                  {{ this.chooseProduit.room_number }}
                  pièces sur {{ this.chooseProduit.area }}m <sup>2</sup>)
                </p>
                <h5 class="text-start bg-danger">CHOIX DU MODE DE PAIEMENT</h5>
                <p class="text-dark">
                  <input type="checkbox" value="true" />{{
                    this.modePaiement_souscription
                  }}
                </p>

                <h5 class="text-start bg-danger">MONTANT SOUSCRIT EN F CFA</h5>
                <p class="text-dark">
                  Montant souscrit :
                  {{ moneyFormat.format(this.PrixSouscription) }} Fcfa
                </p>
                <p v-if="ApportInitial != null">Apport initial : {{  moneyFormat.format(this.ApportInitial) }} Fcfa</p>
                <p class="text-dark">
                  <span>SIGNATURE CLIENT :</span>
                </p>
              </div>
            </div>
          </div>
          <div class="my-2">
            <button class="btn bg-dark text-light" @click.prevent="printDocu">
              Imprimer
            </button>
          </div>
        </div>
      </div>



     
      <div class="conteneur_modify" v-if="showDetailclientForModify">
        <div class="content position-relative">
          <div
            class="ecriteau text-start d-flex justify-content-between align-items-center"
          >
            <h4>Détail du client</h4>
            <i
              class="bi bi-x-circle h1"
              @click.prevent="closeWindowDetailConteneurForModify"
            ></i>
          </div>
          <div class="text-start px-3">
            <div class="submit-pages text-start">
              <div class="row container cont">
                <div class="form-group col-md-12 col-sm-12">
                  <div>
                    <h5 class="badge bg-info">Info Client</h5>
                  </div>
                  <div class="row">
                    <div class="col-md-4 col-sm-12">
                      <strong>Nom</strong>
                      <h6 class="p-0 m-0 profil_name">{{detailClient.last_name}}</h6>
                      <strong>Prénoms</strong>
                      <h6 class="p-0 m-0 profil_name">{{detailClient.first_name}}</h6>
                      <strong>Email</strong>
                      <h6 class="p-0 m-0">{{detailClient.email}}</h6>
                      <strong>Profession</strong>
                      <h6 class="p-0 m-0 profil_name">{{detailClient.job}}</h6>
                      <strong>Sociéte</strong>
                      <h6 class="p-0 m-0 profil_name">{{detailClient.employer}}</h6>
                      <strong>Nationalité</strong>
                      <h6 class="p-0 m-0 profil_name">{{detailClient.nationality}}</h6>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <strong>Date de naissance</strong>
                      <h6 class="p-0 m-0">{{new Date(detailClient.birthday).toLocaleDateString('fr')}}</h6>
                      <strong>Pays</strong>
                      <h6 class="p-0 m-0 profil_name">{{detailClient.country}}</h6>
                      <strong>Banque de domiciliation</strong>
                      <h6 class="p-0 m-0 profil_name">{{detailClient.bank}}</h6>
                      <strong>Pièce d’identité</strong>
                      <h6 class="p-0 m-0">{{detailClient.id_card}}</h6>
                      <strong>Numéro de pièce</strong>
                      <h6 class="p-0 m-0">{{detailClient.cni_number}}</h6>
                      <strong>Code</strong>
                      <h6 class="p-0 m-0">{{detailClient.code}}</h6>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <strong>Pièces jointes</strong>
                      <div class="container row text-center" v-if="imgProspect.length">
                        <div
                          v-for="(item, index) in imgProspect"
                          :key="index"
                          class="col-lg-4 col-sm-6 py-3 text-center position-relative"
                          
                        >
                        <n-image
                        width="200"
                        :src="
                              'http://backend-dexter.invest-ci.com/' +
                              item
                            "
                      />
                         
                        </div> 
                       
                      </div>
                      <div v-else>
 <h6>Pas de pièces de jointes</h6>
                      </div>
                      
                    </div>
                  </div>
                </div>
              
                <div class="form-group col-md-12 col-sm-12">
                  <div>
                    <h5 class="badge bg-info">Info de l'agent commercial</h5>
                  </div>
                  <strong>Nom</strong>
                  <h6 class="p-0 m-0 profil_name">{{detailAgentCommercial.last_name}} {{detailAgentCommercial.first_name}}</h6>
                  <strong>Contact</strong>
                  <h6 class="p-0 m-0">{{detailAgentCommercial.phone}}</h6>
                  <strong>Email</strong>
                  <h6 class="p-0 m-0">{{detailAgentCommercial.email}}</h6>
                  <!-- <strong>Commission</strong>
                  <h6 class="p-0 m-0">10%</h6> -->
                </div>
                <div class="form-group col-md-12 col-sm-12">
                  <div>
                    <h5 class="badge bg-info">Souscription</h5>
                  </div>
                  <table class="table table-bordered w-100">
                    <thead>
                      <tr>
                        <th>Date de souscription</th>
                  
                        <th>Programme</th>
                        <th>Produit</th>
                        <th>Site</th>
                        <th>Montant (Fcfa)</th>
                        <th>Désitée</th>
                        <th>Commentaire</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(sub,index) in listSouscription" :key="index">
                        <td>{{new Date(sub.pivot.created_at).toLocaleDateString('fr')}}</td>
                        <td class="profil_name">{{sub.project.label}}</td>
                        <td class="profil_name">{{sub.type.label}}</td>
                        <td class="profil_name">{{sub.place.label}}</td>
                        <td>{{moneyFormat.format(sub.cost)}}</td>
                        <td>
                          <span v-if="sub.pivot.revok">OUI ({{new Date(sub.pivot.created_at).toLocaleDateString('fr')}})</span>
                          <span v-else class="text-danger">NON</span>
                          
                        </td>
    
                        <td>
                          <span v-if="sub.pivot.comment" @click="seeCommentForRefuse(sub.id)">
                            <i class="bi bi-eye"></i></span>
                          <span v-else class="text-danger"><i class="bi bi-x-circle-fill"></i></span>
                          
                        </td>
                      </tr>
                    
                    </tbody>
                  </table>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
     
    </div>

      <!-- Bookmark Property -->
      <div class="form-submit">	
       
        <ul
        class="d-flex justify-content-start align-items-center px-5 listes"
      >
        <li>
          <a
            href="#"
            :class="{ color: tab == 'Liste des clients' }"
            class="lien"
            @click.prevent="tab = 'Liste des clients'"
            >Liste des clients</a
          >
        </li>
        <li class="mx-3">
          <a
            href="#"
            :class="{ colorNew: tab == 'desistement' }"
            class="lien"
            @click.prevent="tab = 'desistement'"
            >Désistement</a
          >
        </li>
  
      </ul>
      </div>
      <section v-show="tab === 'Liste des clients'">
      <div
        class="form-submit position-relative pt-3"
        :class="preloader ? 'pre' : 'null'"
      >
        <div id="preloader" class="position-absolute" v-show="preloader">
          <div class="preloader"><span></span><span></span></div>
        </div>
        <!-- <h2 class="text-start text-light">Liste des clients</h4> -->
      </div>

      <div class="col-sm-12 dataTable" v-if="!preloader && ListesClient.length">
        <table id="MyTableData" class="table text-center table-responsive">
          <thead>
            <tr class="text-center">
              <th class="bg-light text-center">Nom</th>
              <th class="bg-light text-center">Prénoms</th>
              <th class="bg-light text-center">Contact</th>
              <th class="bg-light text-center">Email</th>
              <th class="bg-light text-center">Adresse géographique</th>
              <th class="bg-light" v-if="this.profil == 'Chargé de clientèle'">
                Action
              </th>

              <th class="bg-light text-center">Détail</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(client,index) in ListesClient" :key="index">
              <td class="profil_name">{{client.last_name}}</td>
              <td class="profil_name">{{client.first_name}}</td>
              <td>{{client.phone1}}</td>
              <td>
                <span v-if="client.email"> {{client.email}}</span>
                <span v-else>pas de mail</span>
               
              </td>
              <td>
                <span v-if="client.address">{{client.address}}</span>
                <span v-else>pas d'adresse</span>
               
              </td>
              <td v-if="this.profil == 'Chargé de clientèle'">
                <button
                  class="btn bg-dark text-light"
                  @click.prevent="seeWindowForNewSubscription(client.id)"
                >
                  Souscription
                </button>
              </td>
              <td class="text-center">
                <span class="d-flex justify-content-center align-items-center">
                  <i
                    class="bi bi-eye mx-2"
                    @click.prevent="viewDetailsClient(client.id)"
                  ></i>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    <section v-show="tab === 'desistement'">
      <div class="col-sm-12 dataTable pt-3" v-if="!preloader && ListesClientDesiste.length">
        <table id="MyTableData1" class="table text-center table-responsive">
          <thead>
            <tr class="text-center">
              <th class="bg-light text-center">Nom</th>
              <th class="bg-light text-center">Prénoms</th>
              <th class="bg-light text-center">Contact</th>
              <th class="bg-light text-center">Email</th>
              <th class="bg-light text-center">Adresse géographique</th>
              <th class="bg-light" v-if="this.profil == 'Chargé de clientèle'">
                Action
              </th>

              <th class="bg-light text-center">Détail</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(client,index) in ListesClientDesiste" :key="index">
              <td class="profil_name">{{client.last_name}}</td>
              <td class="profil_name">{{client.first_name}}</td>
              <td>{{client.phone1}}</td>
              <td>
                <span v-if="client.email">{{client.email}}</span>
                <span v-else>Pas de mail</span>
                </td>
              <td>
                <span v-if="client.address">{{client.address}}</span>
                <span v-else>Pas d'adresse</span>
              </td>
              <td v-if="this.profil == 'Chargé de clientèle'">
                <button
                  class="btn bg-dark text-light"
                  @click.prevent="seeWindowForNewSubscription(client.id)"
                >
                  Souscription
                </button>
              </td>
              <td class="text-center">
                <span class="d-flex justify-content-center align-items-center">
                  <i
                    class="bi bi-eye mx-2"
                    @click.prevent="viewDetailsClient(client.id)"
                  ></i>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    </div>
  </div>
  
</template>


<style scoped>
.lien {
  text-decoration: none;
  font-size: 1.3em;
  color: rgb(159, 158, 158);
  padding:.8em !important;
}
.listes {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.085);
  padding:1em;
  background-color:white;
}
.colorNew,.color{
  color: rgb(255, 255, 255) !important;
  border:1px solid black;
  border-radius:5px;
  padding:0 1em;
  background-color: rgb(33, 33, 75);
}
.cont .form-group{
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.222);
  border-radius:8px;
}
strong {
  font-size: 1em !important;
  color: gray;
  text-decoration:underline;
  padding: 0 0 0.5em 0;
  font-weight: bold;
  text-transform: uppercase;
}
.product .form-group{
  box-shadow:1px 1px 3px rgba(0, 0, 0, 0.356); 
  border-radius:10px;
}
.bg-info{
background-color:rgb(0, 0, 0) !important;
}
.w-50{
  width:150px !important;
}
.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
}
.section span,
p span {
  font-weight: bold;
  text-decoration:underline;
}
.date {
  text-align: right !important;
}
h4 {
  text-align: center;
}
.printDoc {
  width: 100%;
}
.conteneur_modify .content {
  width: 100%;
}

.d-flex {
  text-align: center;
  display: flex;
  justify-content: between;
  align-items: center;
}
h5 {
  background-color: red;
  color: black;
}

.text-center {
  text-align: center;
}
p {
  width: 100%;
  text-align: left;
  padding: 0.5em 1em;
}

#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: rgba(244, 242, 242, 0);
  display: flex;
  justify-content: center;
  place-items: center;
}
.bg-danger,
.bg-danger:hover {
  color: rgb(0, 0, 0) !important;
}
.cancel_comment{
  position:absolute;
  top:0;
  right:.5em;
  font-size:1.5em;
  cursor:pointer;
}
h6 {
  margin: 0 0 1em 0 !important;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}
[type="radio"]:checked + label:after {
  opacity: 0 !important;
}
.circuit {
  padding: 0 !important;
}
.card {
  padding: 1em 3em;
}
.bg-primary {
  background-color: rgb(55, 137, 167) !important;
  border: 2px solid rgb(55, 137, 167) !important;
  color: white !important;
}
.bi-eye,
.bi-x-circle,
.bi-pencil,
.bi-telephone-outbound,
.bi-trash {
  cursor: pointer;
}
.pre {
  padding: 0 0 15em 0;
}
.badge {
  color: white !important;
}
.table {
  border: 1px solid black;
}
td {
  border-right: 2px solid black;
}
.confirmation_modifie_comment{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.confirmation_modifie {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify_fiche {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 90%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.close_modal {
  position: absolute;
  top: 1em;
  right: 1em;
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index: 9999;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.submit-pages {
  padding: 1em 3em;
}
.recherche {
  top: 0;
  right: -0.6em;
  z-index: 99;
}
.recherche input {
  width: 199px;
  height: 35px;
  border-radius: 2px;
}
.delete{
  position:absolute;
  right:0;
  cursor: pointer;
  width:20px;
}
.text-dark,p{
  color:black !important;
}

</style>
