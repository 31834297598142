<script>
import axios from "axios";
import Swal from "sweetalert2";
// import Vue3BasicAutocomplete from "vue3-basic-autocomplete";

const baseUrl = "http://backend-dexter.invest-ci.com/api/";
export default {
  name: "Enregistrer-Produit",
  // components: {
  //   Vue3BasicAutocomplete,
  // },
  data() {
    return {
        label:"",
        convenience:[],
  cost: "",
  doc_type: "",
  approval_number: "",
  habitation_group_number:"",
  room_number: "",
  sector: "",
  lot: "",
  area: "",
  position: "",
  type_id: "",
  project_id: "",
  whoIsProduct:"sua",
  type_housing_id: "",
  place_id: "",
  ilot_id: "",
  categorieProduct:"",
      msgEmailErr:false,
      msgPhoneErr:false,
      msgPhoneErr2:false,
      moneyFormat: new Intl.NumberFormat("de-DE"),
      viewInfoProspect:"",
      preloader:false,
      programme:[],
      Ilot:[],
      place:[],
      typeLogement:[],
      categorie_list:[],
      IdCategorie:"",
      commodite:[],
      commoditeValue:"",

      selectedOptions: [],
      selectedNumbers: {},
      commoditeArray:[],
      fields:[{
      convenience_id:"",
      number: ""
    }],
    membership:"",
    // remiseSouscription:"",
    apportFixeOrPercent:"fixe",
    apportInitialSouscription:"",
    apportInitialFixe:"",
    apportInitialPercent:"",
    programmeId:"",
    IlotId:"",
    typeLogementId:"",
    placeId:"",
    commoditeId:"",
    referenceProduit:"",
    };
  },
  methods: {
    // selectedCommodite(e) {
    //   //console.log("SELECTED", e);
    //   this.commodite.forEach((item) => {
    //     if (item.label == e.target.value) {
    //       this.fields = item.id
    //     }
    //   });
    //   //console.log("this.commoditeId", this.commoditeId);
    // },
    // selectedPlace(e) {
    //   //console.log("SELECTED", e);
    //   this.place.forEach((item) => {
    //     if (item.label == e.target.value) {
    //       this.placeId = item.id
    //     }
    //   });
    //   //console.log("this.placeId", this.placeId);
    // },
    // selectedTypeLogement(evt) {
    //   //console.log("SELECTED", evt);
    //   this.typeLogement.forEach((item) => {
    //     if (item.label == evt.target.value) {
    //       this.typeLogementId = item.id
    //     }
    //   });
    //   //console.log("this.typeLogementId", this.typeLogementId);
    // },
    // selectedIlot(e) {
    //   //console.log("SELECTED", e);
    //   this.Ilot.forEach((item) => {
    //     if (item.label == e.target.value) {
    //       this.IlotId = item.id
    //     }
    //   });
    //   //console.log("this.IlotId", this.IlotId);
    // },
    // selectedProgramme(e) {
    //   //console.log("SELECTED", e);
    //   this.programme.forEach((item) => {
    //     if (item.label == e.target.value) {
    //       this.programmeId = item.id
    //     }
    //   });
    //   //console.log("this.programmeId", this.programmeId);
    // },
    addField:function(){
        this.fields.push({convenience_id:"",number:""})
        //console.log(this.fields);
        },
        deleteField:function(element){
            const DeleteField = this.fields.findIndex(item=>item === element)
            this.fields.splice(DeleteField,1)
        },
    chooseConvenience(e){
      console.log(e.target.value);
      const itemAtdelete = this.commoditeArray.findIndex(
              (item) => item.key == e.target.value
            );
            this.commoditeArray.splice(itemAtdelete, 1);
            //console.log("new tableau",this.commoditeArray)

    },
    chooseCommodite(e){
     this.commoditeArray = []
  console.log(e.target.value);
  this.selectedOptions.forEach(item=>{
    for (const [key, value] of Object.entries(this.selectedNumbers)) {
  if(item == key) {
    this.commoditeArray.push({
      "convenience_id":key,
      "number":value
    })
   
  }
}
  })
  //console.log("tableua",this.commoditeArray)
    },
    closeViewInfoProspect(){
      this.viewInfoProspect = !this.viewInfoProspect
    },
    viewInfo(){
      const phoneRegex = /^[0-9]{10}$/;
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
      if(phoneRegex.test(this.phoneOneprospect)){
        this.msgPhoneErr = false
        if(phoneRegex.test(this.phoneTwoprospect)) {
          this.msgPhoneErr2 = false
          if(emailRegex.test(this.emailProspect)){
          this.msgEmailErr = false
          this.viewInfoProspect = !this.viewInfoProspect
    
        }else{
          Swal.fire({
              icon: "error",
              title: "addresse email non valide",
              showConfirmButton: false,
              timer: 1000,
            });
          this.msgEmailErr = true
        }

        }else{
          Swal.fire({
              icon: "info",
              title: "Numéro de téléphone non valide",
              showConfirmButton: false,
              timer: 1000,
            });
            this.msgPhoneErr2 = true
        }
       
       
      }else{
        Swal.fire({
              icon: "info",
              title: "Numéro de téléphone non valide",
              showConfirmButton: false,
              timer: 1000,
            });
        this.msgPhoneErr = true
      }
     
    },
    listProgramme(){
       axios.get(baseUrl +"projects"
          ,{headers: {
            Authorization: "Bearer " + this.$store.state.token,
          }},
        )
        .then(response => { 
          //console.log("Reponse",response.data);
          this.programme = response.data.data

        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
          Swal.fire({
              icon: "error",
              title: err.response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
        });
    },
    listIlot(){
       axios.get(baseUrl +"ilots"
          ,{headers: {
            Authorization: "Bearer " + this.$store.state.token,
          }},
        )
        .then(response => { 
          //console.log("Reponse",response.data);
          this.Ilot = response.data.data

        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
          Swal.fire({
              icon: "error",
              title: err.response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
        });
    },
    listPlace(){
       axios.get(baseUrl +"places"
          ,{headers: {
            Authorization: "Bearer " + this.$store.state.token,
          }},
        )
        .then(response => { 
          //console.log("Reponse",response.data);
          this.place = response.data.data

        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
          Swal.fire({
              icon: "error",
              title: err.response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
        });
    },
    ListTypeLogement(){
       axios.get(baseUrl +"type-housings"
          ,{headers: {
            Authorization: "Bearer " + this.$store.state.token,
          }},
        )
        .then(response => { 
          //console.log("ReponseTYPE",response.data);
          this.typeLogement = response.data.data

        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
          Swal.fire({
              icon: "error",
              title: err.response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
        });
    },
    listCategorie(){
       axios.get(baseUrl +"types"
          ,{headers: {
            Authorization: "Bearer " + this.$store.state.token,
          }},
        )
        .then(response => { 
          //console.log("ReponseTYPE",response.data);
          this.categorie_list = response.data.data

        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
          Swal.fire({
              icon: "error",
              title: err.response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
        });
    },
    listCommodite(){
       axios.get(baseUrl +"conveniences"
          ,{headers: {
            Authorization: "Bearer " + this.$store.state.token,
          }},
        )
        .then(response => { 
          //console.log("ReponseTYPE",response.data);
          this.commodite = response.data.data

        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
          Swal.fire({
              icon: "error",
              title: err.response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
        });
    },
    cat(e){
     //console.log("CATEGORIE",e.target.value)
     this.categorie_list.forEach(item=>{
      if(item.label == e.target.value){
        this.IdCategorie = item.id
      }
     })
     //console.log("CATEGORIE",this.IdCategorie)
    },
   async saveProduct() {
    //console.log("apportFixeOrPercent",this.apportFixeOrPercent)
    //console.log("this.cost",this.cost)
    let amountAfterPutPercent=""; 
    if(this.apportFixeOrPercent == 'pourcentage'){
      amountAfterPutPercent =  (Number(this.cost) * this.apportInitialPercent) / 100; 
    }
    //console.log("this.fields",this.fields)
    let tableauFieldsFilter = [];
    this.fields.forEach(item=>{
      tableauFieldsFilter.push({convenience_id:item.convenience_id.id,number:item.number})    
    })
     //console.log("this.apportInitialSouscription",amountAfterPutPercent)
      let data = {
    reference:this.referenceProduit,
   label:this.label,
  cost: this.cost,
  doc_type: this.doc_type,
  approval_number: this.approval_number,
  habitation_group_number:this.habitation_group_number,
  room_number: this.room_number,
  sector: this.sector,
  lot: this.lot,
  area: this.area,
  position: this.position,
  type_id: this.IdCategorie,
  project_id: this.project_id.id,
  type_housing_id: this.type_housing_id.id,
  place_id: this.place_id.id,
  ilot_id: this.ilot_id.id, 
  convenience: this.categorieProduct != 'Logement' ?  [] : tableauFieldsFilter,
  membership:this.whoIsProduct,
  contribution_type:this.apportFixeOrPercent,
  initial_contribution:this.apportFixeOrPercent == 'pourcentage' ? amountAfterPutPercent : this.apportInitialFixe ,

      };
      //console.log("DATA",data)
      this.preloader = true;
         await axios.post(baseUrl +"products",data
          ,{headers: {
            Authorization: "Bearer " + this.$store.state.token,
          }},
        )
        .then(response => { 
          //console.log("Reponse",response.data);
          
          if(response.data.status === true){
            //console.log("Reponse",response.data);
            Swal.fire({
              icon: "success",
              title: "le produit a été enregistré.",
              showConfirmButton: true,
            });
            this.$router.push('/liste-produit')
            this.preloader = false;
          }
          if(response.data.status === false){
            //console.log("Reponse",response.data);
            const monObjet = response.data.message
            let tableau = []
            for (let propriete in monObjet) {
  if (Array.isArray(monObjet[propriete])) {
     tableau = monObjet[propriete];
    //console.log(tableau);
  }else{
    tableau.push(response.data.message)
  }
}
            Swal.fire({
              icon: "error",
              title: `${tableau.toString()}`, 
              showConfirmButton: false,
              timer: 1500,
            });
            this.preloader = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
          Swal.fire({
              icon: "error",
              title: err.response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
        });
      //console.log(data);
      
    
     
       
    },
  },
  mounted() {
    this.listProgramme();
    this.listIlot();
    this.listPlace();
   this.ListTypeLogement();
   this.listCategorie()
   this.listCommodite();
  },
};
</script>
<template>

  <div class="col-lg-9 col-md-12 position-relative">
    
    <div class="dashboard-wraper">


















      <div class="row text-start">
        <!-- Submit Form -->
        <div class="col-lg-12 col-md-12">
            <form>
             
              <div class="submit-pages_view text-start">
                
                <div class="form-submit">
                    <h2 class="text-light">Enregistrer un produit</h2>
                  <div class="submit-section">
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label>Catégorie du produit</label>
                            <select
                              v-model="categorieProduct"
                              class="form-control"
                              required
                              @change="cat"
                            >
                              <option disabled value="">
                                Sélectionne une catégorie
                              </option>
                              <option
                              v-for="(categorie,index) in categorie_list"
                              :key="index"
                              :selected="categorie.label"
                              :value="categorie.label"
                              >
                              {{categorie.label}}
                              </option>
                             
                            </select>
                          </div> 
                          
                          <div class="form-group col-md-6" v-if="categorieProduct == 'Logement' || categorieProduct == 'Terrain nu'">
                            <label>Programme</label>
                            
                            <v-select  v-model="project_id" :searchable="true" :options="programme" label="label">
                              <template>
                                <p>{{numberilot}}</p>
                              </template>
                            </v-select>
                           
                          </div> 
                          <div class="form-group col-md-6" v-if="categorieProduct == 'Logement' || categorieProduct == 'Terrain nu'">
                            <label>Ilot</label>
                            
                            <v-select  v-model="ilot_id" :searchable="true" :options="Ilot" label="numberilot">
                              <template>
                                <p>{{numberilot}}</p>
                              </template>
                            </v-select>
                          
                          </div> 
                          <div class="form-group col-md-6" v-if="categorieProduct == 'Terrain nu'">
                            <label>Numéro de lot</label>
                            <input
                              type="text"
                              class="form-control"
                             
                              v-model="lot"
                              required
                            />
                          </div>
                          <div class="form-group col-md-6" v-if="categorieProduct == 'Logement'">
                            <label>Type de Logement</label>
                           
                            <v-select  v-model="type_housing_id" :searchable="true" :options="typeLogement" label="label">
                              <template>
                                <p>{{label}}</p>
                              </template>
                            </v-select>
                           
                          </div> 
                      <div class="form-group col-md-6" v-if="categorieProduct == 'Logement' || categorieProduct == 'Terrain nu'">
                        <label class="text-dark">Libellé</label>
                        <input
                          type="text"
                          class="form-control"
                         
                          v-model="label"
                          required
                        />
                      </div>
                      <div class="form-group col-md-6" v-if="categorieProduct == 'Logement' || categorieProduct == 'Terrain nu'">
                        <label>Prix</label>
                        <input
                          type="number"  min="1"
                          class="form-control"
                         
                          v-model="cost"
                          required
                        />
                      </div>
                     
                      <div class="form-group col-md-6" v-if="categorieProduct == 'Logement'">
                        <label>Nombre de pièces</label>
                    
                        <input
                          type="number"  min="1"
                          class="form-control"
                       
                          v-model="room_number"
                          required
                        />
                       
                      </div>
                     
                       
                   
                      
                      <div class="form-group col-md-6" v-if="categorieProduct == 'Logement' || categorieProduct == 'Terrain nu'">
                        <label>Situé précisément</label>
                    
                        <input
                          type="text"
                          class="form-control"
                       
                          v-model="sector"
                          required
                        />
                      
                      </div>
                      <div class="form-group col-md-6" v-if="categorieProduct == 'Logement' || categorieProduct == 'Terrain nu'">
                        <label>Superficie</label>
                       
                        <input
                          type="number"  min="1"
                          class="form-control"
                          
                          v-model="area"
                        />
                       
                      </div>
                      
                      <div class="form-group col-md-6" v-if="categorieProduct == 'Logement'" >
                        <label>Numéro Logement</label>
                        <input
                          type="text"
                          class="form-control"
                         
                          v-model="habitation_group_number"
                          required
                        />
                      </div>
                      <div class="form-group col-md-6" v-if="categorieProduct == 'Logement' || categorieProduct == 'Terrain nu'">
                        <label>Position</label>
                        <input
                          type="number"  min="1"
                          class="form-control"
                         
                          v-model="position"
                          required
                        />
                      </div>
                      <div class="form-group col-md-6" v-if="categorieProduct == 'Logement' || categorieProduct == 'Terrain nu'">
                        <label>Site</label>
                        
                        <v-select  v-model="place_id" :searchable="true" :options="place" label="label">
                          <template>
                            <p>{{label}}</p>
                          </template>
                        </v-select>
                        <!-- <vue3-basic-autocomplete
                        v-model="place_id"
                        :options="place"
                        trackby="label"
                        none-find=" désole , Pas de resultat"
                        placeholder="Recherche par site"
                        input-class="input-site"
                        @change="selectedPlace"
                      /> -->
                      </div>
                      
                      <div class="form-group col-md-12" v-if="categorieProduct == 'Logement'">
                        
                        <div v-for="field in fields" :key="field" class="position-relative row">
                          <span v-if="field" class="delete text-danger text-end" @click="deleteField(field)"><i class="bi bi-trash"></i></span>
                          <div class="col-lg-6">
                            <label :for="field">Commodité</label>
                            <v-select  v-model="field.convenience_id" :searchable="true" :options="commodite" label="label">
                              <template>
                                <p>{{label}}</p>
                              </template>
                            </v-select>
                            <!-- <vue3-basic-autocomplete
                        v-model="field.convenience_id"
                        :options="commodite"
                        trackby="label"
                        none-find=" désole , Pas de resultat"
                        placeholder="Recherche par commodité"
                        input-class="input-site"
                        @change="selectedCommodite"
                      /> -->
                            
                
                          </div>
                          <div class="col-lg-6">
                            <label :for="field">Nombre de la commodité</label>
                            <input :id="field" type="number"  min="1" class="form-control my-2" v-model="field.number">
                          </div>
                        
                      </div>
                      <div class="text-end">
                        <button @click.prevent="addField" class="btn bg-primary w-25 my-3 addField text-dark bg-warning">Ajouter une commodité +</button>
                       </div>
                      </div>
                      
                      <!-- <div class="form-group col-md-12 row" v-if="categorieProduct == 'Logement'">
                        <label>Commodité</label>
                      <div v-for="commodite in  commodite" :key="commodite.id" class="d-flex justify-content-between align-items-center col-md-6 col-sm-12 my-1">
                        <input type="checkbox" :id="'checkbox-' + commodite.id" :value="commodite.id" v-model="selectedOptions" @input="chooseConvenience">
                        <label :for="'checkbox-' + commodite.id">{{ commodite.label }}</label>
                        <input type="number"  min="1" :id="'number-' + commodite.id" v-model="selectedNumbers[commodite.id]" @input="chooseCommodite">
                      </div>
                      </div>  -->
                      
                    
                      <div class="form-group col-md-6" v-if="categorieProduct == 'Terrain nu'">
                        <label>Document</label>
                        <div class="row container">
                         <section class="col-md-12 circuit ">
                          <div class="col-md-12 d-flex align-items-center my-2">
                            <input
                            type="radio"
                            id="ACD"
                            name="fav_language"
                            value="ACD"
                            required
                            v-model="doc_type"
                          />
                          <label for="ACD">ACD</label> 
                           </div>
    
                            <div class="col-md-12 d-flex align-items-center my-2">
                              <input
                              type="radio"
                              id="Approbation Ministeriel"
                              name="fav_language"
                              value="Approbation Ministeriel"
                              required
                              v-model="doc_type"
                            />
                            <label for="Approbation Ministeriel">Approbation ministériel</label>
                            </div>
    
                          
         
                         </section>
                        
                        </div>
                      </div>
                      <div class="form-group col-md-6" v-if="categorieProduct == 'Terrain nu'">
                        <label>Numéro d'approbation</label>
                        <input
                          type="text"
                          class="form-control"
                         
                          v-model="approval_number"
                          required
                        />
                      </div>
                      <div class="form-group col-md-6" v-if="categorieProduct == 'Terrain nu' || categorieProduct == 'Logement'">
                        <label>Apport initial</label>
                        <section class="col-md-12 circuit ">
                          <div class="col-md-12 d-flex align-items-center my-2">
                            <input
                            type="radio"
                            id="fixe"
                            name="falanguage"
                            value="fixe"
                            required
                            v-model="apportFixeOrPercent"
                          />
                          <label for="fixe">fixe</label> 
                           </div>
    
                            <div class="col-md-12 d-flex align-items-center my-2">
                              <input
                              type="radio"
                              id="pourcentage"
                              name="falanguage"
                              value="pourcentage"
                              required
                              v-model="apportFixeOrPercent"
                            />
                            <label for="pourcentage">pourcentage</label>
                            </div>
                         </section>
                        <input
                        v-if="apportFixeOrPercent == 'fixe'"
                          type="number"  min="1"
                          class="form-control"
                          placeholder="fixe"
                          v-model="apportInitialFixe"
                          required
                        />
                        <input
                        v-else
                          type="number"  min="1"
                          class="form-control"
                          placeholder="pourcentage"
                          v-model="apportInitialPercent"
                          required
                        />
                      </div>

                      <div class="form-group col-md-6" v-if="categorieProduct == 'Terrain nu' || categorieProduct == 'Logement'">
                        <label>Produit pour</label>
                        <div class="row container">
                         

                         <select class="form-control" v-model="whoIsProduct">
                          <option value="sua">
                            SUA
                          </option>
                          <option  value="partenaire">
                            Partenaire
                          </option>
                        </select>
                        
                        </div>
                      </div>
                      <div class="form-group col-md-6" v-if="categorieProduct == 'Logement' || categorieProduct == 'Terrain nu'">
                        <label>Référence</label>
                        <input
                          type="text"
                          class="form-control"
                         
                          v-model="referenceProduit"
                          required
                        />
                      </div>
                      <div class="text-start">
                        <button :disabled="categorieProduct ? false:true" class="btn bg-dark text-light rounded" @click.prevent="saveProduct">
                          Valider
                          <span class="spinner-border spinner-border-sm ms-2" v-show="preloader"  role="status" aria-hidden="true"></span>
                          
                        </button>
                      </div>
                     
                    </div>
                  </div>
                  
                </div>
  
                
              </div>
            </form>
          </div>
      </div>
    </div>
  </div>
</template>
<style scoped>

.vs__search, .vs__search:focus{
  padding:1em 20px !important;
}
.addField{
  width:auto !important;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}
.dashboard-wraper{
  height: 100%;
}
.bi-x-circle{
  cursor:pointer;
}

.circuit{
  padding:0 !important;
}
[type="radio"]:checked + label:after {
  opacity: 0 !important;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index:9999;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.submit-pages_view {
  padding: 1em 3em;
}
.delete{
  position:absolute;
  right:0;
  cursor: pointer;
  width:20px;
}
</style>
