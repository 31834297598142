<script>
import $ from "jquery";
// import {utils} from '../../../../utils'
import Swal from "sweetalert2";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons";
import "datatables.net";
import LienConnected from "../../class_help_me";
export default {
  name: "listeReservation_en_attente",
  data() {
    return {
      idPropriete: "",
      // myUtils:utils,
      idEmetteur: "",
      tabulation: "enCours",
      EcriteauForHeaDoBooking: false,
      windowForCancelBooking: false,
      dataObject: "",
      spaceForModifyMyReservation: false,
      preloaderBooking: false,
      preloaderModifyReservation: false,
      listReservation: [],
      listReservationAnnulees: [],
      listResidenceDisponible: [],
      listReservationResidence: [],
      listClient: [],
      photoResidence: [],
      nbreOfPersonne: "",
      photoPieces: [],
      disabledDates: [],
      addDateWithformatConvert: [],
      municipalityReservation: "",
      detailReservation: "",
      active: false,
      dateReservation: {
        start: "",
        end: "",
      },
      preloaderSpinner: false,
      commentRemoveBooking: "",
      moneyFormat: new Intl.NumberFormat("de-DE"),
      residence: "",
      preload: false,
      preloader: false,
      showDetail: false,
      windowForBook: false,
      nbrPersonAllowReservation: 1,
      prenomsClient: "",
      phoneClient: "",
      passwordClient: "",
      nomClient: "",
      emailClient: "",
      raisonForLocation: "",
      dateEndReservation: "",
      dateStartReservation: "",
      clientReservation: "",
      arrayAllDateDisabled: [],
      lienUrl: new LienConnected().baseUrl,
      propertyAtVerify: "",
      reservationAtModify: {
        user: "",
        propriety: {
          id: "",
          raison: "",
        },
        number_people: "",
      },
      nouvelleProprieteChoose: "",
      listHistoriqueReservation: [],
      lisReservationsd:"",
      responseEssay:"",
    };
  },
  methods: {
    getDatesBetween(startDate, endDate) {
      const dates = [];
      const currentDate = new Date(startDate);
      const lastDate = new Date(endDate);

      while (lastDate > currentDate) {
        dates.push(new Date(currentDate).toISOString().slice(0, 10));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dates;
    },
    helpMeGetList(route) {
      this.dataObject = new LienConnected(route, this.$store.state.token);
    },
    // DoAnReservationWithHome() {},
    chooseResidence(propertyChoose, listReservation = null) {
      //console.log("listReservation", listReservation);

      if (propertyChoose === 0) {
        let date = [];
        this.disabledDates = [];
        this.lisReservationsd = listReservation
        //console.log("this.propertieAtVerify", propertyChoose);
        // console.log("this.listReservation", this.listReservation);
        // console.log(
        //   "this.nouvelleProprieteChoose",
        //   this.nouvelleProprieteChoose
        // );
        if (
          this.reservationAtModify.number_people >
          this.nouvelleProprieteChoose.person_maxi
        ) {
          Swal.fire({
            icon: "info",
            title:
              "La propriété choisie a un nombre de résident inférieur à celle que le client a choisie.",
            showConfirmButton: true,
          });
        }

        this.listReservation.forEach((item) => {
          if (this.nouvelleProprieteChoose.id === item.propriety_id) {
            //console.log(item);

            item.dayOccuped = this.getDatesBetween(
              item.date_start,
              item.date_end
            );
            date.push(item);
          }
        });
        date.forEach((item) => {
          this.disabledDates.push(item.dayOccuped);
        });
        //console.log("this.disabledDates", this.disabledDates);
        this.arrayAllDateDisabled = this.disabledDates.flat();
        this.arrayAllDateDisabled.forEach((item) => {
          this.addDateWithformatConvert.push(
            this.rendreFormatDateInYYYMMDD(item)
          );
        });
        //console.log("this.arrayAllDateDisabled", this.arrayAllDateDisabled);
        //console.log("listReservation", date);
      }

      if (propertyChoose === 1) {
        let date = [];

        this.disabledDates = [];
        //console.log("RESIDENCE CHOOSE", this.residence);
        this.nbreOfPersonne = this.residence.person_maxi;
        //console.log("this.listReservationResidence", this.listReservation);

        this.listReservation.forEach((item) => {
          if (this.residence.id == item.propriety_id) {
            //console.log("listReservation", item);

            item.dayOccuped = this.getDatesBetween(
              item.date_start,
              item.date_end
            );

            date.push(item);
          }
        });
        //console.log("this.nbreOfPersonne", this.nbreOfPersonne);
        date.forEach((item) => {
          this.disabledDates.push(item.dayOccuped);
        });
        this.arrayAllDateDisabled = this.disabledDates.flat();
      }
    },

    async getAllList(route) {
      try {
        this.preloader = true;
        this.helpMeGetList(route);
        const allList = await this.dataObject.getAllData();
        if (allList.data.status === true) {
          this.preloader = false;
          //console.log("allList.data", allList.data);
          return allList.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async modifierStatutReservation(idReservation) {
      try {
        let data = {
          status: "Confirmée",
        };

        const allList = await this.dataObject.modifyData(data, idReservation);
        if (allList.data.status === true) {
          //console.log(allList);
        }
      } catch (error) {
        this.preloader = false;
        console.log(error);
      }
    },
    helpMeIterate(data, folder) {
      let photo = [];
      data.forEach((el) => {
        photo.push(this.lienUrl.replace("/api/", "/") + folder + el);
      });
      return photo;
    },
    putImageInArray(arrayPicture) {
      let compteur = 0;
      let images = [];
      while (compteur < arrayPicture.length) {
        images.push(
          this.lienUrl.replace("/api/", "/") +
            "public/public/image/" +
            arrayPicture[compteur].image
        );
        compteur++;
      }
      return images;
    },
    bouclerInList(liste,id){
      liste.forEach((item) => {
        if (item.id == id) {
          item.flag = 0
          this.detailReservation = item;
          //console.log(this.detailReservation)
        }
      });
    },

    async isItemFlag(liste,id){
      try{
        const dataObject = new LienConnected("updateFlagR/"+id,this.$store.state.token)
      const response = await dataObject.sendData(null);
      //console.log("response",response)
      if(response.data.status){
        this.$store.dispatch("seeAlarmReservation")
      this.bouclerInList(liste,id)
      }
      }catch(error){
        console.log(error)
      }
    },
    windowDetailReservation(id, liste) {
      let piece = [];

      this.photoPieces = [];
      liste.forEach((item) => {
        if (item.id == id) {
          this.photoResidence = this.putImageInArray(item.propriety.photos);
          piece = item.user.cni_doc ? JSON.parse(item.user.cni_doc) : [];
          this.detailReservation = item;
          this.municipalityReservation = item.propriety.municipality;
        }
      });

      this.photoPieces = this.helpMeIterate(piece, "public/public/image/");
      if(this.detailReservation.flag == 1){
        this.isItemFlag(liste,id)
      }
      this.showDetail = !this.showDetail;
    },
    async getListReservation() {
      const ReservationAvenir = await this.getAllList("reservations");
      this.listReservation = ReservationAvenir.data1;
      //console.log("this.listReservation",ReservationAvenir.data1)
      this.listReservationAnnulees = ReservationAvenir.data2;
      const responseHistoriqueReservation = await this.getAllList(
        "ReservationExpirer"
      );
      this.listHistoriqueReservation = responseHistoriqueReservation.data;
      // console.log(
      //   "this.listHistoriqueReservation",
      //   this.listHistoriqueReservation
      // );
      //console.log("this.listReservationAnnulees", this.listReservationAnnulees);
      setTimeout(function () {
        let printCounter = 0;
        $("#MyTableData,#MyTableData1,#MyTableData3").DataTable({
          pagingType: "full_numbers",
          pageLength: 10,
          processing: true,
          dom: "Bfrtip",
          buttons: [
            {
              extend: "copy",
              text: "Copier les données",
              messageTop: function () {
                printCounter++;

                if (printCounter === 1) {
                  Swal.fire({
                    icon: "success",
                    title:
                      "Les données ont été copiées dans le presse-papiers.",
                    showConfirmButton: true,
                  });
                } else {
                  Swal.fire({
                    icon: "info",
                    title:
                      "Les données ont déjà été copiées dans le presse-papiers.",
                    showConfirmButton: true,
                  });
                }
              },
            },
            {
              extend: "csv",
              text: "Exporter en fichier excel",
              fieldSeparator: ";",
              fieldBoundary: '"',
              bom: true,
              exportOptions: {
                columns: [0],
              },
            },
            {
              extend: "print",
              text: "Imprimer les données",
            },
          ],
          order: [],
          language: {
            décimal: "",
            emptyTable: "Aucune donnée disponible dans le tableau",
            infoEmpty: "Showing 0 to 0 of 0 entries",
            info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
            infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
            infoPostFix: "",
            thousands: ",",
            lengthMenu: "Afficher les entrées du _MENU_",
            loadingRecords: "Loading...",
            processing: "Processing...",
            search: "Chercher :",
            stateSave: true,
            zeroRecords: "Aucun enregistrement correspondant trouvé",
            paginate: {
              first: "Premier",
              last: "Dernier",
              next: "Suivant",
              previous: "Précédent",
            },
            aria: {
              sortAscending: ": activate to sort column ascending",
              sortDescending: ": activate to sort column descending",
            },
          },
        });
      }, 10);
    },
    rendreFormatDateInYYYMMDD(date) {
      const options1 = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate = new Date(date);
      const newDate = new Intl.DateTimeFormat("fr-FR", options1);
      let dateConvert = null;
      let getMonthOfDate = newDate.format(formattedDate).split(" ")[2];
      switch (getMonthOfDate) {
        case "janvier":
          dateConvert = newDate.format(formattedDate).replace("ier", "");
          // //console.log(newDate.format(formattedDate).replace("ier", ""))
          break;
        case "février":
          dateConvert = newDate.format(formattedDate).replace("ier", "");
          // //console.log(newDate.format(formattedDate).replace("ier", ""))
          break;
        case "mars":
          dateConvert = newDate.format(formattedDate);
          // //console.log(newDate.format(formattedDate))
          break;
        case "avril":
          dateConvert = newDate.format(formattedDate).replace("il", "");
          // //console.log(newDate.format(formattedDate).replace("il", ""))
          break;
        case "mai":
          dateConvert = newDate.format(formattedDate);
          // //console.log(newDate.format(formattedDate))
          break;
        case "juin":
          dateConvert = newDate.format(formattedDate);
          // //console.log(newDate.format(formattedDate))
          break;
        case "juillet":
          dateConvert = newDate.format(formattedDate).replace("let", "");
          // //console.log(newDate.format(formattedDate).replace("let", ""))
          break;
        case "août":
          dateConvert = newDate.format(formattedDate);
          // //console.log(newDate.format(formattedDate))
          break;
        case "septembre":
          dateConvert = newDate.format(formattedDate).replace("embre", "");
          // //console.log(newDate.format(formattedDate).replace("embre", ""))
          break;
        case "octobre":
          dateConvert = newDate.format(formattedDate).replace("obre", "");
          // //console.log(newDate.format(formattedDate).replace("obre", ""))
          break;
        case "novembre":
          dateConvert = newDate.format(formattedDate).replace("embre", "");
          // //console.log(newDate.format(formattedDate).replace("embre", ""))
          break;
        case "décembre":
          dateConvert = newDate.format(formattedDate).replace("embre", "");
          // //console.log(newDate.format(formattedDate).replace("embre", ""))
          break;
        default:
          dateConvert;
      }

      return dateConvert;
    },
    resetDataForReservation() {
      this.dateReservation = {
        start: "",
        end: "",
      };
      // const element = document.querySelectorAll('.vc-day-content')
      //   element.forEach(item=>{

      //   this.addDateWithformatConvert.forEach(datReserved=>{
      //     //console.log("datReserved",datReserved)
      //     if(item.ariaLabel.replace(".","") === datReserved){
      //       //console.log("ITEM",item)
      //       item.style.backgroundColor='#aeaeae00'

      //     }
      //   })

      // })
    },
    RetouchDataForReservationLaunch(identifiantReservation) {
      this.listReservation.forEach((item) => {
        if (item.id == identifiantReservation) {
          this.reservationAtModify = item;
          this.nouvelleProprieteChoose = item.propriety;
        }
      });

      this.chooseResidence(0, this.reservationAtModify);

      // const element = document.querySelectorAll('.vc-day-content')
      // element.forEach(item=>{

      //   this.addDateWithformatConvert.forEach(datReserved=>{
      //     //console.log("datReserved",datReserved)
      //     if(item.ariaLabel.replace(".","") === datReserved){
      //       //console.log("ITEM",item)
      //       item.style.backgroundColor='red'
      //     }
      //   })

      // })
    },
    DoForBook(booking) {
      //console.log("booking", booking);

      this.windowForBook = !this.windowForBook;
      this.EcriteauForHeaDoBooking = Number(booking) != 0 ? 0 :1;
      this.spaceForModifyMyReservation = Number(booking) > 0 ? 1 : 0;
      this.idEmetteur = booking;
      this.resetDataForReservation();

      if (booking > 0) {
        this.RetouchDataForReservationLaunch(booking);
      }
    },
    async getListResidenceDisponible() {
      try {
        const ResidenceDisponible = await this.getAllList(
          "getUnreserveResidences"
        );
        this.listResidenceDisponible = ResidenceDisponible.data;
        // console.log(
        //   "this.listResidenceDisponible",
        //   this.listResidenceDisponible
        // );
      } catch (error) {
        console.log(error);
      }
    },
    async getListResidenceReserve() {
      try {
        let dataObject = new LienConnected(
          "reservations",
          this.$store.state.token
        );
        const allList = await dataObject.getAllData();
        if (allList.data.status === true) {
          this.listReservationResidence = allList.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getListClient() {
      try {
        let dataObject = new LienConnected("clients", this.$store.state.token);
        const allList = await dataObject.getAllData();
        //console.log("allList", allList);
        if (allList.data.status === true) {
          this.listClient = allList.data.data;
        }
        //console.log("this.listClient", this.listClient);
      } catch (error) {
        console.log(error);
      }
    },
    selectDateForReservation() {
      for (const [key, value] of Object.entries(this.dateReservation)) {
        if (key == "start") {
          this.dateStartReservation = value;
        } else if (key == "end") {
          this.dateEndReservation = value;
        }
      }
    },
    async booking() {
      this.preloaderBooking = true;
      this.selectDateForReservation();
      if (
        this.EcriteauForHeaDoBooking === true &&
        (!this.dateStartReservation || !this.dateEndReservation)
      ) {
        Swal.fire({
          icon: "info",
          title: "Veuillez sélectionner une date début et une date fin.",
          showConfirmButton: true,
        });
        this.preloaderBooking = false;
      }
      const dateStartReservation = this.dateStartReservation
        .toISOString()
        .slice(0, 10);
      const dateEndReservation = this.dateEndReservation
        .toISOString()
        .slice(0, 10);
      let dataSend = {
        user_id: this.clientReservation ? this.clientReservation.id : null,
        lastname: this.nomClient,
        firstname: this.prenomsClient,
        email: this.emailClient,
        phone: this.phoneClient,
        propriety_id: this.residence.id,
        number_people: this.nbreOfPersonne,
        date_start: dateStartReservation,
        date_end: dateEndReservation,
        roles: this.clientReservation ? null : [2],
        raison: this.raisonForLocation,
      };
      try {
        const createdReservation = new LienConnected(
          "reservations",
          this.$store.state.token
        );
        const booking = await createdReservation.sendData(dataSend);
        const response = booking;
        if (response.data.status === true) {
          this.preloaderBooking = false;
          Swal.fire({
            icon: "success",
            title: "Réservation effectuée",
            showConfirmButton: true,
          });

          this.reservationAtModify.date_start = dateStartReservation;
          this.reservationAtModify.date_end = dateEndReservation;
          this.closeWindowReserbooking();
          this.getListReservation();
          //console.log("RESPONSE FOR RESERVATION", response);
        }
      } catch (error) {
        console.log(error);
        this.preloaderBooking = false;
      }

      //console.log("dataSend", dataSend);
    },
    convertDate(date) {
      return date.toISOString().slice(0, 10);
    },
    forBoucleInArray(
      tableau,
      idOfReservation,
      dateStartReservation,
      dateEndReservation
    ) {
      tableau.forEach((item) => {
        if (item.id == idOfReservation) {
          item.date_start = this.convertDate(dateStartReservation);
          item.date_end = this.convertDate(dateEndReservation);
        }
      });
    },
    async modifyReservation(idReservation) {
      this.selectDateForReservation();
      let dataSend = {
        user_id: this.reservationAtModify.user.id,
        propriety_id: this.nouvelleProprieteChoose.id,
        number_people: this.reservationAtModify.number_people,
        date_start: this.dateStartReservation
          ? this.convertDate(this.dateStartReservation)
          : null,
        date_end: this.dateEndReservation
          ? this.convertDate(this.dateEndReservation)
          : null,
        roles: this.clientReservation ? null : [2],
        raison: this.reservationAtModify.raison,
      };
      //console.log("this.dateReservation", this.dateReservation);
      //console.log("dataSendRESTORE", dataSend);
      try {
        this.preloaderModifyReservation = true;
        const createdReservation = new LienConnected(
          "reservations",
          this.$store.state.token
        );
        const booking = await createdReservation.modifyData(
          dataSend,
          idReservation
        );
        const response = booking;

        if (booking.data.status === true) {
          this.preloaderBooking = false;
          Swal.fire({
            icon: "success",
            title: "Réservation modifiée",
            showConfirmButton: true,
          });
          this.windowForBook = false;
          const idOfReservation = booking.data.user.id;
          this.forBoucleInArray(
            this.listReservation,
            idOfReservation,
            this.dateStartReservation,
            this.dateEndReservation
          );
          this.preloaderModifyReservation = false;
          this.responseEssay = response
          //console.log("RESPONSE FOR RESERVATION", response);
        }
      } catch (error) {
        console.log(error);
        this.preloaderModifyReservation = true;
      }
    },
    async removeBooking() {
      let dataSend = {
        status: "Annulée",
        cancellation_reason: this.commentRemoveBooking,
      };
      this.preloaderSpinner = true;
      try {
        const cancelReservation = new LienConnected(
          "reservations",
          this.$store.state.token
        );
        const booking = await cancelReservation.modifyData(
          dataSend,
          this.idPropriete
        );
        const response = booking;
        if (response.data.status === true) {
          //console.log("RESPONSE FOR RESERVATION", response);
          this.windowForCancelBooking = !this.windowForCancelBooking;
          this.preloaderSpinner = false;

          const cancelBooking = this.listReservation.findIndex(
            (p) => p.id === this.idPropriete
          );
          this.listReservation.splice(cancelBooking, 1);
          Swal.fire({
            icon: "success",
            title: "Réservation annulée.",
            showConfirmButton: true,
          });
        }
      } catch (error) {
        console.log(error);
        this.preloaderSpinner = false;
      }
    },
    cancelBooking(id) {
      this.idPropriete = id;
      this.EcriteauForHeaDoBooking = false;
      this.windowForCancelBooking = !this.windowForCancelBooking;
    },
    closeWindowReserbooking() {
      this.windowForBook = !this.windowForBook;
      this.disabledDates = [];
      this.reservationAtModify = "";
      this.clientReservation = "";
      this.arrayAllDateDisabled = [];
      this.dateStartReservation = "";
      this.dateEndReservation = "";
      this.nbreOfPersonne = "";
      this.residence = "";
      this.dateReservation = {
        start: "",
        end: "",
      };
    },
    calculForknowHowDayOfReservation(dateStart, dateEnd) {
      return Math.round(
        (new Date(dateEnd.slice(0, 10)) - new Date(dateStart.slice(0, 10))) /
          (1000 * 60 * 60 * 24)
      );
    },
    verifyIfRoleInclus(role){
      const {roles} = JSON.parse(localStorage.getItem("user"))
        return roles.some(item=>item.label === role)
       }
  },
  created() {
    this.getListReservation();
    this.getListResidenceDisponible();
    this.getListResidenceReserve();
    this.getListClient();
  },
};
</script>
<template>
  <div class="col-lg-9 col-md-12">
    <div class="dashboard-wraper">
      <section v-if="windowForCancelBooking" class="confirmation_modifie">
        <div class="card">
          <h4 class="text-center text-secondary">
            Voulez-vous annuler la réservation ?
          </h4>
          <label class="text-dark text-start h6">Motif</label>
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            v-model="commentRemoveBooking"
          ></textarea>

          <div class="d-flex justify-content-center align-items-center my-2">
            <div>
              <button
                class="btn btn-all bg-dark text-light mx-2"
                @click.prevent="removeBooking"
              >
                Confirmer
                <span
                  class="spinner-border spinner-border-sm ms-2"
                  v-show="preloaderSpinner"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
              <button
                class="btn bg-danger mx-2 text-white"
                @click.prevent="
                  windowForCancelBooking = !windowForCancelBooking
                "
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      </section>
      <section class="conteneur_modify" v-show="windowForBook">
        <div class="content position-relative">
          <div
            class="ecriteau text-start d-flex justify-content-between align-items-center"
          >
            <h4>
              <span v-if="EcriteauForHeaDoBooking">Faire une réservation</span>
              <span v-if="!EcriteauForHeaDoBooking"
                >Modifier la réservation</span
              >
            </h4>
            <i
              class="bi bi-x-circle close h1"
              @click.prevent="closeWindowReserbooking"
            ></i>
          </div>
          <div class="col-lg-12 col-md-12">
            <form>
              <div class="submit-pages text-start">
                <div class="form-submit">
                  <div class="submit-section">
                    <div class="row">
                      <div
                        class="mb-3"
                        v-if="
                          !clientReservation && !spaceForModifyMyReservation
                        "
                      >
                        <n-space>
                          <n-switch
                            v-model:value="active"
                            @update="active = !active"
                          />
                          <span class="text-dark fw-bold"
                            >Enregistrer un nouveau client</span
                          >
                        </n-space>
                      </div>

                      <section class="row" v-if="spaceForModifyMyReservation">
                        <div class="form-group col-md-6" v-if="!active">
                          <label class="text-dark">Email du client</label>

                          <v-select
                            v-model="reservationAtModify.user"
                            :searchable="true"
                            :options="listClient"
                            :disabled="true"
                            label="email"
                          >
                            <template #option="{ firstname, lastname, email }">
                              <div class="conteneur_residence">
                                <span class="mx-2">
                                  {{ firstname }} {{ lastname }}
                                </span>
                                <label class="text-dark">({{ email }})</label>
                              </div>
                            </template>
                          </v-select>
                        </div>
                        <div class="form-group col-md-6">
                          <label class="text-dark">Résidence</label>

                          <v-select
                            v-model="nouvelleProprieteChoose"
                            :searchable="true"
                            :options="listResidenceDisponible"
                            label="name"
                            @update:modelValue="chooseResidence(0)"
                          >
                            <template #option="{ name, photos }">
                              <div class="conteneur_residence">
                                <span class="mx-2">
                                  {{ name }}
                                </span>
                                <img
                                  v-if="photos.length"
                                  class="w-25"
                                  :src="
                                    lienUrl.replace('/api/', '/') +
                                    'public/public/image/' +
                                    photos[0].image
                                  "
                                  :alt="photos[0].image"
                                />
                                <img v-else alt="dexter.img" src="" />
                              </div>
                            </template>
                          </v-select>
                        </div>

                        <div class="form-group col-md-6">
                          <label class="text-dark">Nombre de personne</label>
                          <n-input-number
                            disabled
                            :max="reservationAtModify.number_people"
                            min="1"
                            v-model:value="reservationAtModify.number_people"
                          />
                        </div>

                        <div class="form-group col-md-12">
                          <label class="text-dark d-block">Motif</label>
                          <textarea
                            disabled
                            class="form-control"
                            v-model="reservationAtModify.raison"
                            name=""
                            id=""
                            cols="30"
                            rows="10"
                          >
                          </textarea>
                        </div>
                      </section>

                      <div
                        class="form-group col-md-6"
                        v-if="!active && !spaceForModifyMyReservation"
                      >
                        <label class="text-dark">Email du client</label>

                        <v-select
                          v-model="clientReservation"
                          :searchable="true"
                          :options="listClient"
                          label="email"
                        >
                          <template #option="{ firstname, lastname, email }">
                            <div class="conteneur_residence">
                              <span class="mx-2">
                                {{ firstname }} {{ lastname }}
                              </span>
                              <label class="text-dark">({{ email }})</label>
                            </div>
                          </template>
                        </v-select>
                      </div>

                      <section v-if="active" class="col-md-12 row">
                        <div class="form-group col-md-6">
                          <label class="text-dark">Nom du client</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="nomClient"
                          />
                        </div>

                        <div class="form-group col-md-6">
                          <label class="text-dark">Prénoms du client</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="prenomsClient"
                          />
                        </div>
                        <div class="form-group col-md-6">
                          <label class="text-dark">Email du client</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="emailClient"
                          />
                        </div>
                        <div class="form-group col-md-6">
                          <label class="text-dark"
                            >Téléphone (de préférence numéro de portable)</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="phoneClient"
                            pattern="[0-9]{10}"
                          />
                          <span
                            v-if="
                              phoneClient.length > 10 &&
                              !phoneClient.match(/^[0-9]{10}$/)
                            "
                            class="text-danger"
                            >Veuillez rentrer 10 chiffre</span
                          >
                        </div>
                      </section>

                      <div
                        class="form-group col-md-6"
                        v-if="!spaceForModifyMyReservation"
                      >
                        <label class="text-dark">Résidence</label>

                        <v-select
                          v-model="residence"
                          :searchable="true"
                          :options="listResidenceDisponible"
                          label="name"
                          @update:modelValue="chooseResidence(1)"
                        >
                          <template #option="{ name, photos }">
                            <div class="conteneur_residence">
                              <span class="mx-2">
                                {{ name }}
                              </span>
                              <img
                                v-if="photos.length"
                                class="w-25"
                                :src="
                                  lienUrl.replace('/api/', '/') +
                                  'public/public/image/' +
                                  photos[0].image
                                "
                                :alt="photos[0].image"
                              />
                              <img v-else alt="dexter.img" src="" />
                            </div>
                          </template>
                        </v-select>
                      </div>
                      <div
                        class="form-group col-md-6"
                        v-if="!spaceForModifyMyReservation"
                      >
                        <label class="text-dark"
                          >Nombre de personne autorisées</label
                        >
                        <n-input-number
                          :max="residence.person_maxi"
                          min="1"
                          v-model:value="nbreOfPersonne"
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label class="text-dark">Réserver du : </label>
                        <VDatePicker
                          :min-date="new Date().toJSON().slice(0, 10)"
                          v-model.range="dateReservation"
                          :disabled-dates="arrayAllDateDisabled"
                        />
                      </div>

                      <div
                        class="form-group col-md-12"
                        v-if="!spaceForModifyMyReservation"
                      >
                        <label class="text-dark d-block">Motif</label>
                        <textarea
                          class="form-control"
                          v-model="raisonForLocation"
                          name=""
                          id=""
                          cols="30"
                          rows="10"
                        >
                        </textarea>
                      </div>
                      <div
                        class="text-start mt-3"
                        v-if="
                          residence &&
                          dateReservation &&
                          !spaceForModifyMyReservation
                        "
                      >
                        <button
                          class="btn bg-dark rounded"
                          @click.prevent="booking"
                        >
                          Réserver
                          <span
                            class="spinner-border spinner-border-sm ms-2"
                            v-show="preloaderBooking"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                      </div>
                      <div
                        class="text-start mt-3"
                        v-if="spaceForModifyMyReservation"
                      >
                        <button
                          class="btn bg-dark text-light rounded"
                          @click.prevent="
                            modifyReservation(reservationAtModify.id)
                          "
                        >
                          Modifier
                          <span
                            class="spinner-border spinner-border-sm ms-2"
                            v-show="preloaderModifyReservation"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <div class="conteneur_modify" v-if="showDetail">
        <div class="content position-relative">
          <div
            class="ecriteau text-start d-flex justify-content-between align-items-center"
          >
            <h4>Détail de la réservation</h4>
            <i
              class="bi bi-x-circle h1"
              @click.prevent="showDetail = !showDetail"
            ></i>
          </div>
          <div class="text-start px-3">
            <div
              class="submit-pages text-start color_container"
              v-if="detailReservation"
            >
              <div class="row container product cont">
                <div class="form-group col-md-12 col-sm-12">
                  <div>
                    <h5 class="badge bg-info">Info Résidence</h5>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-sm-12">
                      <strong>Date de réservation</strong>
                      <h6 class="p-0 m-0">
                        {{
                          new Date(
                            detailReservation.created_at
                          ).toLocaleDateString("fr")
                        }}
                      </h6>
                      <!-- <strong>Date d'annulation</strong>
                      <h6 class="p-0 m-0">
                        {{ new Date(detailReservation.created_at).toLocaleDateString('fr')}}
                         
                        </h6> -->
                      <strong v-if="detailReservation.cancellation_reason"
                        >Raison de l'annulation</strong
                      >
                      <h6 class="p-0 m-0">
                        {{ detailReservation.cancellation_reason }}
                      </h6>
                      <strong>Réserver pour</strong>
                      <h6 class="p-0 m-0">
                        {{
                          new Date(
                            detailReservation.date_start
                          ).toLocaleDateString("fr")
                        }}
                        au
                        {{
                          new Date(
                            detailReservation.date_end
                          ).toLocaleDateString("fr")
                        }}
                      </h6>
                      <strong>Code de réservation</strong>
                      <h6 class="p-0 m-0">{{ detailReservation.code }}</h6>
                      <strong>Résidence</strong>
                      <h6 class="p-0 m-0">
                        {{ detailReservation.propriety.name }}
                      </h6>
                      <strong>Prix (Fcfa) </strong>
                      <h6 class="p-0 m-0">
                        {{
                          moneyFormat.format(
                            detailReservation.cost *
                              Math.round(
                                (new Date(
                                  detailReservation.date_end.slice(0, 10)
                                ) -
                                  new Date(
                                    detailReservation.date_start.slice(0, 10)
                                  )) /
                                  (1000 * 60 * 60 * 24)
                              )
                          )
                        }}
                      </h6>
                      <strong v-if="municipalityReservation">Située</strong>
                      <h6 class="p-0 m-0" v-if="municipalityReservation">
                        {{ municipalityReservation.city.name }} ,
                        {{ municipalityReservation.name }}
                      </h6>
                      <strong>Situé précisément</strong>
                      <h6 class="p-0 m-0">
                        {{ detailReservation.propriety.adress }}
                      </h6>
                      <strong v-if="detailReservation.propriety.description"
                        >Description</strong
                      >
                      <h6 class="p-0 m-0">
                        {{ detailReservation.propriety.description }}
                      </h6>
                      <strong>Galerie</strong>
                      <MazGallery
                        v-if="photoResidence.length"
                        :images="photoResidence"
                        :height="400"
                      />
                      <h1 v-else class="text-light">Pas de photo</h1>
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-12 col-sm-12">
                  <div>
                    <h5 class="badge bg-info">Info Client</h5>
                  </div>

                  <strong>Nom & prénoms</strong>
                  <h6 class="p-0 m-0">
                    {{ detailReservation.user.lastname }}
                    {{ detailReservation.user.firstname }}
                  </h6>
                  <strong>Email </strong>
                  <h6 class="p-0 m-0">{{ detailReservation.user.email }}</h6>
                  <strong>Télephone </strong>
                  <h6 class="p-0 m-0">{{ detailReservation.user.phone }}</h6>
                  <strong>Pièces d'identité</strong>

                  <div v-if="photoPieces.length">
                    <span v-for="(item, index) in photoPieces" :key="index">
                      <n-image width="100" :src="item" />
                    </span>
                  </div>
                  <div v-else>
                    <h5 class="text-light">Pas de pièces d'identité</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="form-submit position-relative pt-3"
        :class="preloader ? 'pre' : 'null'"
      >
        <div id="preloader" class="position-absolute" v-show="preloader">
          <div class="preloader"><span></span><span></span></div>
        </div>
        <h2 class="text-start text-light-2">Réservations</h2>
        <ul class="d-flex justify-content-start align-items-center px-5 listes">
          <li @click.prevent="tabulation = 'enCours'">
            <a
              href="#"
              :class="{ color: tabulation == 'enCours' }"
              class="lien position-relative"
              >En cours
              <span
                v-show="tabulation == 'enCours'"
                class="badge bg-danger badge-total position-absolute"
                >{{ listReservation.length }}</span
              >
            </a>
          </li>
          <li @click.prevent="tabulation = 'annule'">
            <a
              href="#"
              :class="{ color: tabulation == 'annule' }"
              class="lien position-relative"
              >Annulées
              <span
                v-show="tabulation == 'annule'"
                class="badge bg-danger badge-total position-absolute"
                >{{ listReservationAnnulees.length }}</span
              >
            </a>
          </li>
          <li @click.prevent="tabulation = 'historiqueReservation'">
            <a
              href="#"
              :class="{ color: tabulation == 'historiqueReservation' }"
              class="lien position-relative"
              >Échues
              <span
                v-show="tabulation == 'historiqueReservation'"
                class="badge bg-danger badge-total position-absolute"
                >{{ listHistoriqueReservation.length }}</span
              >
            </a>
          </li>
        </ul>
        <div class="text-end">
          <button
            class="btn btn-all m-2 bg-dark text-light"
            @click.prevent="DoForBook(0)"
          >
            Faire une réservation
          </button>
        </div>
      </div>
      <section v-show="tabulation == 'enCours'">
        <div class="col-sm-12 dataTable position-relative" v-if="!preloader">
          <table id="MyTableData" class="table text-center table-responsive">
            <thead>
              <tr class="text-center">
                <th class="bg-light text-center">Date de réservation</th>
                <th class="bg-light text-center">Client</th>
                <th class="bg-light text-center">Résidence</th>
                <th class="bg-light text-center">Image</th>
                <th class="bg-light text-center">Date d'entrée</th>
                <th class="bg-light text-center">Date de sortie</th>
                <th class="bg-light text-center">Nombre de jour</th>
                <th class="bg-light text-center">Prix total(Fcfa)</th>
                <th class="bg-light text-center">Code de réservation</th>

                <th class="bg-light text-center" 
                v-if="!this.verifyIfRoleInclus('Commercial')"
                >Actions</th>
                <th class="bg-light text-center">Détail</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in listReservation" :key="index" 
              :class="item.flag == 1 ? 'bg-warning':null"
              >
                <td
                  @click="windowDetailReservation(item.id, this.listReservation)"
                >
                  {{ new Date(item.created_at).toLocaleDateString("fr") }}
                </td>
                <td
                  @click="windowDetailReservation(item.id, this.listReservation)"
                >
                  {{ item.user.lastname }} {{ item.user.firstname }}
                </td>
                <td
                  @click="windowDetailReservation(item.id, this.listReservation)"
                >
                  <span v-if="item.propriety">{{ item.propriety.name }}</span>
                </td>
                <td>
                  <n-image
                    width="50"
                    :src="
                      this.lienUrl.replace('/api/', '/') +
                      'public/public/image/' +
                      item.propriety.photos[0].image
                    "
                    v-if="item.propriety.photos.length"
                  />

                  <img src="" alt="dexter" v-else />
                </td>
                <td
                  @click="windowDetailReservation(item.id, this.listReservation)"
                >
                  {{ new Date(item.date_start).toLocaleDateString("fr") }}
                </td>
                <td
                  @click="windowDetailReservation(item.id, this.listReservation)"
                >
                  {{ new Date(item.date_end).toLocaleDateString("fr") }}
                </td>
                <td
                  @click="windowDetailReservation(item.id, this.listReservation)"
                >
                  {{
                    this.calculForknowHowDayOfReservation(
                      item.date_start,
                      item.date_end
                    )
                  }}
                </td>
                <td
                  @click="windowDetailReservation(item.id, this.listReservation)"
                >
                  {{
                    moneyFormat.format(
                      item.cost *
                        this.calculForknowHowDayOfReservation(
                          item.date_start,
                          item.date_end
                        )
                    )
                  }}
                </td>
                <td
                  @click="windowDetailReservation(item.id, this.listReservation)"
                >
                  {{ item.code }}
                </td>

                <td 
                v-if="!this.verifyIfRoleInclus('Commercial')">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <button class="btn bg-dark text-light" @click="DoForBook(item.id)">
                      Modifier
                    </button>

                    <button
                      class="btn bg-danger"
                      @click="cancelBooking(item.id)"
                    >
                      Annuler
                    </button>
                  </div>
                </td>
                <td>
                  <i
                    class="bi bi-eye"
                    @click="
                      windowDetailReservation(item.id, this.listReservation)
                    "
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section v-show="tabulation == 'annule'">
        <div class="col-sm-12 dataTable position-relative" v-if="!preloader">
          <table id="MyTableData1" class="table text-center table-responsive">
            <thead>
              <tr class="text-center">
                <th class="bg-light text-center">Date de réservation</th>
                <th class="bg-light text-center">Client</th>
                <th class="bg-light text-center">Résidence</th>
                <th class="bg-light text-center">Image</th>
                <th class="bg-light text-center">Date d'entrée</th>
                <th class="bg-light text-center">Date de sortie</th>
                <th class="bg-light text-center">Nombre de jour</th>
                <th class="bg-light text-center">Prix (Fcfa)</th>
                <th class="bg-light text-center">Code de réservation</th>

                <!-- <th class="bg-light text-center">Actions</th> -->
                <th class="bg-light text-center">Détail</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in listReservationAnnulees" :key="index">
                <td
                  @click="
                    windowDetailReservation(item.id, this.listReservationAnnulees)
                  "
                >
                  {{ new Date(item.created_at).toLocaleDateString("fr") }}
                </td>
                <td
                  @click="
                    windowDetailReservation(item.id, this.listReservationAnnulees)
                  "
                >
                  {{ item.user.lastname }} {{ item.user.firstname }}
                </td>
                <td
                  @click="
                    windowDetailReservation(item.id, this.listReservationAnnulees)
                  "
                >
                  <span v-if="item.propriety">{{ item.propriety.name }}</span>
                </td>
                <td>
                  <!-- <n-image
                width="50" 
                :src='this.lienUrl.replace("/api/", "/")+"public/public/image/"+item.propriety.photos[0].image' 
                v-if="item.propriety.photos"
              />
              <img src="" alt="dexter" v-else> -->
                </td>
                <td
                  @click="
                    windowDetailReservation(item.id, this.listReservationAnnulees)
                  "
                >
                  {{ new Date(item.date_start).toLocaleDateString("fr") }}
                </td>
                <td
                  @click="
                    windowDetailReservation(item.id, this.listReservationAnnulees)
                  "
                >
                  {{ new Date(item.date_end).toLocaleDateString("fr") }}
                </td>
                <td
                  @click="
                    windowDetailReservation(item.id, this.listReservationAnnulees)
                  "
                >
                  {{
                    Math.round(
                      (new Date(item.date_end.slice(0, 10)) -
                        new Date(item.date_start.slice(0, 10))) /
                        (1000 * 60 * 60 * 24)
                    )
                  }}
                </td>
                <td
                  @click="
                    windowDetailReservation(item.id, this.listReservationAnnulees)
                  "
                >
                  {{
                    moneyFormat.format(
                      item.cost *
                        Math.round(
                          (new Date(item.date_end.slice(0, 10)) -
                            new Date(item.date_start.slice(0, 10))) /
                            (1000 * 60 * 60 * 24)
                        )
                    )
                  }}
                </td>
                <td
                  @click="
                    windowDetailReservation(item.id, this.listReservationAnnulees)
                  "
                >
                  {{ item.code }}
                </td>

                <!-- <td >
               <div class="d-flex justify-content-between align-items-center">
                <button class="btn bg-warning"
                @click="DoForBook(item.id)">Modifier</button>
  
                <button class="btn bg-danger"
                @click="cancelBooking(item.id)">Annuler</button>
                
              
               </div>
            
              
              </td> -->
                <td>
                  <i
                    class="bi bi-eye"
                    @click="
                      windowDetailReservation(
                        item.id,
                        this.listReservationAnnulees
                      )
                    "
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section v-show="tabulation == 'historiqueReservation'">
        <div class="col-sm-12 dataTable position-relative" v-if="!preloader">
          <table id="MyTableData3" class="table text-center table-responsive">
            <thead>
              <tr class="text-center">
                <th class="bg-light text-center">Date de réservation</th>
                <th class="bg-light text-center">Client</th>
                <th class="bg-light text-center">Résidence</th>
                <th class="bg-light text-center">Image</th>
                <th class="bg-light text-center">Date d'entrée</th>
                <th class="bg-light text-center">Date de sortie</th>
                <th class="bg-light text-center">Nombre de jour</th>
                <th class="bg-light text-center">Prix (Fcfa)</th>
                <th class="bg-light text-center">Code de réservation</th>

                <th class="bg-light text-center">Actions</th>
                <!-- <th class="bg-light text-center">Détail</th> -->
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in listHistoriqueReservation"
                :key="index"
              >
                <td
                  @click="
                    windowDetailReservation(item.id, listHistoriqueReservation)
                  "
                >
                  {{ new Date(item.created_at).toLocaleDateString("fr") }}
                </td>
                <td
                  @click="
                    windowDetailReservation(item.id, listHistoriqueReservation)
                  "
                >
                  {{ item.user.lastname }} {{ item.user.firstname }}
                </td>
                <td
                  @click="
                    windowDetailReservation(item.id, listHistoriqueReservation)
                  "
                >
                  <span v-if="item.propriety">{{ item.propriety.name }}</span>
                </td>
                <td>
                  <!-- <n-image
                width="50" 
                :src='this.lienUrl.replace("/api/", "/")+"public/public/image/"+item.propriety.photos[0].image' 
                v-if="item.propriety.photos.length"
              />
              <img src="" alt="dexter" v-else> -->
                </td>
                <td
                  @click="
                    windowDetailReservation(item.id, listHistoriqueReservation)
                  "
                >
                  {{ new Date(item.date_start).toLocaleDateString("fr") }}
                </td>
                <td
                  @click="
                    windowDetailReservation(item.id, listHistoriqueReservation)
                  "
                >
                  {{ new Date(item.date_end).toLocaleDateString("fr") }}
                </td>
                <td
                  @click="
                    windowDetailReservation(item.id, listHistoriqueReservation)
                  "
                >
                  {{
                    Math.round(
                      (new Date(item.date_end.slice(0, 10)) -
                        new Date(item.date_start.slice(0, 10))) /
                        (1000 * 60 * 60 * 24)
                    )
                  }}
                </td>
                <td
                  @click="
                    windowDetailReservation(item.id, this.listReservationAnnulees)
                  "
                >
                  {{
                    moneyFormat.format(
                      item.cost *
                        Math.round(
                          (new Date(item.date_end.slice(0, 10)) -
                            new Date(item.date_start.slice(0, 10))) /
                            (1000 * 60 * 60 * 24)
                        )
                    )
                  }}
                </td>
                <td
                  @click="
                    windowDetailReservation(item.id, listHistoriqueReservation)
                  "
                >
                  {{ item.code }}
                </td>

                <td>
                  <i
                    class="bi bi-eye"
                    @click="
                      windowDetailReservation(item.id, listHistoriqueReservation)
                    "
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  </div>
</template>
<style scoped>
tbody tr div.n-space {
  justify-content: center !important;
}

.bg-secondary {
  background-color: rgb(73, 72, 72) !important;
}
.text-light {
  font-size: 0.8em !important;
}
.cont .form-group {
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.222);
  border-radius: 8px;
}
strong {
  font-size: 1em !important;
  color: gray;
  text-decoration: underline;
  padding: 0 0 0.5em 0;
  font-weight: bold;
  text-transform: uppercase;
}
.bg-info {
  background-color: black !important;
}
section {
  color: black;
}
.badge,
.text-light-2 {
  color: white !important;
}

.addField {
  width: auto !important;
}
.designation {
  font-size: 1.3em !important;
}
.btn-rejet {
  background-color: rgb(219, 0, 0) !important;
  border: 1px solid rgb(219, 0, 0) !important;
  margin: 0 2em;
  color: white !important;
}
.btn-rejet:hover {
  background-color: rgb(219, 0, 0) !important;
  border: 1px solid rgb(219, 0, 0) !important;
  margin: 0 2em;
  color: white !important;
}
#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: rgba(244, 242, 242, 0);
  display: flex;
  justify-content: center;
  place-items: center;
}
.bg-danger,
.bg-danger:hover {
  color: white !important;
}

h6 {
  margin: 0 0 1em 0 !important;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}
[type="radio"]:checked + label:after {
  opacity: 0 !important;
}
.circuit {
  padding: 0 !important;
}
.card {
  padding: 1em 3em;
  width: 50%;
}
.bg-primary {
  background-color: rgb(55, 137, 167) !important;
  border: 2px solid rgb(55, 137, 167) !important;
  color: white !important;
}
.bi-eye,
.bi-x-circle,
.bi-pencil,
.bi-envelope-paper,
.bi-trash,
.delete {
  cursor: pointer;
}
.pre {
  padding: 0 0 15em 0;
}
.table {
  border: 1px solid black;
}
td {
  border-right: 2px solid black;
}
.reset {
  cursor: pointer;
}
.confirmation_comment {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 909;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.confirmation_modifie {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 90;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 90;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.close_modal {
  position: absolute;
  top: 1em;
  right: 1em;
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index: 909;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.submit-pages {
  padding: 1em 3em;
}
.text-Acquereur {
  margin: 1em 0;
}
.delete {
  position: absolute;
  right: 0;
  cursor: pointer;
  width: 20px;
}
.recherche {
  top: 41px;
  right: -0.6em;
  z-index: 9;
}
.recherche_with_value {
  top: 14px;
  right: -0.6em;
  z-index: 9;
}
.text-dark {
  color: black !important;
}
.recherche input {
  width: 217px;
  height: 35px;
  border: thin solid black;
  border-radius: 2px;
}
.conteneur_residence {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.conteneur_residence .w-25 {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}
.conteneur_residence img {
  object-fit: cover;
}

.listes {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.085);
  background-color: white;
  padding: 1em 0;
  border-radius: 10px;
}
.lien {
  text-decoration: none;
  font-size: 1.3em;

  color: rgb(218, 216, 216);
}
.lien:hover {
  color: rgb(255, 187, 0);
}
.color,
.colorHistorique,
.colorNew {
  color: rgb(0, 0, 0) !important;
  border-radius: 5px;
  padding: 0.5em;
  font-weight: bold;
  background-color: rgb(255, 187, 0);
}
</style>
../../utils