<script>
import $ from "jquery";
import Swal from "sweetalert2";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import LienConnected from "../../class_help_me";

import "vue-good-table-next/dist/vue-good-table-next.css";
export default {
  name: "transaction-vue",
  data() {
    return {
      account: true,
      showModal: false,
      showDetailBien: false,
      listTransactions: [],
      listProprietaires: [],
      moneyFormat: new Intl.NumberFormat("de-DE"),
      listClients: [],
      windowCreateUser: false,
      showDetailTransactiondetailTransactionForModify: false,
      showDetailTransactiondetailTransaction: false,
      detailTransaction: null,
      detailForModifyUser: {
        statut: "",
      },
      options :{
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
},
      photoResidence: [],
      roleUser: "",
      preloaderSpinner: false,
      preloader: false,
      preload: false,
      showWindowDelete: false,
      userId: "",
      idTransaction: "",
      showDetailModeCommodite: false,
      passwordUser: "",
      proofPieces: "",
      dataObject: "",
      lienUrl: new LienConnected().baseUrl,
      cniDoc: [],
      nameUtilisateur: "",
      prenomsUtilisateur: "",
      emailUtilisateur: "",
      phoneUtilisateur: "",
      roleUtilisateur: [],
      documentUtilisateur: [],
      passwordUtilisateur: "",
      confirmPassword: "",
      listRole: [],
      documentUtilisateurForModify: [],
      folder: [],
      detailProduct: null,
      preloaderCreateProprio: false,
      allTransactions: [],
      statusTransaction:{
        SUCCESS:"succes",
        PENDING:"En attente de validation...",
        FAILED:"Annulée"

      }
    };
  },
  methods: {
    async helpMeForRenderAllList(route) {
      this.preloader = true;
      const dataObject = new LienConnected(route, this.$store.state.token);
      let allList = await dataObject.getAllData();
      if (allList.data.status === true) {
        this.preloader = false;
        return allList.data.data;
      }
    },

    async getAllList() {
      this.allTransactions = await this.helpMeForRenderAllList("paiements");
      //console.log("this.allTransactions", this.allTransactions);

      setTimeout(function () {
        let printCounter = 0;
        $("#MyTableData").DataTable({
          pagingType: "full_numbers",
          pageLength: 10,
          processing: true,
          dom: "Bfrtip",
          buttons: [
            {
              extend: "copy",
              text: "Copier les données",
              messageTop: function () {
                printCounter++;

                if (printCounter === 1) {
                  Swal.fire({
                    icon: "success",
                    title:
                      "Les données ont été copiées dans le presse-papiers.",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                } else {
                  Swal.fire({
                    icon: "info",
                    title:
                      "Les données ont déjà été copiées dans le presse-papiers.",
                    showConfirmButton: true,
                  });
                }
              },
            },
            {
              extend: "csv",
              text: "Exporter en fichier excel",
              fieldSeparator: ";",
              fieldBoundary: '"',
              bom: true,
              exportOptions: {
                columns: [0],
              },
            },
            {
              extend: "print",
              text: "Imprimer les données",
            },
          ],
          order: [],
          language: {
            décimal: "",
            emptyTable: "Aucune donnée disponible dans le tableau",
            infoEmpty: "Showing 0 to 0 of 0 entries",
            info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
            infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
            infoPostFix: "",
            thousands: ",",
            lengthMenu: "Afficher les entrées du _MENU_",
            loadingRecords: "Loading...",
            processing: "Processing...",
            search: "Chercher :",
            stateSave: true,
            zeroRecords: "Aucun enregistrement correspondant trouvé",
            paginate: {
              first: "Premier",
              last: "Dernier",
              next: "Suivant",
              previous: "Précédent",
            },
            aria: {
              sortAscending: ": activate to sort column ascending",
              sortDescending: ": activate to sort column descending",
            },
          },
        });
      }, 10);
    },

    viewDetails(id) {
      this.showModal = true;
      this.allTransactions.forEach((item) => {
        if (item.id === id) {
          this.detailTransaction = item;
        }
      });
      this.idTransaction = id;
      //console.log("this.detailTransaction", this.detailTransaction);
    },
  },
  created() {
    this.getAllList();
  },
};
</script>
<template>
  <div class="col-lg-9 col-md-12">
    <div class="dashboard-wraper">
      <n-modal v-model:show="showModal">
        <n-card
          style="width: 800px"
          title="Détail"
          :bordered="false"
          size="huge"
          role="dialog"
          aria-modal="true"
        >
          <div class="container" v-if="this.detailTransaction.reservation">
            <div class="row">
              <div>
                <span
              class="badge mx-1 w-25"
              :class="
              this.detailTransaction.status == 'SUCCESS'
                        ? 'bg-success'
                        : this.detailTransaction.status == 'PENDING' ? 'bg-primary':'bg-danger'
                    "
              >{{  statusTransaction[this.detailTransaction.status] }}</span
            > 
              </div>
              <h6 class="col-lg-8">
                <span class="text-secondary"> Date</span> :
                {{
                  new Date(
                    this.detailTransaction.created_at
                  ).toLocaleDateString("fr")
                }}
              </h6>
              <h6 class="col-lg-8">
                <span class="text-secondary">Réservation enregistrée le </span> :
                {{
                  new Date(
                    this.detailTransaction.reservation.created_at
                  ).toLocaleDateString("fr")
                }}
              </h6>
              <h6 class="col-lg-8">
                <span class="text-secondary"> Client </span> :
                {{
                  `${
                    this.detailTransaction.reservation.user.firstname 
                  } ${
                    this.detailTransaction.reservation.user.lastname
                  }
                  `
                    
                  
                }}
              </h6>
              <h6 class="col-lg-8">
                <span class="text-secondary"> Catégorie</span> :
                {{ this.detailTransaction.source }}
              </h6>
              <h6 class="col-lg-8">
                <span class="text-secondary"> Montant</span> :
                {{ moneyFormat.format(this.detailTransaction.amount) }}
                Fcfa
              </h6>
              <h6 class="col-lg-8">
                <span class="text-secondary"> Opérateur</span> :
                {{ this.detailTransaction.channels === "Wallet" ? "WAVE":this.detailTransaction.channels  }}
              </h6>

              <h6 class="col-lg-8">
                <span class="text-secondary"> Référence de la transaction</span>
                : {{ this.detailTransaction.trans_id }}
              </h6>
              <h6 class="col-lg-8">
                <span class="text-secondary"> Bien </span> :
                {{ this.detailTransaction.reservation.propriety.name }} 
              </h6>
              <h6 class="col-lg-8">
                <span class="text-secondary"> Code de la réservation </span> :
                {{ this.detailTransaction.reservation.code }}
              </h6>
              <h6 class="col-lg-8">
                <span class="text-secondary"> Date d'entrée </span> :
                {{  new Date(this.detailTransaction.reservation.date_start).toLocaleDateString("fr",this.options) }}
              </h6>
              <h6 class="col-lg-8">
                <span class="text-secondary"> Date de sortie </span> :
                {{
                  new Date(
                    this.detailTransaction.reservation. date_end
                  ).toLocaleDateString("fr",this.options)
                }}
              </h6>
            </div>
          </div>
          <div v-else class="container">
            <div class="row">
            <div>
              <span
              class="badge mx-1 w-25"
              :class="
              this.detailTransaction.status == 'SUCCESS'
                        ? 'bg-success'
                        : this.detailTransaction.status == 'PENDING' ? 'bg-primary':'bg-danger'
                    "
              >{{ statusTransaction[this.detailTransaction.status] }}</span
            > 
            </div>
              <h6 class="col-lg-8">
                <span class="text-secondary"> Date</span> :
                {{
                  new Date(
                    this.detailTransaction.created_at
                  ).toLocaleDateString("fr")
                }}
              </h6>
              
              
              <h6 class="col-lg-8">
                <span class="text-secondary"> Catégorie</span> :
                {{ this.detailTransaction.source }}
              </h6>
              <h6 class="col-lg-8">
                <span class="text-secondary"> Montant</span> :
                {{ moneyFormat.format(this.detailTransaction.amount) }}
                Fcfa
              </h6>
              <h6 class="col-lg-8">
                <span class="text-secondary"> Opérateur</span> :
                {{ this.detailTransaction.channels === "Wallet" ? "WAVE":this.detailTransaction.channels  }}
              </h6>

             
             
             
              
            </div>

          </div>
        </n-card>
      </n-modal>

      <div
        class="form-submit position-relative"
        :class="preloader ? 'pre' : 'null'"
      >
        <div id="preloader" class="position-absolute" v-show="preloader">
          <div class="preloader"><span></span><span></span></div>
        </div>
        <h2 class="text-start text-light">Transactions</h2>
      </div>

      <section>
        <div class="col-sm-12 dataTable" v-if="!preloader">
          <table id="MyTableData" class="table text-center table-responsive">
            <thead>
              <tr class="text-center">
                <th class="bg-light text-center">Date</th>
                <th class="bg-light text-center">Référence</th>
                <th class="bg-light text-center">Catégorie</th>
                <th class="bg-light text-center">Statut</th>
                <th class="bg-light text-center">Montant (Fcfa)</th>
                <th class="bg-light text-center">Détail</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(transaction, index) in allTransactions" :key="index">
                <td
                  class="profil_name"
                  @click.prevent="viewDetails(transaction.id)"
                >
                  {{
                    new Date(transaction.created_at).toLocaleDateString("fr")
                  }}
                </td>
                <td @click.prevent="viewDetails(transaction.id)">
                  {{ transaction.trans_id }}
                </td>
                <td @click.prevent="viewDetails(transaction.id)">
                  {{ transaction.source }}
                </td>
                <td @click.prevent="viewDetails(transaction.id)">
                  <span
                    class="badge mx-1"
                    :class="
                      transaction.status == 'SUCCESS'
                        ? 'bg-success'
                        : transaction.status == 'PENDING' ? 'bg-primary':'bg-danger'
                    "
                    >{{ statusTransaction[transaction.status] }}</span
                  >
                </td>
                <td @click.prevent="viewDetails(transaction.id)">
                  {{ moneyFormat.format(transaction.amount) }}
                </td>

                <td class="text-center">
                  <span
                    class="d-flex justify-content-center align-items-center"
                  >
                    <i
                      class="bi bi-eye mx-2"
                      @click.prevent="viewDetails(transaction.id)"
                    ></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  </div>
</template>
<style scoped>
.container_color {
  background: #d9a509 !important;
}
.my_photo_profil {
  border-radius: 100%;
  width: 80px;
  height: 80px;
}

#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: rgba(244, 242, 242, 0);
  display: flex;
  justify-content: center;
  place-items: center;
}
.bg-danger,
.bg-danger:hover {
  color: white !important;
}
h6 {
  margin: 0 0 1em 0 !important;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}
[type="radio"]:checked + label:after {
  opacity: 0 !important;
}
.circuit {
  padding: 0 !important;
}
.card {
  padding: 1em 3em;
}
.bg-primary {
  background-color: rgb(55, 137, 167) !important;
  border: 2px solid rgb(55, 137, 167) !important;
  color: white !important;
}
.bi-eye,
.bi-x-circle,
.bi-pencil,
.bi-trash {
  cursor: pointer;
}
.pre {
  padding: 0 0 15em 0;
}
.badge {
  color: white !important;
}
.table {
  border: 1px solid black;
}
td {
  border-right: 2px solid black;
}
.bg-info {
  background-color: rgb(98, 91, 83) !important;
}
.confirmation_modifie {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.cont .form-group {
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.222);
  border-radius: 8px;
  background-color: black;
  color: white !important;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_detail {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 12;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}

.conteneur_modify .content,
.conteneur_detail .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #d9a509 !important;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.close_modal {
  position: absolute;
  top: 1em;
  right: 1em;
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index: 15;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.submit-pages {
  padding: 1em 3em;
}
.section span,
p span {
  font-weight: bold;
  text-decoration: underline;
}
strong {
  font-size: 1em !important;
  color: gray;
  text-decoration: underline;
  padding: 0 0 0.5em 0;
  font-weight: bold;
  text-transform: uppercase;
}
.text-dark {
  color: black !important;
}

.lien {
  text-decoration: none;
  font-size: 1.3em;

  color: rgb(218, 216, 216);
}

.listes {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.085);
  background-color: white;
  padding: 1em 0;
  border-radius: 10px;
}
.lien:hover {
  color: rgb(255, 187, 0);
}
.color,
.colorHistorique,
.colorNew {
  color: rgb(0, 0, 0) !important;
  border-radius: 5px;
  padding: 0.5em;
  font-weight: bold;
  background-color: rgb(255, 187, 0);
}
</style>
