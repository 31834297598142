<script>
import Swal from "sweetalert2";
// import { ref } from "vue";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import LienConnected from "../../class_help_me";
// const chooseProprietaire = ref(null);
// const dateToday = new Date().toISOString().slice(0, 10);

export default {
  name: "showPaiement",

  data() {
    return {
      lienUrl: new LienConnected().baseUrl,
      startDatePaiement: "",
      endDatePaiement: "",
      moneyFormat: new Intl.NumberFormat("de-DE"),
      preloader: false,
      paymentHistorique:null,
      loading:false,
    };
  },
  methods: {
    async showFacture() {
        this.loading = true
     
      try {
        const dataObject = new LienConnected(
        "getPaymentPropriety",
        this.$store.state.token
      );
      let data = {
        startDate:this.startDatePaiement,
        endDate:this.endDatePaiement
      }
      const response = await dataObject.sendData(data);
      //console.log("response",response)
      this.loading = false
        this.paymentHistorique = response.data.result;
        setTimeout(function () {
          let printCounter = 0;
          $("#MyTableData").DataTable({
            pagingType: "full_numbers",
            pageLength: 10,
            processing: true,
            dom: "Bfrtip",
            buttons: [
              {
                extend: "copy",
                text: "Copier les données",
                messageTop: function () {
                  printCounter++;

                  if (printCounter === 1) {
                    Swal.fire({
                      icon: "success",
                      title:
                        "Les données ont été copiées dans le presse-papiers.",
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  } else {
                    Swal.fire({
                      icon: "info",
                      title:
                        "Les données ont déjà été copiées dans le presse-papiers.",
                      showConfirmButton: true,
                    });
                  }
                },
              },
              {
                extend: "csv",
                text: "Exporter en fichier excel",
                fieldSeparator: ";",
                fieldBoundary: '"',
                bom: true,
                exportOptions: {
                  columns: [0, 1, 2, 3, 5],
                },
              },
              {
                extend: "print",
                text: "Imprimer les données",
              },
            ],
            order: [],
            language: {
              décimal: "",
              emptyTable: "Aucune donnée disponible dans le tableau",
              infoEmpty: "Showing 0 to 0 of 0 entries",
              info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
              infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
              infoPostFix: "",
              thousands: ",",
              lengthMenu: "Afficher les entrées du _MENU_",
              loadingRecords: "Loading...",
              processing: "Processing...",
              stateSave: true,
              zeroRecords: "Aucun enregistrement correspondant trouvé",
              paginate: {
                first: "Premier",
                last: "Dernier",
                next: "Suivant",
                previous: "Précédent",
              },
              aria: {
                sortAscending: ": activate to sort column ascending",
                sortDescending: ": activate to sort column descending",
              },
            },
          });
        }, 10);
      } catch (error) {
        console.log(error);
        this.preloader = false;
      }
    },
  },
};
</script>
<template>
  <div class="col-lg-9 col-md-12 position-relative">
    <div class="dashboard-wraper">
      <div class="row text-start">
        <!-- Submit Form -->
        <div class="col-lg-12 col-md-12">
          <form >
            <div class="submit-pages">
              <div class="form-submit">
                <h3 class="text-light">Voir la facture</h3>
                <div class="submit-section">
                  <div class="row">
                    <div class="form-group col-md-12">
                      <label class="mb-2">Période du </label>
                      <div
                        class="d-flex justify-content-between align-items-center gap-5"
                      >
                        <input
                          type="date"
                          class="form-control"
                          v-model="startDatePaiement"
                        />
                        <span class="fw-bold">Au</span>
                        <input
                          :disabled="!startDatePaiement"
                          type="date"
                          :min="startDatePaiement"
                          class="form-control"
                          v-model="endDatePaiement"
                         
                        />
                      </div>
                      <div  class="text-end my-2 fw-bold">
                        <button class="btn bg-dark text-light"  @click.prevent="showFacture">Envoyer</button>
                      </div> 
                    </div>

                    <div class="form-group col-md-12">
                      <div
                        class="col-sm-12 dataTable position-relative"
                       
                      >
                        <table
                          id="MyTableData"
                          class="table text-center table-responsive"
                          v-if="paymentHistorique"
                        >
                          <thead>
                            <tr class="text-center">
                              <th class="bg-light text-center">
                                Fournisseur
                              </th>
                              <th class="bg-light text-center">
                                Facture (Fcfa)
                              </th>
                             
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(value, key) in paymentHistorique"
                              :key="key"
                            >
                              <td>
                                {{
                                 key
                                }}
                              </td>
                              
                              <td class="text-center">
                               {{ moneyFormat.format(value.total_frais) }}
                              </td>
                              
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="text-center" v-if="loading">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.send-payment {
  width: 150px !important;
  height: 50px !important;
  font-weight: bold;
}
[type="file"] {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 5px;
  color: black;
}
.container {
  /* width: 70%; */
  margin: auto;
  /* height: 100%; */
  background: #f7fafc;
  border: 0.5px solid #a3a8b1;
  border-radius: 10px;
  /* padding: 30px; */
  position: relative;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}
.dashboard-wraper {
  height: 100%;
}
.bi-x-circle {
  cursor: pointer;
}

.circuit {
  padding: 0 !important;
}
[type="radio"]:checked + label:after {
  opacity: 0 !important;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index: 9999;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.submit-pages_view {
  padding: 1em 3em;
}
</style>
