<script>
// import {utils} from '../../../../utils'
import $ from "jquery";
import Swal from "sweetalert2";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import LienConnected from '../../class_help_me'

// const { NumberToLetter } = require("convertir-nombre-lettre");
export default {
  name: "historique-Paiement",
  data() {
    return {
      // utils:utils,
      windowRenderInClient: false,
      showWindowDelete: false,
      msgEmailErr: false,
      msgPhoneErr: false,
      moneyFormat: new Intl.NumberFormat("de-DE"),
      lienUrl:new LienConnected().baseUrl,
      showDetailProspectForModify: false,
      preload: false,
      preloader: false,
     
      user: this.$store.state.user,
      payment:"",
      windowForValidPayment:false,
      preloadValid:false,
      paymentHistorique:"",
      detailPayment:[],
      periodePayment:"",
    };
  },
  methods: {
    onClickAway(event){
    console.log(event)
    this.showDetailProspectForModify = !this.showDetailProspectForModify
    },
    SavePaiement() {
      this.$router.push("/paiement");
    },
    
     async ListeHistorique() {
      const dataObject = new LienConnected("getPayments",this.$store.state.token)
   const response = await dataObject.getAllData()
   //console.log("responseGetPayments",response.data.data)
   try{
    // this.preloader = true;
    this.paymentHistorique = response.data.data
    setTimeout(function () {
      let printCounter = 0;
           $("#MyTableData,#MyTableData1").DataTable({
              pagingType: "full_numbers",
              pageLength: 10,
              processing: true,
              dom: "Bfrtip",
                      buttons: [
        {
            extend: 'copy',
            text: 'Copier les données',
            messageTop: function () {
                    printCounter++;
 
                    if ( printCounter === 1 ) {
                      Swal.fire({
              icon: "success",
              title: 'Les données ont été copiées dans le presse-papiers.',
              showConfirmButton: false,
              timer: 1500,
            }); 
                    }
                    else {
                      Swal.fire({
              icon: "info",
              title: 'Les données ont déjà été copiées dans le presse-papiers.',
              showConfirmButton: true,
            });
                    }
                }
        },
        {
          extend: 'csv',
  text: 'Exporter en fichier excel',
  fieldSeparator: ';',
  fieldBoundary: '"',
  bom: true,
  exportOptions: {
    columns: [0,1,2,3,5]
  }
        },
        {
            extend: 'print',
            text: 'Imprimer les données' 
        }
    ],
              order: [],
              language: {
                décimal: "",
                emptyTable: "Aucune donnée disponible dans le tableau",
                infoEmpty: "Showing 0 to 0 of 0 entries",
                info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "Afficher les entrées du _MENU_",
                loadingRecords: "Loading...",
                processing: "Processing...",
                stateSave: true,
                zeroRecords: "Aucun enregistrement correspondant trouvé",
                paginate: {
                  first: "Premier",
                  last: "Dernier",
                  next: "Suivant",
                  previous: "Précédent",
                },
                aria: {
                  sortAscending: ": activate to sort column ascending",
                  sortDescending: ": activate to sort column descending",
                },
              },
            });
          }, 10);
   }catch(error){
      //console.log(error)
      this.preloader = false;
   }
     
     
         
     
     
    },
    seeDetailsPayment(identifiantCle) {
      

      for (const key in this.paymentHistorique) {
    const value = this.paymentHistorique[key];
 
    if (key === identifiantCle) {
         this.periodePayment = key
          this.detailPayment = value;
        }
    //console.log(this.detailPayment);

}
      this.showDetailProspectForModify = !this.showDetailProspectForModify;
    },
    closeWindowDetailConteneurForModify() {
 
      this.showDetailProspectForModify = !this.showDetailProspectForModify;
    },
  
    screenForvalidPayment(id){
     this.windowForValidPayment = !this.windowForValidPayment;
     this.idPayment = id
    },
    closeScreenForValidPayment(){
      this.windowForValidPayment = !this.windowForValidPayment;
     this.idPayment = ""
    },
    verifyIfRoleInclus(role){
      const {roles} = JSON.parse(localStorage.getItem("user"))
        return roles.some(item=>item.label === role)
       }
  },
  created() {
    this.ListeHistorique();
    
  },
};
</script>
<template>
  <div id="preloader" v-show="preload">
    <div class="preloader"><span></span><span></span></div>
  </div>
 

  <div class="col-lg-9 col-md-12">
    <div class="dashboard-wraper">

      <div class="conteneur_modify" v-if="showDetailProspectForModify">
        <div class="content color_container position-relative">
          <div
            class="ecriteau text-start d-flex justify-content-between align-items-center"
          >
            <h4>Détail des paiements</h4>
            <i
              class="bi bi-x-circle h1"
              @click.prevent="showDetailProspectForModify = !showDetailProspectForModify"
            ></i>
          </div>
          <div class="text-start px-3">
           
          <h5 class="text-center text-light">Période : Du  {{periodePayment.replace(" - "," au ")}}</h5>
            <table id="MyTableData1" class="table text-center table-responsive">
           
              <thead>
                <tr class="text-center">
                  <th class="bg-light text-center">Date réservation</th>
                  <th class="bg-light text-center">Honoraire (Fcfa)</th>
                  <th class="bg-light text-center">Propriétaire</th>     
                  <th class="bg-light text-center">Statut</th>
                  <th class="bg-light text-center">Preuve</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in detailPayment" :key="item">
                  <td>
                 {{ new Date(item.date_start).toLocaleDateString('fr')}} au 
                 {{ new Date(item.date_end).toLocaleDateString('fr') }}      
                  </td>
                  <td class="text-center">
                    {{ moneyFormat.format(item.cost) }}
                    </td>
                  <td>
                    <span v-if="item.propriety">
                      {{ item.propriety.user.lastname }}
                        {{ item.propriety.user.firstname }}
                    </span>
                  </td>
                 <td class="text-center">
                  {{ item.paid }}
                  </td>
                  <td class="text-center">
                    <n-image
                    width="50"
                    v-if="JSON.parse(item.proof).length"
                    :src='this.lienUrl.replace("/api/", "/")+"public/public/image/"+JSON.parse(item.proof)'
                  />
                  <span v-else class="badge bg-danger">x</span>
                   
                    </td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
      </div>
      

      <div
        class="form-submit position-relative"
        :class="preloader ? 'pre' : 'null'"
      >
        <div id="preloader" class="position-absolute" v-show="preloader">
          <div class="preloader"><span></span><span></span></div>
        </div>
        <h2 class="text-start text-light">Historique des paiements</h2>
        <div
          class="text-end"
          v-if="!this.verifyIfRoleInclus('Superviseur')"
        >
          <button class="btn bg-dark text-light m-2" @click.prevent="SavePaiement">
            Effectuer un paiement
          </button>
        </div>
      </div>
      <h5 class="text-center total_amount text-light" v-if="payment">Total: {{moneyFormat.format(this.sumOfAmount)}} Fcfa</h5>
      <div class="col-sm-12 dataTable position-relative" v-if="!preloader">
       
        <table id="MyTableData" class="table text-center table-responsive">
         
          <thead>
            <tr class="text-center">
              <th class="bg-light text-center">Date de paiement</th>
              <th class="bg-light text-center">Période de paiement</th>     
              <th class="bg-light text-center">Détail</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, key) in paymentHistorique" :key="key">
              <td   @click.prevent="seeDetailsPayment(key)">
             
                {{ new Date(value[0].paid_at).toLocaleDateString('fr')}}
              </td>
              <td   @click.prevent="seeDetailsPayment(key)">
                {{ key.replace(" - "," Au ") }}
              </td>
             <td class="text-center">
                <span class="d-flex justify-content-center align-items-center">
                  <i
                    class="bi bi-eye mx-2"
                    @click.prevent="seeDetailsPayment(key)"
                  ></i>
                </span>
              </td>
              <!-- <td>
                <n-image
                v-if="payment.proof"
                width="100"
                :src="
                'http://backend-dexter.invest-ci.com/'+payment.proof
                    "
              />
            
              </td> -->
              
              <!-- <td>
               
              </td>
              
               <td>
                

              </td> -->
                 
              
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>


<style scoped>
.dataTables_wrapper .dataTables_filter{
  display:none;
}
.cont .form-group{
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.222);
  border-radius:8px;
}
strong {
  font-size: 1em !important;
  color: gray;
  text-decoration:underline;
  padding: 0 0 0.5em 0;
  font-weight: bold;
  text-transform: uppercase;
}
.badge {
  background: black !important;
  color: white !important;
}
#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: rgba(244, 242, 242, 0);
  display: flex;
  justify-content: center;
  place-items: center;
}
.bg-danger,
.bg-danger:hover {
  color: white !important;
}

h6 {
  margin: 0 0 1em 0 !important;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}
[type="radio"]:checked + label:after {
  opacity: 0 !important;
}
.circuit {
  padding: 0 !important;
}
.card {
  padding: 1em 3em;
}
.bg-primary {
  background-color: rgb(55, 137, 167) !important;
  border: 2px solid rgb(55, 137, 167) !important;
  color: white !important;
}
.bi-eye,
.bi-x-circle,
.bi-pencil,
.bi-trash {
  cursor: pointer;
}
.pre {
  padding: 0 0 15em 0;
}

.table {
  border: 1px solid black;
}
td {
  border-right: 2px solid black;
}

.confirmation_modifie {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.close_modal {
  position: absolute;
  top: 1em;
  right: 1em;
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index: 9999;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.submit-pages {
  padding: 1em 3em;
}

.recherche{
  top:40px;
  right:-.6em;
  z-index:9;
}

.recherche input{
  width:217px;
  height:35px;
  border:thin solid black;
  border-radius:2px;
}
.bi-check-circle{
  font-size:2em !important;
  color:green !important;

}

</style>
../../utils