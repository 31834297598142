<script>
import Vue3BasicAutocomplete from "vue3-basic-autocomplete";
import Swal from "sweetalert2";
import LienConnected from '../../class_help_me'
export default {
  name: "saveCommune",
  components:{
    Vue3BasicAutocomplete
  },
  data() {
    return {
      nameCommune: "",
      preloader:false,
      listOfVille:[],
      chooseVille:"",
    };
  },
  methods: {
    
    async getListVille() {
      
      const commune = new LienConnected('cities',this.$store.state.token)
     const createCommune = await commune.getAllData()
     const response = createCommune
     this.listOfVille = response.data.data
     //console.log("this.listVille",this.listOfVille)
    },
    async saveCommune() {
      
      try{
        this.preloader = true
        let dataCommune = {
        name: this.nameCommune,
        city_id:this.chooseVille.id
        };
        //console.log("dataCommune",dataCommune) 
        const commune = new LienConnected('municipalite',this.$store.state.token)
     const createCommune = await commune.sendData(dataCommune)
     const response = createCommune
     
     //console.log("dataCommune",response) 
     if(response.data.status === true){
          
               Swal.fire({
              icon: "success",
              title: "Commune enregistrée.",
              showConfirmButton: false,
              timer: 1500,
            });
            this.nameCommune = ""
            this.preloader = false
            setTimeout(()=>{
              this.$router.push({name:"listeCommune"})
            },1500)
            
     }
     
      }catch(error){
        this.preloader = false
      }
     

    },
  },
  created(){
    this.getListVille()
  }
};
</script>
<template>
  <div class="col-lg-9 col-md-12 position-relative">
    <div class="dashboard-wraper">
      <div class="row text-start">
        <!-- Submit Form -->
        <div class="col-lg-12 col-md-12">
          <form @submit.prevent="saveCommune">
            <div class="submit-pages">
              <!-- Basic Information -->
              <div class="form-submit">
                <h3 class="text-light">Enregistrer une commune ou un quartier</h3>
                <div class="submit-section">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label>Ville</label>
                      <vue3-basic-autocomplete
                      v-model="chooseVille"
                      :options="listOfVille"
                      trackby="name"
                      none-find=" désole , Pas de resultat"
                      placeholder="Recherche par ville"
                      input-class="input-site"
                   
                    />
                     
                    </div>
                    <div class="form-group col-md-6">
                      <label class="text-dark">
                        {{this.chooseVille.name == 'Abidjan' ? 'Commune':'Quartier'}}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="nameCommune"
                      />
                    </div>
                   

                    <div>
                      <button
                     
                        class="btn bg-dark text-light rounded"
                        type="submit"
                      >
                        Enregistrer
                        <span class="spinner-border spinner-border-sm ms-2" v-show="preloader"  role="status" aria-hidden="true"></span>
                      </button>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}
.dashboard-wraper {
  height: 100%;
}
.bi-x-circle {
  cursor: pointer;
}

.circuit {
  padding: 0 !important;
}
[type="radio"]:checked + label:after {
  opacity: 0 !important;
}

.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index: 9999;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.submit-pages_view {
  padding: 1em 3em;
}
</style>
