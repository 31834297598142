<script>
import $ from "jquery";
import Swal from "sweetalert2";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";

import LienConnected from '../../class_help_me'
export default {
  name: "listVille",
  data() {
    return {
      idVille:"",
      listVille:[],
      preloaderSpinner:false,
      preloader:false,
      preload:false,
      showWindowDelete:false,
      nameVille:"",
      showDetailVille:false,
      nameOfVille:"",
      list:"",
    };
  },
  methods: {
   async getListVille(){
    this.preloader = true
      const list = new LienConnected('cities',this.$store.state.token)
      const allList = await list.getAllData()
      if(allList.data.status === true){
        this.listVille = allList.data.data
        this.preloader = false
      }
      
      //console.log("NEW LIST", allList)
      setTimeout(function () {
        let printCounter = 0
            $("#MyTableData").DataTable({
              pagingType: "full_numbers",
              pageLength: 10,
              processing: true,
              dom: "Bfrtip",
                      buttons: [
        {
            extend: 'copy',
            text: 'Copier les données',
            messageTop: function () {
                    printCounter++;
 
                    if ( printCounter === 1 ) {
                      Swal.fire({
              icon: "success",
              title: 'Les données ont été copiées dans le presse-papiers.',
              showConfirmButton: false,
              timer: 1500,
            });
                     
                    }
                    else {
                      Swal.fire({
              icon: "info",
              title: 'Les données ont déjà été copiées dans le presse-papiers.',
              showConfirmButton: true,
            });
                    }
                } 
        },
        {
          extend: 'csv',
  text: 'Exporter en fichier excel',
  fieldSeparator: ';',
  fieldBoundary: '"',
  bom: true,
  exportOptions: {
    columns: [0]
  }
        },
        {
            extend: 'print',
            text: 'Imprimer les données' 
        }
    ],
              order: [],
              language: {
                décimal: "",
                emptyTable: "Aucune donnée disponible dans le tableau",
                infoEmpty: "Showing 0 to 0 of 0 entries",
                info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "Afficher les entrées du _MENU_",
                loadingRecords: "Loading...",
                processing: "Processing...",
                search: "Chercher :",
                stateSave: true,
                zeroRecords: "Aucun enregistrement correspondant trouvé",
                paginate: {
                  first: "Premier",
                  last: "Dernier",
                  next: "Suivant",
                  previous: "Précédent",
                },
                aria: {
                  sortAscending: ": activate to sort column ascending",
                  sortDescending: ": activate to sort column descending",
                },
              },
            });
          }, 10);
    },
    async deleteCity(){
      
      const city = new LienConnected('cities',this.$store.state.token)
     const cityDelete = await city.deleteData(this.idVille)
     const response = cityDelete
     //console.log("response",response)
     if(response.data.status === true){
               Swal.fire({
              icon: "success",
              title: "Ville supprimée.",
              showConfirmButton: false,
              timer: 1500,
            });
            const itemAtdelete = this.listVille.findIndex(
              (item) => item.id == this.idCategorie
            );
            this.listVille.splice(itemAtdelete, 1);
            setTimeout(() => {
              this.showWindowDelete = !this.showWindowDelete;
            }, 1500);
     }

    
    },
    async modifyCity(){
      this.preloaderSpinner = true
      const dataCity = {
        name:this.nameVille
      }
      const city = new LienConnected('cities',this.$store.state.token)
     const cityAtModify = await city.modifyData(dataCity,this.idVille)
     const response = cityAtModify
     //console.log("response",response)
     if(response.data.status === true){
          this.listVille.forEach(item=>{
              if(item.id == this.idVille){
                item.name = this.nameVille
              }
            })
               Swal.fire({
              icon: "success",
              title: "Ville modifiée.",
              showConfirmButton: false,
              timer: 1500,
            });
            this.showDetailVille = !this.showDetailVille;
            this.preloaderSpinner = false
     }
    },
    viewDetailVille(id) {
      this.listVille.forEach((item) => {
        if (item.id === id) {
          this.nameVille = item.name;
        }
      });
      this.idVille = id;
      this.showDetailVille = !this.showDetailVille;
    },
    closeWindowDetailVille() {
      this.idVille = ""
      this.showDetailVille = !this.showDetailVille;
      this.preloaderSpinner = false
    },
    saveVille(){
      this.$router.push({name:'saveVille'})
    },
    wantDeleteVille(id){
      this.idVille = id
      let i;
      for(i=0;i<this.listVille.length;i++){
        if(this.listVille[i].id == id){
          this.nameOfVille = this.listVille[i].name
          //console.log("element",this.listVille[i])
        }
      }
      this.showWindowDelete = !this.showWindowDelete
    
    },
    cancelDeleteVille(){
      this.idVille = ""
      this.showWindowDelete = !this.showWindowDelete
    },
  },
  created(){
 
  this.getListVille()
  
  }
};
</script>
<template>
  <div id="preloader" v-show="preload">
    <div class="preloader"><span></span><span></span></div>
  </div>
  <div class="confirmation_modifie" v-show="showWindowDelete">
    <div class="card">
      <h6 class="text-center text-secondary">Voulez-vous supprimer cette ville ?</h6>
      <h6 class="text-center">{{nameOfVille}}</h6>
      <div class="d-flex justify-content-center align-items-center">
       
        <div>
          <button
          class="btn btn-all mx-2 bg-dark text-light" 
          @click.prevent="deleteCity"
        >
          Confirmer
        </button>
        <button class="btn bg-danger mx-2 text-white" @click.prevent="cancelDeleteVille">
          Annuler
        </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-9 col-md-12">
    <div class="dashboard-wraper">


      <div class="conteneur_modify" v-show="showDetailVille">
        <div class="content position-relative">
          <div
            class="ecriteau text-start d-flex justify-content-between align-items-center"
          >
            <h4>Détail</h4>
            <i
              class="bi bi-x-circle h1"
              @click.prevent="closeWindowDetailVille"
            ></i>
          </div>
          <div class="text-start px-3">
            <div class="submit-pages text-start">
              <div class="row container">
                <div class="form-group col-md-12 col-sm-12">
                 
                 
                  <div class="col-lg-12 col-md-12">
                    <form @submit.prevent="modifyCity">
                      <div class="submit-pages">
                        <!-- Basic Information -->
                        <div class="form-submit">
                        
                          <div class="submit-section">
                            <div class="row">
                              <div class="form-group col-md-12">
                                <label class="text-dark">Libellé</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="nameVille"
                                  required
                                />
                              </div>
          
                              <div>
                                <button
                                  class="btn bg-dark text-light rounded"
                                  type="submit"
                                >
                                  Enregistrer
                                  <span class="spinner-border spinner-border-sm ms-2" v-show="preloaderSpinner"  role="status" aria-hidden="true"></span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

      <div
        class="form-submit position-relative"
        :class="preloader ? 'pre' : 'null'"
      >
        <div id="preloader" class="position-absolute"  v-show="preloader">
          <div class="preloader"><span></span><span></span></div>
        </div>
        <h2 class="text-start text-light">Liste des villes</h2>
        <div class="text-end">
          <button class="btn btn-all m-2 bg-dark text-light" 
          @click.prevent="$router.push('/propriete')">
            Enregistrer une propriéte </button>
            <button class="btn btn-all mx-3 bg-dark text-light" @click.prevent="saveVille">
              Enregistrer une nouveau ville </button>
        </div>
      </div>
     

      <div class="col-sm-12 dataTable" v-if="!preloader">
        
        <table id="MyTableData" class="table text-center table-responsive">
          <thead>
            <tr class="text-center">
              <th class="bg-light text-center">Libellé</th>
        
              <th class="bg-light text-center">Détail</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(ville,index) in listVille" :key="index">
              <td>{{ville.name}}</td>

              <td class="text-center">
                <span class="d-flex justify-content-center align-items-center">
                  <i
                    class="bi bi-pencil mx-2"
                    @click.prevent="viewDetailVille(ville.id)"
                  ></i>
                  <i
                  class="bi bi-trash mx-2"
                  @click.prevent="wantDeleteVille(ville.id)"
                ></i>
                </span>
              </td>
            </tr>
          </tbody> 
        </table>
      </div>
    </div>
  </div>
</template>
<style scoped>

#preloader{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: rgba(244, 242, 242, 0);
  display: flex;
  justify-content: center;
  place-items: center;
}
.bg-danger,.bg-danger:hover{
  color:white !important;
}
strong{
  font-size:.9em !important;
  color:gray;
  padding:0 0 .5em 0;
}
h6{
  margin:0 0 1em 0 !important;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}
[type="radio"]:checked + label:after {
  opacity: 0 !important;
}
.circuit {
  padding: 0 !important;
}
.card {
  padding: 1em 3em;
}

.bi-eye,
.bi-x-circle,
.bi-pencil,
.bi-trash{
  cursor: pointer;
}
.pre {
  padding: 0 0 15em 0;
}
.badge {
  color: white !important;
}
.table {
  border: 1px solid black;
}
td {
  border-right: 2px solid black;
}

.confirmation_modifie {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #d9a509 !important;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.close_modal {
  position: absolute;
  top: 1em;
  right: 1em;
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index:9999;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.text-dark{
  color: black  !important;
}
.submit-pages {
  padding: 1em 3em;
}
</style>
