<script>
import $ from "jquery";
import Swal from "sweetalert2";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import LienConnected from '../../class_help_me'
export default {
  name: "liste-visite",
  data() {
    return {
      windowRenderInClient:false,
      reasonForCancelVisit:"",
      showWindow:false,
      dataObject:new LienConnected('visitnoneffectué',this.$store.state.token),
      msgEmailErr:false,
      msgPhoneErr:false,
      moneyFormat: new Intl.NumberFormat("de-DE"),
      showDetailProspectForModify:false,
      preload:false,
      preloader:false,
      photoResidence:[],
      showDetailVisite:false,
      detailVisite:null,
      ListesVisiteEnAttente:[],
      newDate:"",
      rebootVisit:false,
      lienUrl:new LienConnected().baseUrl,
      annuleVisite:false,
      idVisite:"",
      spinnerLocation:false,
    };
  },
  methods: {
    async getListVisite(){
      try{
        this.preloader = true
      const allList = await this.dataObject.getAllData()
      if(allList.data.status){
        this.ListesVisiteEnAttente = allList.data.data
        this.preloader = false
      }
      //console.log("NEW LIST", allList)
      setTimeout(function () {
        let printCounter = 0
            $("#MyTableData").DataTable({
              pagingType: "full_numbers",
              pageLength: 10,
              processing: true,
              dom: "Bfrtip",
                      buttons: [
        {
            extend: 'copy',
            text: 'Copier les données',
            messageTop: function () {
                    printCounter++;
 
                    if ( printCounter === 1 ) {
                      Swal.fire({
              icon: "success",
              title: 'Les données ont été copiées dans le presse-papiers.',
              showConfirmButton: false,
              timer: 1500,
            });
                     
                    }
                    else {
                      Swal.fire({
              icon: "info",
              title: 'Les données ont déjà été copiées dans le presse-papiers.',
              showConfirmButton: true,
            });
                    }
                } 
        },
        {
          extend: 'csv',
  text: 'Exporter en fichier excel',
  fieldSeparator: ';',
  fieldBoundary: '"',
  bom: true,
  exportOptions: {
    columns: [0]
  }
        },
        {
            extend: 'print',
            text: 'Imprimer les données' 
        }
    ],
              order: [],
              language: {
                décimal: "",
                emptyTable: "Aucune donnée disponible dans le tableau",
                infoEmpty: "Showing 0 to 0 of 0 entries",
                info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "Afficher les entrées du _MENU_",
                loadingRecords: "Loading...",
                processing: "Processing...",
                search: "Chercher :",
                stateSave: true,
                zeroRecords: "Aucun enregistrement correspondant trouvé",
                paginate: {
                  first: "Premier",
                  last: "Dernier",
                  next: "Suivant",
                  previous: "Précédent",
                },
                aria: {
                  sortAscending: ": activate to sort column ascending",
                  sortDescending: ": activate to sort column descending",
                },
              },
            });
          }, 10);

      }catch(error){
        this.preloader = false
      }
      
    },
    viewDetailsVisite(id) {
      this.photoResidence=[]
        this.ListesVisiteEnAttente.forEach((item) => {
          if (item.id === id) {
            this.detailVisite = item;
            let photo = item.propriety.image ? JSON.parse(item.propriety.image):[];
          photo.forEach(el=>{
            this.photoResidence.push(this.lienUrl.replace("/api/", "/")+'public/public/image/'+el)
          })
          }
        });
      this.idVisite = id;
      this.showDetailVisite = !this.showDetailVisite;
    },
    async confirmeRebootVisit(){
  this.spinnerLocation = true
  try{
    let data = {
      date:this.newDate,
      status:"Attente"
    }
  const dataObject = new LienConnected('visites',this.$store.state.token)
  const allList = await dataObject.modifyData(data,this.idVisite)
  const response = allList
  if(response.data.status === true){
    //console.log(response) 
    this.showWindow = !this.showWindow
    Swal.fire({
        icon: "success",
        title: "Visite reportée",
        showConfirmButton: false,
        timer: 1500,
            });
            this.reasonForCancelVisit = ""
            this.newDate = ""
            this.spinnerLocation = false
            this.ListesVisitePlanifie.forEach(item=>{
              if(item.id == this.idVisite){
                item.date = this.newDate
              }
            })
          
   
  }
  }catch(error) {
 
    this.spinnerLocation = false
    //console.log(error)
  }
},
async deleteVisite(){
  this.spinnerLocation = true
  try{
    let data = {
      status: "Annulé",
      commentaire:this.reasonForCancelVisit
    }
  const dataObject = new LienConnected('updateStatusV',this.$store.state.token)
  const allList = await dataObject.modifyDataWithPost(data,this.idVisite,'Visite annulée')
  const response = allList
  if(response.data.status === true){
    //console.log(response)
    this.showWindow = !this.showWindow
    
            // const itemAtdelete = this.ListesVisitePlanifie.findIndex(
            //   (item) => item.id == this.idVisite
            // );
            // this.ListesVisitePlanifie.splice(itemAtdelete, 1);
            this.reasonForCancelVisit = ""
            this.newDate = ""
   
    this.spinnerLocation = false
  }
  }catch(error) {
    this.spinnerLocation = false
    //console.log(error)
  }
},

cancelVisit(id){
this.showWindow = !this.showWindow
this.idVisite = id;
this.reasonForCancelVisit = ""
this.newDate = ""
},
changeVisit(value){
 //console.log(value)
  if(value === true && this.annuleVisite === true){
    this.annuleVisite = false
  }
},
removeVisit(value){
  //console.log(value)
  if(value === true && this.rebootVisit === true){
    this.rebootVisit = false
  }
}
  },
  created() {
  this.getListVisite()
  },
};
</script>
<template>
  <div id="preloader" v-show="preload">
    <div class="preloader"><span></span><span></span></div>
  </div>

  <div class="confirmation_modifie " v-show="showWindow">
    <div class="card">
      <div
      class="ecriteau text-start d-flex justify-content-between align-items-center"
    >
      <h4>Modifier la visite</h4>
   
      <i
        class="bi bi-x-circle h1"
        @click.prevent="showWindow = !showWindow"
      ></i>
    </div>
   
      
    <section class="px-3 ">
      <div class="my-5 text-light">
        <n-space> 
          <n-switch  v-model:value="rebootVisit" 
          @update:value="changeVisit" /> Voulez-vous reporter la visite ?
        </n-space>
        <form v-if="rebootVisit" class="mt-3">
          <div class="form-group text-start">
            <label class="text-dark d-block">Nouvelle date</label>
            <input
              type="date"
              class="form-control"
              :min="new Date().toISOString().slice(0, 10)"
              v-model="newDate"
            />
          </div>
    
          <div class="d-flex justify-content-center align-items-center" v-if="newDate">
           
            <div>
              <button
              class="btn mx-2 text-light bg-dark" 
              @click.prevent="confirmeRebootVisit"
            >
              Confirmer
              <span class="spinner-border spinner-border-sm ms-2"
              v-show="spinnerLocation"  role="status" aria-hidden="true"></span>
            </button>
            <!-- <button class="btn bg-danger mx-2 text-white" @click.prevent="showWindow = !showWindow">
              Annuler
            </button> -->
            </div>
          </div>
         </form>
      </div>
      <div class="my-5 text-light">
        <n-space> 
          <n-switch  v-model:value="annuleVisite" 
          @update:value="removeVisit" /> Voulez-vous annuler la visite ?
        </n-space>
      </div>
      
     
    
       <form v-if="annuleVisite" class="mt-3">
        <div class="form-group text-start">
          <label class="text-dark d-block">Raison</label>
         
          <textarea class="w-100" 
          v-model="reasonForCancelVisit" 
          name="" id="" cols="50" rows="10"></textarea>
        </div>
    
        <div class="d-flex justify-content-center align-items-center" >
         
          <div v-if="reasonForCancelVisit">
            <button
            class="btn mx-2 text-light bg-dark" 
            @click.prevent="deleteVisite"
          >
            Confirmer
            <span class="spinner-border spinner-border-sm ms-2"
            v-show="spinnerLocation"  role="status" aria-hidden="true"></span>
          </button>
          
          </div>
        </div>
       </form>
    </section>
    </div>
  </div>


  <div class="col-lg-9 col-md-12">
    <div class="dashboard-wraper">


      <div class="conteneur_modify" v-if="showDetailVisite">
        <div class="content position-relative">
          <div
            class="ecriteau text-start d-flex justify-content-between align-items-center"
          >
            <h4>Détail de la visite</h4>
            <i
              class="bi bi-x-circle h1"
              @click.prevent=" showDetailVisite = !showDetailVisite"
            ></i>
          </div>
          <div class="text-start px-3" v-if="detailVisite">
            <div class="submit-pages text-start color_container">
              <div class="row container cont">
                <div class="form-group col-md-12 col-sm-12">
                  <div>
                    <h5 class="badge bg-info">Info Client</h5>
                  </div>
                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <strong>Nom</strong>
                      <h6 class="p-0 m-0">{{detailVisite.user.lastname}}</h6>
                      <strong>Prénoms</strong>
                      <h6 class="p-0 m-0">{{detailVisite.user.firstname}}</h6>
                      <strong>Email</strong>
                      <h6 class="p-0 m-0">{{detailVisite.user.email}}</h6>
                      <strong>Téléphone</strong>
                      <h6 class="p-0 m-0">{{detailVisite.user.phone}}</h6>
                      
                    </div>
                    
                  </div>
                </div>
                <div class="form-group col-md-12 col-sm-12">
                  <div>
                      <h5 class="badge bg-info">Info de la visite</h5>
                    </div>
                <div class="row">
                  <div class="col-md-12 col-sm-12">
                    <strong>Date de la visite</strong>
                    <h6 class="p-0 m-0">{{new Date(detailVisite.date).toLocaleDateString('fr')}}</h6>
                    <strong>Propriéte</strong>
                    <h6 class="p-0 m-0">{{detailVisite.propriety.name}}</h6>
                    <strong>Lieu</strong>
                    <h6 class="p-0 m-0">{{detailVisite.propriety.name}}</h6>
                    <strong>Prix de la visite</strong>
                    <h6 class="p-0 m-0">{{detailVisite.cost}}</h6>
                    <strong>Raison de la visite</strong>
                    <h6 class="p-0 m-0">{{detailVisite.type}}</h6>
                    <strong>Galerie</strong>
                    <MazGallery v-if="photoResidence.length" :images="photoResidence" :height="400" />
                  </div>
                 
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- Bookmark Property -->
      <div
        class="form-submit position-relative"
        :class="preloader ? 'pre' : 'null'"
      >
        <div id="preloader" class="position-absolute"  v-show="preloader">
          <div class="preloader"><span></span><span></span></div>
        </div>
        <h2 class="text-start text-light">Visites expirées</h2>
        
      </div>
     

      <div class="col-sm-12 dataTable" v-if="!preloader">
        
        <table id="MyTableData" class="table text-center table-responsive">
          <thead>
            <tr class="text-center">
              <th class="bg-light text-center">Date d'enregistrement</th>
              <th class="bg-light text-center">Visiteur</th>
              <th class="bg-light text-center">Date de la visite</th>
              <th class="bg-light text-center">Propriété</th>
              <th class="bg-light text-center">Honoraire (Fcfa)</th>
        
              <th class="bg-light text-center">Action</th>
              <th class="bg-light text-center">Détail</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(visite,index) in ListesVisiteEnAttente" :key="index">
              <td  @click.prevent="viewDetailsVisite(visite.id)">{{new Date(visite.created_at).toLocaleDateString('fr')}}</td>
             
              <td  @click.prevent="viewDetailsVisite(visite.id)">
                <span>{{visite.user.lastname}} {{visite.user.firstname}}</span>
              </td>
              <td  @click.prevent="viewDetailsVisite(visite.id)">
                <span>{{new Date(visite.date).toLocaleDateString('fr')}} </span>
              </td>
       
              <td  @click.prevent="viewDetailsVisite(visite.id)">
                <span >{{visite.propriety.name}}</span>
              </td>
            
              <td  @click.prevent="viewDetailsVisite(visite.id)">
                <span> {{moneyFormat.format(visite.cost)}}</span>
               
              </td>
              
              <td>
               <button class="btn bg-dark text-light"
                @click="cancelVisit(visite.id)">Modifier</button>
              </td>
              <td class="text-center">
                <span class="d-flex justify-content-center align-items-center">
                  <i
                    class="bi bi-eye mx-2"
                    @click.prevent="viewDetailsVisite(visite.id)"
                  ></i>
                </span>
              </td>
            </tr>
          </tbody> 
        </table>
      </div>
    </div>
  </div>
</template>
<style scoped>
.cont .form-group{
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.222);
  border-radius:8px;
}
strong {
  font-size: 1em !important;
  color: gray;
  text-decoration:underline;
  padding: 0 0 0.5em 0;
  font-weight: bold;
  text-transform: uppercase;
}
.badge {
  background: black !important;
  color: white !important;
}

#preloader{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: rgba(244, 242, 242, 0);
  display: flex;
  justify-content: center;
  place-items: center;
}
.bg-danger,.bg-danger:hover{
  color:white !important;
}

h6{
  margin:0 0 1em 0 !important;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}
[type="radio"]:checked + label:after {
  opacity: 0 !important;
}
.circuit {
  padding: 0 !important;
}
.card {
  width:60vw;
  background: #d9a509 !important;
}
.bg-primary {
  background-color: rgb(55, 137, 167) !important;
  border: 2px solid rgb(55, 137, 167) !important;
  color: white !important;
}
.bi-eye,
.bi-x-circle,
.bi-pencil,
.bi-trash{
  cursor: pointer;
}
.bi-x-circle{
  right:.5em;
  top:.5em;
}
.pre {
  padding: 0 0 15em 0;
}
.table {
  border: 1px solid black;
}
td {
  border-right: 2px solid black;
}

.confirmation_modifie {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.close_modal {
  position: absolute;
  top: 1em;
  right: 1em;
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index:9999;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.submit-pages {
  padding: 1em 3em;
}
.text-dark{
  color:black !important;
}
</style>
