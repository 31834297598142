<script>
import $ from "jquery";
import Swal from "sweetalert2";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import 'datatables.net-buttons';
import 'datatables.net';
import LienConnected from '../../class_help_me'
export default {
  name: "listeReservation_annule",
  data() {
    return {
      idPropriete:"",
      windowForCancelBooking:false,
      dataObject:"",
      listReservationAnnulee:[],
      listResidenceDisponible:[],
      listReservationResidence:[],
      listClient:[],
      disabledDates: [],
      municipalityReservation:"",
      detailReservation:"",
      active:false,
      dateReservation:{
        start: "",
        end: "",
      },
      preloaderSpinner:false,
      commentRemoveBooking:"",
      moneyFormat: new Intl.NumberFormat("de-DE"),
      residence:"",
      preload:false,
      preloader:false,
      showDetail:false,
      windowForBook:false,
      nbrPersonAllowReservation:1,
      prenomsClient:"",
      phoneClient:"",
      passwordClient:"",
      nomClient:"",
      emailClient:"",
      raisonForLocation:"",
      dateEndReservation:"",
      dateStartReservation:"",
      clientReservation:"",
      lienUrl:new LienConnected().baseUrl.replace("/api/", "/"),
    };
  },
  methods: {
 
 

   
    helpMeGetList(route){
    this.dataObject=new LienConnected(route,this.$store.state.token)
  },
    async getAllList(route){
    try{
      this.preloader = true
    this.helpMeGetList(route)
    const allList = await this.dataObject.getAllData()
    if(allList.data.status === true){
      this.preloader = false
      return allList.data.data
    }
    }catch(error){
      //console.log(error)
    }
   
  },
  
    windowDetailResidence(id) {
    
    this.listReservationAnnulee.forEach((item) => {
     
    
      
      if (item.id === id) {
        //console.log(item)

        this.detailReservation = item;
        this.municipalityReservation = item.propriety.municipality
        //console.log(this.municipalityReservation)
      }
    
    });
    //console.log(this.photoResidence)
    this.showDetail = !this.showDetail;
  },
    async getReservationAnnulee(){
      this.listReservationAnnulee = await this.getAllList("Annulée")
      //console.log("this.listReservationAnnulee",this.listReservationAnnulee)
      setTimeout(function () {
        let printCounter = 0
            $("#MyTableData").DataTable({
              pagingType: "full_numbers",
              pageLength: 10,
              processing: true,
              dom: "Bfrtip",
                      buttons: [
        {
            extend: 'copy',
            text: 'Copier les données',
            messageTop: function () {
                    printCounter++;
 
                    if ( printCounter === 1 ) {
                      Swal.fire({
              icon: "success",
              title: 'Les données ont été copiées dans le presse-papiers.',
              showConfirmButton: false,
              timer: 1500,
            });
                     
                    }
                    else {
                      Swal.fire({
              icon: "info",
              title: 'Les données ont déjà été copiées dans le presse-papiers.',
              showConfirmButton: true,
            });
                    }
                } 
        },
        {
          extend: 'csv',
  text: 'Exporter en fichier excel',
  fieldSeparator: ';',
  fieldBoundary: '"',
  bom: true,
  exportOptions: {
    columns: [0]
  }
        },
        {
            extend: 'print',
            text: 'Imprimer les données' 
        }
    ],
              order: [],
              language: {
                décimal: "",
                emptyTable: "Aucune donnée disponible dans le tableau",
                infoEmpty: "Showing 0 to 0 of 0 entries",
                info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "Afficher les entrées du _MENU_",
                loadingRecords: "Loading...",
                processing: "Processing...",
                search: "Chercher :",
                stateSave: true,
                zeroRecords: "Aucun enregistrement correspondant trouvé",
                paginate: {
                  first: "Premier",
                  last: "Dernier",
                  next: "Suivant",
                  previous: "Précédent",
                },
                aria: {
                  sortAscending: ": activate to sort column ascending",
                  sortDescending: ": activate to sort column descending",
                },
              },
            });
          }, 10);
    },
    DoForBook(){
      this.windowForBook = !this.windowForBook
    },
    async getListResidenceDisponible(){
      try{
        this.listResidenceDisponible = await this.getAllList('getUnreserveResidences')
        //console.log("NEW LIST", this.listResidenceDisponible)
      }catch(error) {
       
        //console.log(error)
      }
     
    }, 
    async getListResidenceReserve(){

      try{
 
    let dataObject = new LienConnected('reservations',this.$store.state.token)
    const allList = await dataObject.getAllData()
    if(allList.data.status === true){
      this.listReservationResidence =  allList.data.data
    }
    }catch(error){
      //console.log(error)
    }
    },
    async getListClient(){
try{

let dataObject = new LienConnected('clients',this.$store.state.token)
const allList = await dataObject.getAllData()
//console.log("allList",allList)
if(allList.data.status === true){
this.listClient = allList.data.data
}
//console.log("this.listClient",this.listClient)
}catch(error){
//console.log(error)
}
},
    selectDateForReservation(){
      for (const [key, value] of Object.entries(this.dateReservation)) {

if (key == "start") {
  this.dateStartReservation = value;
} else if (key == "end") {
  this.dateEndReservation = value;
}
}
    },
     async booking(){
      this.selectDateForReservation()
  let dataSend = {
  user_id:this.clientReservation ? this.clientReservation.id:null,
  lastname:this.nomClient,
  firstname:this.prenomsClient,
  email:this.emailClient,
  phone:this.phoneClient,
  password:this.passwordClient,
  propriety_id:this.residence.id,
  number_people:this.nbrPersonAllowReservation,
  date_start:this.dateStartReservation.toISOString().slice(0, 10),
  date_end:this.dateEndReservation.toISOString().slice(0, 10),
  roles:this.clientReservation ? null:[2],
  raison:this.raisonForLocation
}
try{
  const createdReservation = new LienConnected('reservations',this.$store.state.token)
     const booking = await createdReservation.sendData(dataSend)
     const response = booking
     if(response.data.status === true){
      //console.log("RESPONSE FOR RESERVATION",response)
     }
}catch(error){
  //console.log(error)
}

//console.log("dataSend",dataSend)

     },
     async removeBooking(){
      let dataSend = {
        status :"Annulée",
        raison:this.commentRemoveBooking
      }
      this.preloaderSpinner = true
      try{
  const cancelReservation = new LienConnected('reservations',this.$store.state.token)
     const booking = await cancelReservation.modifyData(dataSend,this.idPropriete)
     const response = booking
     if(response.data.status === true){
      //console.log("RESPONSE FOR RESERVATION",response)
      this.windowForCancelBooking = !this.windowForCancelBooking
      this.preloaderSpinner = false
     }
}catch(error){
  //console.log(error)
  this.preloaderSpinner = false
}
     },
    cancelBooking(id){
      this.idPropriete = id
   this.windowForCancelBooking = !this.windowForCancelBooking
     },
    
    
  },
  created() {
    this.getReservationAnnulee()
    this.getListResidenceDisponible()
    this.getListResidenceReserve()
    this.getListClient()
    
  },
};
</script>
<template>
  <div class="col-lg-9 col-md-12">
    <div class="dashboard-wraper">
      <section v-if="windowForCancelBooking" class="confirmation_modifie">
        <div class="card">
          <h4 class="text-center text-secondary">
            Voulez-vous annuler la réservation ?</h4>
            <label class="text-dark text-start h6">Motif</label>
            <textarea name="" id="" cols="30" rows="10" v-model="commentRemoveBooking"></textarea>
          
          <div class="d-flex justify-content-center align-items-center my-2">
           
            <div>
              <button
              class="btn btn-all bg-dark text-light  mx-2" 
              @click.prevent="removeBooking"
            >
              Confirmer
              <span class="spinner-border spinner-border-sm ms-2" v-show="preloaderSpinner" 
               role="status" aria-hidden="true"></span>
            </button>
            <button class="btn bg-danger mx-2 text-white" @click.prevent="windowForCancelBooking = !windowForCancelBooking">
              Annuler
            </button>
            </div>
          </div>
        </div>
        
      </section>
      <section class="conteneur_modify" v-if="windowForBook">
        <div class="content position-relative">
          <div
            class="ecriteau text-start d-flex justify-content-between align-items-center"
          >
            <h4>Faire une réservation</h4>
            <i class="bi bi-x-circle close h1" @click.prevent="DoForBook"></i>
          </div>
          <div class="col-lg-12 col-md-12 ">
            <form @submit.prevent="booking">
              <div class="submit-pages text-start" id="container-color">
                <div class="form-submit">
                  <div class="submit-section">
                    <div class="row">
                      <div class="mb-3" v-if="!clientReservation">
                        <n-space>
                          <n-switch v-model:value="active" @update="active = !active" />
                          <span class="text-dark fw-bold">Enregistrer un nouveau client</span>
                        </n-space>
                      </div>
                     
                        <div class="form-group col-md-6" v-if="!active">
                          <label class="text-dark">Email du client</label>
                       
                          <v-select  v-model="clientReservation" :searchable="true"
                          :options="listClient"
                           label="email">
                             <template #option="{firstname,lastname,email}">
                              <div class="conteneur_residence">
                                <span class="mx-2">
                                  {{firstname}} {{ lastname }}
                                </span>
                               <label class="text-dark">({{email}})</label>
                              </div>
                             </template>
                           </v-select>
                        </div>
                       
                        
                          <section v-if="active" class="col-md-12 row">
                            <div class="form-group col-md-6">
                              <label class="text-dark">Nom du client</label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="nomClient" 
                              />
                            </div>
        
                            <div class="form-group col-md-6">
                                <label class="text-dark">Prénoms du client</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="prenomsClient"
                                 
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label class="text-dark">Email du client</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="emailClient"
                                  
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label class="text-dark">Téléphone (de préférence numéro de portable)</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="phoneClient"
                                  pattern="[0-9]{10}"
                                />
                                <span v-if="phoneClient.length > 10 && !phoneClient.match(/^[0-9]{10}$/)" class="text-danger">Veuillez rentrer 10 chiffre</span>
                              </div>
                              <div class="form-group col-md-6">
                                <label class="text-dark">Mot de passe du client</label>
                                <input
                                  type="password"
                                  class="form-control"
                                  v-model="passwordClient"
        
                                />
                              </div>
                          </section>
                        
                        
                        <div class="form-group col-md-6">
                         
                          <label class="text-dark">Résidence</label>
                      
                          <v-select  v-model="residence" :searchable="true"
                          :options="listResidenceDisponible"
                           label="name" @update:modelValue="chooseResidence">
                             <template #option="{name,photos}">
                              <div class="conteneur_residence">
                                <span class="mx-2">
                                  {{name}}
                                </span>
                                <img class="w-25" :src="lienUrl+photos[0].image" :alt="photos[0].image">
                              </div>
                             </template>
                           </v-select>
                        </div>
                        <div class="form-group col-md-6">
                          <label class="text-dark">Nombre de personne autorisés</label>
                          <n-input-number :max="residence.person_maxi" min="1" 
                          v-model:value="nbrPersonAllowReservation" />
                        </div>
                       
                          <div class="form-group col-md-6">
                        
                            <VDatePicker :min-date="new Date().toJSON().slice(0, 10)"
                             v-model.range="dateReservation"  :disabled-dates="disabledDates"  />
                          </div>
                          <div class="form-group col-md-12">
                            <label class="text-dark d-block">Raison</label>
                            <textarea  class="form-control" v-model="raisonForLocation" name="" id="" cols="30" rows="10">
    
                            </textarea>
                          </div>
                          <div class="text-start mt-3">
                            <button type="submit"  class="btn bg-dark text-light rounded">
                            Réserver
                              <span class="spinner-border spinner-border-sm ms-2" v-show="preloader"  role="status" aria-hidden="true"></span>
                              
                            </button>
                          </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <div class="conteneur_modify" v-if="showDetail">
        <div class="content position-relative">
          <div
            class="ecriteau text-start d-flex justify-content-between align-items-center"
          >
            <h4>Détail de la réservation</h4>
            <i
              class="bi bi-x-circle h1"
              @click.prevent="showDetail = !showDetail"
            ></i>
          </div>
          <div class="text-start px-3">
            <div class="submit-pages text-start color_container" v-if="detailReservation">
              <div class="row container product cont">
                <div class="form-group col-md-12 col-sm-12">
                  <div>
                    <h5 class="badge bg-info">Info Résidence</h5>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-sm-12">
                      <strong>Motif</strong>
                      <h6 class="p-0 m-0"> 
                        {{ detailReservation.raison}}
                        
                        </h6>
                      <strong>Date de réservation</strong>
                      <h6 class="p-0 m-0"> 
                        {{ new Date(detailReservation.created_at).toLocaleDateString('fr')}}
                        
                        </h6>
                      <strong>Réservée pour</strong>
                      <h6 class="p-0 m-0">
                        {{ new Date(detailReservation.date_start).toLocaleDateString('fr')}}
                         a {{ new Date(detailReservation.date_end).toLocaleDateString('fr')}} 
                        </h6>
                        <strong>Date d'annulation</strong>
                      <h6 class="p-0 m-0"> 
                        {{ new Date(detailReservation.updated_at).toLocaleDateString('fr')}}
                        
                        </h6>
                        <strong>Code de réservation</strong>  
                        <h6 class="p-0 m-0">{{ detailReservation.code}}</h6>
                        <strong>Résidence</strong>  
                      <h6 class="p-0 m-0">{{ detailReservation.propriety.name}}</h6>
                      <strong>Prix (Fcfa) </strong>
                      <h6 class="p-0 m-0">{{ moneyFormat.format(detailReservation.propriety.cost) }}</h6>
                      <strong v-if="municipalityReservation">Située</strong>
                      <h6 class="p-0 m-0" v-if="municipalityReservation"> {{ municipalityReservation.city.name }} ,
                        {{ municipalityReservation.name }}</h6> 
                      <strong>Situé précisément</strong>
                      <h6 class="p-0 m-0">{{ detailReservation.propriety.adress }}</h6>

                   
                    </div>
                  
                  </div>
                </div>
                <div
                  class="form-group col-md-12 col-sm-12"
                 
                >
                  <div>
                    <h5 class="badge bg-info">Info Client</h5>
                  </div>

                  <strong>Nom & prénoms</strong>
                  <h6 class="p-0 m-0">{{ detailReservation.user.lastname}} {{ detailReservation.user.firstname}}</h6>
                  <strong>Email </strong>
                  <h6 class="p-0 m-0">{{ detailReservation.user.email }}</h6>
                  <strong>Télephone </strong>
                  <h6 class="p-0 m-0">{{ detailReservation.user.phone }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
      class="form-submit position-relative pt-3"
      :class="preloader ? 'pre' : 'null'"
    >
      <div id="preloader" class="position-absolute" v-show="preloader">
        <div class="preloader"><span></span><span></span></div>
      </div>
      <h2 class="text-start text-light-2">Réservations Annulées</h2>
      
    </div>

    <div class="col-sm-12 dataTable position-relative" v-if="!preloader">
      
     
      <table id="MyTableData" class="table text-center table-responsive">
        
        <thead>
          <tr class="text-center">
            <th class="bg-light text-center">Date de réservation</th> 
            <th class="bg-light text-center">Date d'annulation</th> 
            <th class="bg-light text-center">Client</th> 
            <th class="bg-light text-center">Résidence</th> 
            <th class="bg-light text-center">Image</th> 
            <th class="bg-light text-center">Date d'entrée</th>
            <th class="bg-light text-center">Date de sortie</th>
            <th class="bg-light text-center">Nombre de jour</th>
            <th class="bg-light text-center">Code de réservation</th>
           
       
            <th class="bg-light text-center">Détail</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in listReservationAnnulee" :key="index">
            <td @click="windowDetailResidence(item.id)">
             {{ new Date(item.created_at).toLocaleDateString('fr')}}
             </td>
             <td @click="windowDetailResidence(item.id)">
              {{ new Date(item.updated_at).toLocaleDateString('fr',{ year: 'numeric', month: 'long', day: 'numeric' })}}
              </td>
             <td @click="windowDetailResidence(item.id)">
              {{ item.user.lastname }} {{ item.user.firstname }}
             </td>
             <td @click="windowDetailResidence(item.id)">
             {{item.propriety.name}}
             </td>
             <td>
              <n-image
              width="50" 
              :src='this.lienUrl.replace("/api/", "/")+"public/public/image/"+JSON.parse(item.propriety.image)[0]' 
              v-if="item.propriety.image"
            />
              </td>
            
             <td @click="windowDetailResidence(item.id)">
              {{new Date(item.date_start).toLocaleDateString('fr')}}
             </td>
             <td @click="windowDetailResidence(item.id)">
              {{new Date(item.date_end).toLocaleDateString('fr')}}
             </td>
             <td @click="windowDetailResidence(item.id)">
              {{  
                Math.round((new Date(item.date_end.slice(0, 10)) - new Date(item.date_start.slice(0, 10))) / (1000 * 60 * 60 * 24))
                }}
             </td>
            <td @click="windowDetailResidence(item.id)">
              {{item.code}}
            </td>
           
         
             <td>
              <i class="bi bi-eye" @click="windowDetailResidence(item.id)"></i>
             </td>
          </tr>
        </tbody>
      </table>


    </div>
  
   
    </div>
  </div>
</template>
<style scoped>
div.n-space{
  justify-content:center !important;
}

.bg-secondary{
  background-color:rgb(73, 72, 72) !important;
}
.text-light{
  font-size:.8em !important;
}
.cont .form-group{
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.222);
  border-radius:8px;
}
strong {
  font-size: 1em !important;
  color: gray;
  text-decoration:underline;
  padding: 0 0 0.5em 0;
  font-weight: bold;
  text-transform: uppercase;
}
.bg-info{
  background-color:black !important;
}
section{
  color:black;
}
.badge,.text-light-2 {
  color: white !important;
}

.addField {
  width: auto !important;
}
.designation {
  font-size: 1.3em !important;
}
.btn-rejet {
  background-color: rgb(219, 0, 0) !important;
  border: 1px solid rgb(219, 0, 0) !important;
  margin: 0 2em;
  color: white !important;
}
.btn-rejet:hover {
  background-color: rgb(219, 0, 0) !important;
  border: 1px solid rgb(219, 0, 0) !important;
  margin: 0 2em;
  color: white !important;
}
#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: rgba(244, 242, 242, 0);
  display: flex;
  justify-content: center;
  place-items: center;
}
.bg-danger,
.bg-danger:hover {
  color: white !important;
}

h6 {
  margin: 0 0 1em 0 !important;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}
[type="radio"]:checked + label:after {
  opacity: 0 !important;
}
.circuit {
  padding: 0 !important;
}
.card {
  padding: 1em 3em;
  width:50%;
}
.bg-primary {
  background-color: rgb(55, 137, 167) !important;
  border: 2px solid rgb(55, 137, 167) !important;
  color: white !important;
}
.bi-eye,
.bi-x-circle,
.bi-pencil,
.bi-envelope-paper,
.bi-trash,
.delete {
  cursor: pointer;
}
.pre {
  padding: 0 0 15em 0;
}
.table {
  border: 1px solid black;
}
td {
  border-right: 2px solid black;
}
.reset{
  cursor:pointer
}
.confirmation_comment {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 909;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.confirmation_modifie {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 90;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 90;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.close_modal {
  position: absolute;
  top: 1em;
  right: 1em;
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index: 909;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.submit-pages {
  padding: 1em 3em;

}
#container-color{
  background: #d9a509 !important;
}
.text-Acquereur {
  margin: 1em 0;
}
.delete{
  position:absolute;
  right:0;
  cursor: pointer;
  width:20px;
}
.recherche{
  top:41px;
  right:-.6em;
  z-index:9;
}
.recherche_with_value{
  top:14px;
  right:-.6em;
  z-index:9;
}
.text-dark{
  color:black !important;
}
.recherche input{
  width:217px;
  height:35px;
  border:thin solid black;
  border-radius:2px;
}
.conteneur_residence .w-25{
  width:100px;
  height:100px;
  border-radius: 10px;
}
.conteneur_residence img{
  object-fit:cover;
}
</style>
