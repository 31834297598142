<script>
import $ from "jquery";
import Swal from "sweetalert2";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import LienConnected from "../../class_help_me";
export default {
  name: "demande-modification",
  data() {
    return {
      preloaderModify:false,
      idPropriete: "",
      screenForModifyHome: false,
      showWindowDelete: false,
      moneyFormat: new Intl.NumberFormat("de-DE"),
      showDetailResidenceForModify: false,
      preload: false,
      visibleResidence: "",
      preloader: false,
      showDetailClient: false,
      showContrat: false,
      listDemandeModification: [],
      listResidenceIndisponible: [],
      tabulation: "pending",
      dataObject: "",
      detailHome: null,
      idProduct: "",
      IdCategorie: "",
      preloaderSpinner: false,
      photoResidence: [],
      photoPieces: [],
      lienUrl: new LienConnected().baseUrl,
      isDisponible: true,
      isIndisponible: false,
      motifForCancelDemande:"",
     
      photoDetail:[],
      idHouse: "",
      Listcategorie:[],
      type_idDetail: "",
      windowForApproveDemande:false,
      HomeDetail: "",
      typeDetail: "",
      costDetail: "",
      documentDetail: "",
      roomDetail: "",
      cautionDetail: "",
      descriptionDetail: "",
      listTypeOperation:[],
      listDemandeRejete:[],
      listDemandeApprouvee:[],
      listCommune:[],
      listCommodite:[],
      lotDetail: "",
      areaDetail: "",
      ilotDetail: "",
      adressDetail: "",
      person_maxiDetail: "",

      municipalityDetail: "",

      category_idDetail: "",
      registered_byDetail: "",
      spinnerDeletePicture:false,

      nombreDePhotoRestante: "",
      newArrayImage: [],
      idHome:"",
      spinnerDelete:"",
      toggleWindowForDelete:false,
      idDemande:"",
      windowForCancelDemande:false,
    };
  },
  methods: {
   
    
   
   
  
    async getAllListDemandeModification(route) {
      try {
        this.preloader = true;
        this.helpMeGetList(route);
        const allList = await this.dataObject.getAllData();
        if (allList.data.status === true) {
          this.preloader = false;
         
          return allList.data.data;
        }
      } catch (error) {
        console.log(error);
        this.preloader = false;
      }
    },
    helpMeGetList(route) {
      this.dataObject = new LienConnected(route, this.$store.state.token);
    },
    async getListDemande() {
      try {
        
        this.listDemandeModification = await this.getAllListDemandeModification(
          "showrequestP"
        );
        this.listDemandeRejete = await this.getAllListDemandeModification(
          "showrequestR"
        );
        this.listDemandeApprouvee = await this.getAllListDemandeModification(
          "showrequestA"
        );
        // console.log("this.listDemandeModification",this.listDemandeModification)
        //console.log("this.listDemandeApprouvee",this.listDemandeApprouvee)
        setTimeout(function () {
          let printCounter = 0;
          $("#MyTableData,#MyTableData1,#MyTableData2").DataTable({
            pagingType: "full_numbers",
            pageLength: 10,
            processing: true,
            dom: "Bfrtip",
            buttons: [
              {
                extend: "copy",
                text: "Copier les données",
                messageTop: function () {
                  printCounter++;

                  if (printCounter === 1) {
                    Swal.fire({
                      icon: "success",
                      title:
                        "Les données ont été copiées dans le presse-papiers.",
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  } else {
                    Swal.fire({
                      icon: "info",
                      title:
                        "Les données ont déjà été copiées dans le presse-papiers.",
                      showConfirmButton: true,
                    });
                  }
                },
              },
              {
                extend: "csv",
                text: "Exporter en fichier excel",
                fieldSeparator: ";",
                fieldBoundary: '"',
                bom: true,
                exportOptions: {
                  columns: [0],
                },
              },
              {
                extend: "print",
                text: "Imprimer les données",
              },
            ],
            order: [],
            language: {
              décimal: "",
              emptyTable: "Aucune donnée disponible dans le tableau",
              infoEmpty: "Showing 0 to 0 of 0 entries",
              info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
              infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
              infoPostFix: "",
              thousands: ",",
              lengthMenu: "Afficher les entrées du _MENU_",
              loadingRecords: "Loading...",
              processing: "Processing...",
              search: "Chercher :",
              stateSave: true,
              zeroRecords: "Aucun enregistrement correspondant trouvé",
              paginate: {
                first: "Premier",
                last: "Dernier",
                next: "Suivant",
                previous: "Précédent",
              },
              aria: {
                sortAscending: ": activate to sort column ascending",
                sortDescending: ": activate to sort column descending",
              },
            },
          });
        }, 10);
      } catch (error) {
        this.preloader = false;
        console.log(error);
      }
    },
    boucleInArraImage(arrayPicture) {
      let compteur = 0;
      let images = [];
      while (compteur < arrayPicture.length) {
        images.push(arrayPicture[compteur].image);
        compteur++;
      }
      return images;
    },
    bouclerInList(liste,id){
      liste.forEach((item) => {
        if (item.id == id) {
          item.flag = 0
          this.detailHome = item;
          //console.log(this.detailHome)
        }
      });
    },

    async isItemFlag(liste,id){
      try{
        const dataObject = new LienConnected("updateFlagMR/"+id,this.$store.state.token)
      const response = await dataObject.sendData(null);
      //console.log("response",response)
      if(response.data.status){
        this.$store.dispatch("seeAlarmDemandeModification")
      this.bouclerInList(liste,id)
      }
      }catch(error){
        console.log(error)
      }
    },
    viewDetailsDemande(idPropriete,list) {
      list.forEach(prop=>{
        if(prop.id === idPropriete){
          this.detailHome = prop;
        }
      })
      if(this.detailHome.flag == 1){
        this.isItemFlag(list,idPropriete)
      }
      this.showDetailResidenceForModify = !this.showDetailResidenceForModify;
    },
    helpMeIterate(data) {
      let photo = [];
      data.forEach((el) => {
        photo.push(this.lienUrl.replace("/api/", "/") + "public/public/image/" + el);
      });
      return photo;
    },

    approveDemande(id){
      this.idDemande = id
      this.windowForApproveDemande = !this.windowForApproveDemande;
    },
    async confirmerDemande(){
      let data ={
     "status": "Approved",
    "id": this.idDemande
      }
      this.helpMeGetList("approveModification");
       
        const allList = await this.dataObject.sendData(data);
        //console.log("allList",allList)
        if(allList.data.status === true){
          Swal.fire({
        icon: "success",
        title: "Demande approuvée.",
        showConfirmButton: true,
      });
      this.idDemande="";
      this.windowForApproveDemande = !this.windowForApproveDemande;
      this.showDetailResidenceForModify = !this.showDetailResidenceForModify
        }
     //console.log("this.idDemande",this.idDemande)
    },
    rejetDemande(id){
      this.idDemande = id

      this.windowForCancelDemande = !this.windowForCancelDemande
    },
    async cancelDemande(){
      let data ={
     "status": "Rejected",
     "commentaire":this.motifForCancelDemande,
    "id": this.idDemande
      }
      //console.log(data)
      this.helpMeGetList("approveModification");
       
        const allList = await this.dataObject.sendData(data);
        //console.log("allList",allList)
        if(allList.data.status === true){
          Swal.fire({
        icon: "success",
        title: "Demande annulée",
        showConfirmButton: true,
      });
      this.motifForCancelDemande = ""
      this.idDemande = ""
      this.windowForCancelDemande = !this.windowForCancelDemande
      this.showDetailResidenceForModify = !this.showDetailResidenceForModify
      //console.log("this.idDemande",this.idDemande)
    }
    },
    putImageInArray(arrayPicture) {
      let compteur = 0;
      let images = [];
      while (compteur < arrayPicture.length) {
        images.push(
          this.lienUrl.replace("/api/", "/") +
            "public/public/image/" +
            arrayPicture[compteur].image
        );
        compteur++;
      }
      return images;
    },
    iterateInArrCommodite(tableau) {
      let compteur = 0;
      let commoditeArray = [];
      while (compteur < tableau.length) {
        let idLibelle = tableau[compteur].label.id
          ? tableau[compteur].label.id
          : tableau[compteur].pivot.comodity_id;
        commoditeArray.push({
          comodity_id: idLibelle,
          number: tableau[compteur].pivot.number,
        });
        compteur++;
      }
      return commoditeArray;
    },

  },
  mounted() {
    this.getListDemande();
  },
};
</script>
<template>

  <div class="confirmation_modifie" v-show="windowForCancelDemande">
    <div class="card">
      <h6 class="text-center text-secondary">
        Voulez-vous vraiment annuler la demande de modification?
      </h6>
        <div class="conteneur-cancel-demande">
          <textarea name="" id="" cols="30" rows="10" 
          v-model="motifForCancelDemande" placeholder="Le motif"></textarea>

          <div class="d-flex justify-content-center align-items-center">
            <div>
              <button
                class="btn btn-all mx-2 fw-bold bg-dark"
                @click.prevent="cancelDemande()"
              >
                Confirmer
                <span
                  class="spinner-border spinner-border-sm ms-2"
                  v-show="spinnerDelete"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
              <button
                class="btn bg-danger mx-2 text-white fw-bold"
                @click.prevent="windowForCancelDemande = !windowForCancelDemande"
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
     
    </div>
  </div>
 

  <div class="confirmation_modifie" v-show="windowForApproveDemande">
    <div class="card">
      <h6 class="text-center text-secondary">
        Voulez-vous vraiment approuver la demande?
      </h6>

      <div class="d-flex justify-content-center align-items-center">
        <div>
          <button
            class="btn btn-all mx-2 fw-bold bg-dark"
            @click.prevent="confirmerDemande()"
          >
            Confirmer
            <span
              class="spinner-border spinner-border-sm ms-2"
              v-show="spinnerDelete"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
          <button
            class="btn bg-danger mx-2 text-white fw-bold"
            @click.prevent="windowForApproveDemande = !windowForApproveDemande"
          >
            Annuler
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-9 col-md-12">
    <div class="dashboard-wraper">
      

      <div class="conteneur_modify" v-if="showDetailResidenceForModify">
        <div class="content position-relative" v-if="detailHome">
          <div
            class="ecriteau text-start d-flex justify-content-between align-items-center"
          >
            <h4>Détail de la demande ({{detailHome.propriety.name}})</h4>
            <i
              class="bi bi-x-circle h1"
              @click="showDetailResidenceForModify = !showDetailResidenceForModify"
            ></i>
          </div>
          <div class="text-start px-3">
            <div
              class="submit-pages text-start color_container"
              
            >
              <div class="row container product cont">
                <div class="form-group col-md-12 col-sm-12">
                  <div>
                    <h5 class="badge bg-info">Info de la demande</h5>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-sm-12" v-if="detailHome.status === 'Pending' ">
                      
                      <strong v-if="detailHome.fields_to_modify.cost">Prix</strong>
                      <h6 class="p-0 m-0" v-if="detailHome.fields_to_modify.cost">{{ moneyFormat.format(detailHome.fields_to_modify.cost) }} Fcfa</h6>
                      <strong v-if="detailHome.fields_to_modify.description">Description</strong>
                      <h6 class="p-0 m-0">
                        {{ detailHome.fields_to_modify.description }}
                      </h6>
                      <strong v-if="detailHome.fields_to_modify.person_maxi">Nbre de personnes</strong>
                      <h6 class="p-0 m-0">
                        {{ detailHome.fields_to_modify.person_maxi }}
                        
                      </h6>
                      <strong class="d-block" v-if="detailHome.fields_to_modify.comodites">Commoditées</strong>
                      <span
                        v-for="(item, index) in detailHome.comodites"
                        :key="index"
                        class="d-flex"
                      >
                        <b class="text-light">{{ item.pivot.number }}</b>
                        <b class="text-light mx-2">
                          {{ item.label }}
                        </b>
                      </span>
                      <div class="d-flex justify-content-center align-items p-3">
                        <button class="btn bg-success text-light mx-1" @click="approveDemande(detailHome.id)">Approuver</button>
                        <button class="btn bg-danger text-light mx-1" @click="rejetDemande(detailHome.id)">Rejeter</button>
                      </div>
                    </div>
                    <div class="col-md-12 col-sm-12" v-else>
                      <div class="bg-danger p-1 text-center">
                        <h3 class="text-light">Demande annulée</h3>
                      </div>
                      <strong v-if="detailHome.commentaire">Motif d'annulation</strong>
                      <h6 class="p-0 m-0">
                        {{ detailHome.commentaire }}
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-12 col-sm-12">
                  <div>
                    <h5 class="badge bg-info">Info Proprietaire</h5>
                  </div>

                  <strong>Nom & prénoms</strong>
                  <h6 class="p-0 m-0">
                    {{ detailHome.user.lastname }}
                    {{ detailHome.user.firstname }}
                  </h6>
                  <strong>Email </strong>
                  <h6 class="p-0 m-0">{{ detailHome.user.email }}</h6>
                  <strong>Télephone </strong>
                  <h6 class="p-0 m-0">{{ detailHome.user.phone }}</h6>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Bookmark Property -->
      <div
        class="form-submit position-relative"
        :class="preloader ? 'pre' : 'null'"
      >
        <div id="preloader" class="position-absolute" v-show="preloader">
          <div class="preloader"><span></span><span></span></div>
        </div>
        <h2 class="text-start text-light">Demande de modification</h2>
        <ul
        class="d-flex justify-content-start align-items-center px-5 listes"
      >
      <li  @click.prevent="tabulation = 'pending'">
        <a
          href="#"
          :class="{ color: tabulation == 'pending' }"
          class="lien position-relative"
         
          >En attente d'approbation
          <span v-show="tabulation == 'pending'" class="badge bg-danger badge-total position-absolute">{{
            listDemandeModification.length
          }}</span>
          </a
        >
      </li>
      
        <li @click.prevent="tabulation = 'approuve'">
          <a
            href="#"
            :class="{ color: tabulation == 'approuve' }"
            class="lien position-relative"
            
            >Approuvées
            <span v-show="tabulation == 'approuve'" 
            class="badge bg-danger badge-total position-absolute">{{
              listDemandeApprouvee.length
            }}</span>
            </a
          >
        </li>
        <li @click.prevent="tabulation = 'Rejeter'">
          <a
            href="#"
            :class="{ color: tabulation == 'Rejeter' }"
            class="lien position-relative"
            
            >Rejetées
            <span v-show="tabulation == 'Rejeter'" class="badge bg-danger badge-total position-absolute">{{
              listDemandeRejete.length
            }}</span>
            </a
          >
        </li>
      
  
      </ul>

        
      </div>
      <section v-show="tabulation == 'pending'">
        <div class="col-sm-12 dataTable" v-if="!preloader">
          <table id="MyTableData" class="table text-center table-responsive">
            <thead>
              <tr class="text-center">
                <th class="bg-light text-center">Date de la requête</th>
                <th class="bg-light text-center">Propriété</th>
                <th class="bg-light text-center">Propriétaire</th>
                <th class="bg-light text-center">Statut</th>
                <th class="bg-light text-center">Détail</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(propriete, index) in listDemandeModification"
                :key="index"
                :class="propriete.flag == 1 ? 'bg-warning':null"
              >
                <td
                  @click.prevent="
                    viewDetailsDemande(propriete.id, listDemandeModification)
                  "
                >
                  {{ new Date(propriete.created_at).toLocaleDateString('fr') }}
                </td>
                <td @click.prevent="
                viewDetailsDemande(propriete.id, listDemandeModification)
              ">
                  {{ propriete.propriety.name }}
                  
                </td>
                <td
                  @click.prevent="
                    viewDetailsDemande(propriete.id, listDemandeModification)
                  "
                >
                {{ propriete.user.firstname }} {{ propriete.user.lastname }}
                </td>
                <td
                @click.prevent="
                  viewDetailsDemande(propriete.id, listDemandeModification)
                "
              >
              <span class="badge bg-danger"> En attente d'approbation </span>
             
              </td>
                

                
                <td class="text-center">
                  <span
                    class="d-flex justify-content-center align-items-center"
                  >
                    <i
                      class="bi bi-eye mx-2"
                      @click.prevent="
                        viewDetailsDemande(
                          propriete.id,
                          listDemandeModification
                        )
                      "
                    ></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section v-show="tabulation == 'Rejeter'">
        <div class="col-sm-12 dataTable" v-if="!preloader">
          <table id="MyTableData1" class="table text-center table-responsive">
            <thead>
              <tr class="text-center">
                <th class="bg-light text-center">Date de la requête</th>
                <th class="bg-light text-center">Propriété</th>
                <th class="bg-light text-center">Propriétaire</th>
                <th class="bg-light text-center">Statut</th>
                <th class="bg-light text-center">Détail</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(propriete, index) in listDemandeRejete"
                :key="index"
              >
                <td
                  @click.prevent="
                    viewDetailsDemande(propriete.id, listDemandeRejete)
                  "
                >
                  {{ new Date(propriete.created_at).toLocaleDateString('fr') }}
                </td>
                <td @click.prevent="
                viewDetailsDemande(propriete.id, listDemandeRejete)
              ">
                  {{ propriete.propriety.name }}
                  
                </td>
                <td
                  @click.prevent="
                    viewDetailsDemande(propriete.id, listDemandeRejete)
                  "
                >
                {{ propriete.user.firstname }} {{ propriete.user.lastname }}
                </td>
                <td
                @click.prevent="
                  viewDetailsDemande(propriete.id, listDemandeRejete)
                "
              >
              <span class="badge bg-danger"> Rejetée </span>
             
              </td>
                

                
                <td class="text-center">
                  <span
                    class="d-flex justify-content-center align-items-center"
                  >
                    <i
                      class="bi bi-eye mx-2"
                      @click.prevent="
                        viewDetailsDemande(
                          propriete.id,
                          listDemandeRejete
                        )
                      "
                    ></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section v-show="tabulation == 'approuve'">
        <div class="col-sm-12 dataTable" v-if="!preloader">
          <table id="MyTableData2" class="table text-center table-responsive">
            <thead>
              <tr class="text-center">
                <th class="bg-light text-center">Date de la requête</th>
                <th class="bg-light text-center">Propriété</th>
                <th class="bg-light text-center">Propriétaire</th>
                <th class="bg-light text-center">Statut</th>
                <th class="bg-light text-center">Détail</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(propriete, index) in listDemandeApprouvee"
                :key="index"
              >
                <td
                  @click.prevent="
                    viewDetailsDemande(propriete.id, listDemandeApprouvee)
                  "
                >
                  {{ new Date(propriete.created_at).toLocaleDateString('fr') }}
                </td>
                <td @click.prevent="
                viewDetailsDemande(propriete.id, listDemandeApprouvee)
              ">
                  {{ propriete.propriety.name }}
                  
                </td>
                <td
                  @click.prevent="
                    viewDetailsDemande(propriete.id, listDemandeApprouvee)
                  "
                >
                {{ propriete.user.firstname }} {{ propriete.user.lastname }}
                </td>
                <td
                @click.prevent="
                  viewDetailsDemande(propriete.id, listDemandeApprouvee)
                "
              >
              <span class="badge bg-danger"> Rejetée </span>
             
              </td>
                

                
                <td class="text-center">
                  <span
                    class="d-flex justify-content-center align-items-center"
                  >
                    <i
                      class="bi bi-eye mx-2"
                      @click.prevent="
                        viewDetailsDemande(
                          propriete.id,
                          listDemandeApprouvee
                        )
                      "
                    ></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
     
    </div>
  </div>
</template>
<style scoped>
.trash-two {
  top: 0;
  right: 0.2em;
  background-color: white;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.36) inset;
  padding: 0.2em;
  cursor: pointer;
  border-radius: 5px;
}
div.n-space {
  justify-content: center !important;
}
.listes {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.085);
  background-color: white;
  padding: 1em 0;
  border-radius: 10px;
}

.lien {
  text-decoration: none;
  font-size: 1.3em;

  color: rgb(218, 216, 216);
}
.lien:hover {
  color: rgb(255, 187, 0);
}

.color,
.colorHistorique,
.colorNew {
  color: rgb(0, 0, 0) !important;
  border-radius: 5px;
  padding: 0.5em;
  font-weight: bold;
  background-color: rgb(255, 187, 0);
}
.text-dark {
  color: black !important;
}
.product .form-group {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.356);
  border-radius: 10px;
}
.bg-info {
  background-color: rgb(0, 0, 0) !important;
}
.addField {
  width: auto !important;
}
.delete {
  position: absolute;
  right: 0;
  cursor: pointer;
  width: 20px;
}
#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: rgba(244, 242, 242, 0);
  display: flex;
  justify-content: center;
  place-items: center;
}
.bg-danger,
.bg-danger:hover {
  color: white !important;
}
strong {
  font-weight: 900;
  font-size: 0.9em !important;
  color: gray;
  text-transform: uppercase;
  padding: 0 0 0.5em 0;
  text-decoration: underline;
}
h6 {
  margin: 0 0 1em 0 !important;
  text-transform: capitalize;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}
[type="radio"]:checked + label:after {
  opacity: 0 !important;
}
.circuit {
  padding: 0 !important;
}
.card {
  padding: 1em 3em;
}
.bg-primary {
  background-color: rgb(55, 137, 167) !important;
  border: 2px solid rgb(55, 137, 167) !important;
  color: white !important;
}
.bi-eye,
.bi-x-circle,
.bi-pencil,
.bi-envelope-paper,
.bi-trash {
  cursor: pointer;
}
.pre {
  padding: 0 0 15em 0;
}
.badge {
  color: white !important;
}
.table {
  border: 1px solid black;
}
td {
  border-right: 2px solid black;
}

.confirmation_modifie {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #d9a509 !important;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.close_modal {
  position: absolute;
  top: 1em;
  right: 1em;
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index: 999;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.submit-pages {
  padding: 1em 3em;
}
.submit-pages_view {
  padding: 1em 3em;
}
</style>
