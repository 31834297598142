<script>

import Swal from "sweetalert2";
import LienConnected from '../../class_help_me'
export default {
  name: "saveCategorie",
  data() {
    return {
      libelleCategorie: "",
      preloader:false,
      dataObject:new LienConnected('categories',this.$store.state.token)
    };
  },
  methods: {
    async saveCategorie() {
      try {
        let dataCategorie = {
        label: this.libelleCategorie,
      };
      this.preloader = true;
      const createCategorie = await this.dataObject.sendData(dataCategorie)
     const response = createCategorie
     //console.log(response)
     if(response.data.status === true){
          
               Swal.fire({
              icon: "success",
              title: "Categorie enregistrée.",
              showConfirmButton: false,
              timer: 1500,
            });
            this.libelleCategorie = ""
            this.preloader = false
            setTimeout(()=>{
              this.$router.push({name:"listCategorieProduit"})
            },1500)
            
     }
      } catch (error) {
        this.preloader = false
      }

    },
  },
};
</script>
<template>
  <div class="col-lg-9 col-md-12 position-relative">
    <div class="dashboard-wraper">
      <div class="row text-start">
        <!-- Submit Form -->
        <div class="col-lg-12 col-md-12">
          <form @submit.prevent="saveCategorie">
            <div class="submit-pages">
              <!-- Basic Information -->
              <div class="form-submit">
                <h3 class="text-light">Enregistrer une categorie</h3>
                <div class="submit-section">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label class="text-dark">Libellé</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="libelleCategorie"
                     
                      />
                    </div>

                    <div>
                      <button
                        v-if="libelleCategorie"
                        class="btn bg-dark text-light rounded"
                        type="submit"
                      >
                        Enregistrer
                        <span class="spinner-border spinner-border-sm ms-2" v-show="preloader"  role="status" aria-hidden="true"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}
.dashboard-wraper {
  height: 100%;
}
.bi-x-circle {
  cursor: pointer;
}

.circuit {
  padding: 0 !important;
}
[type="radio"]:checked + label:after {
  opacity: 0 !important;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index: 9999;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.submit-pages_view {
  padding: 1em 3em;
}
</style>
