<script>
import $ from "jquery";
import Swal from "sweetalert2";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import 'datatables.net-buttons';
import 'datatables.net';
import LienConnected from '../../class_help_me'
export default {
  name: "listeReservation",
  data() {
    return {
      idPropriete:"",
      commentRemoveBooking:"",
      windowForCancelBooking:false,
      dataObject:new LienConnected('reservations',this.$store.state.token),
      listReservation:[],
      preload:false,
      moneyFormat: new Intl.NumberFormat("de-DE"),
      preloader:false,
      showDetail:false,
      detailReservation:null,
      photoResidence:[],
      photoPieces:[],
      lienUrl:new LienConnected().baseUrl.replace("/api/", "/"),
  
    };
  },
  methods: {
    async modifierStatutReservation(idReservation){
    //console.log("idReservation",idReservation)

    try{
        
        
        let data = {
          status:"Confirmée"
        }
    
      const allList = await this.dataObject.modifyData(data,idReservation)
      if(allList.data.status === true){
        //console.log(allList)
      }

   

      }catch(error) {
        this.preloader = false
        //console.log(error)
      }
  },
    async getListReservation(){
      this.preloader = true
      const allList = await this.dataObject.getAllData()
      if(allList.data.status === true){
        this.listReservation = allList.data.data
        // console.log("this.listReservation",this.listReservation)
     
        this.listReservation.forEach(item=>{
         item.IdResidence = item.id
        
      })
      this.preloader = false
    }
       
      //console.log("NEW LIST", this.listReservation)
      setTimeout(function () {
        let printCounter = 0
            $("#MyTableData").DataTable({
              pagingType: "full_numbers",
              pageLength: 10,
              processing: true,
              dom: "Bfrtip",
                      buttons: [
        {
            extend: 'copy',
            text: 'Copier les données',
            messageTop: function () {
                    printCounter++;
 
                    if ( printCounter === 1 ) {
                      Swal.fire({
              icon: "success",
              title: 'Les données ont été copiées dans le presse-papiers.',
              showConfirmButton: false,
              timer: 1500,
            });
                     
                    }
                    else {
                      Swal.fire({
              icon: "info",
              title: 'Les données ont déjà été copiées dans le presse-papiers.',
              showConfirmButton: true,
            });
                    }
                } 
        },
        {
          extend: 'csv',
  text: 'Exporter en fichier excel',
  fieldSeparator: ';',
  fieldBoundary: '"',
  bom: true,
  exportOptions: {
    columns: [0]
  }
        },
        {
            extend: 'print',
            text: 'Imprimer les données' 
        }
    ],
              order: [],
              language: {
                décimal: "",
                emptyTable: "Aucune donnée disponible dans le tableau",
                infoEmpty: "Showing 0 to 0 of 0 entries",
                info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "Afficher les entrées du _MENU_",
                loadingRecords: "Loading...",
                processing: "Processing...",
                search: "Chercher :",
                stateSave: true,
                zeroRecords: "Aucun enregistrement correspondant trouvé",
                paginate: {
                  first: "Premier",
                  last: "Dernier",
                  next: "Suivant",
                  previous: "Précédent",
                },
                aria: {
                  sortAscending: ": activate to sort column ascending",
                  sortDescending: ": activate to sort column descending",
                },
              },
            });
          }, 10);
    },
    closeWindow(){
      this.showDetail = !this.showDetail
    }, 
    helpMeIterate(data,folder){
    let photo = []
   data.forEach(el=>{
    photo.push(this.lienUrl.replace("/api/", "/")+folder+el)
      })
    return photo
  },
  windowDetailResidence(id) {
      let photo = []
      let piece = []
      this.photoResidence = []
      this.photoPieces = []
      this.listReservation.forEach((item) => {

      if (item.id == id) {
        photo = item.propriety.image ? JSON.parse(item.propriety.image) : [];
        piece = item.user.cni_doc ? JSON.parse(item.user.cni_doc) : [];
        this.detailReservation = item;
      }
    });
    this.photoResidence = this.helpMeIterate(photo,'public/public/image/')
    this.photoPieces = this.helpMeIterate(piece,'public/public/image/')
      
    this.showDetail = !this.showDetail;
  },
   
  async removeBooking(){
      let dataSend = {
        status :"Annulée",
        raison:this.commentRemoveBooking
      }
      this.preloaderSpinner = true
      try{
  const cancelReservation = new LienConnected('reservations',this.$store.state.token)
     const booking = await cancelReservation.modifyData(dataSend,this.idPropriete)
     const response = booking
     //console.log("response",response)
     if(response.data.status === true){
      //console.log("RESPONSE FOR RESERVATION",response)
      this.windowForCancelBooking = !this.windowForCancelBooking
      this.preloaderSpinner = false
      Swal.fire({
      icon: "success",
      title: "Réservation annulée.",
      showConfirmButton: false,
      timer: 1500,
    });
     }
}catch(error){
  //console.log(error)
  this.preloaderSpinner = false
}
     },
    cancelBooking(id){
      this.idPropriete = id
   this.windowForCancelBooking = !this.windowForCancelBooking
     },
  },
  created() {
    this.getListReservation()
  },
};
</script>
<template>
  <div class="col-lg-9 col-md-12">
    <div class="dashboard-wraper">
      <section v-if="windowForCancelBooking" class="confirmation_modifie">
        <div class="card">
          <h4 class="text-center text-secondary">
            Voulez-vous annuler la réservation ?</h4>
            <label class="text-dark text-start h6">Motif</label>
            <textarea name="" id="" cols="30" rows="10" v-model="commentRemoveBooking"></textarea>
          
          <div class="d-flex justify-content-center align-items-center my-2">
           
            <div>
              <button
              class="btn btn-all  bg-dark text-light mx-2 " 
              @click.prevent="removeBooking"
            >
              Confirmer
              <span class="spinner-border spinner-border-sm ms-2" v-show="preloaderSpinner" 
               role="status" aria-hidden="true"></span>
            </button>
            <button class="btn bg-danger mx-2 text-white" @click.prevent="windowForCancelBooking = !windowForCancelBooking">
              Annuler
            </button>
            </div>
          </div>
        </div>
        
      </section>
      <div class="conteneur_modify" v-if="showDetail">
        <div class="content position-relative">
          <div
            class="ecriteau text-start d-flex justify-content-between align-items-center"
          >
            <h4>Détail de la réservation</h4>
            <i
              class="bi bi-x-circle h1"
              @click.prevent="closeWindow"
            ></i>
          </div>
          <div class="text-start px-3">
            <div class="submit-pages text-start color_container" v-if="detailReservation">
              <div class="row container product cont">
                <div class="form-group col-md-12 col-sm-12">
                  <div>
                    <h5 class="badge bg-info">Info Résidence</h5>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-sm-12">
                      <strong>Date de réservation</strong>
                      <h6 class="p-0 m-0">De 
                        {{ new Date(detailReservation.date_start).toLocaleDateString('fr')}}
                         à {{ new Date(detailReservation.date_end).toLocaleDateString('fr')}} 
                        </h6>
                        <strong>Code de réservation</strong>  
                        <h6 class="p-0 m-0">{{ detailReservation.code}}</h6>
                        <strong>Résidence</strong>  
                      <h6 class="p-0 m-0">{{ detailReservation.propriety.name}}</h6>
                      <strong>Prix (Fcfa) </strong>
                      <h6 class="p-0 m-0">{{ moneyFormat.format(detailReservation.propriety.cost) }}</h6>
                      <strong>Située</strong>
                      <h6 class="p-0 m-0"> {{ detailReservation.municipality_id.city }}
                        {{ detailReservation.municipality_id.name }}</h6>
                      <strong>Situé précisément</strong>
                      <h6 class="p-0 m-0">{{ detailReservation.propriety.adress }}</h6>
                      <strong>Galerie</strong>
                      <MazGallery :images="photoResidence" :height="400" />
                    </div>
                  
                  </div>
                </div>
                <div
                  class="form-group col-md-12 col-sm-12"
                 
                >
                  <div>
                    <h5 class="badge bg-info">Info Client</h5>
                  </div>

                  <strong>Nom & prénoms</strong>
                  <h6 class="p-0 m-0">{{ detailReservation.user.lastname}} {{ detailReservation.user.firstname}}</h6>
                  <strong>Email </strong>
                  <h6 class="p-0 m-0">{{ detailReservation.user.email }}</h6>
                  <strong>Télephone </strong>
                  <h6 class="p-0 m-0">{{ detailReservation.user.phone }}</h6>
                  <strong>Pièces d'identité</strong>
             
                      <div v-if="photoPieces.length">
                        <span v-for="item,index in photoPieces" :key="index">
                          <n-image
                          width="100" 
                          :src='item' 
                        />
                        </span>
                      </div>
                      <div v-else>
                        <h5 class="text-light">Pas de pièces d'identité</h5>
                      </div>
                    
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
      class="form-submit position-relative pt-3"
      :class="preloader ? 'pre' : 'null'"
    >
      <div id="preloader" class="position-absolute" v-show="preloader">
        <div class="preloader"><span></span><span></span></div>
      </div>
      <h2 class="text-start text-light-2">Réservations confirmées</h2>
    </div>

    <div class="col-sm-12 dataTable position-relative" v-if="!preloader">
      
     
      <table id="MyTableData" class="table text-center table-responsive">
        
        <thead>
          <tr class="text-center">
            <th class="bg-light text-center">Date de réservation</th> 
            <th class="bg-light text-center">Client</th> 
            <th class="bg-light text-center">Résidence</th> 
            <th class="bg-light text-center">Image</th> 
            <th class="bg-light text-center">Date d'entrée</th>
            <th class="bg-light text-center">Date de sortie</th>
            <th class="bg-light text-center">Code de réservation</th>
          
            <th class="bg-light text-center">Annulée</th>
            <th class="bg-light text-center">Détail</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in listReservation"  :key="index"
          :class="item.flag == 1 ? 'bg-warning':null"
          >
            <td @click="windowDetailResidence(item.id)">
             {{ new Date(item.created_at).toLocaleDateString('fr')}}
             </td >
             <td @click="windowDetailResidence(item.id)">
              {{ item.user.lastname }} {{ item.user.firstname }}
             </td>
             <td @click="windowDetailResidence(item.id)">
             {{item.propriety.name}}
             </td>
             <td>
              <n-image
              width="50" 
              :src='this.lienUrl.replace("/api/", "/")+"public/public/image/"+JSON.parse(item.propriety.image)[0]' 
              v-if="item.propriety.image"
            />
              </td>
             <td @click="windowDetailResidence(item.id)">
              {{new Date(item.date_start).toLocaleDateString('fr')}}
             </td>
             <td @click="windowDetailResidence(item.id)">
              {{new Date(item.date_end).toLocaleDateString('fr')}}
             </td>
            <td @click="windowDetailResidence(item.id)">
              {{item.code}}
            </td>
           
            
             <td>
              <button class="btn bg-danger"
              @click="cancelBooking(item.id)">Annulée</button>
            </td>
             <td>
              <i class="bi bi-eye" @click="windowDetailResidence(item.id)"></i>
             </td>
          </tr>
        </tbody>
      </table>


    </div>
  
   
    </div>
  </div>
</template>
<style scoped>
div.n-space{
  justify-content:center !important;
}
.confirmation_modifie {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.close_modal {
  position: absolute;
  top: 1em;
  right: 1em;
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index: 9999;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.bg-secondary{
  background-color:rgb(73, 72, 72) !important;
}
.text-light{
  font-size:.8em !important;
}
.cont .form-group{
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.222);
  border-radius:8px;
}
strong {
  font-size: 1em !important;
  color: gray;
  text-decoration:underline;
  padding: 0 0 0.5em 0;
  font-weight: bold;
  text-transform: uppercase;
}
.bg-info{
  background-color:black !important;
}
section{
  color:black;
}
.badge,.text-light-2 {
  color: white !important;
}

.addField {
  width: auto !important;
}
.designation {
  font-size: 1.3em !important;
}
.btn-rejet {
  background-color: rgb(219, 0, 0) !important;
  border: 1px solid rgb(219, 0, 0) !important;
  margin: 0 2em;
  color: white !important;
}
.btn-rejet:hover {
  background-color: rgb(219, 0, 0) !important;
  border: 1px solid rgb(219, 0, 0) !important;
  margin: 0 2em;
  color: white !important;
}
#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: rgba(244, 242, 242, 0);
  display: flex;
  justify-content: center;
  place-items: center;
}
.bg-danger,
.bg-danger:hover {
  color: white !important;
}

h6 {
  margin: 0 0 1em 0 !important;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}
[type="radio"]:checked + label:after {
  opacity: 0 !important;
}
.circuit {
  padding: 0 !important;
}
.card {
  padding: 1em 3em;
  width:50%;
}
.bg-primary {
  background-color: rgb(55, 137, 167) !important;
  border: 2px solid rgb(55, 137, 167) !important;
  color: white !important;
}
.bi-eye,
.bi-x-circle,
.bi-pencil,
.bi-envelope-paper,
.bi-trash,
.delete {
  cursor: pointer;
}
.pre {
  padding: 0 0 15em 0;
}
.table {
  border: 1px solid black;
}
td {
  border-right: 2px solid black;
}
.reset{
  cursor:pointer
}
.confirmation_comment {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 909;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.confirmation_modifie {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 90;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 90;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.close_modal {
  position: absolute;
  top: 1em;
  right: 1em;
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index: 909;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.submit-pages {
  padding: 1em 3em;
}
.text-Acquereur {
  margin: 1em 0;
}
.delete{
  position:absolute;
  right:0;
  cursor: pointer;
  width:20px;
}
.recherche{
  top:41px;
  right:-.6em;
  z-index:9;
}
.recherche_with_value{
  top:14px;
  right:-.6em;
  z-index:9;
}
.text-dark{
  color:black !important;
}
.recherche input{
  width:217px;
  height:35px;
  border:thin solid black;
  border-radius:2px;
}
</style>
