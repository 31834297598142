<script>

import Swal from "sweetalert2";
import { ref } from 'vue'
import LienConnected from '../../class_help_me'
const chooseProprietaire = ref(null);
const dateToday = new Date().toISOString().slice(0, 10);

export default {
  name: "Enregistrer-Paiement",
 
  data() {
    return {
      imageBlob:"",
      Montant: "",
      loadingShowProprietaire:false,
      lienUrl:new LienConnected().baseUrl,
      numeroAttribution: "",
      modePaiement: "",
      pieceJointe: "",
      moyenPaiement: "",
      fields:[{
      user_id:"",
      proof:"",
    }],
    tableauPreuvePush:[],
    chooseProprietaire,
    dateToday,
    startDatePaiement:"",
    endDatePaiement:"",
      moneyFormat: new Intl.NumberFormat("de-DE"),
      preloader: false,
      listProprietaire: [],
      listMoyenPayment: [],
      ProofPieces:"",
      Reference:"",
      libelleMoyenPaiement:"",
      tableauUserId:[],
        tableauProof:[],
    };
  },
  methods: {
   isObjectsEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }
 
    return true;
},
 isObjectEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
},
deleteField: function (element) {
      const DeleteField = this.fields.findIndex((item) => item === element);
      this.fields.splice(DeleteField, 1);
    },
    addField:function(){
        this.fields.push({user_id:"",proof:""})
        },
        onInput(val) {
      // Emit `input` event with the selected value
      this.$emit("input", val);
      //console.log(val)
    },
        inputSelect(e){
          console.log(e);
        },
    selectMoyenPaiement(e){
      this.listMoyenPayment.forEach(item=>{
        if(e.target.value == item.id){
          this.libelleMoyenPaiement = item.label
        }
      })
    },
    closeViewInfoProspect() {
      this.viewInfoProspect = !this.viewInfoProspect;
    },
   async getAllFournisseur() {
    this.loadingShowProprietaire = true
      const dataObject = new LienConnected("proprietaires",this.$store.state.token)
   const response = await dataObject.getAllData()
   //console.log("responsePROPREITAIRE",response)
   try{
    if(response.data.status === true){
      this.loadingShowProprietaire = false
    this.listProprietaire = response.data.data
    }

   }catch(error){
      //console.log(error)
      this.loadingShowProprietaire = false
   }

    },
 
  
    handleMedias(field,e) {
      //console.log(e.target.files[0])
     
    //console.log(this.fields[field].proof)

      this.fields[field].proof = e.target.files[0]
    },
    selectedProduit(e) {
      //console.log("SELECTED", e);
      this.listProprietaire.forEach((item) => {
        if (item.label == e.target.value) {
          this.numeroAttribution = item.id
        }
      });
      //console.log("this.numeroAttribution", this.numeroAttribution);
    },
   
    middlewarePayment(){
      if(!this.startDatePaiement || !this.endDatePaiement){
        Swal.fire({
         icon: "info",
         title: "Veuillez renseigner une Période (date début et date fin).",
         showConfirmButton: true,
       
       });
      }else if(!this.fields[0].user_id || !this.fields[0].proof){
        Swal.fire({
         icon: "info",
         title: "Sélectionner des propriétaires en ajoutant une preuve.",
         showConfirmButton: true,
       
       });
      }else{
        this.sendPaiement()
      }
    },
    recupereFiles(){
      this.tableauUserId = [];
      this.tableauPreuvePush = [];
      let tableauProof = new FormData();

      this.fields.forEach(item=>{  
        this.tableauUserId.push(item.user_id.id);
        this.tableauPreuvePush.push(item.proof);
    })
  
    this.tableauPreuvePush.forEach(item=>{
      tableauProof.append("proof[]",item);
    })
    this.tableauUserId.forEach(item=>{
      tableauProof.append("owners[]",item);
    })
    tableauProof.append("startDate",this.startDatePaiement);
    tableauProof.append("endDate",this.endDatePaiement);
    return tableauProof;
    },
  
    async sendPaiement() {
    
  let tableauProof = this.recupereFiles()
 
      try{
    this.preloader = true
    const dataObject = new LienConnected('paySelectedOwners',this.$store.state.token)

   const response = await dataObject.sendData(tableauProof)
   //console.log("response",response)
   if(response.data.status === true){
    let proprietairewhoPaymentNotSucess = []
    response.data.owners_without_reservations.forEach(item=>{
      // let nameProprietaire = `${item.firstname item.lastname}`;
      proprietairewhoPaymentNotSucess.push(" "+item.firstname+' '+item.lastname)
    })
          Swal.fire({
         icon: "success",
         title: response.data.message,
         footer:proprietairewhoPaymentNotSucess,
         showConfirmButton: true,
       
       });
       this.startDatePaiement = "";
       this.endDatePaiement = "";
       
       this.fields=[{
      user_id:"",
      proof:"",
    }];
    document.querySelector(".image_proof").value = ""
    this.preloader = false   
}
   }catch(error){
    this.preloader = false
   
   }

     
    },
    
  },
  created() {
    this.getAllFournisseur();
  },
};
</script>
<template>
  <div class="col-lg-9 col-md-12 position-relative">
    <div class="dashboard-wraper">
      <div class="row text-start">
        <!-- Submit Form -->
        <div class="col-lg-12 col-md-12">
          <form @submit.prevent="middlewarePayment">
            <div class="submit-pages">
              <div class="form-submit">
                <h3 class="text-light">Effectuer un paiement</h3>
                <div class="submit-section">
                  <div class="row">
                    <div class="form-group col-md-12 ">
                      <label  class="mb-2">Période du </label>
                     <div class="d-flex justify-content-between align-items-center gap-5">
                      <input type="date"  class="form-control"  v-model="startDatePaiement">
                      <span class="fw-bold">Au</span>
                      <input type="date" :min="startDatePaiement"  class="form-control" v-model="endDatePaiement">
                     </div>
                   
                    </div>
                  
                    <div class="form-group col-md-12">
                     
                      <div v-for="(field,index) in fields" 
                      :key="index" class="position-relative row">
                        <span v-if="field" class="delete badge bg-danger fw-bold"
                         @click="deleteField(field)">
                         <i class="bi bi-trash3"></i>
                        </span>
                        <div class="col-lg-6">
                          <label :for="field" class="mb-2">Choisir un propriétaire</label>
                          <v-select  v-model="field.user_id" 
                          :searchable="true"
                          placeholder="Liste des propriétaires"
                           :options="listProprietaire"
                           :value="index"
                           ref="chooseProprietaire"
                           :loading="loadingShowProprietaire"
                          
                            label="lastname"> 
                            <template #option="{firstname,lastname}">
                              <div class="conteneur_residence">
                                <span class="mx-2">
                                  {{firstname}} {{ lastname }}
                                </span>
                              
                              </div>
                             </template>
                          </v-select>
                        </div>
                      
                        <div class="col-lg-6">
                          <label :for="field" class="d-block mb-2">Preuve</label>
                          <input
                          :id="field"
                          type="file"
                          ref="imageProof"
                          class="image_proof"
                          @change="handleMedias(index,$event)"
                       />
                        </div>
                      
                    </div>
                    <div class="text-end">
                      <button @click.prevent="addField" 
                      class="btn bg-primary w-25 my-3 addField  bg-dark text-light">
                      Ajouter un propriétaire +</button>
                     </div>
                    </div>

                    
                    <div>
                      <button
                        class="btn bg-dark send-payment text-light rounded"
                        type="submit"
                      >
                    
                        Envoyer
                        <i class="bi bi-send-fill"></i>
                        <span
                          class="spinner-border spinner-border-sm ms-2"
                          v-show="preloader"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.send-payment{
  width:150px !important;
  height:50px !important;
  font-weight:bold;
}
[type="file"]{
   background-color:white;
   width:100%;
   padding:1em;
   border-radius:5px;
   color:black ;
   
}
.container {
  /* width: 70%; */
  margin: auto;
  /* height: 100%; */
  background: #f7fafc;
  border: 0.5px solid #a3a8b1;
  border-radius: 10px;
  /* padding: 30px; */
  position: relative;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}
.dashboard-wraper {
  height: 100%;
}
.bi-x-circle {
  cursor: pointer;
}

.circuit {
  padding: 0 !important;
}
[type="radio"]:checked + label:after {
  opacity: 0 !important;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index: 9999;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.submit-pages_view {
  padding: 1em 3em;
}
</style>
