<script>


export default {
  name: "NavBarComponent",
  data() {
    return {
      user: this.$store.state.user,
      showBtnDeconnexion:false,
      ConteneurArrowUp: false,
      ConteneurArrowDown: true,
      AnnuleArrowUp: false,
      AnnuleArrowDown: true,
      createIlotArrowDown: true,
      createIlotArrowUp: false,
      createcommissionArrowDown: true,
      createcommissionArrowUp: false,
      createModePaiementArrowDown: true,
      createSiteArrowDown: true,
      createModePaiementArrowUp: false,
      createSiteArrowUp: false,
      ClientArrowDown: true,
      ClientArrowUp: false,
      roleArrowDown: true,
      roleArrowUp: false,
      PaimentArrowDown: true,
      PaimentArrowUp: false,
      VisiteArrowDown: true,
      VisiteArrowUp: false,
      createCommoditeArrowDown: true,
      createCommoditeArrowUp: false,
      createProgrammeArrowDown: true,
      createProgrammeArrowUp: false,
      createPersonnelArrowDown: true,
      createPersonnelArrowUp: false,
      createReservationArrowDown: true,
      createReservationArrowUp: false,
      createRoleArrowDown: true,
      createRoleArrowUp: false,
      createTypeArrowDown: true,
      createTypeArrowUp: false,
      configureArrowDown: true,
      configureArrowUp: false,
      appelArrowDown: true,
      appelArrowUp: false,
      createVisiteArrowDown: true,
      createVisiteArrowUp: false,
      path: "",
      positionActuel: false,
      NombreConteneurLivre: "",
      createProduitArrowDown: true,
      createProduitArrowUp: false,
      profil: "",
      toogleVisibility:true,
    };
  },
  methods: {
    
    toogleProduitArrow() {
      this.createProduitArrowDown = !this.createProduitArrowDown;
      this.createProduitArrowUp = !this.createProduitArrowUp;
    },
   
    openFilterSearch() {
      this.$store.commit("toogleMenu");
    },
    toogleReservationArrow() {
      this.createReservationArrowDown = !this.createReservationArrowDown;
      this.createReservationArrowUp = !this.createReservationArrowUp;
    },
    toogleVisiteArrow() {
      this.createVisiteArrowDown = !this.createVisiteArrowDown;
      this.createVisiteArrowUp = !this.createVisiteArrowUp;
    },
    toogleTypeIlotArrow() {
      this.createTypeArrowDown = !this.createTypeArrowDown;
      this.createTypeArrowUp = !this.createTypeArrowUp;
    },
    toogleconfigureArrow() {
      this.configureArrowDown = !this.configureArrowDown;
      this.configureArrowUp = !this.configureArrowUp;
    },
    toogleProgrammeIlotArrow() {
      this.createProgrammeArrowDown = !this.createProgrammeArrowDown;
      this.createProgrammeArrowUp = !this.createProgrammeArrowUp;
    },
    toogleRoleIlotArrow() {
      this.createRoleArrowDown = !this.createRoleArrowDown;
      this.createRoleArrowUp = !this.createRoleArrowUp;
    },
    toogleCommoditeIlotArrow() {
      this.createCommoditeArrowDown = !this.createCommoditeArrowDown;
      this.createCommoditeArrowUp = !this.createCommoditeArrowUp;
    },
    toogleroleArrow() {
      this.roleArrowDown = !this.roleArrowDown;
      this.roleArrowUp = !this.roleArrowUp;
    },
    toogleSiteIlotArrow() {
      this.createSiteArrowDown = !this.createSiteArrowDown;
      this.createSiteArrowUp = !this.createSiteArrowUp;
    },
    toogleModePaiementIlotArrow() {
      this.createModePaiementArrowDown = !this.createModePaiementArrowDown;
      this.createModePaiementArrowUp = !this.createModePaiementArrowUp;
    },
    tooglecreateIlotArrow() {
      this.createIlotArrowUp = !this.createIlotArrowUp;
      this.createIlotArrowDown = !this.createIlotArrowDown;
    },
    tooglecommissionIlotArrow() {
      this.createcommissionArrowUp = !this.createcommissionArrowUp;
      this.createcommissionArrowDown = !this.createcommissionArrowDown;
    },
    toogleArrow() {
      this.ConteneurArrowUp = !this.ConteneurArrowUp;
      this.ConteneurArrowDown = !this.ConteneurArrowDown;
    },
    tooglePersonnelIlotArrow() {
      this.createPersonnelArrowDown = !this.createPersonnelArrowDown;
    },
    AnnuletoogleArrow() {
      this.AnnuleArrowUp = !this.AnnuleArrowUp;
      this.AnnuleArrowDown = !this.AnnuleArrowDown;
    },
    tooglePaymentArrow(){
      this.toogleVisibility = !this.toogleVisibility
    }
,
    DeconnecterUser() {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("profil");
      this.$store.state.user = "";
      this.$store.state.token = "";
      this.$router.push("/");
    },
    verifyIfRoleInclus(role){
      const {roles} = JSON.parse(localStorage.getItem("user"))
        return roles.some(item=>item.label === role)
       }
    
  
  },
};
</script>
<template>
  <div class="col-lg-3 col-md-12 coolbar position-relative">
  <div class="sm-sidebar simple-sidebar navBar" id="filter_search">
      <div class="container_logo_dexter position-relative">
        <img
        src="../views/dashboard/pages/assets/dexter_logo1.jpg"
    
        alt="image"
      />
      </div>
      <div class="sidebar-widgets">
        <div
          :class="
            this.path != 'prospect'
              ? 'dashboard-navbar'
              : 'dashboard-navbar_prospect'
          "
        >
          <div class="d-user-avater">
            
           
            <i class="bi bi-person-fill img-fluid avater h1"></i>
          
            
            <div class="position-relative">
              <h4 class="profil_name d-flex 
              align-items-center justify-content-center"
               @click="showBtnDeconnexion = !showBtnDeconnexion">
                <span >{{ user.lastname}}</span>
                <span class="mx-1">{{ user.firstname }}</span>
                <i class="bi bi-caret-down-fill mx-1 mt-1" v-if="!showBtnDeconnexion"></i>
                <i class="bi bi-caret-up-fill mx-1 mt-1" v-if="showBtnDeconnexion"></i>
              </h4>
              <ul class="position-absolute menu-drowp" v-show="showBtnDeconnexion" 
              @mouseleave="showBtnDeconnexion = !showBtnDeconnexion"
              >
                <li class="fw-bold" 
                 @click.prevent="DeconnecterUser">Se déconnecter</li>
              </ul>

            </div>
            

            <span
              v-for="(profil, index) in user.roles"
              :key="index"
              class="d-flex justify-content-center align-items"
            >
              <strong class="badge text-bg-warning d-block w-25 mt-1">
                {{ profil.label }}</strong>
            </span>
          </div>
          <div class="d-navigation">
            <ul class="position-relative liste_menu">
             
              <li class="collapseLi">
                <router-link to="/tableau_de_bord"  class="lien">
                  <span>
                    <i class="bi bi-bar-chart-fill"></i>Tableau de
                    bord
                  </span> </router-link
                >
              </li>
              <li class="collapseLi">
                <router-link :to="{ name: 'Profil' }" class="lien">
                  <span ><i class="bi bi-person-lines-fill"></i>Mon Profil</span>  </router-link
                >
              </li>
              <li class="collapseLi"
              v-if="this.verifyIfRoleInclus('Manager') || 
              this.verifyIfRoleInclus('Superviseur') || 
              this.verifyIfRoleInclus('Commercial')
              "
              >
                <router-link :to="{name:'listPersonnel'}" class="lien">
                  <span ><i class="bi bi-person-badge-fill"></i>Utilisateur</span>  
                </router-link>
              </li>
              <li class="collapseLi"
              v-if="!this.verifyIfRoleInclus('Comptable')"
              >
                <router-link :to="{name:'listeRequete'}" class="lien">
                  <span ><i class="bi bi-search"></i> Requêtes</span>  
                </router-link>
              </li>
              
              <li  class="collapseLi position-relative" 
              v-if="!this.verifyIfRoleInclus('Comptable')">
              <span class="badge alert-alarm" v-if="this.$store.state.flagProprieteLocation > 0">
                New
              </span>
                <a
                  data-bs-toggle="collapse"
                  href="#reservation"
                  aria-expanded="false"
                  aria-controls="conteneur"
                  @click.prevent="toogleReservationArrow"
                >
                  <span class="px-2">
                    <i class="bi bi-house-door-fill"></i>
                    Location</span
                  >
                  <i
                    class="bi bi-plus position-plus"
                    v-show="createReservationArrowDown"
                  ></i>
                  <i
                    class="bi bi-dash position-plus"
                    v-show="createReservationArrowUp"
                  ></i>
                </a>
                <ul class="collapse my-2 px-1" id="reservation">
                
                  <li class="card card-body">
                  
                    <a
                    data-bs-toggle="collapse"
                    href="#home"
                    aria-expanded="false"
                    aria-controls="conteneur"
                    @click.prevent="toogleMaisonArrow"
                  >
                    <span class="px-2">
                    
                     Maisons <i
                     class="bi bi-plus position-plus"
                   ></i>
                   </span
                    >
                  
                    <i
                      class="bi bi-dash position-plus"
                    
                    ></i>
                  </a>
                  <ul id="home" class="collapse my-2 px-1">
                    <li>
                      <router-link :to="{name:'locationHome'}" class="lien">
                        Liste <span class="badge alert-alarm" v-if="this.$store.state.flagMaisonLocation > 0">
                          {{this.$store.state.flagMaisonLocation}}</span> 
                          
                       </router-link>
                       </li>
                    <li >
                      <router-link :to="{name:'occupeHome'}" class="lien">
                        Occupées
                       </router-link>
                      </li>
                  </ul>
                  </li>
                  <li class="card card-body">
                   
                    <a
                    data-bs-toggle="collapse"
                    href="#residence"
                    aria-expanded="false"
                    aria-controls="conteneur"
                  >
                    <span class="px-2">
                   
                      Résidences meublées <i
                      class="bi bi-plus position-plus"
                    ></i>
                    </span
                    >
                   
                    <i
                      class="bi bi-dash position-plus"
                     
                    ></i>
                  </a>
                  <ul id="residence" class="collapse my-2 px-1">
                    <li>
                      
                      <router-link  :to="{ name: 'listResidence' }" class="lien">
                        Liste <span class="badge alert-alarm" v-if="this.$store.state.flagResidenceLocation > 0">
                          {{this.$store.state.flagResidenceLocation}}</span>
                      </router-link>
                    </li>
                      <li 
                      v-if="!this.verifyIfRoleInclus('Commercial')">
                        <router-link  :to="{ name: 'demandeModification' }" class="lien">
                          Demande de modification <span class="badge alert-alarm" v-if="this.$store.state.flagDemandeModification > 0">
                            {{this.$store.state.flagDemandeModification}}</span>
                        </router-link></li>
                    <li>
                      
                      <router-link  :to="{name: 'reservationConfirmee'}" class="lien">
                        Réservations 
                        <span class="badge alert-alarm" 
                        v-if="this.$store.state.flagReservation > 0 ">
                          {{this.$store.state.flagReservation}}</span>
                           
                      </router-link>
                      
                    </li>
                  </ul>
                    
                  </li>
                </ul>
              </li>
              <li  class="collapseLi position-relative" 
              v-if="!this.verifyIfRoleInclus('Comptable')">
              
              
                <a
                  data-bs-toggle="collapse"
                  href="#Produit"
                  aria-expanded="false"
                  aria-controls="conteneur"
                  @click.prevent="toogleProduitArrow"
                >
                  <span class="px-2">
                    <i class="bi bi-house-door-fill"></i>
                  Vente 
                  <span class="badge alert-alarm" 
                  v-if="this.$store.state.flagVentePropriete > 0"
              >
                {{"New"}}</span> 
                  </span
                  >
                  <i
                    class="bi bi-plus position-plus"
                    v-show="createProduitArrowDown"
                  ></i>
                  <i
                    class="bi bi-dash position-plus"
                    v-show="createProduitArrowUp"
                  ></i>
                </a>
                <ul class="collapse my-2 px-1" id="Produit">
                  
                 
                  <li class="card card-body">
                    <router-link :to="{name:'listAppartement'}" class="lien">
                     Maisons <span class="badge alert-alarm" 
                     v-if="this.$store.state.flagVenteMaison > 0"
                 >
                   {{this.$store.state.flagVenteMaison}}</span>  
                    </router-link>
                  </li>
                  <li class="card card-body">
                    <router-link :to="{name:'listTerrain'}" class="lien">
                     Terrains <span class="badge alert-alarm" 
                     v-if="this.$store.state.flagVenteTerrain > 0"
                 >
                   {{this.$store.state.flagVenteTerrain}}</span>  
                    </router-link>
                  </li>
                </ul>
              </li>
              <li  class="collapseLi position-relative"
               v-if="!this.verifyIfRoleInclus('Comptable')">
               
               
                <a
                  data-bs-toggle="collapse"
                  href="#visite"
                  aria-expanded="false"
                  aria-controls="conteneur"
                  @click.prevent="toogleVisiteArrow"
                >
                  <span class="px-2">
                    <i class="bi bi-house-door-fill"></i>
                    Visites <span class="badge alert-alarm" v-if="this.$store.state.flagVisite > 0">
                      New</span>
                      </span
                  >
                  <i
                    class="bi bi-plus position-plus"
                    v-show="createVisiteArrowDown"
                  ></i>
                  <i
                    class="bi bi-dash position-plus"
                    v-show="createVisiteArrowUp"
                  ></i>
                </a>
                <ul class="collapse my-2 px-1" id="visite">
                  <li class="card card-body">
                    <router-link :to="{name:'ListeVisiteNonEffectue'}" class="lien">
                      Planifiées 
                      <span class="badge alert-alarm" v-if="this.$store.state.flagVisite > 0">
                        {{this.$store.state.flagVisite}}</span>
                    </router-link>
                  </li>
                  <li class="card card-body">
                    <router-link  :to="{ name: 'listeVisite' }" class="lien">
                      Effectuées
                    </router-link>
                  </li>
                  <li class="card card-body">
                    <router-link  :to="{ name: 'VisiteNonEffectuee' }" class="lien">
                     Expirées
                    </router-link>
                  </li>
                  <li class="card card-body">
                    <router-link  :to="{ name: 'visiteAnnulee' }" class="lien">
                      Annulées
                    </router-link>
                  </li>
                 
                </ul>
              </li>
              <li  class="collapseLi position-relative"
               v-if="this.verifyIfRoleInclus('Comptable') || this.verifyIfRoleInclus('Manager') ||
                 this.verifyIfRoleInclus('Superviseur')">
                <a
                  data-bs-toggle="collapse"
                  href="#paiement"
                  aria-expanded="false"
                  aria-controls="conteneur"
                  @click.prevent="tooglePaymentArrow"
                >
                  <span class="px-2">
                    <i class="bi bi-credit-card-2-back-fill"></i>
                    Paiements</span
                  >
                  <i
                    class="bi bi-plus position-plus"
                    v-if="toogleVisibility"
                  ></i>
                  <i
                    class="bi bi-dash position-plus"
                    v-else
                  ></i>
                </a>
                <ul class="collapse my-2 px-1" id="paiement">
                  <li class="card card-body">
                    <router-link  :to="{ name: 'showPaiement' }" class="lien">
                     Voir la facture
                    </router-link>
                  </li>
                  <li class="card card-body" 
                  v-if="!this.verifyIfRoleInclus('Superviseur')">
                    <router-link :to="{name:'EnregistrePaiement'}" class="lien">
                      Effectuer un paiement
                    </router-link>
                  </li>
                  
                  <li class="card card-body">
                    <router-link  :to="{ name: 'historiquePaiement' }" class="lien">
                     Historique des paiements
                    </router-link>
                  </li>

                </ul>
              </li>

              <li class="collapseLi"
              v-if="this.verifyIfRoleInclus('Comptable') ||
                this.verifyIfRoleInclus('Manager') ||
                 this.verifyIfRoleInclus('Superviseur')"
              >
                <router-link :to="{name:'transactions'}" class="lien">
                  <span ><i class="bi bi-wallet2"></i>Transactions</span>  
                </router-link>
              </li>





              <li  class="collapseLi position-relative"
              v-if="this.verifyIfRoleInclus('Comptable') || this.verifyIfRoleInclus('Manager') ||
                this.verifyIfRoleInclus('Superviseur')">
               <a
                 data-bs-toggle="collapse"
                 href="#promotions"
                 aria-expanded="false"
                 aria-controls="conteneur"
                 @click.prevent="tooglePaymentArrow"
               >
                 <span class="px-2">
                   <i class="bi bi-credit-card-2-back-fill"></i>
                   Promotions</span
                 >
                 <i
                   class="bi bi-plus position-plus"
                   v-if="toogleVisibility"
                 ></i>
                 <i
                   class="bi bi-dash position-plus"
                   v-else
                 ></i>
               </a>
               <ul class="collapse my-2 px-1" id="promotions">
                 <li class="card card-body">
                   <router-link  :to="{name:'sendPromotions'}" class="lien">
                    Envoie des Messages
                   </router-link>
                 </li>
                 <li class="card card-body" 
                 v-if="!this.verifyIfRoleInclus('Superviseur')">
                   <router-link :to="{name:'listePromotions'}" class="lien">
                     Liste
                   </router-link>
                 </li>
               </ul>
             </li>
              <!-- <li class="collapseLi"
              v-if="this.verifyIfRoleInclus('Comptable') ||
                this.verifyIfRoleInclus('Manager') ||
                 this.verifyIfRoleInclus('Superviseur')"
              >
                <router-link :to="{name:'sendPromotions'}" class="lien">
                  <span ><i class="bi bi-send"></i>Promotions</span>  
                </router-link>
              </li> -->
              
              <li
                class="position-relative collapseLi"
                v-if="this.verifyIfRoleInclus('Manager') ||
                 this.verifyIfRoleInclus('Superviseur')"
              >
                <a
                  data-bs-toggle="collapse"
                  href="#configure"
                  aria-expanded="false"
                  aria-controls="configure"
                  @click.prevent="toogleconfigureArrow"
                >
                  <span class="px-2">
                    <i class="bi bi-gear-fill"></i> Configuration</span
                  >
                  <i
                    class="bi bi-plus position-plus"
                    v-show="configureArrowDown"
                  ></i>
                  <i
                    class="bi bi-dash position-plus"
                    v-show="configureArrowUp"
                  ></i>
                </a>
                <ul class="collapse my-2 px-1" id="configure">
                  <li>
                    <router-link :to="{name:'createPropriete'}"  class="lien">
                      Création de propriéte
                    </router-link>
                  </li>
                   
                  <li>
                    <router-link :to="{name:'listCategorieProduit'}"  class="lien">
                      Catégories de bien
                    </router-link>
                  </li>
                  <li>
                    <router-link
                    :to="{name:'ListeCommodite'}"
                      class="lien"
                    >
                      Commodités
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/ville"  class="lien">
                      Villes
                    </router-link>
                  </li> 
                  <li>
                    <router-link :to="{name:'listeCommune'}" class="lien">
                      Communes/quartier
                    </router-link>
                  
                  </li>
                  <li>
                    <router-link :to="{name:'listRole'}"  class="lien">
                      Role
                    </router-link>
                   
                  </li>
                  
                  
                </ul>
              </li>
              <li class="se_deconnecter">
               
              </li> 
             
            </ul>
           
           
            
      
          </div>
        </div>
      </div>
    </div>

    <div
      class="simple-sidebar sm-sidebar barMenuMobile navBar"
      v-show="this.$store.state.showMenu"
    >
    <em
    class="bi bi-x-circle h5 mx-5 position-absolute close-menu-mobile"
    @click.prevent="openFilterSearch"
  ></em>
    <div class="container_logo_dexter position-relative">
      <img
      src="../views/dashboard/pages/assets/dexter_logo1.jpg"
  
      alt="image"
    />
    </div>

    <div class="sidebar-widgets">
      <div
        :class="
          this.path != 'prospect'
            ? 'dashboard-navbar'
            : 'dashboard-navbar_prospect'
        "
        class="position-relative"
      >
     
        <div class="d-user-avater">
          
         
          <i class="bi bi-person-fill img-fluid avater h1"></i>
        
          
          <div class="position-relative">
            <h4 class="profil_name d-flex 
            align-items-center justify-content-center"
             @click="showBtnDeconnexion = !showBtnDeconnexion">
              <span >{{ user.lastname}}</span>
              <span class="mx-1">{{ user.firstname }}</span>
              <i class="bi bi-caret-down-fill mx-1 mt-1" v-if="!showBtnDeconnexion"></i>
              <i class="bi bi-caret-up-fill mx-1 mt-1" v-if="showBtnDeconnexion"></i>
            </h4>
            <ul class="position-absolute menu-drowp" v-show="showBtnDeconnexion" 
            @mouseleave="showBtnDeconnexion = !showBtnDeconnexion"
            >
              <li class="fw-bold" 
               @click.prevent="DeconnecterUser">Se déconnecter</li>
            </ul>

          </div>
          

          <span
            v-for="(profil, index) in user.roles"
            :key="index"
            class="d-flex justify-content-center align-items"
          >
            <strong class="badge text-bg-warning d-block w-25 mt-1">{{ profil.label }}</strong>
          </span>
        </div>
        <div class="d-navigation">
          <ul class="position-relative liste_menu">
           
            <li class="collapseLi">
              <router-link to="/tableau_de_bord"  class="lien">
                <span>
                  <i class="bi bi-bar-chart-fill"></i>Tableau de
                  bord
                </span> </router-link
              >
            </li>
            <li class="collapseLi">
              <router-link :to="{ name: 'Profil' }" class="lien">
                <span ><i class="bi bi-person-lines-fill"></i>Mon Profil</span>  </router-link
              >
            </li>
            <li class="collapseLi"
            v-if="this.verifyIfRoleInclus('Manager') || 
            this.verifyIfRoleInclus('Superviseur') || 
            this.verifyIfRoleInclus('Commercial')
            "
            >
              <router-link :to="{name:'listPersonnel'}" class="lien">
                <span ><i class="bi bi-person-badge-fill"></i>Utilisateur</span>  
              </router-link>
            </li>
            <li class="collapseLi"
            v-if="!this.verifyIfRoleInclus('Comptable')"
            >
              <router-link :to="{name:'listeRequete'}" class="lien">
                <span ><i class="bi bi-search"></i> Requêtes</span>  
              </router-link>
            </li>
            
            <li  class="collapseLi position-relative" 
            v-if="!this.verifyIfRoleInclus('Comptable')">
              <a
                data-bs-toggle="collapse"
                href="#reservation"
                aria-expanded="false"
                aria-controls="conteneur"
                @click.prevent="toogleReservationArrow"
              >
                <span class="px-2">
                  <i class="bi bi-house-door-fill"></i>
                  Location</span
                >
                <i
                  class="bi bi-plus position-plus"
                  v-show="createReservationArrowDown"
                ></i>
                <i
                  class="bi bi-dash position-plus"
                  v-show="createReservationArrowUp"
                ></i>
              </a>
              <ul class="collapse my-2 px-1" id="reservation">
              
                <li class="card card-body">
                
                  <a
                  data-bs-toggle="collapse"
                  href="#home"
                  aria-expanded="false"
                  aria-controls="conteneur"
                  @click.prevent="toogleMaisonArrow"
                >
                  <span class="px-2">
                  
                   Maisons <i
                   class="bi bi-plus position-plus"
                 ></i>
                 </span
                  >
                
                  <i
                    class="bi bi-dash position-plus"
                  
                  ></i>
                </a>
                <ul id="home" class="collapse my-2 px-1">
                  <li>
                    <router-link :to="{name:'locationHome'}" class="lien">
                      Liste
                     </router-link>
                     </li>
                  <li >
                    <router-link :to="{name:'occupeHome'}" class="lien">
                      Occupées
                     </router-link>
                    </li>
                </ul>
                </li>
                <li class="card card-body">
                  <a
                  data-bs-toggle="collapse"
                  href="#residence"
                  aria-expanded="false"
                  aria-controls="conteneur"
                >
                  <span class="px-2">
                 
                    Résidences meublées <i
                    class="bi bi-plus position-plus"
                  ></i>
                  </span
                  >
                 
                  <i
                    class="bi bi-dash position-plus"
                   
                  ></i>
                </a>
                <ul id="residence" class="collapse my-2 px-1">
                  <li>
                    <router-link  :to="{ name: 'listResidence' }" class="lien">
                      Liste
                    </router-link></li>
                    <li 
                    v-if="!this.verifyIfRoleInclus('Commercial')">
                      <router-link  :to="{ name: 'demandeModification' }" class="lien">
                        Demande de modification
                      </router-link></li>
                  <li>
                    
                    <router-link  :to="{name: 'reservationConfirmee'}" class="lien">
                      Réservations
                    </router-link>
                    
                  </li>
                </ul>
                  
                </li>
              </ul>
            </li>
            <li  class="collapseLi position-relative" 
            v-if="!this.verifyIfRoleInclus('Comptable')">
              <a
                data-bs-toggle="collapse"
                href="#Produit"
                aria-expanded="false"
                aria-controls="conteneur"
                @click.prevent="toogleProduitArrow"
              >
                <span class="px-2">
                  <i class="bi bi-house-door-fill"></i>
                Vente</span
                >
                <i
                  class="bi bi-plus position-plus"
                  v-show="createProduitArrowDown"
                ></i>
                <i
                  class="bi bi-dash position-plus"
                  v-show="createProduitArrowUp"
                ></i>
              </a>
              <ul class="collapse my-2 px-1" id="Produit">
                
               
                <li class="card card-body">
                  <router-link :to="{name:'listAppartement'}" class="lien">
                   Maisons
                  </router-link>
                </li>
                <li class="card card-body">
                  <router-link :to="{name:'listTerrain'}" class="lien">
                   Terrains
                  </router-link>
                </li>
              </ul>
            </li>
            <li  class="collapseLi position-relative"
             v-if="!this.verifyIfRoleInclus('Comptable')">
              <a
                data-bs-toggle="collapse"
                href="#visite"
                aria-expanded="false"
                aria-controls="conteneur"
                @click.prevent="toogleVisiteArrow"
              >
                <span class="px-2">
                  <i class="bi bi-house-door-fill"></i>
                  Visites</span
                >
                <i
                  class="bi bi-plus position-plus"
                  v-show="createVisiteArrowDown"
                ></i>
                <i
                  class="bi bi-dash position-plus"
                  v-show="createVisiteArrowUp"
                ></i>
              </a>
              <ul class="collapse my-2 px-1" id="visite">
                <li class="card card-body">
                  <router-link :to="{name:'ListeVisiteNonEffectue'}" class="lien">
                    Planifiées
                  </router-link>
                </li>
                <li class="card card-body">
                  <router-link  :to="{ name: 'listeVisite' }" class="lien">
                    Effectuées
                  </router-link>
                </li>
                <li class="card card-body">
                  <router-link  :to="{ name: 'VisiteNonEffectuee' }" class="lien">
                   Expirées
                  </router-link>
                </li>
                <li class="card card-body">
                  <router-link  :to="{ name: 'visiteAnnulee' }" class="lien">
                    Annulées
                  </router-link>
                </li>
               
              </ul>
            </li>
            <li  class="collapseLi position-relative"
             v-if="this.verifyIfRoleInclus('Comptable') || this.verifyIfRoleInclus('Manager') ||
               this.verifyIfRoleInclus('Superviseur')">
              <a
                data-bs-toggle="collapse"
                href="#paiement"
                aria-expanded="false"
                aria-controls="conteneur"
                @click.prevent="tooglePaymentArrow"
              >
                <span class="px-2">
                  <i class="bi bi-credit-card-2-back-fill"></i>
                  Paiements</span
                >
                <i
                  class="bi bi-plus position-plus"
                  v-if="toogleVisibility"
                ></i>
                <i
                  class="bi bi-dash position-plus"
                  v-else
                ></i>
              </a>
              <ul class="collapse my-2 px-1" id="paiement">
                <li class="card card-body">
                  <router-link  :to="{ name: 'showPaiement' }" class="lien">
                   Voir la facture
                  </router-link>
                </li>
                <li class="card card-body" 
                v-if="!this.verifyIfRoleInclus('Superviseur')">
                  <router-link :to="{name:'EnregistrePaiement'}" class="lien">
                    Effectuer un paiement
                  </router-link>
                </li>
                <li class="card card-body">
                  <router-link  :to="{ name: 'historiquePaiement' }" class="lien">
                   Historique des paiements
                  </router-link>
                </li>

              </ul>
            </li>

            <li class="collapseLi"
            v-if="this.verifyIfRoleInclus('Comptable') ||
              this.verifyIfRoleInclus('Manager') ||
               this.verifyIfRoleInclus('Superviseur')"
            >
              <router-link :to="{name:'transactions'}" class="lien">
                <span ><i class="bi bi-wallet2"></i>Transactions</span>  
              </router-link>
            </li>
            
            <li
              class="position-relative collapseLi"
              v-if="this.verifyIfRoleInclus('Manager') ||
               this.verifyIfRoleInclus('Superviseur')"
            >
              <a
                data-bs-toggle="collapse"
                href="#configure"
                aria-expanded="false"
                aria-controls="configure"
                @click.prevent="toogleconfigureArrow"
              >
                <span class="px-2">
                  <i class="bi bi-gear-fill"></i> Configuration</span
                >
                <i
                  class="bi bi-plus position-plus"
                  v-show="configureArrowDown"
                ></i>
                <i
                  class="bi bi-dash position-plus"
                  v-show="configureArrowUp"
                ></i>
              </a>
              <ul class="collapse my-2 px-1" id="configure">
                <li>
                  <router-link :to="{name:'createPropriete'}"  class="lien">
                    Création de propriéte
                  </router-link>
                </li>
                 
                <li>
                  <router-link :to="{name:'listCategorieProduit'}"  class="lien">
                    Catégories de bien
                  </router-link>
                </li>
                <li>
                  <router-link
                  :to="{name:'ListeCommodite'}"
                    class="lien"
                  >
                    Commodités
                  </router-link>
                </li>
                <li>
                  <router-link to="/ville"  class="lien">
                    Villes
                  </router-link>
                </li> 
                <li>
                  <router-link :to="{name:'listeCommune'}" class="lien">
                    Communes
                  </router-link>
                
                </li>
                <li>
                  <router-link :to="{name:'listRole'}"  class="lien">
                    Role
                  </router-link>
                 
                </li>
                
                
              </ul>
            </li>
            <li class="se_deconnecter">
             
            </li> 
           
          </ul>
 
        </div>
        
      </div>
    </div>
  
    </div>
  
  </div>

</template>
<style scoped>

.button_logout{
  border:3px solid rgb(187, 121, 0);
  font-size: 1em;
}
.container_logo_dexter{
  padding:.5em;
  height:150px;
}
.container_logo_dexter>img{
  position:relative;
  top:0;
  left:0;
  width:50%;
  border:1px solid rgb(187, 121, 0);
  border-radius:10px;
  height:100%;
}
.collapseLi{
  background-color:white !important;
  padding: 0.4em 0.9em;
    border-radius: 5px;
    margin:.5em 0;
    cursor: pointer;
}
.liste_menu .se_deconnecter{
  margin-top: 50vh !important;
}
.image-50 {
  top: 0;
  width:60% !important;
}
.navBar {
  border-radius: 5px;
  background-color: black;
  padding: 0.5em;
  height:100%;
 /* background-color:image: url('../assets/img.png');
  background-position: -227px 170px;*/
  box-shadow: 1px 1px 6px #00000070;
}
.bi-box-arrow-in-left {
  font-size: 1em;
}
.position-plus {
  position: absolute !important;
  right: 0;
}
.bi-power {
  color: rgb(255, 0, 51);
}
.deconnecterUser {
  cursor: pointer;
  margin: 1em 0 0 0;
}
.d-navigation ul li a {
  font-size: 1em !important;
  width:100%;
}
@media screen and (max-width: 990px) {
  .navBar {
    border: 0px;
    display: none;
  }
}
.dashboard-navbar {
  height: 100%;
}

.dashboard-navbar::-webkit-scrollbar-track {
  border: 0px solid #000;
  padding: 2px 0;
  background-color: transparent;
}
.close-menu-mobile {
  top: 0.5em;
  right: -2em;
  z-index:999;
  cursor: pointer;
  color:white !important;
}
.coolbar {
background-color:black;
}



.dashboard-navbar::-webkit-scrollbar {
  width: 0px;
}

.dashboard-navbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  visibility: hidden;
  box-shadow: inset 0 0 6px transparent;
  background-color: transparent !important;
  border: 0px solid transparent;
}
a.router-link-exact-active {
  background-color: rgb(79, 83, 84);
  color: white;
}

.lien {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0.4em 0.5em;
  border-radius: 5px;
  cursor: pointer;
  background: white;
}

.card-body {
  border: 0 !important;
  padding: 0 !important;
  margin-top: 1em !important;
}
.barMenuMobile {
  display: none;
}

@media (max-width: 992px) {
  .barMenuMobile {
    display: block;
    z-index: 90;
    position: relative;
    overflow: auto;
  }
  .coolbar {
    width: 0;
    height: 0 !important;
  }
}
</style>
../views/dashboard/utils