<script>
import axios from "axios";
import Swal from "sweetalert2";
const baseUrl = "http://backend-dexter.invest-ci.com/api/";
export default {
  name: "Enregistrer-type",
  data() {
    return {
      libelle_type: "",
      preloader:false,
    };
  },
  methods: {
    async saveType() {
      let data = {
        label: this.libelle_type,
      };
      this.preloader = true;
        await axios.post(baseUrl +"type-housings",data
        ,{headers: {
          Authorization: "Bearer " + this.$store.state.token,
        }},
      )
      .then(response => {
        //console.log("Reponse",response.data);

        if(response.data.status === true){
          //console.log("Reponse",response.data);
          Swal.fire({
            icon: "success",
            title: "le type de logement a été enregistré.",
            showConfirmButton: true,
          });
          this.$router.push('/list-type-logement')
          this.preloader = false;
        }
        if (response.data.status === false) {
            //console.log("ReponseModify", response.data);
            const monObjet = response.data.message
            let tableau = []
            for (let propriete in monObjet) {
  if (Array.isArray(monObjet[propriete])) {
     tableau = monObjet[propriete];
    //console.log(tableau);
  }else{
    tableau.push(response.data.message)
  }
}
            Swal.fire({
              icon: "error",
              title: `${tableau.toString()}`, 
              showConfirmButton: false,
              timer: 1500,
            });
          this.preloader = false;
        }

      })
      .catch((err) => {
        console.log(err);
        this.preloader = false;
        Swal.fire({
              icon: "error",
              title: err.response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
      });
      //console.log(data);
    
    },
  },
};
</script>
<template>
  <div class="col-lg-9 col-md-12 position-relative">
    <div class="dashboard-wraper">
      <div class="row text-start">
        <!-- Submit Form -->
        <div class="col-lg-12 col-md-12">
          <form @submit.prevent="saveType">
            <div class="submit-pages">
              <!-- Basic Information -->
              <div class="form-submit">
                <h3>Enregistrer un type de logement</h3>
                <div class="submit-section">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label class="text-dark">Libellé</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="libelle_type"
                        required
                      />
                    </div>

                    <div>
                      <button
                        class="btn bg-dark text-light rounded"
                        type="submit"
                      >
                        Enregistrer
                        <span class="spinner-border spinner-border-sm ms-2" v-show="preloader"  role="status" aria-hidden="true"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}
.dashboard-wraper {
  height: 100%;
}
.bi-x-circle {
  cursor: pointer;
}

.circuit {
  padding: 0 !important;
}
[type="radio"]:checked + label:after {
  opacity: 0 !important;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index: 9999;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.submit-pages_view {
  padding: 1em 3em;
}
</style>
