<script>
import axios from "axios";
import Swal from "sweetalert2";
const baseUrl = "http://backend-dexter.invest-ci.com/api/";
export default {
  name: "Enregistrer-personnel",
  data() {
    return {
        last_name: "",
        first_name:"",
        email:"",
        password:"",
        phone:"",
        role:"",
        listRole:[],
        msgPhoneErr:false,
      preloader:false,
    };
  },
  methods: {
    verifChampFormulaire(){
      const phoneRegex = /^[0-9]{10}$/;
      if(phoneRegex.test(this.phone)){
        this.msgPhoneErr = false
      this.savePersonnel()
      }else{
        // Swal.fire({
        //       icon: "info",
        //       title: "Numéro de téléphone non valide",
        //       showConfirmButton: false,
        //       timer: 1000,
        //     });
        this.msgPhoneErr = true
      }
     
    },
    ListeProfil() {
     
       axios
        .get(
          baseUrl + "roles"
          ,{headers: {
            Authorization: "Bearer " + this.$store.state.token,
          }},
        )
        .then((response) => {
          //console.log("response",response);
          this.listRole = response.data.data
          //console.log("Liste", this.listRole);
         
      
        })
        .catch(err => {
          console.log(err);
        });
    },
    async savePersonnel() {
      let data = {
  last_name: this.last_name,
  first_name: this.first_name,
  email: this.email,
  password: this.password,
  phone: this.phone,
  role: this.role
      };
      this.preloader = true;
        await axios.post(baseUrl +"users",data
        ,{headers: {
          Authorization: "Bearer " + this.$store.state.token,
        }},
      )
      .then(response => {
        //console.log("Reponse",response.data);

        if(response.data.status === true){
          //console.log("Reponse",response.data);
          Swal.fire({
            icon: "success",
            title: "Utilisateur enregistré.",
            showConfirmButton: true,
          });
         this.$router.push("/liste-utilisateur");
          this.preloader = false;
        }
        if (response.data.status === false) {
            //console.log("ReponseModify", response.data);
            const monObjet = response.data.message
            let tableau = []
            for (let propriete in monObjet) {
  if (Array.isArray(monObjet[propriete])) {
     tableau = monObjet[propriete];
    //console.log(tableau);
  }else{
    tableau.push(response.data.message)
  }
}
            Swal.fire({
              icon: "error",
              title: `${tableau.toString()}`, 
              showConfirmButton: false,
              timer: 1500,
            });
          this.preloader = false;
        }

      })
      .catch((err) => {
        console.log(err);
        this.preloader = false;
        Swal.fire({
              icon: "error",
              title: err.response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
      });
      //console.log(data);
     
    },
  },
  created(){
    this.ListeProfil()
  }
};
</script>
<template>
  <div class="col-lg-9 col-md-12 position-relative">
    <div class="dashboard-wraper">
      <div class="row text-start">
        <!-- Submit Form -->
        <div class="col-lg-12 col-md-12">
          <form @submit.prevent="verifChampFormulaire">
            <div class="submit-pages">
              <!-- Basic Information -->
              <div class="form-submit">
                <h2 class="text-light">Enregistrers un utilisateur</h2>
                <div class="submit-section">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label>Nom</label>
                      <input                
                        type="text"
                        class="form-control"
                        v-model="last_name"
                        required
                      />
                    </div>

                    <div class="form-group col-md-6">
                        <label>Prénoms</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="first_name"
                          required
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label>Email</label>
                        <input
                          type="email"
                          class="form-control"
                          v-model="email"
                          required
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label>Mot de passe</label>
                        <input
                          type="password"
                          class="form-control"
                          v-model="password"
                          required
                        />
                        
                      </div>

                      <div class="form-group col-md-6">
                        <label>Téléphone</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="phone"
                          required
                        />
                        <p class="text-danger p-0 m-0" v-if="msgPhoneErr">Numéro de téléphone non valide(10 chiffre)</p>
                      </div>
                      <div class="form-group col-md-6" >
                        <label>Profil</label>
                        <select
                          
                          v-model="role"
                          class="form-control"
                          required
                        >
                          <option disabled value="">
                            Séléctionne un profil
                          </option>
                          <option
                          v-for="(profil,index) in listRole"
                          :key="index"
                          :value="profil.name"
                          >
                          {{ profil.name }}
                          </option>
                          
                        </select>
                      </div> 

                    <div>
                      <button
                        class="btn bg-dark text-light rounded"
                        type="submit"
                      >
                        Enregistrer
                        <span class="spinner-border spinner-border-sm ms-2" v-show="preloader"  role="status" aria-hidden="true"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}
.dashboard-wraper {
  height: 100%;
}
.bi-x-circle {
  cursor: pointer;
}

.circuit {
  padding: 0 !important;
}
[type="radio"]:checked + label:after {
  opacity: 0 !important;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index: 9999;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.submit-pages_view {
  padding: 1em 3em;
}
</style>
