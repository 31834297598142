<script>
import $ from "jquery";
import Swal from "sweetalert2";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons";
import "datatables.net";
// import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import axios from "axios";
// import printJS from "print-js";
const baseUrl = "http://backend-dexter.invest-ci.com/api/";
const { NumberToLetter } = require("convertir-nombre-lettre");
export default {
  name: "liste-Souscription",
  data() {
    return {
      windowRenderInClient: false,
      showWindowDelete: false,
      idSubscription: "",
      showWindowComment: false,
      comment: "",
      last_name: "",
      spinnerfilter:false,
      first_name: "",
      DataType:'year',
      chooseTimeForFilterGblobal:"month",
      phone1: "",
      afficheZone:false,
      nameZone:"",
      phone2: "",
      email: "",
      YearSelected:false,
      birthday: "",
      job: "",
      listProductFilter: [],
      selectedYear:"",
      statGlobalObject:[],
      monthFilter:"",
      detailProspectFiliationUser: "",
      employer: "",
      nationality: "",
      address: "",
      zipcode: "",
      marital_status: "",
      country: "",
      bank: "",
      selectionFilter: "",
      id_card: "",
      other_piece: "",
      cni_number: "",
      produit_souscription: "",
      prix: "",
      ApportInitial: "",
      status: "",
      hideThemeProduit:true,
      msgEmailErr: false,
      msgPhoneErr: false,
      moneyFormat: new Intl.NumberFormat("de-DE"),
      showDetailProspectForModify: false,
      preload: false,
      preloader: false,
      choiceTypeBienImmobilier: "",
      choiceIlot: "",
      preloaderZone: false,
      preloaderProduct: false,
      preloadeRejet: false,
      preloaderValider: false,
      choiceProduit: "",
      choiceLot: "",
      choiceProgramme: "",
      showDetailClient: false,
      confirmApportInitial: "",
      showContrat: false,
      ListeFiltreSub: [],
      detailProduct: {},
      detailProspect: "",
      detail: {},
      sommeVersement: [],
      detailVersment: [],
      ListeSubscription: [],
      subscriptionDetail: {},
      listProduit: [],
      DateSouscription: "",
      afficheListFilter: false,
      subscriptionDetailVersment: {},
      subscriptionDetailProspect: {},
      subscriptionDetailProduct: {},
      subscriptionDetailProductProjet: {},
      subscriptionDetailProductPlace: {},
      subscriptionDetailProductIlot: {},
      lettreChiffre: "",
      listProduitAchete: [],
      nameChargeClientèle: "",
      tab: "Globale",
      ListeFilter: [],
      listZoneFilter: [],
      token: this.$store.state.token,
      subscriptionDetailProductVersement: [],
      showContratLogement: false,
      filterDay: "",
      ListeOfSubscription: [],
      ListeSubscriptionFilter: [],
      remiseSouscription: "",
      detailPayment: [],
      searchActive: false,

      windowFordeleteSubscription: false,
      listZone: [],
      selectionByZone: "",
      selectionByProduit: "",
      selectionByAgentCommerciale: "",
      statusId: "",
      commentForDeleteSubscription: "",
      preloadValid: false,
      chooseZone: "",
      listUser: [],
      afficheListFilterProduct: false,
      afficheWindowStat: false,
      detailForProduitStat: [],
    };
  },
  methods: {
    verfIfMontantIsBiggerZero(tableau){
     tableau.forEach(item=>{
      if(item.montant != 0){
        return item
      }else{
        return []
      }
     })
    },
    doFilterForStatisticGlobal(){
    

      if(this.monthFilter){
       let tableau = []
       let tableauFilter = []
       for(let i=0;i<this.statGlobalObject.length; i++){
        let tableauSell = []
        let total = 0; 
        this.statGlobalObject[i].product_sell.forEach(item=>{
          if(item.created_at.substring(0,7) == this.monthFilter){
            total += Number(item.Prix)
            tableauSell.push(item)
       }
        })
        
        tableau.push({name:this.statGlobalObject[i].name,product_sell:tableauSell,montant:total})
     }
     tableau.sort((a, b) => b.montant - a.montant);
    tableau.forEach(item=>{
      if(item.montant != 0){
        tableauFilter.push(item)
      }
     })
     tableauFilter.sort((a, b) => b.montant - a.montant);
     this.ListeSubscription = tableauFilter.length ? tableauFilter : [];
    //console.log("this.ListeSubscription", this.ListeSubscription);
    
    if (!$.fn.DataTable.isDataTable('#MyTableData')) {
    setTimeout(function () {
      let printCounter = 0
    $("#MyTableData").DataTable({
    pagingType: "full_numbers",
    pageLength: 10,
    width: "100%",
    processing: true,
    order: [],
    dom: "Bfrtip",
    buttons: [
      {
        extend: "copy",
        text: "Copier les données",
        messageTop: function () {
          printCounter++;

          if (printCounter === 1) {
            Swal.fire({
              icon: "success",
              title:
                "Les données ont été copiées dans le presse-papiers.",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              icon: "info",
              title:
                "Les données ont déjà été copiées dans le presse-papiers.",
              showConfirmButton: true,
            });
          }
        },
      },
      {
        extend: "csv",
        text: "Exporter en fichier excel",
        fieldSeparator: ";",
        fieldBoundary: '"',
        bom: true,
        exportOptions: {
          columns: [0, 1, 2],
        },
      },

      {
        extend: "print",
        text: "Imprimer les données",
      },
    ],
    language: {
      décimal: "",
      emptyTable: "Aucune donnée disponible dans le tableau",
      infoEmpty: "Showing 0 to 0 of 0 entries",
      info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
      infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
      infoPostFix: "",
      thousands: ",",
      lengthMenu: "Afficher les entrées du _MENU_",
      loadingRecords: "Loading...",
      processing: "Processing...",

      stateSave: true,
      zeroRecords: "Aucun enregistrement correspondant trouvé",
      paginate: {
        first: "Premier",
        last: "Dernier",
        next: "Suivant",
        previous: "Précédent",
      },
      aria: {
        sortAscending: ": activate to sort column ascending",
        sortDescending: ": activate to sort column descending",
      },
    },
  });
}, 10);
}
     //console.log("TABLEAU",tableau)
      }
      if(this.selectedYear){
      
          let tableau = []
          let tableauFilter = []
       for(let i=0;i<this.statGlobalObject.length; i++){
        let tableauSell = []
        let total = 0; 
        this.statGlobalObject[i].product_sell.forEach(item=>{
          if(new Date(item.created_at).getFullYear() == this.selectedYear.$y){
            total += Number(item.Prix)
            tableauSell.push(item)
       }
        })
        
        tableau.push({name:this.statGlobalObject[i].name,product_sell:tableauSell,montant:total})
     }

     tableau.sort((a, b) => b.montant - a.montant);

     tableau.forEach(item=>{
      if(item.montant != 0){
        tableauFilter.push(item)
      }
     })
     this.ListeSubscription = tableauFilter.length ? tableauFilter : [];

    //console.log("this.ListeSubscription", this.ListeSubscription);
    this.spinnerfilter = false;
    if (!$.fn.DataTable.isDataTable('#MyTableData')) {
    setTimeout(function () {
      let printCounter = 0
    $("#MyTableData").DataTable({
    pagingType: "full_numbers",
    pageLength: 10,
    width: "100%",
    processing: true,
    order: [],
    dom: "Bfrtip",
    buttons: [
      {
        extend: "copy",
        text: "Copier les données",
        messageTop: function () {
          printCounter++;

          if (printCounter === 1) {
            Swal.fire({
              icon: "success",
              title:
                "Les données ont été copiées dans le presse-papiers.",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              icon: "info",
              title:
                "Les données ont déjà été copiées dans le presse-papiers.",
              showConfirmButton: true,
            });
          }
        },
      },
      {
        extend: "csv",
        text: "Exporter en fichier excel",
        fieldSeparator: ";",
        fieldBoundary: '"',
        bom: true,
        exportOptions: {
          columns: [0, 1, 2],
        },
      },

      {
        extend: "print",
        text: "Imprimer les données",
      },
    ],
    language: {
      décimal: "",
      emptyTable: "Aucune donnée disponible dans le tableau",
      infoEmpty: "Showing 0 to 0 of 0 entries",
      info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
      infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
      infoPostFix: "",
      thousands: ",",
      lengthMenu: "Afficher les entrées du _MENU_",
      loadingRecords: "Loading...",
      processing: "Processing...",

      stateSave: true,
      zeroRecords: "Aucun enregistrement correspondant trouvé",
      paginate: {
        first: "Premier",
        last: "Dernier",
        next: "Suivant",
        previous: "Précédent",
      },
      aria: {
        sortAscending: ": activate to sort column ascending",
        sortDescending: ": activate to sort column descending",
      },
    },
  });
}, 10);
}
     //console.log("TABLEAU",tableau)
      }
     
    },
    chooseTime(){
     if(this.chooseTimeForFilterGblobal == 'year'){
      this.monthFilter = ""
      //console.log("this.monthFilter",this.monthFilter)
     }
     if(this.chooseTimeForFilterGblobal == 'month'){
      this.selectedYear = ""
      //console.log("this.selectedYear",this.selectedYear)
     }
    },
    changeForFilter() {
      setTimeout(function () {
        let printCounter = 0;
        $("#MyTableData1").DataTable({
          pagingType: "full_numbers",
          pageLength: 10,
          width: "100%",
          processing: true,
          order: [],
          dom: "Bfrtip",
          buttons: [
            {
              extend: "copy",
              text: "Copier les données",
              messageTop: function () {
                printCounter++;

                if (printCounter === 1) {
                  Swal.fire({
                    icon: "success",
                    title:
                      "Les données ont été copiées dans le presse-papiers.",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                } else {
                  Swal.fire({
                    icon: "info",
                    title:
                      "Les données ont déjà été copiées dans le presse-papiers.",
                    showConfirmButton: true,
                  });
                }
              },
            },
            {
              extend: "csv",
              text: "Exporter en fichier excel",
              fieldSeparator: ";",
              fieldBoundary: '"',
              bom: true,
              exportOptions: {
                columns: [0, 1, 2, 3, 4, 5, 7],
              },
            },

            {
              extend: "print",
              text: "Imprimer les données",
            },
          ],
          language: {
            décimal: "",
            emptyTable: "Aucune donnée disponible dans le tableau",
            infoEmpty: "Showing 0 to 0 of 0 entries",
            info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
            infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
            infoPostFix: "",
            thousands: ",",
            lengthMenu: "Afficher les entrées du _MENU_",
            loadingRecords: "Loading...",
            processing: "Processing...",

            stateSave: true,
            zeroRecords: "Aucun enregistrement correspondant trouvé",
            paginate: {
              first: "Premier",
              last: "Dernier",
              next: "Suivant",
              previous: "Précédent",
            },
            aria: {
              sortAscending: ": activate to sort column ascending",
              sortDescending: ": activate to sort column descending",
            },
          },
        });
      }, 10);
    },
    doFilterForStatisticZone() {
      let dataZone = {
        zone: this.selectionByZone.id,
      };
      this.preloaderZone = true;
     
      axios
        .post(baseUrl + "StatZone", dataZone, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          //console.log("response", response);

          this.afficheListFilter = true;
          this.listZoneFilter = []
          
          let tableau = []
          for (let sub in response.data.data) {
            
            if (sub != null && response.data.data[sub][0] != null) {
              tableau.push({
                name: sub,
                montant: response.data.data[sub][0].PrixGlobal,
                product_sell: response.data.data[sub],
              });
               tableau.sort((a, b) =>b.montant - a.montant);
            }
          }
          this.preloader = false;
          this.afficheZone = true;
          this.hideThemeProduit = true;
          this.listZoneFilter = tableau
          //console.log("tableau", tableau);
          //console.log("this.listZoneFilter", this.listZoneFilter);
          this.preloaderZone = false;
          if (!$.fn.DataTable.isDataTable('#MyTableData1')) {
          setTimeout(function () {
            let printCounter = 0;
            $("#MyTableData1").DataTable({
              pagingType: "full_numbers",
              pageLength: 10,
              width: "100%",
              processing: true,
              order: [],
              dom: "Bfrtip",
              buttons: [
                {
                  extend: "copy",
                  text: "Copier les données",
                  messageTop: function () {
                    printCounter++;

                    if (printCounter === 1) {
                      Swal.fire({
                        icon: "success",
                        title:
                          "Les données ont été copiées dans le presse-papiers.",
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    } else {
                      Swal.fire({
                        icon: "info",
                        title:
                          "Les données ont déjà été copiées dans le presse-papiers.",
                        showConfirmButton: true,
                      });
                    }
                  },
                },
                {
                  extend: "csv",
                  text: "Exporter en fichier excel",
                  fieldSeparator: ";",
                  fieldBoundary: '"',
                  bom: true,
                  exportOptions: {
                    columns: [0, 1, 2, 3, 4, 5, 7],
                  },
                },

                {
                  extend: "print",
                  text: "Imprimer les données",
                },
              ],
              language: {
                décimal: "",
                emptyTable: "Aucune donnée disponible dans le tableau",
                infoEmpty: "Showing 0 to 0 of 0 entries",
                info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "Afficher les entrées du _MENU_",
                loadingRecords: "Loading...",
                processing: "Processing...",

                stateSave: true,
                zeroRecords: "Aucun enregistrement correspondant trouvé",
                paginate: {
                  first: "Premier",
                  last: "Dernier",
                  next: "Suivant",
                  previous: "Précédent",
                },
                aria: {
                  sortAscending: ": activate to sort column ascending",
                  sortDescending: ": activate to sort column descending",
                },
              },
            });
          }, 10);
        }
        })
        .catch((err) => {
          console.log(err);
          this.preloaderZone = false;
        });
    },
    doFilterForStatisticProduit() {
      this.listProductFilter = [];
      this.preloaderProduct = true;
     
      let dataProduit = {
        produit: this.selectionByProduit.id,
      };
      axios
        .post(baseUrl + "StatZone", dataProduit, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          //console.log("response", response);

          this.afficheListFilterProduct = true;
          this.afficheZone = false;
          this.hideThemeProduit = false;
          for (let sub in response.data.data) {
            if (sub != null && response.data.data[sub][0] != null) {
              this.listProductFilter.push({
                name: sub,
                montant: response.data.data[sub][0].PrixGlobal,
                product_sell: response.data.data[sub],
              });
            } else {
              this.listProductFilter = [];
            }
          }
          this.preloader = false;
          this.listProductFilter.sort((a, b) => b.montant - a.montant);
          //console.log("this.listProductFilter", this.listProductFilter);
          this.preloaderProduct = false;
          if (!$.fn.DataTable.isDataTable('#MyTableData5')) {
          setTimeout(function () {
            let printCounter = 0;
            $("#MyTableData5").DataTable({
              pagingType: "full_numbers",
              pageLength: 10,
              width: "100%",
              processing: true,
              order: [],
              dom: "Bfrtip",
              buttons: [
                {
                  extend: "copy",
                  text: "Copier les données",
                  messageTop: function () {
                    printCounter++;

                    if (printCounter === 1) {
                      Swal.fire({
                        icon: "success",
                        title:
                          "Les données ont été copiées dans le presse-papiers.",
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    } else {
                      Swal.fire({
                        icon: "info",
                        title:
                          "Les données ont déjà été copiées dans le presse-papiers.",
                        showConfirmButton: true,
                      });
                    }
                  },
                },
                {
                  extend: "csv",
                  text: "Exporter en fichier excel",
                  fieldSeparator: ";",
                  fieldBoundary: '"',
                  bom: true,
                  exportOptions: {
                    columns: [0, 1, 2, 3, 4, 5, 7],
                  },
                },

                {
                  extend: "print",
                  text: "Imprimer les données",
                },
              ],
              language: {
                décimal: "",
                emptyTable: "Aucune donnée disponible dans le tableau",
                infoEmpty: "Showing 0 to 0 of 0 entries",
                info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "Afficher les entrées du _MENU_",
                loadingRecords: "Loading...",
                processing: "Processing...",

                stateSave: true,
                zeroRecords: "Aucun enregistrement correspondant trouvé",
                paginate: {
                  first: "Premier",
                  last: "Dernier",
                  next: "Suivant",
                  previous: "Précédent",
                },
                aria: {
                  sortAscending: ": activate to sort column ascending",
                  sortDescending: ": activate to sort column descending",
                },
              },
            });
          }, 10); }
        })
        .catch((err) => {
          console.log(err);
          this.preloaderProduct = false;
        });
    },
    ListeUser() {
      axios
        .get(baseUrl + "users", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          //console.log("responseaaa", response);
          response.data.data.forEach((item) => {
            if (item.roles && item.roles[0].name == "Chargé de clientèle") {
              this.listUser.push(item);
            }
          });

          //console.log("Liste", this.listUser);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ListeProduit() {
      axios
        .get(baseUrl + "products-sell", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          //console.log("this.listProduitAchete", response);
          this.listProduitAchete = response.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ListeLieu() {
      axios
        .get(baseUrl + "places", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          //console.log("response", response);
          this.listZone = response.data.data;
          //console.log("Liste", this.listZone);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    ListeAllSubscription() {
      this.preloader = true;

      axios
        .get(baseUrl + "statistique", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          let printCounter = 0;

          //console.log("RESPONSE", response);
         
          for (let sub in response.data.data) {
            if(sub != null && response.data.data[sub][0] != null){
              this.ListeSubscription.push({
              name: sub,
              montant: response.data.data[sub][0].PrixGlobal,
              product_sell: response.data.data[sub],
            }); 
            }
            
          }
          this.preloader = false;
          this.ListeSubscription.sort((a, b) => b.montant - a.montant);
          this.statGlobalObject = this.ListeSubscription

          //console.log("this.ListeSubscription", this.ListeSubscription);
          setTimeout(function () {
            $("#MyTableData").DataTable({
              pagingType: "full_numbers",
              pageLength: 10,
              width: "100%",
              processing: true,
              order: [],
              dom: "Bfrtip",
              buttons: [
                {
                  extend: "copy",
                  text: "Copier les données",
                  messageTop: function () {
                    printCounter++;

                    if (printCounter === 1) {
                      Swal.fire({
                        icon: "success",
                        title:
                          "Les données ont été copiées dans le presse-papiers.",
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    } else {
                      Swal.fire({
                        icon: "info",
                        title:
                          "Les données ont déjà été copiées dans le presse-papiers.",
                        showConfirmButton: true,
                      });
                    }
                  },
                },
                {
                  extend: "csv",
                  text: "Exporter en fichier excel",
                  fieldSeparator: ";",
                  fieldBoundary: '"',
                  bom: true,
                  exportOptions: {
                    columns: [0, 1, 2],
                  },
                },

                {
                  extend: "print",
                  text: "Imprimer les données",
                },
              ],
              language: {
                décimal: "",
                emptyTable: "Aucune donnée disponible dans le tableau",
                infoEmpty: "Showing 0 to 0 of 0 entries",
                info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "Afficher les entrées du _MENU_",
                loadingRecords: "Loading...",
                processing: "Processing...",

                stateSave: true,
                zeroRecords: "Aucun enregistrement correspondant trouvé",
                paginate: {
                  first: "Premier",
                  last: "Dernier",
                  next: "Suivant",
                  previous: "Précédent",
                },
                aria: {
                  sortAscending: ": activate to sort column ascending",
                  sortDescending: ": activate to sort column descending",
                },
              },
            });
          }, 10);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // async modifyConteneur() {
    //   const phoneRegex = /^[0-9]{10}$/;
    //   const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    //   let data = {
    //     nom_prospect: this.nom_prospect,
    //     prenoms_prospect: this.prenoms_prospect,
    //     phoneOneprospect: this.phoneOneprospect,
    //     phoneTwoprospect: this.phoneTwoprospect,
    //     emailProspect: this.emailProspect,
    //     dateBirthProspect: this.dateBirthProspect,
    //     profession: this.profession,
    //     societeProspect: this.societeProspect,
    //     nationaliteProspect: this.nationaliteProspect,
    //     LieuProspect: this.LieuProspect,
    //     adressePostaleProspect: this.adressePostaleProspect,
    //     situationMatrimonialeProspect: this.situationMatrimonialeProspect,
    //     paysProspect: this.paysProspect,
    //     BanqueProspect: this.BanqueProspect,
    //     pieceIdentite: this.pieceIdentite,
    //     otherPiece: this.otherPiece,
    //     numeroPiece: this.numeroPiece,
    //   };

    //   //console.log(data);
    //   if (phoneRegex.test(this.phoneOneprospect)) {
    //     this.msgPhoneErr = false;
    //     if (phoneRegex.test(this.phoneTwoprospect)) {
    //       this.msgPhoneErr2 = false;
    //       if (emailRegex.test(this.emailProspect)) {
    //         this.msgEmailErr = false;
    //         this.preloader = true;
    //         //   await axios.post(baseUrl +"commandes",data
    //         //   ,{headers: {
    //         //     Authorization: "Bearer " + this.$store.state.token,
    //         //   }},
    //         // )
    //         // .then(response => {
    //         //   //console.log("Reponse",response.data);

    //         //   if(response.data.status === true){
    //         //     //console.log("Reponse",response.data);
    //         //     Swal.fire({
    //         //       icon: "success",
    //         //       title: "le conteneur a été enregistré.",
    //         //       showConfirmButton: false,
    //         //       timer: 1500,
    //         //     });
    //         //     this.$router.push('/liste_conteneur')
    //         //     this.preloader = false;
    //         //   }
    //         //   if(response.data.status === false){
    //         //     //console.log("Reponse",response.data);
    //         //     Swal.fire({
    //         //       icon: "info",
    //         //       title:"Pas de tarif pour cette taille,ce terminal, ce mode de livraison et cette zone.",
    //         //       showConfirmButton: false,
    //         //       timer: 2500,
    //         //     });
    //         //     this.preloader = false;
    //         //   }

    //         // })
    //         // .catch((err) => {
    //         //   console.log(err);
    //         //   this.preloader = false;
    //         //   Swal.fire({
    //         //       icon: "error",
    //         //       title: "Problème avec le serveur....",
    //         //       showConfirmButton: false,
    //         //       timer: 2500,
    //         //     });
    //         // });
    //       } else {
    //         // Swal.fire({
    //         //     icon: "error",
    //         //     title: "addresse email non valide",
    //         //     showConfirmButton: false,
    //         //     timer: 1000,
    //         //   });
    //         this.msgEmailErr = true;
    //       }
    //     } else {
    //       // Swal.fire({
    //       //     icon: "info",
    //       //     title: "Numéro de téléphone non valide",
    //       //     showConfirmButton: false,
    //       //     timer: 1000,
    //       //   });
    //       this.msgPhoneErr2 = true;
    //     }
    //   } else {
    //     // Swal.fire({
    //     //       icon: "info",
    //     //       title: "Numéro de téléphone non valide",
    //     //       showConfirmButton: false,
    //     //       timer: 1000,
    //     //     });
    //     this.msgPhoneErr = true;
    //   }
    // },
    viewDetailsSubscription(id) {
      let paymentSubscription = [];
      this.ListeSubscription.forEach((item) => {
        if (item.id === id) {
          this.detail = item;
          this.detailVersment = item.versements;
          this.detailProspectFiliationUser = item.prospects.filiation_user;
          this.detailPayment = item.payments;
          this.detailProspect = item.prospects;
          this.detailProduct = item.product;
        }
      });
      this.detailVersment.forEach((item) => {
        paymentSubscription.push(item.amount);
      });
      this.sommeVersement = paymentSubscription.length
        ? paymentSubscription.reduce((a, b) => {
            return a + b;
          })
        : null;
      this.idSubscription = id;
      this.showDetailProspectForModify = !this.showDetailProspectForModify;
    },
    closeWindowDetailConteneurForModify() {
      this.showDetailProspectForModify = !this.showDetailProspectForModify;
    },
    becomeClient(id) {
      this.idProspect = id;
    },

    backWindow() {
      this.choiceTypeBienImmobilier = "";
      this.choiceIlot = "";
      this.choiceProduit = "";
      this.choiceLot = "";
      this.choiceProgramme = "";
      this.windowRenderInClient = !this.windowRenderInClient;
    },
    renderInClient() {
      this.windowRenderInClient = !this.windowRenderInClient;
    },
    wantDeleteProspect() {
      this.showWindowDelete = !this.showWindowDelete;
    },
    closeWindowDetailclient() {
      this.showDetailClient = !this.showDetailClient;
    },
    windowClientDetail(id) {
      this.ListeSubscription.forEach((item) => {
        if (item.id === id) {
          //console.log("item", item);
          this.subscriptionDetail = item;
          this.subscriptionDetailVersment = item.payments;
          this.subscriptionDetailProspect = item.prospects;
          this.prix = item.cost;
          this.ApportInitial =
            item.init_cost != null ? item.init_cost : item.cost;
          this.last_name = item.prospects.last_name;
          this.first_name = item.prospects.first_name;
          this.phone1 = item.prospects.phone1;
          this.phone2 = item.prospects.phone2;
          this.email = item.prospects.email;
          this.birthday = item.prospects.birthday;
          this.job = item.prospects.job;
          this.employer = item.prospects.employer;
          this.nationality = item.prospects.nationality;
          this.address = item.prospects.address;
          this.zipcode = item.prospects.zipcode;
          this.marital_status = item.prospects.marital_status;
          this.country = item.prospects.country;
          this.bank = item.prospects.bank;
          this.status = item.status;
          this.other_piece = item.prospects.id_card;
          this.id_card =
            item.prospects.id_card == "CNI"
              ? "CNI"
              : item.prospects.id_card == "passeport"
              ? "passeport"
              : "autre";
          this.cni_number = item.prospects.cni_number;
          this.produit_souscription = item.product.id;
        }
      });
      this.idSubscription = id;
      this.showDetailClient = !this.showDetailClient;
    },
    deleteProspect() {
      this.showWindowDelete = !this.showWindowDelete;
    },
    returnDetailClient() {
      this.prix = "";
      this.ApportInitial = "";
      this.last_name = "";
      this.first_name = "";
      this.phone1 = "";
      this.phone2 = "";
      this.email = "";
      this.birthday = "";
      this.job = "";
      this.employer = "";
      this.nationality = "";
      this.address = "";
      this.zipcode = "";
      this.marital_status = "";
      this.country = "";
      this.bank = "";
      this.other_piece = "";
      this.id_card = "";
      this.cni_number = "";
      this.produit_souscription = "";
      this.showDetailClient = "";
      this.confirmApportInitial = "";
    },
    closeContrat() {
      this.showContrat = !this.showContrat;
    },
    windowContrat(id) {
      //console.log(id);
      this.showContrat = !this.showContrat;
      this.ListeSubscription.forEach((item) => {
        if (item.id == id) {
          this.subscriptionDetail = item;
          this.lettreChiffre = NumberToLetter(item.cost);
          this.subscriptionDetailVersment = item.payments;
          this.subscriptionDetailProspect = item.prospects;
          this.subscriptionDetailProduct = item.product;
          this.subscriptionDetailProductPlace = item.product.place;
          this.subscriptionDetailProductProjet = item.product.project;
          this.subscriptionDetailProductIlot = item.product.ilot;
          this.subscriptionDetailProductVersement = item.versements;
          this.prix = item.cost;
          this.ApportInitial =
            item.init_cost != null ? item.init_cost : item.cost;
          this.last_name = item.prospects.last_name;
          this.first_name = item.prospects.first_name;
          this.phone1 = item.prospects.phone1;
          this.phone2 = item.prospects.phone2;
          this.email = item.prospects.email;
          this.birthday = item.prospects.birthday;
          this.job = item.prospects.job;
          this.employer = item.prospects.employer;
          this.nationality = item.prospects.nationality;
          this.address = item.prospects.address;
          this.zipcode = item.prospects.zipcode;
          this.marital_status = item.prospects.marital_status;
          this.country = item.prospects.country;
          this.bank = item.prospects.bank;
          this.status = item.status;
          this.other_piece = item.prospects.id_card;
          this.id_card =
            item.prospects.id_card == "CNI"
              ? "CNI"
              : item.prospects.id_card == "passeport"
              ? "passeport"
              : "autre";
          this.cni_number = item.prospects.cni_number;
          this.produit_souscription = item.product.id;
        }
      });
      this.idSubscription = id;

      // this.subscriptionDetailProduct ? this.showContrat = !this.showContrat : this.showContratLogement = !this.showContratLogement;
    },
    rejetObject() {
      this.showWindowComment = !this.showWindowComment;
    },
    seeDetailStat(id) {
      this.afficheWindowStat = !this.afficheWindowStat;
      this.ListeSubscription.forEach((item) => {
        if (item.name == id) {
          this.nameChargeClientèle = item.name;
       
       
          this.detailForProduitStat = item.product_sell;
          setTimeout(function () {
            let printCounter = 0;
            $("#MyTableData3").DataTable({
              pagingType: "full_numbers",
              pageLength: 10,
              width: "100%",
              processing: true,
              order: [],
              dom: "Bfrtip",
              buttons: [
                {
                  extend: "copy",
                  text: "Copier les données",
                  messageTop: function () {
                    printCounter++;

                    if (printCounter === 1) {
                      Swal.fire({
                        icon: "success",
                        title:
                          "Les données ont été copiées dans le presse-papiers.",
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    } else {
                      Swal.fire({
                        icon: "info",
                        title:
                          "Les données ont déjà été copiées dans le presse-papiers.",
                        showConfirmButton: true,
                      });
                    }
                  },
                },
                {
                  extend: "csv",
                  text: "Exporter en fichier excel",
                  fieldSeparator: ";",
                  fieldBoundary: '"',
                  bom: true,
                  exportOptions: {
                    columns: [0, 1, 2, 3, 4, 5, 6, 7],
                  },
                },

                {
                  extend: "print",
                  text: "Imprimer les données",
                },
              ],
              language: {
                décimal: "",
                emptyTable: "Aucune donnée disponible dans le tableau",
                infoEmpty: "Showing 0 to 0 of 0 entries",
                info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "Afficher les entrées du _MENU_",
                loadingRecords: "Loading...",
                processing: "Processing...",

                stateSave: true,
                zeroRecords: "Aucun enregistrement correspondant trouvé",
                paginate: {
                  first: "Premier",
                  last: "Dernier",
                  next: "Suivant",
                  previous: "Précédent",
                },
                aria: {
                  sortAscending: ": activate to sort column ascending",
                  sortDescending: ": activate to sort column descending",
                },
              },
            });
          }, 10);
        }
      });
     
    },
    seeDetailStatFilter(id) {
      this.afficheWindowStat = !this.afficheWindowStat;

      this.listZoneFilter.forEach((item) => {
        if (item.name == id) {
          this.nameChargeClientèle = item.name;
          this.nameZone = item.product_sell[0].product.place.label
          this.detailForProduitStat = item.product_sell;
          setTimeout(function () {
            let printCounter = 0;
            $("#MyTableData3").DataTable({
              pagingType: "full_numbers",
              pageLength: 10,
              width: "100%",
              processing: true,
              order: [],
              dom: "Bfrtip",
              buttons: [
                {
                  extend: "copy",
                  text: "Copier les données",
                  messageTop: function () {
                    printCounter++;

                    if (printCounter === 1) {
                      Swal.fire({
                        icon: "success",
                        title:
                          "Les données ont été copiées dans le presse-papiers.",
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    } else {
                      Swal.fire({
                        icon: "info",
                        title:
                          "Les données ont déjà été copiées dans le presse-papiers.",
                        showConfirmButton: true,
                      });
                    }
                  },
                },
                {
                  extend: "csv",
                  text: "Exporter en fichier excel",
                  fieldSeparator: ";",
                  fieldBoundary: '"',
                  bom: true,
                  exportOptions: {
                    columns: [0, 1, 2, 3, 4, 5, 6],
                  },
                },

                {
                  extend: "print",
                  text: "Imprimer les données",
                },
              ],
              language: {
                décimal: "",
                emptyTable: "Aucune donnée disponible dans le tableau",
                infoEmpty: "Showing 0 to 0 of 0 entries",
                info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "Afficher les entrées du _MENU_",
                loadingRecords: "Loading...",
                processing: "Processing...",

                stateSave: true,
                zeroRecords: "Aucun enregistrement correspondant trouvé",
                paginate: {
                  first: "Premier",
                  last: "Dernier",
                  next: "Suivant",
                  previous: "Précédent",
                },
                aria: {
                  sortAscending: ": activate to sort column ascending",
                  sortDescending: ": activate to sort column descending",
                },
              },
            });
          }, 10);
        }
      });
    },
    seeDetailStatFilterProduit(id){
      this.afficheWindowStat = !this.afficheWindowStat;

      this.listProductFilter.forEach((item) => {
        if (item.name == id) {
          this.nameChargeClientèle = item.name;
          this.detailForProduitStat = item.product_sell;
          setTimeout(function () {
            let printCounter = 0;
            $("#MyTableData3").DataTable({
              pagingType: "full_numbers",
              pageLength: 10,
              width: "100%",
              processing: true,
              order: [],
              dom: "Bfrtip",
              buttons: [
                {
                  extend: "copy",
                  text: "Copier les données",
                  messageTop: function () {
                    printCounter++;

                    if (printCounter === 1) {
                      Swal.fire({
                        icon: "success",
                        title:
                          "Les données ont été copiées dans le presse-papiers.",
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    } else {
                      Swal.fire({
                        icon: "info",
                        title:
                          "Les données ont déjà été copiées dans le presse-papiers.",
                        showConfirmButton: true,
                      });
                    }
                  },
                },
                {
                  extend: "csv",
                  text: "Exporter en fichier excel",
                  fieldSeparator: ";",
                  fieldBoundary: '"',
                  bom: true,
                  exportOptions: {
                    columns: [0, 1, 2, 3, 4, 5, 6],
                  },
                },

                {
                  extend: "print",
                  text: "Imprimer les données",
                },
              ],
              language: {
                décimal: "",
                emptyTable: "Aucune donnée disponible dans le tableau",
                infoEmpty: "Showing 0 to 0 of 0 entries",
                info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "Afficher les entrées du _MENU_",
                loadingRecords: "Loading...",
                processing: "Processing...",

                stateSave: true,
                zeroRecords: "Aucun enregistrement correspondant trouvé",
                paginate: {
                  first: "Premier",
                  last: "Dernier",
                  next: "Suivant",
                  previous: "Précédent",
                },
                aria: {
                  sortAscending: ": activate to sort column ascending",
                  sortDescending: ": activate to sort column descending",
                },
              },
            });
          }, 10);
        }
      });
    }
  },
  created() {
    this.ListeLieu();
    this.ListeAllSubscription();
    this.ListeProduit();
    this.ListeUser();
  },
};
</script>
<template>
  <div id="preloader" v-show="preload">
    <div class="preloader"><span></span><span></span></div>
  </div>
  <div class="col-lg-9 col-md-12">
    <div class="dashboard-wraper">
      <div class="conteneur_modify" v-if="afficheWindowStat">
        <div class="content position-relative">
          <div
            class="ecriteau text-start d-flex justify-content-between align-items-center"
          >
            <h4>Détail</h4>
            <i
              class="bi bi-x-circle h1"
              @click.prevent="afficheWindowStat = !afficheWindowStat"
            ></i>
          </div>
          <div class="text-start px-3">
            <div class="submit-pages text-start">
              <div class="row container cont">
                <div class="form-group col-md-12 col-sm-12">
                  <h3 class="text-center profil_name">
                    Produit(s) vendu(s) par : {{ nameChargeClientèle }} <br v-if="afficheZone">
                     <span v-if="afficheZone">Zone : {{nameZone}}</span> <br />
                    ({{ detailForProduitStat.length }}) 
                  </h3>

                  <table
                    id="MyTableData3"
                    class="table text-center table-responsive"
                  >
                    <thead>
                      <tr class="text-center">
                        <th class="bg-light text-center" v-if="hideThemeProduit">Produit</th>
                        <th class="bg-light text-center">
                          Apporteur d'affaire
                        </th>
                        <th class="bg-light text-center">Client</th>
                        <th class="bg-light text-center">
                          Date de souscription
                        </th>
                        <th class="bg-light text-center">Date butoir</th>
                        <th class="bg-light text-center">Prix (Fcfa)</th>
                        <th class="bg-light text-center">
                          Montant restant (Fcfa)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in detailForProduitStat"
                        :key="index"
                      >
                        <td v-if="hideThemeProduit">
                          <span>{{ item.product.label }}</span>
                        </td>
                        <!-- <td v-if="!selectionByZone"> 
                          <span>{{ item.product.place.label }}</span>
                        </td> -->
                        <td>
                          <span v-if="item.prospects.filiation_user"
                            >{{ item.prospects.filiation_user.last_name }}
                            {{ item.prospects.filiation_user.last_name }}</span
                          >
                          <span v-else class="badge bg-danger">x</span>
                        </td>
                        <td>
                          <span
                            >{{ item.prospects.last_name }}
                            {{ item.prospects.first_name }}</span
                          >
                        </td>
                        <td>
                          <span>
                            {{
                              new Date(item.created_at).toLocaleDateString("fr")
                            }}
                          </span>
                        </td>
                        <td>
                          {{
                            new Date(item.delay_date).toLocaleDateString("fr")
                          }}
                        </td>
                        <td>
                          {{ moneyFormat.format(item.product.cost) }}
                        </td>
                        <td>
                          {{ moneyFormat.format(item.rest) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="conteneur_modify" v-show="showDetailClient">
        <div class="content position-relative">
          <div
            class="ecriteau text-start d-flex justify-content-between align-items-center"
          >
            <h4>Confirmation du Montant</h4>
            <i
              class="bi bi-x-circle h1"
              @click.prevent="returnDetailClient"
            ></i>
          </div>
          <div class="col-lg-12 col-md-12">
            <form>
              <div class="submit-pages text-start">
                <!-- Basic Information -->
                <div class="form-submit">
                  <div class="submit-section">
                    <div class="row">
                      <div class="form-group col-md-6">
                        <label class="text-dark">Nom</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="last_name"
                          required
                          disabled
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="text-dark">Prénoms</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="first_name"
                          required
                          disabled
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label class="text-dark">Contact 1</label>

                        <input
                          type="text"
                          class="form-control"
                          v-model="phone1"
                          required
                          disabled
                        />
                        <p class="text-danger p-0 m-0" v-if="msgPhoneErr">
                          Numéro de téléphone non valide(10 chiffre)
                        </p>
                      </div>
                      <div class="form-group col-md-6">
                        <label class="text-dark">Contact 2</label>

                        <input
                          type="text"
                          class="form-control"
                          v-model="phone2"
                          required
                          disabled
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="text-dark">Email</label>

                        <input
                          type="email"
                          class="form-control"
                          v-model="email"
                          disabled
                        />
                        <p class="text-danger p-0 m-0" v-if="msgEmailErr">
                          Adresse email non valide
                        </p>
                      </div>
                      <div class="form-group col-md-6">
                        <label class="text-dark">Date de naissance</label>
                        <input
                          type="date"
                          class="form-control"
                          v-model="birthday"
                          required
                          disabled
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="text-dark">Profession</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="job"
                          required
                          disabled
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label class="text-dark"> Société </label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="employer"
                          required
                          disabled
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="text-dark">Nationalité </label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="nationality"
                          required
                          disabled
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="text-dark">Adresse géographique</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="address"
                          required
                          disabled
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="text-dark">Adresse postale </label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="zipcode"
                          required
                          disabled
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="text-dark">Situation matrimoniale</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="marital_status"
                          required
                          disabled
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="text-dark">Pays</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="country"
                          required
                          disabled
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="text-dark">Banque de domiciliation</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="bank"
                          required
                          disabled
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="text-dark">Pièce d’identité</label>
                        <div class="row container">
                          <section class="col-md-12 circuit">
                            <div
                              class="col-md-12 d-flex align-items-center my-2"
                            >
                              <input
                                type="radio"
                                id="CNI"
                                name="fav_language"
                                value="CNI"
                                required
                                v-model="id_card"
                                disabled
                              />
                              <label class="text-dark" for="CNI">CNI</label>
                            </div>

                            <div
                              class="col-md-12 d-flex align-items-center my-2"
                            >
                              <input
                                type="radio"
                                id="passeport"
                                name="fav_language"
                                value="passeport"
                                required
                                v-model="id_card"
                                disabled
                              />
                              <label class="text-dark" for="passeport"
                                >Passeport</label
                              >
                            </div>

                            <div
                              class="col-md-12 d-flex align-items-center my-2"
                            >
                              <input
                                type="radio"
                                id="autre"
                                name="fav_language"
                                value="autre"
                                required
                                v-model="id_card"
                                disabled
                              />
                              <label class="text-dark" for="autre"
                                >Autre
                              </label>
                              <input
                                v-if="id_card == 'autre'"
                                class="form-control mx-3"
                                type="text"
                                v-model="other_piece"
                                disabled
                              />
                            </div>
                            <div class="col-md-12 my-2">
                              <label class="text-dark">Numéro de pièce</label>
                              <input
                                class="form-control"
                                type="text"
                                v-model="cni_number"
                                disabled
                              />
                            </div>
                          </section>
                        </div>
                      </div>

                      <div class="form-group col-md-12">
                        <label class="text-dark">Produit</label>
                        <select
                          v-model="produit_souscription"
                          class="form-control"
                          @change="slectedProduit"
                          required
                          disabled
                        >
                          <option disabled value="">
                            Sélectionne un produit
                          </option>
                          <option
                            v-for="(product, index) in listProduit"
                            :key="index"
                            :value="product.id"
                          >
                            {{ product.label }}
                          </option>
                        </select>
                      </div>

                      <div class="form-group col-md-6">
                        <label class="d-block text-dark mb-2"
                          >Prix souscrit</label
                        >
                        <input
                          class="form-control"
                          type="text"
                          v-model="prix"
                          disabled
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="d-block text-dark mb-2"
                          >Montant versé</label
                        >
                        <input
                          class="form-control"
                          type="text"
                          v-model="ApportInitial"
                          disabled
                        />
                      </div>
                      <div class="form-group col-md-6" v-if="status == 0">
                        <label class="text-dark">Remise</label>
                        <input
                          type="number"
                          min="1"
                          class="form-control"
                          v-model="remiseSouscription"
                          required
                        />
                      </div>

                      <div class="text-start" v-if="status == 0">
                        <button
                          class="btn bg-dark text-light rounded"
                          @click.prevent="validerSouscription"
                        >
                          Valider
                          <span
                            class="spinner-border spinner-border-sm ms-2"
                            v-show="preloaderValider"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                        <button
                          class="btn bg-dark text-light btn-rejet rounded"
                          @click.prevent="rejetObject"
                        >
                          Réjeter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Bookmark Property -->

      <div class="form-submit position-relative pt-3">
        <h1 class="text-start text-light-2">Statistique</h1>
      </div>

      <div class="form-submit">
        <ul class="d-flex justify-content-start align-items-center px-5 listes">
          <li>
            <a
              href="#"
              :class="{ color: tab == 'Globale' }"
              class="lien"
              @click.prevent="tab = 'Globale'"
              >Globale</a
            >
          </li>
          <li class="mx-3">
            <a
              href="#"
              :class="{ colorNew: tab == 'Filtre par zone' }"
              class="lien"
              @click.prevent="tab = 'Filtre par zone'"
              >Filtre par zone</a
            >
          </li>
          <li class="mx-3">
            <a
              href="#"
              :class="{ colorNew: tab == 'Filtre par produit' }"
              class="lien"
              @click.prevent="tab = 'Filtre par produit'"
              >Filtre par produit</a
            >
          </li>
        </ul>
      </div>
      <div
        class="form-submit position-relative"
        :class="preloader ? 'pre' : 'null'"
      >
        <div id="preloader" class="position-absolute" v-show="preloader">
          <div class="preloader"><span></span><span></span></div>
        </div>
      </div>

      <div class="col-sm-12 dataTable position-relative" v-if="!preloader">
        <section v-show="tab === 'Globale'">
         
          <div class="row container">
           <div class="col-lg-3 col-sm-12">
            <label class="d-block text-start text-light">Filtrer par:</label>
            <select class="form-control" v-model="chooseTimeForFilterGblobal" @change="chooseTime">
              <option value="" disabled>filtrer par</option>
              <option value="month">Mois</option>
              <option value="year">Année</option>
            </select>
           </div>
            <div class="col-lg-3 col-sm-12" v-if="chooseTimeForFilterGblobal == 'month'">
              <label class="d-block text-start text-light">Mois</label>
              <input type="month" class="form-control" v-model="monthFilter"
               @change="filtreDateByMonthADV">
            </div>
            <div class="col-lg-3 col-sm-12" v-if="chooseTimeForFilterGblobal == 'year'">
              <label class="d-block text-start text-light">Année</label>
              <a-space  :size="12" class="form-control">
                <a-select v-model:value="DataType" v-show="YearSelected">
                  <a-select-option value="year">Year</a-select-option>
                </a-select>
                <template v-if="DataType === 'time'">
                  <a-time-picker />
                </template>
                <template v-else>
                  <a-date-picker :picker="DataType" v-model:value="selectedYear"  />
                
                </template>
              </a-space>
            </div>
          </div>
          <div class="text-start my-3">
            
            <button
            
            class="btn bg-dark text-light"
            :disabled="selectedYear || monthFilter  ? false : true"
            @click="doFilterForStatisticGlobal"
          >
            Recherche
          </button>
          </div>
          <div id="preloader" class="position-absolute" v-show="spinnerfilter">
            <div class="preloader"><span></span><span></span></div>
          </div>
          <div v-if="ListeSubscription.length">
            <table id="MyTableData" class="table text-center table-responsive" >
              <thead>
                <tr class="text-center">
                  <th class="bg-light text-center">Commercial</th>
                  <th class="bg-light text-center">Quantité de produit vendu</th>
                  <th class="bg-light text-center">Chiffre d'affaire réalisé (Fcfa)</th>
                  <th class="bg-light text-center">Classement</th>
                  <th class="bg-light text-center">Détail</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in ListeSubscription" :key="index">
                  <td>
                    <span class="profil_name">{{ item.product_sell[0].prospects.created_by.last_name }}
                       {{ item.product_sell[0].prospects.created_by.first_name }}</span>
                  </td>
                  <td>
                    <span>{{ item.product_sell.length }}</span>
                  </td>
                  <td>
                    <span>
                      {{ moneyFormat.format(item.montant) }}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{ (index += 1) }} <sup v-if="index == 1">er</sup>
                      <sup v-if="index != 1">ème</sup>
                    </span>
                  </td>
                  <td>
                    <i class="bi bi-eye" @click="seeDetailStat(item.name)"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <h1 class="text-center text-light">Aucune donnée disponible.</h1>
          </div>
          
        </section>

        <section v-show="tab === 'Filtre par zone'" class="section-filter">
         
          <div class="row container">
            <div class="col-lg-6 col-sm-12">
              <label class="d-block text-start text-light">Zone</label>
              <v-select
                class="w-50"
                v-model="selectionByZone"
                :searchable="true"
                :options="listZone"
                label="label"
              >
                <template>
                  <p>{{ label }}</p>
                </template>
              </v-select>

              <div class="text-start my-3">
                <button
                  class="btn bg-dark text-light"
                  :disabled="selectionByZone ? false : true"
                  @click="doFilterForStatisticZone"
                >
                  Recherche
                </button>
              </div>
            </div>
          </div>

          <div>
            <div
              class="form-submit position-absolute"
              :class="preloaderZone ? 'pre' : 'null'"
            >
              <div id="preloader" v-show="preloaderZone">
                <div class="preloader"><span></span><span></span></div>
              </div>
            </div>
            <div v-if="listZoneFilter.length">
              <table
                id="MyTableData1"
                class="table text-center table-responsive"
              >
                <thead>
                  <tr class="text-center">
                    <th class="bg-light text-center">Commercial</th>
                    <th class="bg-light text-center">Quantité de Produit vendu</th>
                    <th class="bg-light text-center">Chiffre d'affaire réalisé (Fcfa)</th>
                    <th class="bg-light text-center">Classement</th>
                    <th class="bg-light text-center">Détail</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in listZoneFilter" :key="index">
                    <td>
                      <span class="profil_name">{{ item.name }}</span>
                    </td>
                    <td>
                      <span>{{ item.product_sell.length }}</span>
                    </td>
                    <td>
                      <span>
                        {{ moneyFormat.format(item.montant) }}
                      </span>
                    </td>
                    <td>
                      <span>
                        {{ (index += 1) }} <sup v-if="index == 1">er</sup>
                        <sup v-if="index != 1">ème</sup>
                      </span>
                    </td>
                    <td>
                      <i
                        class="bi bi-eye"
                        @click="seeDetailStatFilter(item.name)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="afficheListFilter && !listZoneFilter.length">
             <h1 class="text-center text-light">Aucune donnée disponible.</h1>
            </div>
          </div>
        </section>

        <section v-show="tab === 'Filtre par produit'" class="section-filter">
          
          <div class="row container">
            <div class="col-lg-6 col-sm-12">
              <label class="d-block text-start text-light">Produit</label>
              <v-select
                class="w-100"
                v-model="selectionByProduit"
                :searchable="true"
                :options="listProduitAchete"
                label="label"
                @change="changeForFilter"
              >
                <template>
                  <p>{{ label }}</p>
                </template>
              </v-select>

              <div class="text-start my-3">
                <button
                  class="btn bg-dark text-light"
                  :disabled="selectionByProduit ? false : true"
                  @click="doFilterForStatisticProduit"
                >
                  Recherche
                </button>
              </div>
            </div>
          </div>
          <div>
            <div
              class="form-submit position-absolute"
              :class="preloaderProduct ? 'pre' : 'null'"
            >
              <div id="preloader" v-show="preloaderProduct">
                <div class="preloader"><span></span><span></span></div>
              </div>
            </div>
            <div v-if="afficheListFilterProduct">
              <table
                id="MyTableData5"
                class="table text-center table-responsive"
              >
                <thead>
                  <tr class="text-center">
                    <th class="bg-light text-center">Commercial</th>
                    <th class="bg-light text-center">Quantité de Produit vendu</th>
                    <th class="bg-light text-center">Chiffre d'affaire réalisé (Fcfa)</th>
                    <th class="bg-light text-center">Détail</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in listProductFilter" :key="index">
                    <td>
                      <span class="profil_name">{{ item.name }}</span>
                    </td>
                    <td>
                      <span>{{ item.product_sell.length }}</span>
                    </td>
                    <td>
                      <span>
                        {{ moneyFormat.format(item.montant) }}
                      </span>
                    </td>

                    <td>
                      <i
                        class="bi bi-eye"
                        @click="seeDetailStatFilterProduit(item.name)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<style scoped>
.section-filter {
  height: 100vh !important;
}
.lien {
  text-decoration: none;
  font-size: 1.3em;
  color: rgb(159, 158, 158);
  padding: 0.8em !important;
}
.listes {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.085);
  padding: 1em;
  background-color: white;
}
label {
  font-size: 1.5em !important;
}
.colorNew,
.color {
  color: rgb(255, 255, 255) !important;
  border: 1px solid black;
  border-radius: 5px;
  padding: 0 1em;
  background-color: rgb(33, 33, 75);
}
.filter-zone {
  top: 0;
  right: 10em;
  z-index: 99;
}
.cont .form-group {
  color: black !important;
  border-radius: 8px;
}
strong {
  font-size: 1em !important;
  color: gray;
  text-decoration: underline;
  padding: 0 0 0.5em 0;
  font-weight: bold;
  text-transform: uppercase;
}
.bg-info {
  background-color: black !important;
}
section {
  color: black;
}
.badge,
.text-light-2 {
  color: white !important;
}

.addField {
  width: auto !important;
}
.designation {
  font-size: 1.3em !important;
}
.btn-rejet {
  background-color: rgb(219, 0, 0) !important;
  border: 1px solid rgb(219, 0, 0) !important;
  margin: 0 2em;
  color: white !important;
}
.btn-rejet:hover {
  background-color: rgb(219, 0, 0) !important;
  border: 1px solid rgb(219, 0, 0) !important;
  margin: 0 2em;
  color: white !important;
}
#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: rgba(244, 242, 242, 0);
  display: flex;
  justify-content: center;
  place-items: center;
}
.bg-danger,
.bg-danger:hover {
  color: white !important;
}

h6 {
  margin: 0 0 1em 0 !important;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}
[type="radio"]:checked + label:after {
  opacity: 0 !important;
}
.circuit {
  padding: 0 !important;
}
.card {
  padding: 1em 3em;
}
.bg-primary {
  background-color: rgb(55, 137, 167) !important;
  border: 2px solid rgb(55, 137, 167) !important;
  color: white !important;
}
.bi-eye,
.bi-x-circle,
.bi-pencil,
.bi-envelope-paper,
.bi-trash,
.delete {
  cursor: pointer;
}
.pre {
  padding: 0 0 15em 0;
}
.table {
  border: 1px solid black;
}
td {
  border-right: 2px solid black;
}
.reset {
  cursor: pointer;
}
.confirmation_comment {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 909;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.confirmation_modifie {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 90;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 90;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.close_modal {
  position: absolute;
  top: 1em;
  right: 1em;
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index: 909;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.submit-pages {
  padding: 1em 3em;
}
.text-Acquereur {
  margin: 1em 0;
}
.delete {
  position: absolute;
  right: 0;
  cursor: pointer;
  width: 20px;
}
.recherche {
  top: 41px;
  right: -0.6em;
  z-index: 9;
}
.recherche_with_value {
  top: 14px;
  right: -0.6em;
  z-index: 9;
}
.text-dark {
  color: black !important;
}
.recherche input {
  width: 217px;
  height: 35px;
  border: thin solid black;
  border-radius: 2px;
}

</style>
