<script>

export default {
  name: "PageStatistique",
  data() {
    return {
      listeProprietaire: [],
      listClient: [],
      ListeConteneurLivre:[],
      listBiensImmobilier: [],
      listResidences: [],
      ListesConteneurSave:[],
      ListesConteneurAnnule:[],
      currentYear: new Date().getFullYear(),
      filterSelect:"annee",
      day:"",
      month_debut:"",
      month_fin:"",
      year:"",
      chartDataAnnee : [["janv", 4], ["Febr", 2], ["Mars", 10], ["Avril", 5], ["mai", 3], ["Juin", 3], ["Juillet", 3]],
      chartDataJour: [[new Date().toLocaleDateString('fr'), 4]],
      chartDataMois:[["01/05/2023", 4], ["02/05/2023", 2], ["10/05/2023", 10], ["20/05/2023", 5], ["25/05/2023", 3], ["30/05/2023", 3], ["31/05/2023", 3]],
      chartDataSemaine:[["01/05/2023", 4], ["02/05/2023", 2], ["03/05/2023", 10], ["04/05/2023", 5], ["05/05/2023", 3], ["06/05/2023", 3], ["07/05/2023", 3]],
      
    };
  },
  methods: {
//    ListeAllConteneur() {
     
//      axios
//         .get(
//           baseUrl + "tousconteneurs"
//           // ,{headers: {
//           //   Authorization: "Bearer " + this.$store.state.token,
//           // }},
//         )
//         .then((response) => {
//           this.ListesConteneurSave = response.data.data;
//           //console.log("ListeSave", response.data.data);
//           response.data.data.forEach(item=>{
//             if(item.status.numero == 4 && this.currentYear == item.created_at.substring(0,4)){
//               this.ListeConteneurLivre.push(item);
             
//             }
//             if(item.status.numero == 3 && this.currentYear == item.created_at.substring(0,4)){
//               this.ListesConteneurAnnule.push(item);
//             }
//           })

//           //console.log("year",this.ListeConteneurLivre)
        
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//     },
    
 
  },
  mounted() { 
    setInterval(() => {
    this.currentYear = new Date().getFullYear()
  }, 1000)
    // this.$store.dispatch('getListUser')
    //console.log("ANNEE",this.currentYear)
    // this.ListeAllConteneur()
  },
};
</script>
<template>
  <div class="col-lg-9 col-md-12">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 text-start p-3">
        <h1>Statistique</h1>
     
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-12">
        <div class="dashboard-stat widget-1">
          <div class="d-flex justify-content-between align-items-center px-3">
            <span>Tous les Conteneurs</span>
            <span class="h5">
              <strong>{{ ListesConteneurSave.length }}</strong></span>
         
          </div>
        
        </div>
      </div>

     
      <div class="col-lg-4 col-md-4 col-sm-12">
        <div class="dashboard-stat widget-2">
          <div class="d-flex justify-content-between align-items-center px-2">
            <span>Conteneurs livrés</span>
            <span class="h5">{{ListeConteneurLivre.length}}</span>
           
          </div>
           
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12">
        <div class="dashboard-stat widget-2">
          <div class="d-flex justify-content-between align-items-center px-3">
            <span>Conteneurs annulés</span>
            <span class="h5" >{{ ListesConteneurAnnule.length }}</span>
           
          </div>
          
        </div>
      </div>

    </div> -->
    <section>
        <div class="row">
            <select class="col-lg-4" v-model="filterSelect">
                <option value="annee">Année</option>
                <option value="mois">Mois</option>
                <option value="jour">Jour</option>
            </select>
            <div class="col-lg-8">
            <n-date-picker v-model:value="timestamp" type="year"   clearable v-if="filterSelect == 'annee'" />
            <input type="date"  v-model="day" v-if="filterSelect == 'jour'">
        

            <input type="month" class="mx-2" v-model="month_debut" v-if="filterSelect == 'mois'">
            <input type="month"  v-model="month_fin" v-if="filterSelect == 'mois'">
            

            </div>
        </div>
      <div class="card-container">
        <div class="mt-4 p-2" v-if="filterSelect == 'mois'">
            <column-chart
              type="bar"
              thousands=","
              :stacked="true"
              empty="Pas de donnée."
              :dataset="{width:'90'}"
              :data="chartDataMois"
              xtitle="Mois"
              ytitle="Conteneur livré"
            ></column-chart>
          </div>
          <div class="mt-4 p-2" v-if="filterSelect == 'semaine'">
            <column-chart
              type="bar"
              thousands=","
              :stacked="true"
              empty="Pas de donnée."
              :dataset="{width:'90'}"
              :data="chartDataSemaine"
              xtitle="Semaine"
              ytitle="Conteneur livré"
            ></column-chart>
          </div>
          <div class="mt-4 p-2 w-50 mx-auto" v-if="filterSelect == 'jour'">
            <column-chart
              type="bar"
              thousands=","
              :stacked="true"
              empty="Pas de donnée."
              :data="chartDataJour"
              xtitle="Jour"
              ytitle="Conteneur livré"
            ></column-chart>
          </div>
          <div class="mt-4 p-2" v-if="filterSelect == 'annee'">
            <column-chart
              type="bar"
              thousands=","
              :stacked="true"
              empty="Pas de donnée."
              :dataset="{width:'90'}"
              :data="chartDataAnnee"
              xtitle="Mois"
              ytitle="Conteneur livré"
            ></column-chart>
          </div>
      </div>
    </section>
  </div>
</template>
<style scoped>
.card-container p {
  margin: 0;
}
.card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -16px;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  background: #fff;
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}
#components-tabs-demo-card-top .code-box-demo {
  padding: 24px;
  overflow: hidden;
  background: #f5f5f5;
}
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -8px;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
[data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
  background: #000;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  background: #141414;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #141414;
  border-color: #141414;
}
.count{
  width:20px;
  height:20px;
  background-color: rgb(83, 144, 165) !important;
}
input,.input{
    padding:1em !important;

}
</style>
