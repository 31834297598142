<script>
import $ from "jquery";
import Swal from "sweetalert2";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import axios from "axios";
const baseUrl = "http://backend-dexter.invest-ci.com/api/";
export default {
  name: "liste-type-logement",
  data() {
    return {
      listTypeLogement: [],
      showDetailProspectForModify: false,
      preloaderSpinner: false,
      preloader: false,
      preload: false,
      showWindowDelete: false,
      libelle_typeLogement: "",
      showDetailTypeLogement: false,
      idTypeLogement: "",
      labelAtDeleted:{},
    };
  },
  methods: {
    viewDetailsTypeLogement(id) {
      this.listTypeLogement.forEach((item) => {
        if (item.id === id) {
          this.libelle_typeLogement = item.label;
        }
      });
      this.idTypeLogement = id;
      this.showDetailTypeLogement = !this.showDetailTypeLogement;
    },
    closeWindowDetailTypeLogement() {
      this.idTypeLogement = "";
      this.showDetailTypeLogement = !this.showDetailTypeLogement;
    },
    SaveTypeLogement() {
      this.$router.push("/type-logement");
    },
    async modifyTypeLogement() {
      this.preloaderSpinner = true;
      let data = {
        label: this.libelle_typeLogement,
      };
      //console.log(data);
      //console.log("DATA SEND", data);
      await axios
        .put(baseUrl + "projects/" + this.idTypeLogement, data, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          //console.log("Reponse", response.data);

          if (response.data.status === true) {
            //console.log("Reponse", response.data);
            Swal.fire({
              icon: "success",
              title: "le type de logement a été modifié.",
              showConfirmButton: false,
              timer: 1500,
            });
            this.listTypeLogement.forEach((item) => {
              if (item.id == this.idTypeLogement) {
                item.label = this.libelle_typeLogement;
              }
            });
            this.preloaderSpinner = false;
            setTimeout(() => {
              this.showDetailTypeLogement = !this.showDetailTypeLogement;
            }, 1500);
          }
          if (response.data.status === false) {
            //console.log("ReponseModify", response.data);
            const monObjet = response.data.message
            let tableau = []
            for (let propriete in monObjet) {
  if (Array.isArray(monObjet[propriete])) {
     tableau = monObjet[propriete];
    //console.log(tableau);
  }else{
    tableau.push(response.data.message)
  }
}
            Swal.fire({
              icon: "error",
              title: `${tableau.toString()}`, 
              showConfirmButton: false,
              timer: 1500,
            });
          }
          this.preloaderSpinner = false;
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
              icon: "error",
              title: err.response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          this.preloaderSpinner = false;
        });
    },
    ListeTypeLogement() {
      this.preloader = true;
      axios
        .get(baseUrl + "type-housings", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          //console.log("response", response);
          this.listTypeLogement = response.data.data;
          let printCounter = 0;
          //console.log("Liste", this.listTypeLogement);
          setTimeout(function () {
            $("#MyTableData").DataTable({
              pagingType: "full_numbers",
              pageLength: 10,
              processing: true,
              dom: "Bfrtip",
                      buttons: [
        {
            extend: 'copy',
            text: 'Copier les données',
            messageTop: function () {
                    printCounter++;
 
                    if ( printCounter === 1 ) {
                      Swal.fire({
              icon: "success",
              title: 'Les données ont été copiées dans le presse-papiers.',
              showConfirmButton: false,
              timer: 1500,
            });
                     
                    }
                    else {
                      Swal.fire({
              icon: "info",
              title: 'Les données ont déjà été copiées dans le presse-papiers.',
              showConfirmButton: true,
            });
                    }
                } 
        },
        {
          extend: 'csv',
  text: 'Exporter en fichier excel',
  fieldSeparator: ';',
  fieldBoundary: '"',
  bom: true,
  exportOptions: {
    columns: [0,1,2,3,4,6]
  }
        },
        {
            extend: 'print',
            text: 'Imprimer les données' 
        }
    ],
              order: [],
              language: {
                décimal: "",
                emptyTable: "Aucune donnée disponible dans le tableau",
                infoEmpty: "Showing 0 to 0 of 0 entries",
                info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "Afficher les entrées du _MENU_",
                loadingRecords: "Loading...",
                processing: "Processing...",
                search: "Chercher :",
                stateSave: true,
                zeroRecords: "Aucun enregistrement correspondant trouvé",
                paginate: {
                  first: "Premier",
                  last: "Dernier",
                  next: "Suivant",
                  previous: "Précédent",
                },
                aria: {
                  sortAscending: ": activate to sort column ascending",
                  sortDescending: ": activate to sort column descending",
                },
              },
            });
          }, 10);
          this.preloader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    wantDeleteType(id) {
      this.idTypeLogement = id;
      let i;
      for(i=0;i<this.listTypeLogement.length;i++){
        if(this.listTypeLogement[i].id == id){
          this.labelAtDeleted = this.listTypeLogement[i]
          //console.log("element",this.listTypeLogement[i])
        }
      }
      this.showWindowDelete = !this.showWindowDelete;
    },
    AnnuleDeleteType() {
      this.idTypeLogement = "";
      this.showWindowDelete = !this.showWindowDelete;
    },
    deleteTypeLogement() {
      axios
        .delete(baseUrl + "type-housings/" + this.idTypeLogement, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          //console.log(response);
          if (response.data.status === true) {
            //console.log("Reponse", response.data);
            Swal.fire({
              icon: "success",
              title: "type supprimé.",
              showConfirmButton: false,
              timer: 1500,
            });
            const itemAtdelete = this.listTypeLogement.findIndex(
              (item) => item.id == this.idTypeLogement
            );
            this.listTypeLogement.splice(itemAtdelete, 1);
            this.preloaderSpinner = false;
            setTimeout(() => {
              this.showWindowDelete = !this.showWindowDelete;
            }, 1500);
          }
          if (response.data.status === false) {
            //console.log("ReponseModify", response.data);
            const monObjet = response.data.message
            let tableau = []
            for (let propriete in monObjet) {
  if (Array.isArray(monObjet[propriete])) {
     tableau = monObjet[propriete];
    //console.log(tableau);
  }else{
    tableau.push(response.data.message)
  }
}
            Swal.fire({
              icon: "error",
              title: `${tableau.toString()}`, 
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.ListeTypeLogement();
  },
};
</script>
<template>
  <div id="preloader" v-show="preload">
    <div class="preloader"><span></span><span></span></div>
  </div>
  <div class="confirmation_modifie" v-show="showWindowDelete">
    <div class="card">
      <h6 class="text-center text-secondary">
        Voulez-vous supprimer ce type de logement ?</h6>
        <h6 class="text-center">{{labelAtDeleted.label}}</h6>
      <div class="d-flex justify-content-center align-items-center">
        <div>
          <button
            class="btn bg-dark text-light mx-2"
            @click.prevent="deleteTypeLogement"
          >
            Oui
          </button>
          <button
            class="btn bg-danger mx-2 text-white"
            @click.prevent="AnnuleDeleteType"
          >
            Retour
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-9 col-md-12">
    <div class="dashboard-wraper">
      <div class="conteneur_modify" v-show="showDetailTypeLogement">
        <div class="content position-relative">
          <div
            class="ecriteau text-start d-flex justify-content-between align-items-center"
          >
            <h4>Détail du Type de logement</h4>
            <i
              class="bi bi-x-circle h1"
              @click.prevent="closeWindowDetailTypeLogement"
            ></i>
          </div>
          <div class="text-start px-3">
            <div class="submit-pages text-start">
              <div class="row container">
                <div class="form-group col-md-12 col-sm-12">
                  <div class="col-lg-12 col-md-12">
                    <form @submit.prevent="modifyTypeLogement">
                      <div class="submit-pages">
                        <!-- Basic Information -->
                        <div class="form-submit">
                          <div class="submit-section">
                            <div class="row">
                              <div class="form-group col-md-12">
                                <label class="text-dark">Libellé</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="libelle_typeLogement"
                                  required
                                />
                              </div>

                              <div>
                                <button
                                  class="btn bg-dark text-light rounded"
                                  type="submit"
                                >
                                  Enregistrer
                                  <span
                                    class="spinner-border spinner-border-sm ms-2"
                                    v-show="preloaderSpinner"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="form-submit position-relative"
        :class="preloader ? 'pre' : 'null'"
      >
        <div id="preloader" class="position-absolute" v-show="preloader">
          <div class="preloader"><span></span><span></span></div>
        </div>
        <h2 class="text-start text-light">Liste des types de logement</h2>
        <div class="text-end">
          <button class="btn bg-dark text-light m-2" @click.prevent="SaveTypeLogement">
            Enregistrer un nouveau type de logement
          </button>
        </div>
      </div>

      <div class="col-sm-12 dataTable" v-if="!preloader">
        <table id="MyTableData" class="table text-center table-responsive">
          <thead>
            <tr class="text-center">
              <th class="bg-light text-center">Libellé</th>

              <th class="bg-light text-center">Détail</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(type, index) in listTypeLogement" :key="index">
              <td>{{ type.label }}</td>
              <td class="text-center">
                <span class="d-flex justify-content-center align-items-center">
                  <i
                    class="bi bi-pencil mx-2"
                    @click.prevent="viewDetailsTypeLogement(type.id)"
                  ></i>
                  <i
                    class="bi bi-trash mx-2"
                    @click.prevent="wantDeleteType(type.id)"
                  ></i>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<style scoped>
#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: rgba(244, 242, 242, 0);
  display: flex;
  justify-content: center;
  place-items: center;
}
.bg-danger,
.bg-danger:hover {
  color: white !important;
}
strong {
  font-size: 0.9em !important;
  color: gray;
  padding: 0 0 0.5em 0;
}
h6 {
  margin: 0 0 1em 0 !important;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}
[type="radio"]:checked + label:after {
  opacity: 0 !important;
}
.circuit {
  padding: 0 !important;
}
.card {
  padding: 1em 3em;
}
.bg-primary {
  background-color: rgb(55, 137, 167) !important;
  border: 2px solid rgb(55, 137, 167) !important;
  color: white !important;
}
.bi-eye,
.bi-x-circle,
.bi-pencil,
.bi-trash {
  cursor: pointer;
}
.pre {
  padding: 0 0 15em 0;
}
.badge {
  color: white !important;
}
.table {
  border: 1px solid black;
}
td {
  border-right: 2px solid black;
}

.confirmation_modifie {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.close_modal {
  position: absolute;
  top: 1em;
  right: 1em;
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index: 9999;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.submit-pages {
  padding: 1em 3em;
}
.text-dark{
  color: black  !important;
}
</style>
