<script>
import axios from "axios";
const baseUrl = "http://backend-dexter.invest-ci.com/api/";
import LienConnected from '../../class_help_me'
export default {
  name: "PromotionsVue",
  data() {
    return {
      message: "",
      objet: "",
      listUser:[],
      users:"",
      preloader: false,
      dataObject:new LienConnected('roles',this.$store.state.token)
    };
  },
  methods: {
    async sendPromotions() {
      try {
        this.preloader = true;
        let data = {
          objet:this.objet,
          texte:this.message,
          groupe:this.users
        }
        console.log(data)
        const response = await axios.post(baseUrl +"messages",data
        ,{headers: {
          Authorization: "Bearer " + this.$store.state.token,
        }})
        console.log(response);
        this.preloader = false;
      } catch (error) {
        this.preloader = false;
        console.log("error", error);
      }
    },
    async getListRole(){
     
      try{
        const allList = await this.dataObject.getAllData()
        console.log("allList",allList)
        this.listUser = allList.data.data.filter(item=>item.label === "Client" || item.label === "Propriétaire")
      }catch(error){
        console.log(error)
      }

    },
  },
  created(){
    this.getListRole()
  }
};
</script>
<template>
  <div class="col-lg-9 col-md-12">
    <div class="dashboard-wraper">
      <div class="form-submit position-relative">
       
        <h2 class="text-start text-light">Promotions</h2>
      </div>

      <div class="form-group col-md-12 text-start">
        <label>Objet</label>
        <input type="text" class="form-control" v-model="objet" />
        <label class="mt-3">Message</label>
        <textarea
          class="form-control"
          v-model="message"
          name=""
          id=""
          cols="30"
          rows="10"
        >
        </textarea>
        <div class="form-group col-md-6 text-start">
          <p class="text-light">Utilisateurs</p>
                <select class="form-control" v-model="users">
                  <option value="" >Sélectionner un profil</option>
                  <option :value="item.id" v-for="item in listUser"
                  :key="item.id"
                  >{{item.label}}</option>
                </select>
         
        </div>
      </div>
      
      <div class="text-end">
        <button
          class="btn-lg bg-dark text-light"
          @click.prevent="sendPromotions"
        >
          {{ preloader ? "chargement..." : "Envoyer" }}
        </button>
      </div>
    </div>
  </div>
</template>
