<script>

import $ from "jquery";
import Swal from "sweetalert2";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import LienConnected from "../../class_help_me";
export default {
  name: "liste-appartement-occupe",
  data() {
    return {
      showWindowDelete: false,
      moneyFormat: new Intl.NumberFormat("de-DE"),
      showDetailResidenceForModify: false,
      preload: false,
      visibleResidence: "",
      preloader: false,
      showDetailClient: false,
      showContrat: false,
      listAppartement: [],
      windowConfirmeSold:false,
     
      dataObject: "",
      detailProduct: null,
      idProduct: "",
      IdCategorie: "",
      preloaderSpinner: false,
      photoResidence: [],
      lienUrl: new LienConnected().baseUrl,
      spinner:false,
    };
  },
  methods: {
    putImageInArray(arrayPicture) {
      let compteur = 0;
      let images = [];
      while (compteur < arrayPicture.length) {
        images.push(
          this.lienUrl.replace("/api/", "/") +
            "public/public/image/" +
            arrayPicture[compteur].image
        );
        compteur++;
      }
      return images;
    },
    async modifierStatutHome(status, idHome) {
      this.spinner = true
      //console.log("idHome", idHome);
      //console.log("status", status);
      try {
        switch(status){
        case "Disponible": 
        status == "Disponible"
        break;
        case "Indisponible": 
        status == "Indisponible"
        break;
        default:
        status == "Louer";
        }
        let data = {
          status:status,
        };
        //console.log("STATUS", data);
        this.helpMeGetList("updateStatus");
        const allList = await this.dataObject.modifyDataWithPost(data, idHome,status);
        //console.log("allList", allList);
        if (allList.data.status === true) {
          //console.log(allList);
          this.getListAppartementOccupe();
        
          this.windowConfirmeSold = false
        }

        //console.log("NEW LIST", this.listResidenceDisponible);
        this.spinner = false
      } catch (error) {
        console.log(error);
        this.spinner = false
      }
    },
    helpMeGetList(route) {
      this.dataObject = new LienConnected(route, this.$store.state.token);
    },
    async getListAppartementOccupe() {
      try {
        this.preloader = true;
        this.helpMeGetList("getLocateMaisons");
        const allList = await this.dataObject.getAllData();
        //console.log("allList",allList)
        if (allList.data.status === true) {
          let list = [];
          list = allList.data.data;

          list.forEach((item) => {
            
            item.photoPropriete = this.putImageInArray(item.photos) ;
            item.IdHome = item.id;
          });
          this.preloader = false;
          this.listAppartement = list;
          setTimeout(function () {
        let printCounter = 0;
        $("#MyTableData").DataTable({
          pagingType: "full_numbers",
          pageLength: 10,
          processing: true,
          dom: "Bfrtip",
          buttons: [
            {
              extend: "copy",
              text: "Copier les données",
              messageTop: function () {
                printCounter++;

                if (printCounter === 1) {
                  Swal.fire({
                    icon: "success",
                    title:
                      "Les données ont été copiées dans le presse-papiers.",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                } else {
                  Swal.fire({
                    icon: "info",
                    title:
                      "Les données ont déjà été copiées dans le presse-papiers.",
                    showConfirmButton: true,
                  });
                }
              },
            },
            {
              extend: "csv",
              text: "Exporter en fichier excel",
              fieldSeparator: ";",
              fieldBoundary: '"',
              bom: true,
              exportOptions: {
                columns: [0],
              },
            },
            {
              extend: "print",
              text: "Imprimer les données",
            },
          ],
          order: [],
          language: {
            décimal: "",
            emptyTable: "Aucune donnée disponible dans le tableau",
            infoEmpty: "Showing 0 to 0 of 0 entries",
            info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
            infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
            infoPostFix: "",
            thousands: ",",
            lengthMenu: "Afficher les entrées du _MENU_",
            loadingRecords: "Loading...",
            processing: "Processing...",
            search: "Chercher :",
            stateSave: true,
            zeroRecords: "Aucun enregistrement correspondant trouvé",
            paginate: {
              first: "Premier",
              last: "Dernier",
              next: "Suivant",
              previous: "Précédent",
            },
            aria: {
              sortAscending: ": activate to sort column ascending",
              sortDescending: ": activate to sort column descending",
            },
          },
        });
      }, 10);
         
        }
      } catch (error) {
        console.log(error);
      }
    },

    // async getListAppartementOccupe() {
    //   this.listAppartement = await this.getAllListAppartement(
    //     "getUnpayMaisons"
    //   );
   
    // },
   
   

    viewDetailsHome(id, list) {
    
      list.forEach((item) => {
        if (item.id == id) {
          //console.log(item);
          this.detailProduct = item;
          //console.log(item.image);
          this.photoResidence = this.putImageInArray(item.photos);
        }
      });

      this.idProduct = id;
      this.showDetailResidenceForModify = !this.showDetailResidenceForModify;
    },
    closeWindowDetailResidenceForModify() {
      this.showDetailResidenceForModify = !this.showDetailResidenceForModify;
    },
    sellHome(id){
      this.windowConfirmeSold = !this.windowConfirmeSold
      this.idHome = id
    },
    verifyIfRoleInclus(role){
      const {roles} = JSON.parse(localStorage.getItem("user"))
        return roles.some(item=>item.label === role)
       }
  },
  mounted() {
    this.getListAppartementOccupe();
  },
};
</script>
<template>
  <div class="confirmation_modifie" v-show="windowConfirmeSold">
    <div class="card">
      <h6 class="text-center text-secondary ">
        Veuillez-confirmer ?</h6>
      
      <div class="d-flex justify-content-center align-items-center">
       
        <div>
          <button
          class="btn btn-all  mx-2 fw-bold bg-dark" 
          @click.prevent="modifierStatutHome('Disponible',this.idHome)"
        >
          Confirmer
          <span class="spinner-border spinner-border-sm ms-2"
          v-show="spinner"  role="status" aria-hidden="true"></span>
        </button>
        <button class="btn bg-danger mx-2 text-white fw-bold" @click.prevent="windowConfirmeSold = !windowConfirmeSold">
          Annuler
        </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-9 col-md-12">

    <div class="dashboard-wraper">
      <div class="conteneur_modify" v-if="showDetailResidenceForModify">
        <div class="content position-relative">
          <div
            class="ecriteau text-start d-flex justify-content-between align-items-center"
          >
            <h4>Détail de la maison</h4>
            <i
              class="bi bi-x-circle h1"
              @click.prevent="closeWindowDetailResidenceForModify"
            ></i>
          </div>
          <div class="text-start px-3 color_container">
            <div class="submit-pages text-start" v-if="detailProduct">
              <div class="row container product cont">
                <div class="form-group col-md-12 col-sm-12">
                  <div class="p-3 text-center bg-danger 
                  bg-gradient text-white container-occupe">
                    <h4 class="text-light fw-bold">Occupée</h4>
                  </div>
                  <div>
                    <h5 class="badge bg-info">Info Résidence</h5>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-sm-12">
                      <strong>Appartement</strong>
                      <h6 class="p-0 m-0">{{ detailProduct.name }}</h6>
                      <strong v-if="detailProduct.sale_type">Type de l'opération</strong>
                      <h6 class="p-0 m-0">{{ detailProduct.sale_type }}</h6>
                     
                      <strong>Prix (Fcfa) </strong>
                      <h6 class="p-0 m-0">
                        {{ moneyFormat.format(detailProduct.cost) }}
                      </h6>
                      <strong v-if="detailProduct.caution">Caution (Nbre de mois) </strong>
                      <h6 class="p-0 m-0">
                        {{ detailProduct.caution }}
                      </h6>
                      <strong>Située</strong>
                      <h6 class="p-0 m-0">
                        {{ detailProduct.municipality.city.name }}
                        {{ detailProduct.municipality.name }}
                      </h6>
                      <strong>Situé précisément</strong>
                      <h6 class="p-0 m-0">{{ detailProduct.adress }}</h6>
                      <strong v-if="detailProduct.description"
                        >Description</strong
                      >
                      <h6 class="p-0 m-0">{{ detailProduct.description }}</h6>
                      <strong>Galerie</strong>
                      <MazGallery
                        v-if="photoResidence.length"
                        :images="photoResidence"
                        :height="400"
                      />
                      <h6 v-else>Pas de photo</h6>
                    
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-12 col-sm-12">
                  <div>
                    <h5 class="badge bg-info">Info Proprietaire : <span class="text-danger">{{ detailProduct.registered_by }}</span> </h5>
                  </div>

                  <strong>Nom & prénoms</strong>
                  <h6 class="p-0 m-0">
                    {{ detailProduct.user.lastname }}
                    {{ detailProduct.user.firstname }}
                  </h6>
                  <strong>Email </strong>
                  <h6 class="p-0 m-0">{{ detailProduct.user.email }}</h6>
                  <strong>Télephone </strong>
                  <h6 class="p-0 m-0">{{ detailProduct.user.phone }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Bookmark Property -->
      <div
        class="form-submit position-relative"
        :class="preloader ? 'pre' : 'null'"
      >
        <div id="preloader" class="position-absolute" v-show="preloader">
          <div class="preloader"><span></span><span></span></div>
        </div>
        <h2 class="text-start text-light">Maisons occupées</h2>
        
      </div>
      <section>
        <div class="col-sm-12 dataTable" v-if="!preloader">
          <table id="MyTableData" class="table text-center table-responsive">
            <thead>
              <tr class="text-center">
                <th class="bg-light text-center">Maison</th>
                <th class="bg-light text-center">Image</th>
                <th class="bg-light text-center">Prix(Fcfa)</th>
                <th class="bg-light text-center">Située</th>
                <th class="bg-light text-center">Nbre de pièces</th>

                <th class="bg-light text-center">Propriétaire</th>
                <th class="bg-light text-center" 
                v-if="this.verifyIfRoleInclus('Commercial')"
                >Action</th>
                <th class="bg-light text-center">Détail</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(produit, index) in listAppartement" :key="index">
                <td
                  @click.prevent="viewDetailsHome(produit.id, listAppartement)"
                >
                  {{ produit.name }}
                </td>
                <td>
                  <n-image
                    width="50"
                    :src="
                      this.lienUrl.replace('/api/', '/') +
                      'public/public/image/' +
                      produit.photos[0].image
                    "
                    v-if="produit.photos.length"
                  />
                  <span class="badge bg-danger" v-else>x</span>
                </td>
                <td
                  @click.prevent="viewDetailsHome(produit.id, listAppartement)"
                >
                  {{ moneyFormat.format(produit.cost) }}
                </td>
                <td
                  @click.prevent="viewDetailsHome(produit.id, listAppartement)"
                >
                  {{ produit.municipality.city.name }},
                  {{ produit.municipality.name }}
                </td>
                <td
                  @click.prevent="viewDetailsHome(produit.id, listAppartement)"
                >
                  {{ produit.room }}
                </td>

                <td
                  @click.prevent="viewDetailsHome(produit.id, listAppartement)"
                >
                  {{ produit.user.lastname }} {{ produit.user.firstname }}
                </td>
                <td 
                v-if="this.verifyIfRoleInclus('Commercial')">
                    <button class="btn bg-dark text-light" @click="sellHome(produit.IdHome)">Rendre Disponible</button>
                   </td>
                
                
                <td class="text-center">
                  <span
                    class="d-flex justify-content-center align-items-center"
                  >
                    <i
                      class="bi bi-eye mx-2"
                      @click.prevent="
                        viewDetailsHome(produit.id, listAppartement)
                      "
                    ></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
     
    </div>
  </div>
</template>
<style scoped>
.container-occupe{
  transform: rotateZ(332deg) translateX(33px) translateY(220px);
  background-color: rgba(245, 40, 81, 0.392) !important;
  border-radius: 20px;
  width: 702px;
}

div.n-space {
  justify-content: center !important;
}

.listes {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.085);
  background-color: white;
  padding: 1em 0;
}

.lien {
  text-decoration: none;
  font-size: 1.3em;

  color: rgb(159, 158, 158);
}
.color,
.colorHistorique,
.colorNew {
  color: rgb(255, 255, 255) !important;
  border-radius: 5px;
  padding: 0 1em;
  background-color: rgb(33, 33, 75);
}
.text-dark {
  color: black !important;
}
.product .form-group {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.356);
  border-radius: 10px;
}
.bg-info {
  background-color: rgb(0, 0, 0) !important;
}
.addField {
  width: auto !important;
}
.delete {
  position: absolute;
  right: 0;
  cursor: pointer;
  width: 20px;
}
#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: rgba(244, 242, 242, 0);
  display: flex;
  justify-content: center;
  place-items: center;
}
.bg-danger,
.bg-danger:hover {
  color: white !important;
}
strong {
  font-weight: 900;
  font-size: 0.9em !important;
  color: gray;
  text-transform: uppercase;
  padding: 0 0 0.5em 0;
  text-decoration: underline;
}
h6 {
  margin: 0 0 1em 0 !important;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}
[type="radio"]:checked + label:after {
  opacity: 0 !important;
}
.circuit {
  padding: 0 !important;
}
.card {
  padding: 1em 3em;
}
.bg-primary {
  background-color: rgb(55, 137, 167) !important;
  border: 2px solid rgb(55, 137, 167) !important;
  color: white !important;
}
.bi-eye,
.bi-x-circle,
.bi-pencil,
.bi-envelope-paper,
.bi-trash {
  cursor: pointer;
}
.pre {
  padding: 0 0 15em 0;
}
.badge {
  color: white !important;
}
.table {
  border: 1px solid black;
}
td {
  border-right: 2px solid black;
}

.confirmation_modifie {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.close_modal {
  position: absolute;
  top: 1em;
  right: 1em;
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index: 9999;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.submit-pages {
  padding: 1em 3em;
}
.submit-pages_view {
  padding: 1em 3em;
}
</style>
../../utils