import { createStore } from "vuex";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";
import LienConnected from "../views/dashboard/class_help_me"

export default createStore({
  state: {
    showMenu:false,
    showPreload:false,
    Profil:null,
    user: JSON.parse(localStorage.getItem("user")),
    token: JSON.parse(localStorage.getItem("token")),
    // dataPropriete: new LienConnected("proprietes", this.$store.state.token),
    // dataReservation: new LienConnected("reservations", this.$store.state.token),
    // dataVisite: new LienConnected("visites", this.$store.state.token),
    spinner: false,
    activationCompte:false,
    lien:new LienConnected(),
    flagProprieteLocation:JSON.parse(localStorage.getItem("flagProprieteLocation"))|| "",
    flagResidenceLocation:JSON.parse(localStorage.getItem("flagResidenceLocation"))|| "",
    flagMaisonLocation:JSON.parse(localStorage.getItem("flagMaisonLocation"))|| "",
    flagReservation:localStorage.getItem("flagReservation")|| "",
    flagVisite:JSON.parse(localStorage.getItem("flagVisite"))|| "",
    flagVentePropriete:JSON.parse(localStorage.getItem("flagVentePropriete"))|| "",
    flagVenteMaison:JSON.parse(localStorage.getItem("flagVenteMaison"))|| "",
    flagVenteTerrain:JSON.parse(localStorage.getItem("flagVenteTerrain"))|| "",
    flagDemandeModification:JSON.parse(localStorage.getItem("flagDemande"))|| "",
  },
  getters: {},
  mutations: {
    
    CONNECT_USER(state, infoUser) {
      state.user = infoUser;
    },
  
    toogleMenu(state) {
      state.showMenu = !state.showMenu;
    },
    COMMIT_PROPRIETE_LOCATION(state,data) {
      state.flagProprieteLocation = data;
      localStorage.setItem("flagProprieteLocation", JSON.stringify(data));
    },
    COMMIT_PROPRIETE_VENTE(state,data) {
      state.flagVentePropriete = data;
      localStorage.setItem("flagVentePropriete", JSON.stringify(data));
    },
    COMMIT_RESIDENCE_LOCATION(state,data) {
      state.flagResidenceLocation = data;
      localStorage.setItem("flagResidenceLocation",JSON.stringify(data));
    },
    COMMIT_MAISON_LOCATION(state,data){
      state.flagMaisonLocation = data;
      localStorage.setItem("flagMaisonLocation", JSON.stringify(data));
    },
    COMMIT_RESERVATION_LOCATION(state,data){
      state.flagReservation = data;
      localStorage.setItem("flagReservation", JSON.stringify(data));
    },
    COMMIT_VISITE(state,data){
      state.flagVisite = data;
      localStorage.setItem("flagVisite", JSON.stringify(data));
    },
    COMMIT_MAISON_VENTE(state,data){
      state.flagVenteMaison = data;
      localStorage.setItem("flagVenteMaison", JSON.stringify(data));
    },
    COMMIT_TERRAIN_VENTE(state,data){
      state.flagVenteTerrain = data;
      localStorage.setItem("flagVenteTerrain", JSON.stringify(data));
    },
    COMMIT_CHANGE_RESIDENCE_FLAG(state){
      state.flagResidenceLocation--;
      state.flagProprieteLocation--;
      localStorage.setItem("flagResidenceLocation",JSON.stringify(state.flagResidenceLocation))
      localStorage.setItem("flagProprieteLocation", JSON.stringify(state.flagProprieteLocation));
    },
    COMMIT_CHANGE_MAISONLOCATION_FLAG(state){
      state.flagMaisonLocation--;
      state.flagProprieteLocation--;
      localStorage.setItem("flagResidenceLocation",JSON.stringify(state.flagMaisonLocation))
      localStorage.setItem("flagProprieteLocation", JSON.stringify(state.flagProprieteLocation));
    },
    COMMIT_CHANGE_RESERVATION_FLAG(state){
      state.flagReservation--;
      localStorage.setItem("flagReservation", JSON.stringify(state.flagReservation));
    },
    COMMIT_CHANGE_MAISONSELL_FLAG(state){
      state.flagVenteMaison --;
      state.flagVentePropriete--;
      localStorage.setItem("flagVenteMaison", JSON.stringify(state.flagVenteMaison));
      localStorage.setItem("flagVentePropriete", JSON.stringify(state.flagVentePropriete));
    },
    COMMIT_CHANGE_TERRAINSELL_FLAG(state){
      state.flagVenteTerrain--;
      state.flagVentePropriete--;
      localStorage.setItem("flagVenteTerrain", JSON.stringify(state.flagVenteTerrain));
      
      localStorage.setItem("flagVentePropriete", JSON.stringify(state.flagVentePropriete));
    },
    COMMIT_CHANGE_VISITE_FLAG(state){
      state.flagVisite--;
      localStorage.setItem("flagVisite", JSON.stringify(state.flagVisite));
    },
    COMMIT_DEMANDE(state,data){
      state.flagDemandeModification = data;
      localStorage.setItem("flagDemande", JSON.stringify(data));
    },
    COMMIT_DEMANDE_FLAG(state){
      state.flagDemandeModification--;
      localStorage.setItem("flagDemande", JSON.stringify(state.flagDemandeModification));
    }
  },

  actions: {
    async ConnectUser({commit ,state}, dataForConnect) {
      state.spinner = true
      try{
   const response = await axios.post(state.lien.baseUrl+"login", dataForConnect)

   if (response.data.status === true) {
    Swal.fire({
      icon: "success",
      title: "Connecté",
      showConfirmButton: false,
      timer: 1500,
    });
    commit("CONNECT_USER", response.data.data.user);
    //console.log("USER INFO : ", response);
    localStorage.setItem("user", JSON.stringify(response.data.data.user));
    localStorage.setItem(
      "token",
      JSON.stringify(response.data.data.access_token)
    );
    state.user = JSON.parse(localStorage.getItem("user"))
    state.token = JSON.parse(localStorage.getItem("token"))
    setTimeout(() => {
      router.push({ path: '/accueil' })
    },1500)
    state.spinner = false
  }
  }catch(error){
        Swal.fire({
          icon: "error",
          title: error,
          showConfirmButton: true,
        });
        state.spinner = false
      }
     
    },
    DeconnecterUser({state}) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("profil");
      state.user = "";
      state.token = "";
      state.Profil = "";
      
      Swal.fire({
        icon: "success",
        title: "Deconnexion",
        showConfirmButton: false,
        timer: 1500,
      });
    },
    async ModifyUser({ commit,state }, dataForConnect) {
      state.spinner = true
      try{
      const dataModify = await axios.post(state.lien.baseUrl+"updateusers"+'/'+state.user.id, dataForConnect, {
          headers: {
            Authorization: "Bearer " + state.token,
          },
        })
        const response = dataModify
    
      if(response.data.status === true){
        commit("CONNECT_USER", response.data.user);
            localStorage.setItem("user", JSON.stringify(response.data.user));
            Swal.fire({
              icon: "success",
              title: "Profil mis à jour",
              showConfirmButton: false,
              timer: 1500,
            });
            state.spinner = false
      }
     
      }catch(error){
        console.log(error);
        state.spinner = false
        Swal.fire({
          icon: "error",
          title: error.response.data.message,
          showConfirmButton: true,
        })
  
      }

    },
    async getAllNew({ commit, state }){
      const allPropriete = await axios.get(state.lien.baseUrl+"proprietes", {
        headers: {
          Authorization: "Bearer " + state.token,
        },
      });
      const allProprieteFilter = allPropriete.data.data.filter(item=>item.flag == 1 && item.types.label === "Location")
      const allProprieteLength = allProprieteFilter.length
   
      commit("COMMIT_PROPRIETE_LOCATION",allProprieteLength)
      commit("COMMIT_RESIDENCE_LOCATION",allProprieteFilter.filter(item=>item.types.label === "Location" && item.category.label === "Résidence").length)
      commit("COMMIT_MAISON_LOCATION",allProprieteFilter.filter(item=>item.types.label === "Location" && item.category.label === "Maison").length)
  
    },
    async getAllreservation({commit,state}){
      const allReservation = await axios.get(state.lien.baseUrl+"reservations", {
        headers: {
          Authorization: "Bearer " + state.token,
        },
      });
      commit("COMMIT_RESERVATION_LOCATION",allReservation.data.data1.filter(item=>item.flag == 1).length)
    
      // console.log("flagReservation",allReservation.data.data1.filter(item=>item.flag == 1).length)
    },
    async getAllVisites({commit,state}){
      const allVisite = await axios.get(state.lien.baseUrl+"visites", {
        headers: {
          Authorization: "Bearer " + state.token,
        },
      });
      commit("COMMIT_VISITE",allVisite.data.data.length)
      localStorage.setItem("flagVisite", JSON.stringify(allVisite.data.data.length));
      // console.log("flagVisite",allVisite.data.data.length)
    },
    async getAllProprieteSold({commit,state}){
      const allPropriete = await axios.get(state.lien.baseUrl+"proprietes", {
        headers: {
          Authorization: "Bearer " + state.token,
        },
      });
      const allProprieteFilter = allPropriete.data.data.filter(item=>item.flag == 1 && item.types.label === "Vente" )
      const allProprieteLength = allProprieteFilter.length
      // console.log("allProprieteLength",allProprieteLength)
      commit("COMMIT_PROPRIETE_VENTE",allProprieteLength)
      commit("COMMIT_MAISON_VENTE",allProprieteFilter.filter(item=>item.types.label === "Vente" && item.category.label === "Maison").length)
      commit("COMMIT_TERRAIN_VENTE",allProprieteFilter.filter(item=>item.types.label === "Vente" && item.category.label === "Terrain").length)
    },
    async getAllDemande({commit,state}){
      const allDemande = await axios.get(state.lien.baseUrl+"showrequestP", {
        headers: {
          Authorization: "Bearer " + state.token,
        },
      });
      commit("COMMIT_DEMANDE",allDemande.data.data.filter(item=>item.flag == 1).length)
      localStorage.setItem("flagDemande", JSON.stringify(allDemande.data.data.filter(item=>item.flag == 1).length));
      // console.log("flagDemande",allDemande.data.data.filter(item=>item.flag == 1).length)
    },
    
 seeAlarmResidence({commit}){
        commit("COMMIT_CHANGE_RESIDENCE_FLAG")
    },
    seeAlarmHomeLocation({commit}){
  commit("COMMIT_CHANGE_MAISONLOCATION_FLAG")
    },
    seeAlarmReservation({commit}){
      commit("COMMIT_CHANGE_RESERVATION_FLAG")
    },
    seeAlarmMaisonSell({commit}){
      commit("COMMIT_CHANGE_MAISONSELL_FLAG")
    },
    seeAlarmTerrainSell({commit}){
      commit("COMMIT_CHANGE_TERRAINSELL_FLAG")
    },
    seeAlarmVisite({commit}){
      commit("COMMIT_CHANGE_VISITE_FLAG")
    },
    seeAlarmDemandeModification({commit}){
      commit("COMMIT_DEMANDE_FLAG")
    }
  },

  modules: {},
});
