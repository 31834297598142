import axios from "axios";
import Swal from "sweetalert2";
class LienConnected {
  constructor(route, token) {
    this.baseUrl = "https://backend.dexter-immo.com/api/";
    this.route = route;
    this.token = token ? token:null;
  }
  async getAllData() {
    try {
      const response = await axios.get(this.baseUrl + this.route, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
        showConfirmButton: true,
      });
    }
  }

  async sendData(data) {
    try {
      const response = await axios.post(this.baseUrl + this.route, data, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      });

      return response;
    } catch (error) {
      console.log("ERROR8POST",error);

      // Swal.fire({
      //   icon: "error",
      //   title: error.response.data.message,
      //   showConfirmButton: true,
      // });
    }
  }
  async modifyDataWithPost(data,idItem,sayActiveOrDesactive=null) {
    try {
      const response = await axios.post(
        this.baseUrl + this.route + "/" + idItem,
        data,
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      );
      if(response.data.status === true){
        switch(sayActiveOrDesactive){
        case 'Indisponible':
          sayActiveOrDesactive = 'Désactivé(e)'
          break;
          case 'Disponible':
          sayActiveOrDesactive = 'Activé(e)'
          break;
          case 'Louer':
          sayActiveOrDesactive = 'Propriété Louée'
          break;
          case 'Vente':
          sayActiveOrDesactive = 'Propriété Vendue'
          break;
          default:
          sayActiveOrDesactive;

        }
        
        Swal.fire({
          icon: "success",
          title: sayActiveOrDesactive,
          showConfirmButton: true,
        });
      }
      return response;
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
        showConfirmButton: true,
      });
    }
  }

  async modifyData(data, idItem) {
    try {
      const response = await axios.put(
        this.baseUrl + this.route + "/" + idItem,
        data,
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
        showConfirmButton: true,
      });
    }
  }

  async deleteData(idItem) {
    try {
      const response = await axios.delete(
        this.baseUrl + this.route + "/" + idItem,
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
        showConfirmButton: true,
      });
    }
  }
  async sendMailForRebootPassword(data){
    try {
      const response = await axios.post(this.baseUrl + this.route,data);
      return response;
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
        showConfirmButton: true,
      });
    }
  }
}
export default LienConnected;
