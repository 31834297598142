<script>
export default {
    setup() {
        
    },
}
</script>
<template>
    <div class="col-lg-9 col-md-12">
        <div class="dashboard-wraper text-start">
        
            <!-- Basic Information -->
            <div class="form-submit">	
                <h4>Changer mon mot de passe</h4>
                <div class="submit-section">
                    <div class="row">
                    
                        <div class="form-group col-lg-12 col-md-6">
                            <label>Ancien mot de passe</label>
                            <input type="password" class="form-control">
                        </div>
                        
                        <div class="form-group col-md-6">
                            <label>Nouveau mot de passe</label>
                            <input type="password" class="form-control">
                        </div>
                        
                        <div class="form-group col-md-6">
                            <label>Confirmer mot de passe</label>
                            <input type="password" class="form-control">
                        </div>
                        
                        <div class="form-group col-lg-12 col-md-12">
                            <button class="btn bg-dark text-light rounded" type="submit">Sauvegarder</button>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>