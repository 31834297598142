<script>
import $ from "jquery";
import Swal from "sweetalert2";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import LienConnected from '../../class_help_me'
export default {
  name: "requeteTable",
  data() {
    return {
      showWindowDelete: false,
      moneyFormat: new Intl.NumberFormat("de-DE"),
      showDetailResidenceForModify: false,
      preload: false,
      visibleResidence:"",
      preloader: false,
      showDetailClient: false,
      showContrat: false,
      tableSearch: [],
      windowConfirmeSold:false,
      dataObject:"",
      detailSearch:null,
      idProduct: "",
      IdCategorie: "",
      preloaderSpinner:false,
      photoTerrain:[],
      photoPieces:[],
     
      tabulation:"disponible",
      lienUrl:new LienConnected().baseUrl,
      showDetailResidenceSearch:false,
      
    };
  },
  methods: {
    helpMeWithRoute(route){
    this.dataObject=new LienConnected(route,this.$store.state.token)
  },
  async getListTerrainApprouve(){
    try{
      this.preloader = true
    this.helpMeWithRoute('showSearches')
    const allList = await this.dataObject.getAllData()
    //console.log("showSearches",allList)
      if(allList.data.status === true){
       
        this.tableSearch = allList.data.data
        this.preloader = false
      
        setTimeout(function () {
        let printCounter = 0
            $("#MyTableData").DataTable({
              pagingType: "full_numbers",
              pageLength: 10,
              processing: true,
              dom: "Bfrtip",
                      buttons: [
        {
            extend: 'copy',
            text: 'Copier les données',
            messageTop: function () {
                    printCounter++;
 
                    if ( printCounter === 1 ) {
                      Swal.fire({
              icon: "success",
              title: 'Les données ont été copiées dans le presse-papiers.',
              showConfirmButton: false,
              timer: 1500,
            });
                     
                    }
                    else {
                      Swal.fire({
              icon: "info",
              title: 'Les données ont déjà été copiées dans le presse-papiers.',
              showConfirmButton: true,
            });
                    }
                } 
        },
        {
          extend: 'csv',
  text: 'Exporter en fichier excel',
  fieldSeparator: ';',
  fieldBoundary: '"',
  bom: true,
  exportOptions: {
    columns: [0]
  }
        },
        {
            extend: 'print',
            text: 'Imprimer les données' 
        }
    ],
              order: [],
              language: {
                décimal: "",
                emptyTable: "Aucune donnée disponible dans le tableau",
                infoEmpty: "Showing 0 to 0 of 0 entries",
                info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "Afficher les entrées du _MENU_",
                loadingRecords: "Loading...",
                processing: "Processing...",
                search: "Chercher :",
                stateSave: true,
                zeroRecords: "Aucun enregistrement correspondant trouvé",
                paginate: {
                  first: "Premier",
                  last: "Dernier",
                  next: "Suivant",
                  previous: "Précédent",
                },
                aria: {
                  sortAscending: ": activate to sort column ascending",
                  sortDescending: ": activate to sort column descending",
                },
              },
            });
          }, 10);
      }
    }catch(error){
      //console.log(error)
    }
   
  },
  iterateArr(arrayItem,idPropriete){
   let compteur = 0;
   let arrayElement = null
   while(compteur<arrayItem.length){
    if(arrayItem[compteur].id == idPropriete ){
      arrayElement = arrayItem[compteur]
    }
    compteur++;
   }
   return arrayElement;
  },
  seeDetailSearch(idPropriete){
    this.showDetailResidenceSearch = !this.showDetailResidenceSearch;
    this.detailSearch = this.iterateArr(this.tableSearch,idPropriete);
    //console.log("this.detailSearch",this.detailSearch)
  }
  },
  mounted() {
   this.getListTerrainApprouve()
  },
};
</script>
<template>
  
  
  <div class="col-lg-9 col-md-12">
    <div class="dashboard-wraper">
      
      <div class="conteneur_modify" v-if="showDetailResidenceSearch">
        <div class="content position-relative color_container">
          <div
            class="ecriteau text-start d-flex justify-content-between align-items-center"
          >
            <h4>Détail de la recherche</h4>
            <i
              class="bi bi-x-circle h1"
              @click.prevent="showDetailResidenceSearch =  !showDetailResidenceSearch"
            ></i>
          </div>
          <div class="text-start px-3">
            <div class="submit-pages text-start" v-if="detailSearch">
              <div class="row container product cont">
                <div class="form-group col-md-12 col-sm-12">
                  <div>
                    <h5 class="badge bg-info">Info Propriété</h5>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-sm-12">
                      <strong>Date</strong>
                      <h6 class="p-0 m-0">{{ new Date(detailSearch.created_at).toLocaleDateString('fr')}}</h6>
                        <strong>Propriété</strong>
                      <h6 class="p-0 m-0">{{detailSearch.search_data.name}}</h6>
                      <strong>Type de propriété</strong>
                      <h6 class="p-0 m-0"> {{detailSearch.search_data.type}}</h6>
                      <strong>Catégorie</strong>
                      <h6 class="p-0 m-0">{{detailSearch.search_data.category_id}}</h6>
                      <strong>Type de l'opération</strong>
                      <h6 class="p-0 m-0">{{ detailSearch.search_data.type_id }}</h6>
                      <strong >Lieu</strong>
                      <h6 class="p-0 m-0">{{ detailSearch.search_data.address}}</h6>
                      <strong >Prix minimun</strong>
                      <h6 class="p-0 m-0">{{ detailSearch.search_data.minPrix }}</h6>
                      <strong >Prix maximun</strong>
                      <h6 class="p-0 m-0">{{ detailSearch.search_data.maxPrix}}</h6>
                      <strong>Superficie</strong>
                      <h6 class="p-0 m-0">{{ detailSearch.search_data.area }}</h6>
                      <strong>Document</strong>
                      <h6 class="p-0 m-0">{{ detailSearch.search_data.document }}</h6>
                      <strong>Nbre de pièces</strong>
                      <h6 class="p-0 m-0">{{ detailSearch.search_data.room }}</h6>
                      <strong>Nbre de personnes choisies</strong>
                      <h6 class="p-0 m-0">{{ detailSearch.search_data.person_max }}</h6>
                      <strong>Caution</strong>
                      <h6 class="p-0 m-0">{{ detailSearch.search_data.caution }}</h6>
                      
                
                    </div>
                  
                  </div>
                </div>
                <div
                  class="form-group col-md-12 col-sm-12"
                 
                >
                  <div>
                    <h5 class="badge bg-info">Info Client</h5>
                  </div>

                  <strong>Nom & prénoms</strong>
                  <h6 class="p-0 m-0">{{ detailSearch.user.lastname}} {{ detailSearch.user.firstname}}</h6>
                  <strong>Email </strong>
                  <h6 class="p-0 m-0">{{ detailSearch.user.email }}</h6>
                  <strong>Télephone </strong>
                  <h6 class="p-0 m-0">{{ detailSearch.user.phone }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Bookmark Property -->
      <div
        class="form-submit position-relative"
        :class="preloader ? 'pre' : 'null'"
      >
        <div id="preloader" class="position-absolute" v-show="preloader">
          <div class="preloader"><span></span><span></span></div>
        </div>
        <h2 class="text-start text-light">Requêtes</h2>
        
       
      </div>
      <section>
        <div class="col-sm-12 dataTable" v-if="!preloader">
          <table id="MyTableData" class="table text-center table-responsive">
            <thead>
              <tr class="text-center">
                <th class="bg-light text-center">Date</th>
                <th class="bg-light text-center">Nom</th>
                <th class="bg-light text-center">Type de propriété</th>
                <th class="bg-light text-center">Catégorie</th>
                <th class="bg-light text-center">Type de l'opération</th>
                <th class="bg-light text-center">Lieu</th>
                <th class="bg-light text-center">Prix minimum(Fcfa)</th>
                <th class="bg-light text-center">Prix maximun(Fcfa)</th>
                <th class="bg-light text-center">Superficie</th>
                <th class="bg-light text-center">Équipements</th>
                <th class="bg-light text-center">Document</th>
                <th class="bg-light text-center">Pièces</th>
              
                <th class="bg-light text-center">Résident</th>
                <th class="bg-light text-center">Caution</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(propriete, index) in tableSearch" :key="index">
                <td @click="seeDetailSearch(propriete.id)">{{new Date(propriete.created_at).toLocaleDateString('fr')}}</td>
                <td @click="seeDetailSearch(propriete.id)">
                    
                <span v-if="propriete.search_data.name">{{propriete.search_data.name}}</span>
                <span class="badge bg-danger" v-else>x</span>
            </td>
                <td @click="seeDetailSearch(propriete.id)">

                    <span v-if="propriete.search_data.type">{{propriete.search_data.type}}</span>
                    <span class="badge bg-danger" v-else>x</span>
                </td>
                <td @click="seeDetailSearch(propriete.id)">
                    <span v-if="propriete.search_data.category_id">{{propriete.search_data.category_id}}</span>
                    <span class="badge bg-danger" v-else>x</span>
                    </td>
                <td @click="seeDetailSearch(propriete.id)">
                    <span v-if="propriete.search_data.type_id">{{propriete.search_data.type_id}}</span>
                    <span class="badge bg-danger" v-else>x</span>
                    </td>
                <td @click="seeDetailSearch(propriete.id)">
                    <span v-if="propriete.search_data.address">{{propriete.search_data.address}}</span>
                    <span class="badge bg-danger" v-else>x</span>

                   
                </td>
                <td @click="seeDetailSearch(propriete.id)">
                    <span v-if="propriete.search_data.minPrix">{{propriete.search_data.minPrix}}</span>
                    <span class="badge bg-danger" v-else>x</span>
                   </td>
                <td @click="seeDetailSearch(propriete.id)">
                    <span v-if="propriete.search_data.maxPrix">{{propriete.search_data.maxPrix}}</span>
                    <span class="badge bg-danger" v-else>x</span>

                   </td>
                <td @click="seeDetailSearch(propriete.id)">
                    <span v-if="propriete.search_data.area">{{propriete.search_data.area}}</span>
                    <span class="badge bg-danger" v-else>x</span>
                    </td>
                <td @click="seeDetailSearch(propriete.id)">
                    
                    <span v-if="propriete.search_data.comodity_id">{{propriete.search_data.comodity_id}}</span>
                    <span class="badge bg-danger" v-else>x</span>
                    
                    </td>
                <td @click="seeDetailSearch(propriete.id)">
                    <span v-if="propriete.search_data.document">{{propriete.search_data.document}}</span>
                    <span class="badge bg-danger" v-else>x</span>
                    
                    </td>
                <td @click="seeDetailSearch(propriete.id)">
                    
                    <span v-if="propriete.search_data.room">{{propriete.search_data.room}}</span>
                    <span class="badge bg-danger" v-else>x</span>
                   </td>
             
         <td @click="seeDetailSearch(propriete.id)">
            <span v-if="propriete.search_data.person_max">{{propriete.search_data.person_max}}</span>
            <span class="badge bg-danger" v-else>x</span>
            
            </td>
         <td @click="seeDetailSearch(propriete.id)">
            
            <span v-if="propriete.search_data.caution">{{propriete.search_data.caution}}</span>
            <span class="badge bg-danger" v-else>x</span>
            
           </td>
                
              </tr>
            </tbody>
          </table>
        </div>
      </section>
   
    

     
    </div>
  </div>
</template>
<style scoped>
tbody tr div.n-space{
  justify-content:center !important;
}
.listes {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.085);
  background-color: white;
  padding:1em 0;
}

.lien {
  text-decoration: none;
  font-size: 1.3em;

  color: rgb(218, 216, 216);
}
.lien:hover {
  color: black;
}
.color,
.colorHistorique,
.colorNew{
  color: rgb(255, 255, 255) !important;

  border-radius:5px;
  padding:0 1em;
  background-color: rgb(33, 33, 75);
}
.text-dark{
  color:black !important;
}
.product .form-group{
  box-shadow:1px 1px 3px rgba(0, 0, 0, 0.356); 
  border-radius:10px;
}
.bg-info{
background-color:rgb(0, 0, 0) !important;
}
.addField{
  width:auto !important;
}
.delete{
  position:absolute;
  right:0;
  cursor: pointer;
  width:20px;
}
#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: rgba(244, 242, 242, 0);
  display: flex;
  justify-content: center;
  place-items: center;
}
.bg-danger,
.bg-danger:hover {
  color: white !important;
}
strong {
  font-weight: 900;
  font-size: .9em !important;
  color: gray;
  text-transform: uppercase;
  padding: 0 0 0.5em 0;
  text-decoration: underline;
}
h6 {
  margin: 0 0 1em 0 !important;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}
[type="radio"]:checked + label:after {
  opacity: 0 !important;
}
.circuit {
  padding: 0 !important;
}
.card {
  padding: 1em 3em;
}
.bg-primary {
  background-color: rgb(55, 137, 167) !important;
  border: 2px solid rgb(55, 137, 167) !important;
  color: white !important;
}
.bi-eye,
.bi-x-circle,
.bi-pencil,
.bi-envelope-paper,
.bi-trash {
  cursor: pointer;
}
.pre {
  padding: 0 0 15em 0;
}
.badge {
  color: white !important;
}
.table {
  border: 1px solid black;
}
td {
  border-right: 2px solid black;
}

.confirmation_modifie {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 80%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.close_modal {
  position: absolute;
  top: 1em;
  right: 1em;
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  z-index: 9999;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
}
.submit-pages {
  padding: 1em 3em;
}
.submit-pages_view {
  padding: 1em 3em;
}
</style>
