<script>
import LienConnected from "../class_help_me";
import { Chart, Grid,Tooltip, Responsive,Bar } from "vue3-charts";
import printJS from "print-js";
// import { AgChartsVue } from "ag-charts-vue3";

export default {
  name: "BienvenueView",
  components: {
    Chart,
    Grid,
    Tooltip,
    Responsive,
    Bar ,
    // AgChartsVue 
  }, 
  data() {
    return {
      options:null,
      user: this.$store.state.user,
      chiffreAffaire:"",
      spinnerConteneur:false,
      moneyFormat: new Intl.NumberFormat("de-DE"),

      DataType: "year",
      direction : 'horizontal',
      tooltipConfig:{
        name: { label: 'Date',color: 'black' },
        one_data:{label: 'nombre', color: 'red'}
      },
      axis: {
        primary: {
          type: "band",
        },
        secondary: {
          domain: ['dataMin', 'dataMax'],
          type: "linear",
          ticks: 8,
        },
      },
      margin : {
        left: 20,
      top: 20,
      right: 10,
      bottom: 10
    },
      spinner: false,
      chooseTime:"",
      timeDay:"",
      timeWeek:"",
      timeMonth:"",
      timeYear:"",
      timeCustomizeStart:null,
      timeCustomizeEnd:null,
      currentYear: new Date().getFullYear(),
      statisticUser:[{ name: "", one_data: 0}],
      statisticAllReservation:[{ name: "", one_data: 0}],
      statistiVisite:[{ name: "", one_data: 0}],
      statisticChiffreAffaire:[],
      statistiqueFournisseur:"",
      hiddenChiffre:false,
      preload:false,
      getData: [
    { year: '2009', visitors: 409 },
    { year: '2010', visitors: 229 },
    { year: '2011', visitors: 449 },
    { year: '2012', visitors: 466},
    { year: '2013', visitors: 487 },
    { year: '2014', visitors: 508 },
    { year: '2015', visitors: 480 },
    { year: '2016', visitors: 727 },
    { year: '2017', visitors: 715 },
    { year: '2018', visitors: 944 },
    { year: '2019', visitors: 236 },
  ],
    };
  },
  methods: {
    launchStatistique(){
      this.options = {
      autoSize: true,
      data: this.statisticChiffreAffaire, 
    
      title: {
        text: 'Total Visitors to Museums and Galleries',
        fontSize: 25,
        spacing: 25, 
      },
      series: [
        {
          type: 'column',
          xKey: 'month',
          yKey: 'total',
          fill: '#0084e7',
          strokeWidth: 0,
          shadow: {
            xOffset: 3,
          },
          label: {
            formatter: ({ value }) => (value == null ? '' : value.toFixed(0)),
            color: '#eeeeee',
            // formatter: ({ value }) => value,
          },
          tooltip: {
            renderer: ({ yValue, xValue }) => {
              return { title: xValue, content: yValue };
            },
          },
        },
        // {
        //   type: 'column',
        //   xKey: 'year',
        //   yKey: 'visitors',
        //   fill: '#0084e7',
        //   strokeWidth: 0,
     
        //   shadow: {
        //     xOffset: 3,
        //   },
          
        //   label: {
        //     // enabled: true,
        //     color: '#eeeeee',
        //     // fontSize:5,
        //     formatter: ({ value }) => (value == null ? '' : value.toFixed(0)),
        //     // formatter: ({ value }) => value,
        //   },
        //   tooltip: {
        //     renderer: ({ yValue, xValue }) => {
        //       return { title: xValue, content: yValue };
        //     },
        //   },
        // },
        // {
        //   type: 'column',
        //   xKey: 'month',
        //   yKey: 'total',
        //   fill: '#0084e8',
        //   strokeWidth: 0,
     
        //   shadow: {
        //     xOffset: 3,
        //   },
          
        //   label: {
        //     // enabled: true,
        //     color: '#eeeeee',
        //     // fontSize:5,
        //     formatter: ({ value }) => (value == null ? '' : value.toFixed(0)),
        //     // formatter: ({ value }) => value,
        //   },
        //   tooltip: {
        //     renderer: ({ yValue, xValue }) => {
        //       return { title: xValue, content: yValue };
        //     },
        //   },
        // },
      ],
      axes: [
        {
          type: 'category',
          position: 'bottom',
          title: {
            text: 'Year',
          },
        },
        {
          type: 'number',
          position: 'left',
          tick: {
            minSpacing: 5,
            maxSpacing: 5,
          },
          label: {
            formatter: ({ value }) => value,
          },
          
        },
      ],
    };
    },
  
    printDocu() {
      this.hiddenChiffre = true
      const style = `@media print { 
        *{
          font-size:3em;
        }
        .chiffre-affaire{
  width:100%;

}

.statistique_ecriteau{
  padding:.2em;
  text-align:center;
  background-color:red;
  font-weight:bold;
  color:white;
}
        .satistique_conteneur{
  background-color:rgb(228, 228, 228);
  position:relative;
  margin:.3em 0;
  border-radius:10px;
}
.conteneur-notice-statistique span{
  font-weight:bold;
  padding:0 .5em;
  border-radius:5px;
  margin:0 .2em;
}
.conteneur-notice-statistique{
  display:flex;
  justify-content:center;
  align-items:center;

}
.conteneur-notice-statistique span .span-one{
 
  background:#00e0ff69;
}
.conteneur-notice-statistique span .span-two{
  background:#40a9ffab;
}
.conteneur-notice-statistique span .span-three{
  background: #af0b6c;

}


       } `;
      this.preload = true;
      setTimeout(() => {
        printJS({
          printable: "printjs_doc",
          type: "html",
          style: style,
        });
        this.preload = false;
        this.hiddenChiffre = false;
      }, 1000);
    },
 
    async launchFilterStatistique(){
  
if(this.timeDay || this.timeWeek || this.timeMonth || this.timeYear){
  this.statisticAllReservation = await this.doFilter("nbreReservations",
  "nbreReservationAnnulés","nbreReservationConfirmés")
  // console.log("this.statisticAllReservation",this.statisticAllReservation)
   this.statisticUser = await this.doFilter("nbreClients","nbrePropriétaires")
   //console.log("this.statisticUser",this.statisticUser)
   this.statistiVisite = await this.doFilter("nbreVisits",
   "nbreVisitAnnulés",
   "nbreVisitEffectués")
   //console.log("this.statistiVisite",this.statistiVisite)
   this.statisticChiffreAffaire = await this.filterChiffreAffaire("chiffreAM")
   //console.log("this.statisticChiffreAffaire",this.statisticChiffreAffaire)
}

if(this.timeCustomizeStart){
  this.statisticAllReservation = await this.doFilter("nbreReservationsFP","nbreReservationAnnulésFP","nbreReservationConfirméFP")
  //console.log("this.statisticAllReservation",this.statisticAllReservation)
  this.statisticUser = await this.doFilter("nbreClientsFP","nbrePropriétairesFP")
  this.statistiVisite = await this.doFilter("nbreVisitsFP","nbreAnnulésFP","nbreVisitEffectuésFP")
}

},
    async doFilter(routeGlobale,otherRoute,route=null){
      this.spinnerConteneur = true
      
      let data = {
   jour: this.timeDay ? this.timeDay :null,
  semaine: this.timeWeek ? 1:null,
  date_debut: this.timeWeek ? this.weekStart:this.timeCustomizeStart,
  date_fin: this.timeWeek ? this.weekEnd:this.timeCustomizeEnd,
  mois: this.timeMonth ? this.timeMonth:null,
  annee: this.timeYear ? this.timeYear : null,
}
      //console.log("filtreData",data)
     
      try{

        let LaunchThree = null;
      
      const responseOne = await this.helpLaunchRouteForStatistic(
        routeGlobale,
        data
      );
      const responseTwo = await this.helpLaunchRouteForStatistic(
        otherRoute,
        data
      );
     
      if(responseOne.data.status === true 
      && responseTwo.data.status === true ) this.spinnerConteneur = false
      if(route){
      //  console.log('ROUTE',route)
        const responseThree = await this.helpLaunchRouteForStatistic(
        route,data
      );
      const statisticThree = this.renderTableauInObject(responseThree.data.detail)
      LaunchThree = this.helpMeShowStatistic(statisticThree)
     
      }
      const statisticOne = this.renderTableauInObject(responseOne.data.detail)
      
      const statisticTwo = this.renderTableauInObject(responseTwo.data.detail)

      const LaunchOne = this.helpMeShowStatistic(statisticOne)
       const launchTwo = this.helpMeShowStatistic(statisticTwo)
       //console.log("LaunchThree",LaunchThree)

        const AllDataAfterFilter = 
        this.helpmeWithBoucle(LaunchOne,launchTwo,LaunchThree)
      //console.log("AllDataAfterFilter",AllDataAfterFilter)
        return  AllDataAfterFilter;
       

      }catch(error){
        //console.log(error)
        this.spinnerConteneur = false
      }
     
      
    },
    async filterChiffreAffaire(route){
      let data = {
  annee: this.timeYear ? this.timeYear : 2024,
}
try{
  const responseFilterChiffreAffaire = await this.helpLaunchRouteForStatistic(
    route,data);
     
      return responseFilterChiffreAffaire.data.detail;
   
}catch(error){
//console.log(error)
}
    }
  ,
  cleanVariable(){
    this.timeCustomizeStart = null
    this.timeCustomizeEnd = null
     this.timeDay ="" 
  this.timeWeek="" 
  this.timeWeek=""
   this.timeWeek="" 
  this.timeMonth="" 
   this.timeYear=""
  },
   getDayOfWeek(e) {
      
      this.timeWeek = e.target.value;
      let year = parseInt(this.timeWeek.slice(0, 4), 10);
      let week = parseInt(this.timeWeek.slice(6), 10);
      let day = 1 + (week - 1) * 7;
      let dayOffset = new Date(year, 0, 1).getDay();
      dayOffset--;
      let days = [];
      for (let i = 0; i < 7; i++) {
        days.push(
          new Date(year, 0, day - dayOffset + i).toISOString().substring(0, 10)
        );
      }
      this.weekStart = days[0];
      this.weekEnd = days[days.length - 1];
    },
    async helpLaunchRouteForStatistic(route, data) {
      const lien = new LienConnected(route, this.$store.state.token);
      const info = await lien.sendData(data);
      //console.log("route",route)
      //console.log("info",info)
      const response = info;
      return response;
    },
    helpMeShowStatistic(dataStatistic){
    
      const transformDataInArray = [];

const entries = Object.entries(dataStatistic);

for (const [key, value] of entries) {
  transformDataInArray.push({name:key,one_data:value != null ? value:0});
}

      return transformDataInArray
    },
    renderTableauInObject(tableau) {
      let myCle = null
      const FilterTableau = tableau.reduce((acc, [cle, valeur]) => {
         myCle = new Date(cle).toLocaleDateString("fr-FR", {
          month: "long",
          year: "numeric",
        });
        if(this.timeWeek || this.timeDay || this.timeMonth){
           myCle = new Date(cle).toLocaleDateString("fr-FR", {
            weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
        });
       
        }
       
        acc[myCle] = valeur;
        return acc;
      }, {});
       //console.log("FilterTableau",FilterTableau)
      return FilterTableau;
    },
    helpmeWithBoucle(tableauOne,tableauTwo,tableauThree=null){
      let compteur=0;
        //console.log("tableauOne",tableauOne)
        // console.log("tableauTwo",tableauTwo)
        // console.log("tableauThree",tableauThree)
       while(compteur < tableauOne.length){
   
        if(
          tableauOne[compteur].name === tableauTwo[compteur].name || 
tableauOne[compteur].name === tableauThree[compteur].name){

          if(tableauThree){
            tableauOne[compteur].three_data =  tableauTwo[compteur].one_data
          tableauOne[compteur].four_data =  tableauThree[compteur].one_data
          }else{
            tableauOne[compteur].two_data = tableauTwo[compteur].one_data
          }
        }
        compteur = compteur+1;
       }
       //console.log("return",tableauOne)
       return tableauOne;
    },

    async getStatisticUser() {
      let data = {
        annee: 2024,
      };
      const response = await this.helpLaunchRouteForStatistic("nbreClientG",data);
      const response1 = await this.helpLaunchRouteForStatistic("nbrePropriétaireG",data)
     
      
       const statisticsClient = this.renderTableauInObject(response.data.detail)
       const statisticsFournisseur = this.renderTableauInObject(response1.data.detail)
     
     
       this.statistiqueFournisseur = this.helpMeShowStatistic(statisticsFournisseur)
  
       const statisticUser = this.helpMeShowStatistic(statisticsClient)

      this.statisticUser = this.helpmeWithBoucle(statisticUser,this.statistiqueFournisseur)

      
    },
   
    async getStatisticReservations() {
      try{
        let data = {
        annee: 2024,
      }
      this.spinnerConteneur = true
      const responseReservationsGlobales = await this.helpLaunchRouteForStatistic(
        "nbreReservationG",
        data
      );
      const responseReservationsAnnulees = await this.helpLaunchRouteForStatistic(
        "nbreReservationAnnuléG",
        data
      );
      const responseReservationsConfirmee = await this.helpLaunchRouteForStatistic(
        "nbreReservationConfirméG",
        data
      );
      
      const statisticReservationConfirmee = 
      this.renderTableauInObject(responseReservationsConfirmee.data.detail)
      const statisticReservationGlobales = 
      this.renderTableauInObject(responseReservationsGlobales.data.detail)
      const statisticReservationAnnulees = 
      this.renderTableauInObject(responseReservationsAnnulees.data.detail)
      if(responseReservationsGlobales.data.status === true){
        this.spinnerConteneur = false
      }
       const allReservations = this.helpMeShowStatistic(statisticReservationGlobales)
       const reservationCancel = this.helpMeShowStatistic(statisticReservationAnnulees)
       const reservationConfirmee = this.helpMeShowStatistic(statisticReservationConfirmee)
       //console.log("reservationConfirmee",reservationConfirmee)
       
       this.statisticAllReservation = this.helpmeWithBoucle(allReservations,reservationCancel,reservationConfirmee)
       //console.log("this.statisticAllReservation",this.statisticAllReservation)
      }catch(error){
        //console.log(error)
        this.spinnerConteneur = false
      }
     
    },
    async getStatisticVisite() { 
     try{
      let data = {
        annee: 2024,
      };
      const responseAfficherVisiteForYearCurrent = await this.helpLaunchRouteForStatistic(
        "nbreVisitG",
        data
      );
      const responseAfficherVisiteEffectueForYearCurrent = await this.helpLaunchRouteForStatistic(
        "nbreVisitEffectuéG",
        data
      );
      const responseAfficherVisiteCancelForYearCurrent = await this.helpLaunchRouteForStatistic(
        "nbreVisitAnnuléG",
        data
      );
      
      //console.log("responseAfficherVisiteEffectueForYearCurrent",responseAfficherVisiteEffectueForYearCurrent)
      //console.log("responseAfficherVisiteCancelForYearCurrent",responseAfficherVisiteCancelForYearCurrent)
      const statisticsAfficherVisiteForYearCurrent = this.renderTableauInObject(responseAfficherVisiteForYearCurrent.data.detail)
      const statisticsAfficherVisiteEffectueForYearCurrent = this.renderTableauInObject(responseAfficherVisiteEffectueForYearCurrent.data.detail)
      const statisticsAfficherVisiteCancelForYearCurrent = this.renderTableauInObject(responseAfficherVisiteCancelForYearCurrent.data.detail)


      const orderArrayAfficherVisiteEffectueForYearCurrent = this.helpMeShowStatistic(statisticsAfficherVisiteEffectueForYearCurrent)
      const orderArrayAfficherVisiteCancelForYearCurrent = this.helpMeShowStatistic(statisticsAfficherVisiteCancelForYearCurrent)
      this.statistiVisite = this.helpMeShowStatistic(statisticsAfficherVisiteForYearCurrent)
      

      this.statistiVisite = this.helpmeWithBoucle(this.statistiVisite,orderArrayAfficherVisiteEffectueForYearCurrent,orderArrayAfficherVisiteCancelForYearCurrent)

   

     }catch(error){
      //console.log(error)
     }
//console.log("this.statistiVisite",this.statistiVisite)
    
      
    },
   async getChiffreAffaireDexter(){
      const lien = new LienConnected("Frais_payment", this.$store.state.token);
      const info = await lien.getAllData();
      this.chiffreAffaire = info.data.somme_frais_dexter
      //console.log("Chiffre Affaire", info)
    },
    async getStatisticChiffreAffaire() { 
     try{
      const lien = new LienConnected('chiffreA', this.$store.state.token);
      const info = await lien.getAllData();
      const response = info;
    //console.log("getStatisticChiffreAffaire",response)
    this.statisticChiffreAffaire = response.data.detail
      // //console.log("responseAfficherVisiteEffectueForYearCurrent",responseAfficherVisiteEffectueForYearCurrent)
      // //console.log("responseAfficherVisiteCancelForYearCurrent",responseAfficherVisiteCancelForYearCurrent)
      // const statisticsAfficherVisiteForYearCurrent = this.renderTableauInObject(responseAfficherVisiteForYearCurrent.data.detail)
      // const statisticsAfficherVisiteEffectueForYearCurrent = this.renderTableauInObject(responseAfficherVisiteEffectueForYearCurrent.data.detail)
      // const statisticsAfficherVisiteCancelForYearCurrent = this.renderTableauInObject(responseAfficherVisiteCancelForYearCurrent.data.detail)


      // const orderArrayAfficherVisiteEffectueForYearCurrent = this.helpMeShowStatistic(statisticsAfficherVisiteEffectueForYearCurrent)
      // const orderArrayAfficherVisiteCancelForYearCurrent = this.helpMeShowStatistic(statisticsAfficherVisiteCancelForYearCurrent)
      // this.statistiVisite = this.helpMeShowStatistic(statisticsAfficherVisiteForYearCurrent)
      

      // this.statistiVisite = this.helpmeWithBoucle(this.statistiVisite,
      // orderArrayAfficherVisiteEffectueForYearCurrent,orderArrayAfficherVisiteCancelForYearCurrent)

   

     }catch(error){
      //console.log(error)
     }

    
      
    },
   
    
  },
  created() {
    this.getChiffreAffaireDexter()
    this.getStatisticUser();
    this.getStatisticReservations();
    this.getStatisticVisite();
    this.getStatisticChiffreAffaire();
    this.launchStatistique()

  },
};
</script>
<template>
  <div class="spinner-loader" v-show="preload">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
   </div>
  <div class="col-lg-9 col-md-12 bord">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 text-start p-2">
        <aside>
          <button
            class="btn mb-1 bg-dark text-light"
            @click="this.$router.push('/accueil')"
          >
            <i class="bi bi-arrow-left"></i> Accueil
          </button>
        </aside>
        <div class="tableau-bord px-3 position-relative bg-dark">
          <div>
            <h1 class="text-light">Tableau de bord</h1>
          <h5 class="text-light">
            Bienvenue
            <span class="profil_name"
              >{{ this.$store.state.user.lastname }}
              {{ this.$store.state.user.firstname }}</span
            >
          </h5>
          </div>
          <div>
            <h1 class="text-light">
              Revenus <i class="bi bi-cash h3"></i>
            </h1>
            
            <h1 class="text-light text-left">
               {{chiffreAffaire ? chiffreAffaire :0}} Fcfa</h1> 
          </div>
          
        </div>
       
        <div class="filter_statistic pt-1 d-flex justify-content-between align-items-center flex-2">
          <select v-model="chooseTime" @change="cleanVariable"  class="form-control">
           <option value="" disabled>Filtrer avec une période</option>
           <option value="year">Année</option>
           <option value="month">Mois</option>
           <option value="week">Semaine</option>
           <option value="day">Jour</option>
           <option value="customize">Période</option>
          </select>
          <div class="input_filter py-3 d-flex justify-content-between align-items-center">
           <input type="date" v-model="timeDay" v-if="chooseTime == 'day'" class="form-control">
           <input type="week" v-model="timeWeek" @change="getDayOfWeek" v-if="chooseTime == 'week'"  class="form-control">
           <input type="month" v-model="timeMonth" v-if="chooseTime == 'month'"  class="form-control">
           <input type="number" min="1" v-model="timeYear" v-if="chooseTime == 'year'"
             class="form-control">
          <div  v-if="chooseTime == 'customize'" class="d-flex justify-content-center align-items-center">
           
            
            <input type="date"
            v-model="timeCustomizeStart" class="form-control">
         
         
            <label class="text-light d-block fw-bold mx-2">Au</label>
            <input type="date" :min="timeCustomizeStart" 
            v-model="timeCustomizeEnd"  class="form-control">
         
          </div>
            <button class="btn bg-dark text-light" @click="launchFilterStatistique" v-if="chooseTime && !spinner">
              Filtre <i class="bi bi-funnel"></i>
            </button>
            <span
                class="spinner-border spinner-border-sm ms-2"
                v-show="spinner" role="status"
                aria-hidden="true"
              ></span>
          </div>
         </div>
        <p class="position-absolute printer">
          <i class="bi bi-printer-fill" @click="printDocu"></i>
        </p>
         
        <div class="conteneur_statistique" id="printjs_doc" >
         <h1 class="chiffre-affaire statistique_ecriteau" v-show="hiddenChiffre">
          DEXTER EN CHIFFRE
         </h1>
        <h1 class="chiffre-affaire" style="margin-top:1em;" v-show="hiddenChiffre ">
         Revenus : <br> {{chiffreAffaire}} Fcfa
      </h1>


    
     
     
      <div class="satistique_conteneur">
        <div class="spinner-conteneur">
              <span
              v-if="spinnerConteneur"
            class="spinner-border spinner-border-sm ms-2"
             role="status"
            aria-hidden="true"
          ></span>
            </div>
        <h4>Revenus</h4>
     
        <Responsive class="w-full">
          <template #main="{ width }">
            <Chart
              :size="{ width, height: 200 }"
              :data="statisticChiffreAffaire"
              :margin="margin"
              :direction="direction"
              :axis="axis"
            >
              <template #layers>
                <Grid strokeDasharray="2,2"/>
              
                <Bar
                :dataKeys="['month', 'total']"    
                :barStyle="{ fill: '#00e0ff69' }"
                    />
                <!-- <LabelsLayer :dataKeys="['name', 'one_data']" /> -->
                
              </template>
  
              <template #widgets>
                <Tooltip borderColor="#48CAE4" :config="tooltipConfig" />
              </template>
            </Chart>
          </template>
         
        </Responsive>
      </div>
          <div class="satistique_conteneur">
            
            <h4>Réservations</h4>
            <div class="d-flex justify-content-center align-items conteneur-notice-statistique">
              <span class="mx-2 ">
                <span class="square-color span-one"></span>
                Enregistrée</span>
              <span>
                <span class="square-color span-two"></span>Annulées</span>
              <span class="mx-2 span-three">
                <span class="square-color span-three"></span>Confirmées</span>
            </div>
           <div>
             <Responsive class="w-full">
              <template #main="{ width}">
                <Chart
                  :size="{ width, height: 200 }"
                  :data="statisticAllReservation"
                  :margin="margin"
                  :direction="direction"
                  :axis="axis"
                >
                  <template #layers>
                    <Grid strokeDasharray="2,2"/>
                    <Bar
                      :dataKeys="['name', 'one_data']"
                      
                      :barStyle="{ fill: '#00e0ff69' }"
                    />
                    
                  <Bar
                  :dataKeys="['name', 'three_data']"
                  
                  :barStyle="{ fill: '#40a9ffab' }"
                />
                <Bar
                :dataKeys="['name', 'four_data']"
                
                :barStyle="{ fill: '#af0b6c' }"
              />
                  
                    
                  </template>
      
                  <template #widgets>
                  
                    <Tooltip borderColor="#48CAE4" :config="{
                      name: { label: 'Date',color: 'black' },
                      one_data:{label: 'Enregistrées', color: 'black'},
                      four_data:{label: 'Confirmées', color: 'black'},
                      three_data:{label: 'Annulées', color: 'black'}
                     
                    }" />
                  </template>
                </Chart>
              </template>
             
            </Responsive>
           </div>
          </div>

          
         
          <div class="satistique_conteneur">
            <h4 >Utilisateurs</h4>
            <div class="d-flex justify-content-center align-items conteneur-notice-statistique">
              <span class="mx-2"> 
                <span class="square-color span-one"></span>
                Clients</span>
              <span>
                <span class="square-color span-two"></span>
                Propriétaires</span>
           
            </div>
            
            <Responsive class="w-full">
              <template #main="{ width }">
                <Chart
                  :size="{ width, height: 200 }"
                  :data="statisticUser"
                  :margin="margin"
                  :direction="direction"
                  :axis="axis"
                >
                  <template #layers>
                    <Grid strokeDasharray="2,2"/>
                    
                    <Bar
                    :dataKeys="['name', 'one_data']"
                    
                    :barStyle="{ fill: '#00e0ff69' }"
                  />
                  <Bar
                  :dataKeys="['name', 'two_data']"
                  
                  :barStyle="{ fill: '#40a9ffab' }"
                />
                   
                    
                  </template>
      
                  <template #widgets>
                    <Tooltip borderColor="#48CAE4" :config="{
                      name: { label: 'Date',color: 'black' },
                      one_data:{label: 'Clients', color: 'black'},
                      two_data:{label: 'Proprietaires', color: 'black'}
                    }" />
                  </template>
                </Chart>
              </template>
             
            </Responsive>
          </div>

         
          <div class="satistique_conteneur">
            <h4 >Visites</h4>
            <div class="d-flex justify-content-center align-items conteneur-notice-statistique">
              <span class="mx-2">
                <span class="square-color span-one"></span>Planifiées</span>
              <span >
                <span class="square-color span-two"></span>
                Effectuées</span>
              <span class="mx-2">
                <span class="square-color span-three"></span>
                Annulées</span>
            </div>
           
            <Responsive class="w-full">
              <template #main="{ width }">
                <Chart
                  :size="{ width, height: 200 }"
                  :data="statistiVisite"
                  :margin="margin"
                  :direction="direction"
                  :axis="axis"
                >
                  <template #layers>
                    <Grid strokeDasharray="2,2"/>
                    <Bar
                      :dataKeys="['name', 'one_data']"
                      
                      :barStyle="{ fill: '#00e0ff69' }"
                    />
                  <Bar
                  :dataKeys="['name', 'three_data']"
                  
                  :barStyle="{ fill: '#40a9ffab' }"
                />
                <Bar
                :dataKeys="['name', 'four_data']"
                
                :barStyle="{ fill: '#af0b6c' }"
              />
                  </template>
      
                  <template #widgets>
                    <Tooltip borderColor="#48CAE4" :config="{
                      name: { label: 'Date',color: 'black' },
                    one_data:{label: `Planifiées`, color: 'black'},
                      three_data:{label: `Effecutées`, color: 'black'},
                      four_data:{label: `Annulées`, color: 'black'}
                    }" />
                  </template>
                </Chart>
              </template>
             
            </Responsive>
          </div>
         
         

        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.square-color{
  width:30px;
  height:30px;
  border-radius:0px;
  margin:0 .5em;
  background-color:rgb(0, 0, 0);
}
.spinner-loader{
  position:fixed;
  width:100%;
  height:100%;
  top:0;
  left:0;
  display: flex;
  justify-content: center;
  place-items: center;
  background-color:rgba(0, 0, 0, 0.221);
  z-index: 99999;
}
.chiffre-affaire{
  position:absolute;
  z-index:-1;
  background: red;
  width:100%;
}

.conteneur-notice-statistique span{
  font-weight:bold;
  padding:0 .5em;
  border-radius:5px;
  color:gray;
}
.conteneur-notice-statistique span .span-one{
  background:#00e0ff69;
}
.conteneur-notice-statistique span .span-two{
  background:#40a9ffab;
}
.conteneur-notice-statistique span .span-three{
  background: #af0b6c;

}

.bi-printer-fill{
  background:black !important;
  padding:.5em;
  border-radius:10px;
  cursor:pointer;
}
.printer{
  right:1em;
  z-index:12;
}
select{
  width:40%;
}
.tableau-bord{
  padding:2em;
  color:white;
  display:flex;
  flex-wrap:wrap;
  gap:1em;
  justify-content:space-between;
}
.satistique_conteneur{
  background-color:rgb(228, 228, 228);
  margin:1em 0;
  padding:1em;
  position:relative;
  border-radius:10px;
}
h4{
  margin:0;
}
.chiffre_affaire div{
  background:orange;
  height:auto;
  padding:1em;
}

.spinner-conteneur{
  position:absolute;
  margin-left:50%;
  transform:translateX(-50%);
}
.satistique_conteneur .spinner-border-sm {
  --bs-spinner-width: 2rem !important;
  --bs-spinner-height: 2rem  !important;
  --bs-spinner-border-width: 0.2em ;
   color:black  !important;
}
.w-full{
  color:rgb(9, 8, 8);
  font-weight:bold;
  margin:0 auto;
}









.spinner {
  margin-left: 50%;
  margin-top: 20%;
}

.input_filter input{
  width:100%;
  padding:1em;
  display:inline-block;
  margin-right:1em;
}




.hover-me:hover,
.hover-me:active {
  background: orange !important;
}
</style>
